import { ICellRendererParams, ValueFormatterParams } from '@plarin/inputs';
import { currency, int, percent2, percent3, shortDate, shortDateTime } from '@plarin/utils';
import { TMetricGroup, TYDMetricGroup } from '../../../types/metrics';

export const defRendererSelector =
  (item: TMetricGroup['fields'][0] | TYDMetricGroup['fields'][0]) => (params: ICellRendererParams) => {
    if (item.math_default === 'avg' && params.node.rowPinned) {
      return { component: 'DefaultAvgRenderer', params: { ...params, type: item.type } };
    }
    if (item.math_default === 'sum' && params.node.rowPinned) {
      return { component: 'DefaultSumRenderer', params: { ...params, type: item.type } };
    }

    if (params.node.group) {
      return { component: 'DefaultGroupRenderer' };
    }
    return undefined;
  };

export const getPercentType = (type: string, roundDigits?: number): string => {
  if (type === 'percent') {
    if (roundDigits === 2) return 'percent2';
    if (roundDigits === 3) return 'percent3';
  }
  return type;
};

export const formatValues = (props: ValueFormatterParams, type: string, round_digits?: number): string => {
  if (typeof props.value === 'string' && props.value.includes('<br />')) {
    return props.value;
  }
  switch (type) {
    case typeof props.value != 'string' && 'currency':
      return currency(props.value, props.data.accountCurrency);
    case 'int':
      return int(props.value);
    case 'percent3':
      return percent3(props.value);
    case 'percent2':
      return percent2(props.value);
    case 'date':
      return shortDate(props.value || '--');
    case 'datetime':
      return shortDateTime(props.value || '--');
    case 'datetime_short':
      return shortDateTime(props.value || '--');
    case 'str':
      return props.value ? props.value : '--';
    case 'json':
      // @ts-ignore
      return props.value && props.column?.colId === 'issues' ? props.value[0].code : '--';
    case 'float':
      return props.value ? (+props.value).toFixed(round_digits) : '--';
    default:
      return props.value;
  }
};
