export enum MTTypesEnum {
  agency = 'Агентство',
  manager = 'Менеджер',
  client = 'Рекламодатель',
  standalone = 'Рекламодатель',
}

export enum MTCabinetsConnectionEnum {
  agency = 'Через агентство',
  manager = 'Через менеджера',
  client = 'Прямое подключение',
  standalone = 'Прямое подключение',
}

export enum VKTypesEnum {
  agency = 'Агентство',
  general = 'Пользователь',
}

export enum VKCabinetsConnectionEnum {
  agency = 'Через агентство',
  general = 'Через пользователя',
}

export enum VKRightsEnum {
  admin = 'Главный администратор',
  manager = 'Администратор',
  reports = 'Наблюдатель',
}

export enum IntegrationStatusEnum {
  active = 'Активный',
  deleted = 'Удален',
  blocked = 'Ошибка подключения',
}

export enum AccIntegrationStatusEnum {
  active = 'Включена',
  deleted = 'Выключена',
  blocked = 'Ошибка подключения',
}

export enum CabinetStatusEnum {
  active = 'Активный',
  deleted = 'Удален',
  blocked = 'Ошибка подключения',
}

export enum SyncStatusEnum {
  enabled = 'Включена',
  disabled = 'Выключена',
  loading = 'loading',
}

export enum CurrencyEnum {
  RUB = '₽',
  USD = '$',
  CNY = '¥',
  EUR = '€',
}

export const currencyOrder = ['RUB', 'USD', 'EUR', 'CNY'];

export enum NetworkEnum {
  VkAds = 'va',
  myTarget = 'mt',
  VKontakte = 'vk',
}
