import { useMemo } from 'react';

function getType(element?: string, reverse?: boolean) {
  if (element) {
    if (/^[!?,\\/&$%#@^*()\-_+={}[\]:;"'<>.]/i.test(element)) {
      return reverse ? 5 : 1; // Цифра
    }
    if (/^\d+/i.test(element)) {
      return reverse ? 4 : 2; // Цифра
    }
    if (/^[а-яё]+/i.test(element.trim())) {
      return 3; // Русский текст
    }
    if (/^[a-z]+/i.test(element.trim())) {
      return reverse ? 2 : 4;
    }
  }

  return reverse ? 1 : 5; // Остальное
}

// Сортировка массива
// export const sortRow = (a: string, b: string, reverse?: boolean) => {
//   const typeA = getType(a, reverse);
//   const typeB = getType(b, reverse);

//   if (typeA !== typeB) {
//     return typeA - typeB; // Сортировка по типу
//   } else {
//     return a?.localeCompare(b, 'ru', { sensitivity: 'base' });
//   }
// };

// Функция для сортировки строк с учетом типа и возможности реверсирования
export const sortRow = (a: any, b: any, reverse?: boolean) => {
  const typeA = getType(a, reverse);
  const typeB = getType(b, reverse);

  if (typeA !== typeB) {
    return typeA - typeB; // Сортировка по типу
  } else {
    // Преобразуем в строки, если это не строки
    const aStr = typeof a === 'string' ? a : String(a);
    const bStr = typeof b === 'string' ? b : String(b);

    // Учитываем параметр reverse для реверсированной сортировки
    const comparison = aStr.localeCompare(bStr, 'ru', { sensitivity: 'base' });
    return reverse ? -comparison : comparison;
  }
};

export const getSecondLetter = (name: string) =>
  name.split(' ')[1] ? name.split(' ')[1] : name[1] ? name[1] : undefined;
