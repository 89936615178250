import { TooltipNoChanges, ArrowUpIcon, ArrowDownIcon } from '@plarin/design';
import { int } from '@plarin/utils';
import React from 'react';
import classes from './style.module.scss';
import TooltipPortal from './tooltip-portal';

const ReturnArrow = ({ ctr, allCtr }: { ctr: number; allCtr: number }) => {
  const resultCTR = ctr - allCtr;
  return (
    <>
      {resultCTR < 0 && <ArrowUpIcon size={20} color="status_success" className={classes.arrow} />}
      {resultCTR > 0 && <ArrowDownIcon size={20} color="status_failed" className={classes.arrow} />}
      {resultCTR === 0 && <TooltipNoChanges />}
    </>
  );
};

type TooltipContentProps = {
  allClicks: number;
  allImpressions: number;
  ctr: number;
  allCTR: number;
  fastStatType: 'fastStat' | 'fastStat72';
};

export const TooltipContent = (data: any) => {
  if (data.active && data.payload && data.payload.length) {
    const CTR = (data.payload[0].value && (data.payload[1].value / data.payload[0].value) * 100) || 0;

    return (
      <TooltipPortal>
        <div className={classes.customTooltip}>
          Время: {data.fastStatType === 'fastStat' ? data.payload[0].payload.t : data.payload[0].payload.d}
          <br />
          Показы: {data.payload[0].name === 'impressions' && int(data.payload[0].value)}
          <br />
          Клики: {data.payload[1].name === 'clicks' && int(data.payload[1].value)}
          <br />
          CTR: {CTR ? `${CTR.toFixed(3)}%` : '--'}
          <br />
          <br />
          <span className={classes.tooltipSubheader}>
            {data.fastStatType === 'fastStat' ? 'За 60 минут:' : 'За 72 часа:'}
          </span>
          Показы: {int(data.allImpressions)}
          <br />
          Клики: {int(data.allClicks)}
          <br />
          <div>
            <span className={classes.marginRight}> CTR: {data.allCTR ? `${data.allCTR.toFixed(3)}%` : '--'}</span>
            <ReturnArrow ctr={data.ctr} allCtr={data.allCTR} />
          </div>
        </div>
      </TooltipPortal>
    );
  }

  return null;
};
