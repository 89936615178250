import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { CopyableCell } from '../copyable-cell';
import { DefaultRenderer } from './default-renderer';
import classes from './style.module.scss';
import { CurrencyEnum, currency as formatCurrency } from '@plarin/utils';
import { currencyOrder } from './default-sum-renderer';

interface AvgRendererProps extends ICellRendererParams {
  type:
    | 'object'
    | 'json'
    | 'int'
    | 'url'
    | 'str'
    | 'bool'
    | 'date'
    | 'float'
    | 'percent'
    | 'datetime'
    | 'datetime_short'
    | 'currency'
    | 'faststat'
    | 'int[]'
    | 'str[]'
    | 'dict[]'
    | 'string[]'
    | 'currency[]'
    | 'int/str';
}

type TCurrencyAverages = Record<string, { total: number; count: number }>;

export const DefaultAvgRenderer = ({ type, column, api }: AvgRendererProps) => {
  const currencyAverages: TCurrencyAverages = {} as TCurrencyAverages;

  column &&
    api.forEachNodeAfterFilter(rowNode => {
      const currency = rowNode.data?.accountCurrency as CurrencyEnum;
      const value = rowNode.data?.[column.getColId()];

      if (value && !rowNode.data.isGroupRow) {
        if (currency && type === 'currency') {
          if (!currencyAverages[currency]) {
            currencyAverages[currency] = { total: 0, count: 0 };
          }
          currencyAverages[currency].total += +value;
          currencyAverages[currency].count += 1;
        } else {
          if (!currencyAverages[type]) {
            currencyAverages[type] = { total: 0, count: 0 };
          }
          currencyAverages[type].total += +value;
          currencyAverages[type].count += 1;
        }
      }
    });

  const renderAvgForCurrency = (
    currency: keyof typeof CurrencyEnum,
    total: number,
    count: number,
    isMultipleCurrencies: boolean,
  ) => {
    const avg = count > 0 ? total / count : 0;
    const isCopyable = avg > 0;
    const formattedAvg = isMultipleCurrencies ? formatCurrency(avg, currency) : avg;

    if (!isCopyable) {
      return <DefaultRenderer value={formattedAvg} formatter={column?.getColDef().valueFormatter} />;
    }

    return (
      <CopyableCell
        value={formattedAvg}
        className={classes.footerSum}
        formatter={column?.getColDef().valueFormatter}
        column={column}
      >
        <DefaultRenderer value={formattedAvg} formatter={column?.getColDef().valueFormatter} />
      </CopyableCell>
    );
  };

  const isMultipleCurrencies = Object.keys(currencyAverages).length > 1;

  return (
    <>
      {Object.entries(currencyAverages)
        .sort(
          ([currencyA], [currencyB]) =>
            currencyOrder.indexOf(currencyA as CurrencyEnum) - currencyOrder.indexOf(currencyB as CurrencyEnum),
        )
        .map(([currency, { total, count }]) => (
          <div key={currency}>
            {renderAvgForCurrency(currency as keyof typeof CurrencyEnum, total, count, isMultipleCurrencies)}
          </div>
        ))}
    </>
  );
};
