import {
  ManageVkTabNameEnum,
  IntegrationsTabNameEnum,
  useInterval,
  useTabVisibility,
  ManageYDTabNameEnum,
  getSafeParsedLocalStorage,
} from '@plarin/utils';
import {
  BodyScrollEvent,
  CellMouseOverEvent,
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  FirstDataRenderedEvent,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  IRowNode,
  RowClickedEvent,
  RowHeightParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { ModelUpdatedEvent, RowGroupOpenedEvent, SortChangedEvent } from 'ag-grid-community/dist/lib/events';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import clsx from 'clsx';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Lockscreen } from '../lockscreen';
import { SkeletonTable } from '../preloader';
import {
  AdsSumRenderer,
  BidCellRenderer,
  BidRenderer,
  BudgetRenderer,
  BudgetRendererYd,
  CampaignGroupingRenderer,
  CircleAvatarNameRenderer,
  CustomFooterRenderer,
  DefaultAvgRenderer,
  DefaultGroupRenderer,
  DefaultSumRenderer,
  DefaultTooltipCell,
  GroupNameRenderer,
  HeaderNameRenderer,
  LinkCellRenderer,
  MemberRender,
  NameCountRenderer,
  NameEmailUser,
  NameRenderer,
  PercentsRenderer,
  PeriodRenderer,
  PreloaderRenderer,
  ProjectListRenderer,
  ProjectsRenderer,
  RangeDateRenderer,
  RightsRenderer,
  SimpleCircleAvatarNameRenderer,
  SquareAvatarNameRenderer,
  StatusRenderer,
  TeamMembersRenderer,
  TranslationRenderer,
  TranslationRendererYd,
  TwoLinesRenderer,
  GoalsRenderer,
  IntegrationStatusRenderer,
} from './cell-renderers';
import { FastStatRenderer } from './cell-renderers/fast-stat';
import { handleKeyBindings, TooltipRenderer, TooltipText } from './checkboxUtils';
import { comparator } from './comparator';
import { ErrorTable } from './error-table/ErrorTable';
import { EmptyDataAfterFilter } from './error-table/variants';
import classNames from './table.module.scss';
import {
  applyColumnState,
  customSelectNodes,
  saveColumnState,
  useAutoHeightFix,
  isNotResizable,
  toggleClassFillHeightScroll,
  isActualGridApi,
} from './utils';

export interface Column extends ColDef {}

interface TableProps<T> {
  rowData: T[];
  columns: Column[];
  onGridReady?: (gridApi: GridApi) => void;
  showStatusBar?: boolean;
  statusBar?: any;
  onRowClicked?: (e: RowClickedEvent) => void;
  isRowGroup?: boolean;

  onBodyScroll?(nodeRendered: IRowNode[], api: GridApi): void;
  onBodyScrollFastStat?(nodeRendered: IRowNode[], api: GridApi): void;

  ActionError?: () => Promise<void>;
  errorTable?: boolean;
  colDef?: ColDef;
  selectedRowKeys?: number[];
  getSelectedIds?: (tabName: any) => number[];
  tabName?: ManageVkTabNameEnum | ManageYDTabNameEnum | IntegrationsTabNameEnum;
  setSelectedKeys?: (ids: number[], tabName: any) => void;
  setLeftNavigationIds?: (selectedRows: T[], tabName: ManageVkTabNameEnum) => void;
  setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
  setDeleteAction?: (action?: () => void) => void;
  // emptyDataComponent?: JSX.Element | React.ReactNode;
  tableLoading: boolean;
  columnState?: ColumnState[];
  saveColumnState?: (columnState: ColumnState[]) => void;
  isGroupDestroyed?: boolean;
  setGroupDestroyed?: (value: boolean) => void;
  needAutoHeight?: boolean;
  noRowsContent?: () => JSX.Element;
  agNoRowsOverlay?: () => JSX.Element;
  suppressRowClickSelection?: boolean;
  groupDefaultExpanded?: number;
  extraFooterHeight?: number;
  spaceAboveTable?: number;
  disableContainersHeight?: boolean;
  onRowGroupOpened?: (event: RowGroupOpenedEvent) => void;
}

const defColDef: ColDef = {
  sortable: true,
  checkboxSelection: false,
  headerCheckboxSelectionFilteredOnly: true,
  sortingOrder: ['desc', 'asc', null],
  minWidth: 75,
  width: 150,
  comparator,
  resizable: true,
  tooltipComponent: TooltipRenderer, // Компонент для отображения header checkbox tooltip
  tooltipComponentParams: { value: TooltipText },
  cellDataType: false,
};

const defaultSpaceAboveTable = 250;

export const Table = <RD,>({
  rowData = [],
  columns = [],
  onGridReady,
  showStatusBar,
  statusBar,
  onBodyScroll,
  onBodyScrollFastStat,
  ActionError,
  errorTable,
  selectedRowKeys,
  getSelectedIds,
  tabName,
  setSelectedKeys,
  setLeftNavigationIds,
  setSelectedRows,
  tableLoading,
  isGroupDestroyed,
  needAutoHeight,
  agNoRowsOverlay,
  noRowsContent,
  suppressRowClickSelection = false,
  onRowClicked,
  groupDefaultExpanded = 0,
  extraFooterHeight = 0,
  disableContainersHeight = false,
  spaceAboveTable = defaultSpaceAboveTable,
  onRowGroupOpened,
}: TableProps<RD>) => {
  const gridRef = useRef<AgGridReact>(null);
  const [apiGrid, setApiGrid] = useState<GridApi<any> | null>(null);
  const href = window.location.href;
  const columnState = getSafeParsedLocalStorage<Record<string, ColumnState[]>>('columns'); // Указываем, что это объект с ключами-строками

  // определяем высоту всех видимых строк таблицы + футер
  const needHeight = () => {
    const allRowsHeight = document.querySelector('.ag-center-cols-clipper')?.clientHeight || 1;

    return allRowsHeight + extraFooterHeight;
  };

  // определяет высоту таблицы
  const currentTableHeight = () => {
    const pageDomElement = document.querySelector('.autoHeightAnchor');
    return pageDomElement ? pageDomElement!.getBoundingClientRect().height - spaceAboveTable : 0;
  };
  // Применяем эту функцию при фильтрации таблицы и при изменении ее размера.
  // Позволяет использовать горизонтальный скролл по белому пространству над скроллбаром.
  const setFillHeightScroll = (api: GridApi) => {
    toggleClassFillHeightScroll(classNames.fillHeightScroll, needAutoHeight);
  };

  // для того что бы при отрисовке таблицы, не было мигания во время построения колонок
  const upDefaultColumnsFromLocalStorage = () => {
    let columnStateLS: ColumnState[] = [];
    if (columnState) {
      // Проверяем, существует ли элемент с индексом href
      if (columnState[href] !== undefined) {
        columnStateLS = columnState[href];
      }
    }
    // какие колонки добавить в конце таблицы
    const addColumn = columns.filter(column => {
      return !columnStateLS.filter(columnLS => {
        return column.field === columnLS.colId;
      }).length;
    });
    // для того что бы, когда добавили или удалили колонку, при последующем добавление, колонка становилась в конец
    /* //этот фрагмент пока не удаляю - возможно понадобится для задачи с алгоритмом колонок и закоментив его тем самым вернула прежний порядок отрисовки колонок
     if (columnStateLS.length > columns.length || addColumn.length) {
      isCampaigns && gridRef?.current && saveColumnState(gridRef.current?.columnApi);
    }*/
    // формирование колонок в соответствие с колонками из LocalStorage
    return [
      ...columnStateLS?.map((columnLS: ColumnState) => {
        return {
          ...columns.filter(column => {
            return column.field === columnLS.colId;
          })[0],
          width: columnLS.width,
          pinned: columnLS.pinned,
        };
      }),
      ...addColumn,
    ].filter(el => el.field);
  };

  const isCampaigns =
    tabName === ManageVkTabNameEnum.ACCOUNTS ||
    tabName === ManageVkTabNameEnum.ADPLANS ||
    tabName === ManageVkTabNameEnum.ADS ||
    tabName === ManageVkTabNameEnum.ADGROUPS ||
    tabName === ManageYDTabNameEnum.ACCOUNTS ||
    tabName === ManageYDTabNameEnum.GROUPS ||
    tabName === ManageYDTabNameEnum.ADS ||
    tabName === ManageYDTabNameEnum.CAMPAIGNS;

  const onGridReadyWrapper = ({ api, columnApi }: GridReadyEvent) => {
    api && onGridReady && onGridReady(api);
    // const nodes = api && api?.getRenderedNodes().filter(node => !node.data.isGroupRow);
    // const fastStatNodes = nodes?.filter(el => el.data?.fastStat === 0);
    // const previewNodes = nodes?.filter(el => el.data?.previewSmall === 'none');
    // fastStatNodes?.length && previewNodes?.length && onBodyScroll && onBodyScroll(nodes, api);
    setApiGrid(api);
    const headerViewport = document.querySelector('.ag-header-viewport ');
    const headerContainer = document.querySelector('.ag-header-container');

    if (
      headerContainer &&
      headerViewport &&
      headerContainer.clientWidth < headerViewport.clientWidth &&
      !isNotResizable(columns)
    ) {
      api.sizeColumnsToFit();
    }

    applyColumnState(columnApi, tabName);

    toggleClassFillHeightScroll(classNames.fillHeightScroll, needAutoHeight);

    // без этой строчки таблица рендерится в нормальном режиме, а через секунду переключается на автовысоту, если соблюдены условия, при которых должна быть автовысота. Чтобы таблица не "прыгала" видимо для пользователя, вызываем checkDomLayout в этом месте
    needAutoHeight && gridRef.current?.api && checkDomLayout(gridRef.current?.api);

    // nodes.length === 0 ? api.showNoRowsOverlay() : api.hideOverlay();
  };

  const onFirstDataRendered = ({ api }: FirstDataRenderedEvent) => {
    if (isCampaigns && tabName && getSelectedIds) {
      customSelectNodes(getSelectedIds(tabName), false, api);
    } else {
      customSelectNodes(selectedRowKeys, false, api);
    }
  };

  // Настройка высоты строк
  const getRowHeight = (params: RowHeightParams) => {
    const bidCurrent = params.node?.data?.bidCurrent || params.node?.data?.statisticsSpent;

    if (typeof bidCurrent === 'string') {
      const brCount = (bidCurrent.match(/<br\s*\/>/g) || []).length;
      if (brCount === 2) {
        return 64;
      }
    }
    // высота футера мне мультивалюте
    if (params.node.rowPinned && uniqueCurrency.length >= 3) {
      return 64;
    }
    return 40;
  };

  const onSelectionWrapper = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows().filter(el => !el.isGroupRow);
    const isGroupingEnabled = selectedRows.length && selectedRows[0].hasOwnProperty('orgHierarchy');

    // При выборе чекбокса передаем данные строки для навигации справа налево
    if (tabName && Object.values(ManageVkTabNameEnum).includes(tabName as ManageVkTabNameEnum)) {
      setLeftNavigationIds && setLeftNavigationIds(selectedRows, tabName as ManageVkTabNameEnum);
    }

    if (!isGroupDestroyed && isCampaigns && setSelectedKeys && tabName) {
      setSelectedKeys(
        selectedRows.reduce((acc: number[], row) => {
          if (isGroupingEnabled) {
            row.hasOwnProperty('orgHierarchy') && row.orgHierarchy && acc.push(row.key);
          } else {
            acc.push(row.key);
          }

          return acc;
        }, []),
        tabName,
      );
    } else {
      setSelectedRows && setSelectedRows(selectedRows.filter(row => !(row.rights === 'Множественное подключение')));
    }
  };

  // здесь происходит переключение режима таблицы с автовысоты на максимальную высоту
  const checkDomLayout = (api: GridApi) => {
    if (isActualGridApi(api) && api) {
      if (needHeight() > currentTableHeight()) {
        api.setDomLayout('normal');
      }

      if (needHeight() < currentTableHeight()) {
        api.setDomLayout('autoHeight');
      }
    }
  };

  const onModelUpdated = ({ api }: ModelUpdatedEvent) => {
    needAutoHeight && checkDomLayout(api);

    toggleClassFillHeightScroll(classNames.fillHeightScroll, needAutoHeight);
    // const nodes = api ? api?.getRenderedNodes() : [];
    // const fastStatNodes = nodes?.filter(el => el.data.fastStat === 0);
    // const previewNodes = nodes?.filter(el => el.data.previewSmall === 'none');
    // (fastStatNodes?.length || previewNodes.length) && onBodyScroll && onBodyScroll(nodes, api);
  };

  const onGridSizeChanged = () => {
    if (isActualGridApi(apiGrid) && apiGrid) {
      setFillHeightScroll(apiGrid);
      needAutoHeight && checkDomLayout(apiGrid);
      const headerViewport = document.querySelector('.ag-header-viewport ');
      const headerContainer = document.querySelector('.ag-header-container');

      if (
        headerContainer &&
        headerViewport &&
        headerContainer.clientWidth < headerViewport.clientWidth &&
        !isNotResizable(columns)
      ) {
        apiGrid.sizeColumnsToFit();
      }

      const nodes = apiGrid?.getRenderedNodes()?.filter(node => !node.data.isGroupRow);
      const fastStatNodes = nodes?.filter(el => el.data.fastStat === 0);
      const previewNodes = nodes?.filter(el => el.data.previewSmall === 'none');
      (fastStatNodes?.length || (tabName === ManageVkTabNameEnum.ADS && previewNodes?.length)) &&
        onBodyScroll &&
        onBodyScroll(nodes, apiGrid);
    }
  };

  const onBodyScrollWrapper = ({ type, direction }: BodyScrollEvent) => {
    if (isActualGridApi(apiGrid) && apiGrid) {
      needAutoHeight && checkDomLayout(apiGrid);
      const nodes = apiGrid?.getRenderedNodes()?.filter(node => !node.data.isGroupRow);
      const fastStatNodes = nodes?.filter(el => el.data.fastStat === 0);
      fastStatNodes?.length &&
        type === 'bodyScroll' &&
        direction === 'vertical' &&
        onBodyScroll &&
        onBodyScroll(nodes, apiGrid);
    }
  };

  const onColumnResized = ({ columnApi }: ColumnResizedEvent) => {
    isCampaigns && saveColumnState(columnApi);
  };

  const onColumnMoved = ({ columnApi }: ColumnMovedEvent) => {
    isCampaigns && saveColumnState(columnApi);
  };

  const onSortChanged = useCallback(
    ({ api, columnApi }: SortChangedEvent) => {
      if (isActualGridApi(api) && columnApi) {
        isCampaigns && saveColumnState(columnApi);
        const nodes = api.getRenderedNodes()?.filter(node => !node.data.isGroupRow);
        const fastStatNodes = nodes?.filter(el => el.data.fastStat === 0);

        fastStatNodes?.length && onBodyScroll && onBodyScroll(nodes, api);
      }
    },
    [rowData],
  );

  const onRowClick = (event: RowClickedEvent) => {
    if (groupDefaultExpanded !== 1 && event.node.childrenAfterFilter?.length) {
      event.api.setRowNodeExpanded(event.node, !event.node.expanded);
    }

    if (groupDefaultExpanded === 1) {
      event.node.data.isParent && event.node.setSelected(!event.node.isSelected());
    }

    if (onRowClicked) {
      onRowClicked(event);
    }
  };

  const onCellMouseOver = (event: CellMouseOverEvent) => {
    groupDefaultExpanded !== 1 && event.node.childrenAfterFilter?.length
      ? event.api.setSuppressRowClickSelection(true)
      : event.api.setSuppressRowClickSelection(false);
  };

  // update fastStat 1 min
  const handleBodyScroll = useCallback(() => {
    if (onBodyScrollFastStat && gridRef.current) {
      const nodes = gridRef.current.api.getRenderedNodes().filter(node => !node.data.isGroupRow);

      nodes && onBodyScrollFastStat(nodes, gridRef.current.api);
    }
  }, [onBodyScrollFastStat, gridRef]);

  const isTabActive = useTabVisibility();
  useInterval(
    () => {
      if (gridRef.current && isTabActive) {
        handleBodyScroll();
      }
    },
    60000,
    isTabActive,
  );

  useEffect(() => {
    return () => {
      onGridReady && onGridReady(null as unknown as GridApi);
    };
  }, [onGridReady]);

  useEffect(() => {
    const handleKeyBindingsWrapper = (event: KeyboardEvent) => handleKeyBindings(event, gridRef, columns);

    document.addEventListener('keydown', handleKeyBindingsWrapper);
    return () => {
      document.removeEventListener('keydown', handleKeyBindingsWrapper);
    };
  }, []);

  useLayoutEffect(() => {
    if (tableLoading) {
      setApiGrid(null);
    }
  }, [tableLoading]);

  useAutoHeightFix(!!needAutoHeight, gridRef, needHeight(), spaceAboveTable); // фикс переключения автовысоты для редких случаев ресайза окна

  if (tableLoading) {
    return (
      <>
        <Lockscreen visible={tableLoading} />
        <SkeletonTable />
      </>
    );
  }

  if (errorTable) return <ErrorTable error={errorTable} action={ActionError} />;

  const restProps: AgGridReactProps = {};
  showStatusBar && (restProps.pinnedBottomRowData = [{}]);

  // @ts-ignore
  const rowDataCurrency = rowData.map(row => row?.accountCurrency || '');
  const uniqueCurrency = [...new Set(rowDataCurrency)];

  return (
    <div className={clsx(!disableContainersHeight ? classNames.tableShadow : classNames.tableShadowWithoutHeight)}>
      <div
        className={clsx(
          classNames.table,
          'ag-theme-alpine',
          showStatusBar && classNames.fitXScroll,
          uniqueCurrency.length >= 3 && classNames.fitXScrollHight,
          onRowClicked && classNames.rowPointer,
        )}
      >
        <AgGridReact
          ref={gridRef}
          {...restProps}
          rowSelection="multiple"
          getRowHeight={getRowHeight}
          rowData={rowData}
          getRowId={({ data }) => (isCampaigns ? data.key : data.orgHierarchy ? data.orgHierarchy.join('/') : data.key)}
          getDataPath={data => data.orgHierarchy || [data.key]}
          tooltipShowDelay={0}
          tooltipMouseTrack={true}
          components={{
            agColumnHeader: HeaderNameRenderer,
            NameRenderer,
            CustomFooterRenderer,
            GoalsRenderer,
            TwoLinesRenderer,
            RightsRenderer,
            NameCountRenderer,
            DefaultSumRenderer,
            AdsSumRenderer,
            DefaultAvgRenderer,
            DefaultGroupRenderer,
            BudgetRenderer,
            BudgetRendererYd,
            RangeDateRenderer,
            PercentsRenderer,
            BidRenderer,
            NameEmailUser,
            FastStatRenderer,
            BidCellRenderer,
            PreloaderRenderer,
            StatusRenderer,
            TranslationRenderer,
            TranslationRendererYd,
            CampaignGroupingRenderer,
            PeriodRenderer,
            GroupNameRenderer,
            DefaultTooltipCell,
            MemberRender,
            noRowsContent,
            agNoRowsOverlay: () => <EmptyDataAfterFilter<RD> gridRef={gridRef} agNoRowsOverlay={agNoRowsOverlay} />,
            SquareAvatarNameRenderer,
            CircleAvatarNameRenderer,
            TeamMembersRenderer,
            ProjectListRenderer,
            ProjectsRenderer,
            SimpleCircleAvatarNameRenderer,
            LinkCellRenderer,
            IntegrationStatusRenderer,
          }}
          defaultColDef={defColDef}
          suppressCellFocus
          suppressContextMenu
          suppressDragLeaveHidesColumns
          cacheQuickFilter
          rowBuffer={0}
          animateRows
          treeData
          groupSelectsChildren
          groupSelectsFiltered
          statusBar={statusBar}
          groupDisplayType="custom"
          onGridReady={onGridReadyWrapper}
          onFirstDataRendered={onFirstDataRendered}
          onSelectionChanged={onSelectionWrapper}
          columnDefs={columnState && columnState[href] ? upDefaultColumnsFromLocalStorage() : columns}
          onGridSizeChanged={debounce(onGridSizeChanged, 500)}
          onBodyScroll={debounce(onBodyScrollWrapper, 500)}
          onSortChanged={debounce(onSortChanged, 500)}
          onModelUpdated={e =>
            // здесь убран дебаунс для ситуации, когда мы разворачиваем группировку, чтобы футер быстро отскочил к низу страницы при переключении с автовысоты на максимальную высоту
            // в остальных случаях дебаунс срабатывает, и футер отскакивает с задержкой
            needHeight() > currentTableHeight() && needAutoHeight ? onModelUpdated(e) : debounce(onModelUpdated, 500)(e)
          }
          onRowClicked={onRowClick}
          onCellMouseOver={suppressRowClickSelection ? () => {} : onCellMouseOver}
          suppressRowClickSelection={suppressRowClickSelection}
          onFilterChanged={({ api }) => {
            const nodes = api.getRenderedNodes();
            const fastStatNodes = nodes?.filter(el => el.data.fastStat === 0);

            isCampaigns && fastStatNodes?.length && onBodyScroll && onBodyScroll(nodes, api);
            setFillHeightScroll(api);
            // не удалять, иначе падает
            setTimeout(() => {
              api.redrawRows();
            });
            api.getDisplayedRowCount() === 0 ? api.showNoRowsOverlay() : api.hideOverlay();
          }}
          rowClassRules={{
            'background-row-error': param => {
              return (
                param.data.plarin_status === 'enabled' &&
                (param.data.integrationStatusCode === 'blocked' || param.data.syncStatusCode === 'blocked')
              );
            },
            hasOrgHierarchy1: params => params.node.data.isGroupRow && !params.node.data?.orgHierarchy?.length,
            hasOrgHierarchy2: params => params.node.data.isGroupRow && params.node.data?.orgHierarchy?.length > 1,
            hasOrgHierarchy3: params => params.node.data.isGroupRow && params.node.data?.orgHierarchy?.length > 2,
            'row-is-parent': param => param.data.isGroupRow,
          }}
          onColumnResized={onColumnResized}
          onColumnMoved={onColumnMoved}
          groupDefaultExpanded={groupDefaultExpanded}
          onRowGroupOpened={onRowGroupOpened}
        />
      </div>
    </div>
  );
};
