import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { CopyableCell } from '../copyable-cell';
import { DefaultRenderer } from './default-renderer';
import classes from './style.module.scss';
import { CurrencyEnum, currency as formatCurrency } from '@plarin/utils';

interface SumRendererProps extends ICellRendererParams {
  type:
    | 'object'
    | 'json'
    | 'int'
    | 'url'
    | 'str'
    | 'bool'
    | 'date'
    | 'float'
    | 'percent'
    | 'datetime'
    | 'datetime_short'
    | 'currency'
    | 'faststat'
    | 'int[]'
    | 'str[]'
    | 'dict[]'
    | 'string[]'
    | 'currency[]'
    | 'int/str';
}

export const currencyOrder = ['RUB', 'USD', 'EUR', 'CNY'];

export const DefaultSumRenderer = ({ type, column, api }: SumRendererProps) => {
  const currencySums: Record<string, number> = {} as Record<CurrencyEnum, number>;

  column &&
    api.forEachNodeAfterFilter(rowNode => {
      const currency = (rowNode.data?.accountCurrency || rowNode.data.currency) as CurrencyEnum;
      const value = rowNode.data?.[column.getColId()];
      if (
        value ||
        (!rowNode.data.isGroupRow && !rowNode.data.orgHierarchy) ||
        (rowNode.data.parentId && !rowNode.data.orgHierarchy)
      ) {
        if (currency && type === 'currency') {
          if (!currencySums[currency]) {
            currencySums[currency] = 0;
          }
          currencySums[currency] += +value || 0;
        } else {
          if (!currencySums[type]) {
            currencySums[type] = 0;
          }
          currencySums[type] += +value || 0;
        }
      }
    });

  const renderSumForCurrency = (currency: keyof typeof CurrencyEnum, sum: number, isMultipleCurrencies: boolean) => {
    const isCopyable = sum > 0;
    const formattedSum = isMultipleCurrencies ? formatCurrency(sum, currency) : sum;

    if (!isCopyable) {
      return <DefaultRenderer value={formattedSum} formatter={column?.getColDef().valueFormatter} />;
    }

    return (
      <CopyableCell
        value={formattedSum}
        className={classes.footerSum}
        formatter={column?.getColDef().valueFormatter}
        column={column}
      >
        <DefaultRenderer value={formattedSum} formatter={column?.getColDef().valueFormatter} />
      </CopyableCell>
    );
  };

  const isMultipleCurrencies = Object.keys(currencySums).length > 1;

  return (
    <>
      {Object.entries(currencySums)
        .sort(
          ([currencyA], [currencyB]) =>
            currencyOrder.indexOf(currencyA as CurrencyEnum) - currencyOrder.indexOf(currencyB as CurrencyEnum),
        )
        .map(([currency, sum]) => (
          <div key={currency}>
            {renderSumForCurrency(currency as keyof typeof CurrencyEnum, sum as number, isMultipleCurrencies)}
          </div>
        ))}
    </>
  );
};

export const AdsSumRenderer = ({ column, api }: SumRendererProps) => {
  let sumActive = 0;
  let sumPaused = 0;
  let sumArchived = 0;
  column &&
    api.forEachNodeAfterFilter(rowNode => {
      sumActive += rowNode.data.adsActive;

      sumPaused += rowNode.data.adsPaused;

      sumArchived += rowNode.data.adsArchived;
    });
  const result = `${sumActive} / ${sumPaused} / ${sumArchived}`;
  return <DefaultRenderer value={result} />;
};

export const BidRenderer = ({ column, api }: SumRendererProps) => {
  let sum = 0;
  column &&
    api.forEachNodeAfterFilter(
      rowNode =>
        (sum +=
          column && !rowNode.data.orgHierarchy && parseInt(rowNode.data[column.getColId()])
            ? parseInt(rowNode.data[column.getColId()])
            : 0),
    );
  return <DefaultRenderer value={sum} formatter={column?.getColDef().valueFormatter} />;
};
