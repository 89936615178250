import { CopyTextIcon } from '@plarin/design';
import { Column, ValueFormatterParams } from 'ag-grid-community';
import clsx from 'clsx';
import React, { useState, useRef, useEffect } from 'react';
import { MainTooltip } from '../../../tooltip';
import { formatValue } from '../../utils';
import classes from './style.module.scss';

interface CopyableCellProps {
  value: number | string;
  className?: string;
  children: JSX.Element;
  formatter?: string | ((params: ValueFormatterParams) => string);
  column?: Column;
  data?: any;
}

export const CopyableCell: React.FC<CopyableCellProps> = ({ value, className, children, formatter, data = {} }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  let timeoutId: NodeJS.Timeout | null = null;

  const formattedValue = typeof value === 'string' ? value : formatValue(+value, formatter, data);

  const handleCopy = () => {
    if (ref.current) {
      navigator.clipboard.writeText(formattedValue.toString()).then(() => {
        setIsCopied(true);
      });
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <MainTooltip
      tooltipMessage={isCopied ? 'Cкопировано!' : 'Нажмите, чтобы скопировать'}
      isVisible={true}
      component="div"
      placement="bottom-start"
      componentFeature="textEllips"
      textAlign="left"
      followCursor={true}
    >
      <div
        ref={ref}
        onClick={handleCopy}
        className={clsx(classes.wrapper, classes.wrapperCopyable)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
          setTimeout(() => {
            setIsCopied(false);
          }, 300);
        }}
      >
        {isHovered && <CopyTextIcon size={16} color="primary60" />}
        <div className={clsx(className, isHovered && classes.activeValue)}>{children}</div>
      </div>
    </MainTooltip>
  );
};
