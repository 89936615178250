import { CellClassParams, Column, GetQuickFilterTextParams } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { returnCellRules } from '../../utils/manage-vk';
import { ActionsName } from '../actions-name';
import { NameNavigationCell } from '../name-navigation-cell';

enum DefaultColumnNamesEnum {
  CHECKBOX,
  ACCOUNTS,
  ADPLANS,
  ADGROUPS,
  ADS,
}

export const defaultColumns: Record<keyof typeof DefaultColumnNamesEnum, Column> = {
  CHECKBOX: {
    pinned: 'left',
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    resizable: false,
    headerCheckboxSelection: true,
    field: 'checkbox',
    checkboxSelection: params =>
      !params.data.cellLoadingName && params.data.price !== 'loading' && params.data.status !== 'loading',
    cellRendererParams: {
      suppressCount: true,
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
      value: '',
    },
    cellRendererSelector: params => {
      if (new Set(Object.keys(params.data)).has('orgHierarchy') && params.data.isGroupRow) {
        return { component: 'agGroupCellRenderer' };
      }
      if (params.data.cellLoadingName || params.data.price === 'loading' || params.data.status === 'loading') {
        return { component: 'PreloaderRenderer' };
      }
      return { component: null };
    },
    cellClass: params => `campaignsStatusCode checkbox ${params.data.statusColor}`,
    headerClass: 'checkbox',
    cellClassRules: {
      deleted: param => param.data.status === 'Архивное' || param.data.status === 'Архивная',
      active: param => param.data.status === 'Активная' || param.data.status === 'Активное',
      stopStatus: param =>
        param.data.status === 'Остановлена' ||
        param.data.status === 'Остановлено' ||
        param.data.deliveryStatus === 'not_delivering',
    },
    headerTooltip: 'true',
    sortable: false,
  },
  ACCOUNTS: {
    headerName: 'Рекламный кабинет',
    headerComponentParams: { subheader: 'ID Рекламного кабинета' },
    field: 'accountName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      first: 'accountName',
      firstLine: 'accountName',
      firstBold: true,
      second: 'accountUsername',
      secondCopy: true,
      alternativeBullet: 'status',
      ActionsName: ActionsName,
      disableEditName: true,
      suppressCount: true,
      cellName: 'accounts',
      selectedTab: ManageVkTabNameEnum.ACCOUNTS,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) =>
      `${params.value} ${params.data.accountUsername} ${params.data.accountId}`,
    minWidth: 300,
    lockPinned: true,
  },
  ADPLANS: {
    headerName: 'Кампания',
    headerComponentParams: { subheader: 'ID Кампании' },
    field: 'adPlanName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      first: 'adPlanName',
      firstLine: 'adPlanName',
      firstBold: true,
      second: 'adPlanId',
      secondCopy: true,
      alternativeBullet: 'status',
      ActionsName: ActionsName,
      suppressCount: true,
      cellName: 'adplans',
      selectedTab: ManageVkTabNameEnum.ADPLANS,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.value} ${params.data.adPlanId}`,
    minWidth: 300,
    lockPinned: true,
  },
  ADGROUPS: {
    headerName: 'Группа объявлений',
    headerComponentParams: { subheader: 'ID Группы объявлений' },
    field: 'campaignName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      first: 'campaignName',
      firstLine: 'campaignName',
      firstBold: true,
      second: 'campaignId',
      secondCopy: true,
      alternativeBullet: 'status',
      ActionsName: ActionsName,
      suppressCount: true,
      cellName: 'adgroups',
      selectedTab: ManageVkTabNameEnum.ADGROUPS,
    },
    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.value} ${params.data.campaignId}`,
    minWidth: 300,
    lockPinned: true,
  },
  ADS: {
    headerName: 'Объявление',
    headerComponentParams: { subheader: 'ID Объявления' },
    field: 'adName',
    cellRenderer: NameNavigationCell,
    cellRendererParams: {
      first: 'adName',
      firstLine: 'adName',
      firstBold: true,
      second: 'adId',
      secondCopy: true,
      alternativeBullet: 'status',
      ActionsName: ActionsName,
      suppressCount: true,
      cellName: 'ads',
      selectedTab: ManageVkTabNameEnum.ADS,
    },

    cellClassRules: {
      'sync-status-disabled': (params: CellClassParams) => returnCellRules(params.data.status),
      banned: param => param.data.statusColor === 'banned',
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => `${params.data.adName} ${params.data.adId}`,
    minWidth: 300,
    lockPinned: true,
  },
};
