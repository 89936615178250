import { Checkbox } from '@plarin/inputs';
import React from 'react';
import classes from './style.module.scss';

export const Ecommerce = ({ value, onChange }: { value: boolean; onChange: (e: boolean) => void }) => {
  return (
    <div className={classes.blockCheckbox}>
      <Checkbox
        label="Получать E-commerce данные"
        labelSize="Main"
        weightLabel={600}
        colorLabel="TextPrimary"
        size="small"
        value={value}
        onChange={e => {
          onChange(e);
        }}
      />
    </div>
  );
};
