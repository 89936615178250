import { Typography } from '@plarin/design';
import { EllipsisTooltip } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import classes from './style.module.scss';

interface IBodyFirstBlock {
  data: { title: string; name: string; id: number | string }[];
}

export const BodyFirstBlock = observer(({ data }: IBodyFirstBlock) => {
  return (
    <div className={clsx(classes.wrapBlock, classes.blockHr, classes.indentation)}>
      {data.map((el, index) => {
        return (
          <div className={classes.content} key={index}>
            <Typography
              component="p"
              weight={400}
              size="TableRowSubHeader"
              color="TextSecondary"
              componentProps={{ className: classes.label }}
            >
              {el.title}
            </Typography>
            <EllipsisTooltip classNameContainer={classes.ellipsis} tooltipMessage={el.name}>
              <Typography component="p" ellips weight={600} size="AuthContent">
                {el.name}
              </Typography>
            </EllipsisTooltip>
            {el.id && (
              <EllipsisTooltip classNameContainer={classes.ellipsis} tooltipMessage={el.id}>
                <Typography component="p" ellips weight={400} size="TableRowSubHeader" color="TextSecondary">
                  {el.id}
                </Typography>
              </EllipsisTooltip>
            )}
          </div>
        );
      })}
    </div>
  );
});
