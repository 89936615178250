import { StatusEnumVK, TDateOption, ManageVkTabs } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo, useState } from 'react';
import { TTabsData } from '../../../types/common-types';
import { FilterState } from '../../../types/manage-vk/types';
import { useAppDataStore } from '../../components/app-data-provider';
import { ReportDrawer } from '../../components/drawer/report-drawer';
import { LayoutTable } from '../../components/layout-table';
import { ListVk } from '../../components/list/list-vk';
import { routerPaths } from '../../routing/router-path';
import { dateAdapter } from '../../utils/reports';
import { MetricsDrawer } from './manage-vk-drawer/metrics-drawer';
import { AdPlansTab, AdsTab, AdGroupsTab, ClientsTab } from './tabs-content';

const ManageVkPage = observer(() => {
  const {
    manageVK: {
      drawerVisible,
      drawerAction,
      closeDrawer,
      setMetricFilterValue,
      metricsFilterValue,
      checkedTabs,
      filterValue,
      setFilterValue,
      setDateOption,
      setFilterStatuses,
      filterStatuses,
      dateOptions,
      date,
      tableLoading,
      quantitySelected,
      setSelectedIds,
      deleteAction,
      currentTab,
      resetLeftNavigationIds,
    },
    reports: { updateInitialStateDrawer },
    profileStore: { workspaceShortname },
  } = useAppDataStore();

  const tabsData = useMemo(
    (): TTabsData => ({
      tabs: [
        {
          label: 'Выберите вкладки',
          isGroup: true,
          key: 'group',
          path: '',
          content: <></>,
        },
        {
          label: 'Рекламные кабинеты',
          key: ManageVkTabs.accounts,
          path: `/${workspaceShortname}${routerPaths.manageVK.ACCOUNTS}`,
          checkbox: true,
          checked: true,
          // checked: campaignTabs.includes(1),
          disableSelected: true,
          action: () => checkedTabs(1),
          content: <ClientsTab />,
          rowsCount: quantitySelected.selectedClients,
          deleteAction: () => {
            setSelectedIds([], ManageVkTabNameEnum.ACCOUNTS);
            resetLeftNavigationIds(ManageVkTabNameEnum.ACCOUNTS);
            currentTab === ManageVkTabNameEnum.ACCOUNTS && deleteAction && deleteAction();
          },
        },
        {
          label: 'Кампании',
          key: ManageVkTabs.adPlans,
          path: `/${workspaceShortname}${routerPaths.manageVK.ADPLANS}`,
          checkbox: true,
          // checked: campaignTabs.includes(2),
          disableSelected: true,
          checked: true,
          action: () => checkedTabs(2),
          content: <AdPlansTab />,
          rowsCount: quantitySelected.selectedAdPlans,
          deleteAction: () => {
            setSelectedIds([], ManageVkTabNameEnum.ADPLANS);
            resetLeftNavigationIds(ManageVkTabNameEnum.ADPLANS);
            currentTab === ManageVkTabNameEnum.ADPLANS && deleteAction && deleteAction();
          },
        },
        {
          label: 'Группы объявлений',
          key: ManageVkTabs.adGroups,
          path: `/${workspaceShortname}${routerPaths.manageVK.AD_GROUPS}`,
          checkbox: true,
          checked: true,
          disableSelected: true,
          content: <AdGroupsTab />,
          rowsCount: quantitySelected.selectedAdGroups,
          deleteAction: () => {
            setSelectedIds([], ManageVkTabNameEnum.ADGROUPS);
            resetLeftNavigationIds(ManageVkTabNameEnum.ADGROUPS);
            currentTab === ManageVkTabNameEnum.ADGROUPS && deleteAction && deleteAction();
          },
        },
        {
          label: 'Объявления',
          key: ManageVkTabs.ads,
          path: `/${workspaceShortname}${routerPaths.manageVK.ADS}`,
          checkbox: true,
          checked: true,
          disableSelected: true,
          content: <AdsTab />,
          rowsCount: quantitySelected.selectedAds,
          deleteAction: () => {
            setSelectedIds([], ManageVkTabNameEnum.ADS);
            resetLeftNavigationIds(ManageVkTabNameEnum.ADS);
            currentTab === ManageVkTabNameEnum.ADS && deleteAction && deleteAction();
          },
        },
      ],
      settings: true,
    }),
    [
      quantitySelected.selectedClients,
      quantitySelected.selectedAdPlans,
      quantitySelected.selectedAdGroups,
      quantitySelected.selectedAds,
      checkedTabs,
      setSelectedIds,
      deleteAction,
    ],
  );

  const setDate = useCallback(
    (date: TDateOption) => {
      setDateOption(date);
      updateInitialStateDrawer(dateAdapter(date));
    },
    [updateInitialStateDrawer, setDateOption],
  );

  const setStatuses = useCallback(
    (newStatus: StatusEnumVK[]) => {
      setFilterStatuses(newStatus);
      updateInitialStateDrawer({
        statuses: newStatus.length
          ? newStatus.map(status => {
              if (status === StatusEnumVK.blocked) return 0;
              if (status === StatusEnumVK.active) return 1;
              return 2;
            })
          : [],
      });
    },
    [updateInitialStateDrawer, setFilterStatuses],
  );

  return (
    <>
      <LayoutTable<FilterState>
        tabsData={tabsData}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setFilterStatuses={setStatuses}
        setDateFilterOptions={setDate}
        dateOption={dateOptions}
        date={date}
        tableLoading={tableLoading}
        statuses={filterStatuses}
      />
      <MetricsDrawer
        drawerVisible={drawerVisible}
        drawerAction={drawerAction}
        closeDrawer={closeDrawer}
        setMetricFilterValue={setMetricFilterValue}
        metricsFilterValue={metricsFilterValue}
      >
        <ListVk />
      </MetricsDrawer>
      <ReportDrawer />
    </>
  );
});

export default ManageVkPage;
