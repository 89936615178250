import { Drawer } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { WsDrawerPreloader } from '../../../../components/drawer/preloaders/preloaders';
import { TeamsDrawerFooter } from './teams-drawer-footer';
import { TeamsDrawerMembers } from './teams-drawer-members';
import { TeamsDrawerName } from './teams-drawer-name';
import { TeamsDrawerProject } from './teams-drawer-project';
import { TeamsDrawerSettings } from './teams-drawer-settings';
import { TeamsDrawerTitle } from './teams-drawer-title';
import classes from './teams-drawer.module.scss';

interface TeamsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TeamsDrawer = observer(({ isOpen, onClose }: TeamsDrawerProps) => {
  const {
    teams: { dataDrawer, setModalData, isDrawerDataChanged },
  } = useAppDataStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isEditTeam = dataDrawer?.isEditDrawer;

  const closeHandler = () => {
    if (isDrawerDataChanged && dataDrawer && dataDrawer.name !== '') {
      setModalData({ type: 'saveExit', data: dataDrawer });
    } else {
      onClose();
    }
  };

  // костыль, чтобы при появлении дровера лейбл в инпуте для названия проекта не менял своего положения
  useEffect(() => {
    if (isOpen && isEditTeam) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 350);
    }
  }, [isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      closeDrawer={closeHandler}
      workspaceDrawer
      title={<TeamsDrawerTitle />}
      actionBlock={<TeamsDrawerFooter />}
      preloader={<WsDrawerPreloader />}
      loading={isLoading}
    >
      <div className={classes.content}>
        <TeamsDrawerName />
        {!dataDrawer?.canOnlyView && <TeamsDrawerSettings />}
        <TeamsDrawerMembers />
        <TeamsDrawerProject />
      </div>
    </Drawer>
  );
});
