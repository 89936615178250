import { Typography } from '@plarin/design';
import { int, float2 } from '@plarin/utils';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React, { useState } from 'react';
import { getFormType, getTypeVariants, headerTypeVariants2 } from '../../../edit-bid';
import { getSingleBidMode, StrategyBidModes, formatObjective } from '../../../edit-bid/strategy-form-utils';
import { getPriceForBidCell } from '../../../edit-bid/bidsUtils';
import { getItemsType } from '../../../inputs';
import { getCurrencySymbol } from '../../../inputs';
import { MtOptimisationLevel } from '../../../inputs/bids/utils';
import { Popover } from '../../../popover';
import { SkeletonTableBlock } from '../../../preloader';
import classes from './style.module.scss';

interface TComponent extends React.FunctionComponent {
  gridApi: GridApi;
  close: () => void;
}

interface TBidCellRendererParams extends ICellRendererParams {
  Component: TComponent;
}

export const BidCellRenderer = (props: TBidCellRendererParams) => {
  const { data, Component, api } = props;
  const { bidType, cellLoadingName, price } = data;
  const [isFocused, setIsFocused] = useState(false);
  const archived: Boolean = data.status?.includes('Архивн');
  const isSurvey = data.adPlanObjective === 'survey';
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const currencySymbol = getCurrencySymbol(data.accountCurrency);

  const handleClick = (event: MouseEvent, ref: HTMLDivElement) => {
    event.stopPropagation();
    if (!archived) {
      setAnchorEl(ref);
      setIsFocused(true);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(false);
  };

  const open = Boolean(anchorEl);

  if (cellLoadingName === 'bid' || price === 'loading')
    return <SkeletonTableBlock withCheckbox isReversLong height={17} isRightContent width={100} />;

  const fixedPrice = () => {
    const bidVal = `${getPriceForBidCell(data.bidCurrent, currencySymbol)}`;

    return (
      <>
        <div
          className={clsx(classes.dashed, archived && classes.dashedArchived)}
          ref={ref => {
            if (!ref) return;
            ref.onclick = event => handleClick(event, ref);
          }}
        >
          {archived ? (
            <Typography color="textDisable">{bidVal}</Typography>
          ) : (
            <Typography color={!isFocused ? 'LinkPrimary' : ''}>{bidVal}</Typography>
          )}
        </div>
        <Typography color={archived ? 'textDisable' : 'TextSecondary'} size="TableRowSubHeader">
          {bidType}
        </Typography>
      </>
    );
  };

  const strategyPrice = () => {
    const getHeader = () => {
      if (getTypeVariants(data.adPlanObjective) === 'inputsTypeVariants2') {
        return headerTypeVariants2(data.bidAutobiddingMode, data.price, currencySymbol);
      }

      const minPrice = 'Мин. цена';
      const getMaxPrice = () => {
        const formattedPrice = getPriceForBidCell(data.maxPrice, currencySymbol);
        return `Пред. цена${data.maxPrice && ` (${formattedPrice})`}`;
      };

      return getSingleBidMode(data) === StrategyBidModes.maxPrice ? getMaxPrice() : minPrice;
    };

    const bidType = data.bidType ? `${data.bidType}` : '';

    const subheader = `${formatObjective(data)}` + `${bidType && ' — '}${bidType}`;

    return (
      <>
        {isSurvey ? null : data.budgetOptimizationLevel === MtOptimisationLevel.adGroup &&
          getItemsType(window.location.pathname) === 'ad_plan' ? (
          /* Если это ячейка для вкладки "Кампании" для кампании (адплана) с оптимизацией бюджетов на уровне группы, то в ячейке не пишем название стратегии (мы ее даже не знаем, статегия определена для групп)*/
          <div className={clsx(archived && classes.dashedArchived)}>
            <Typography color={archived ? 'textDisable' : 'TextPrimary'}>Стратегия группы</Typography>
          </div>
        ) : (
          <div
            className={clsx(classes.dashed, archived && classes.dashedArchived)}
            ref={ref => {
              if (!ref) return;
              ref.onclick = event => handleClick(event, ref);
            }}
          >
            <Typography color={archived ? 'textDisable' : !isFocused ? 'LinkPrimary' : ''}>{getHeader()}</Typography>
          </div>
        )}
        <Typography color={archived ? 'textDisable' : 'TextSecondary'} size="TableRowSubHeader">
          {subheader}
        </Typography>
      </>
    );
  };

  const PlugAutoPrice = (
    <div className={clsx(classes.rightAlign, archived && classes.dashedArchived)}>
      <Typography color={archived ? 'textDisable' : 'TextPrimary'}>
        Автоуправление
        <br /> ценой
      </Typography>
    </div>
  );

  const PlugMarginalRate = () => {
    return (
      <>
        <div className={clsx(archived && classes.dashedArchived)}>
          <Typography color={archived ? 'textDisable' : 'TextPrimary'}>
            Пред. цена{data.maxPrice && ` (${getPriceForBidCell(data.maxPrice, currencySymbol)})`}
          </Typography>
        </div>
      </>
    );
  };

  return (
    <div className={clsx(classes.cellWrapper, isSurvey && classes.cellWrapperSurvey, isFocused && classes.cellFocused)}>
      {getFormType(data) === 'FixedEditBid' && fixedPrice()}
      {getFormType(data) === 'StrategyEditBid' && strategyPrice()}

      {/* Неклибальные ячейки для рекламных объектов, редактирование которых мы не поддерживаем */}
      {getFormType(data) === 'PlugAutoPrice' && PlugAutoPrice}
      {/* {getFormType(data) === 'PlugMaxShows' && PlugMaxShows} */}
      {getFormType(data) === 'PlugMarginalRate' && PlugMarginalRate()}

      <Popover
        classNameWrapper={classes.popoverWrapper}
        isListLeft
        isOpen={open}
        anchorEl={anchorEl?.parentElement || null}
        setIsOpen={handleClose}
        marginTop={3}
        zIndex={1200}
      >
        <Component
          // @ts-ignore
          data={props.data}
          close={handleClose}
          gridApi={api}
        />
      </Popover>
    </div>
  );
};
