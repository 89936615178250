import { Typography } from '@plarin/design';
import { Modal, ModalActionNamesEnum } from '@plarin/inputs';
import React from 'react';

export const ModalCorrection = ({
  firstAction,
  secondAction,
  onCloseModal,
}: {
  firstAction: () => void;
  secondAction: () => void;
  onCloseModal: () => void;
}) => {
  return (
    <Modal
      open={true}
      closeModal={onCloseModal}
      workspaceHeader="saveExit"
      title="Сохранить изменения?"
      secondAction={{
        actionTitle: ModalActionNamesEnum.NoSaveExit,
        action: firstAction,
      }}
      firstAction={{
        actionTitle: ModalActionNamesEnum.SaveExit,
        action: secondAction,
      }}
    >
      <Typography size="AuthContent">
        Вы внесли изменения, которые не были сохранены.
        <br />
        Сохранить изменения перед выходом?
      </Typography>
    </Modal>
  );
};
