import { ModalProps as MUIModalProps } from '@mui/material';

export type Action = {
  actionTitle?: ModalActionNamesEnum;
  action?: () => void;
  isLoading?: boolean;
};

export enum ModalActionNamesEnum {
  Change = 'Изменить',
  Cancel = 'Отменить',
  Delete = 'Удалить',
  Save = 'Сохранить',
  SaveExit = 'Сохранить и выйти',
  NoSaveExit = 'Выйти без сохранения',
  Send = 'Отправить',
  block = 'Заблокировать',
  unBlock = 'Разблокировать',
  Archive = 'Архивировать',
  UnArchive = 'Разархивировать',
  Completed = 'Завершить',
  Active = 'Восстановить',
  Untie = 'Отвязать',
  Pause = 'Выключить',
  Unplug = 'Отключить',
  Invite = 'Пригласить',
  Connect = 'Подключить',
}

export interface ModalProps extends MUIModalProps {
  closeModal?: () => void;
  title?: string;
  firstAction?: Action;
  secondAction?: Action;
  width?: number;
  disabledExit?: boolean;
  disabledActions?: boolean;
  disabledFirstAction?: boolean;
  icon?: JSX.Element;
  workspaceHeader?:
    | 'invite'
    | 'block'
    | 'unBlock'
    | 'remove'
    | 'archive'
    | 'unArchive'
    | 'completed'
    | 'active'
    | 'person'
    | 'profile'
    | 'untie'
    | 'edit'
    | 'pause'
    | 'saveExit'
    | 'connectAppsflyer';
  classNameChildren?: string;
  classNameIcon?: string;
}

export type TModalActionProps = ModalProps['firstAction'];

export type TSetModalAction = (modalAction?: TModalActionProps) => Action | undefined;
