import { Typography } from '@plarin/design';
import { getMemberName, shortDate, typografNames } from '@plarin/utils';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import clsx from 'clsx';
import React from 'react';
import { HightLightText } from '../../../../hightlight-text';
import { EllipsisTooltip } from '../../../../tooltip';
import classes from '../style.module.scss';

type TLinesMember = {
  fname?: string;
  lname?: string;
  email: string;
  created?: string;
  api: GridApi;
};

export const LinesMember = ({ fname, lname, email, created, api }: TLinesMember) => {
  // @ts-ignore
  const filterValue = api.filterManager.quickFilter || '';

  return (
    <div className={classes.lines}>
      {created ? (
        <>
          <div className={clsx(classes.first)}>
            <EllipsisTooltip tooltipMessage={email}>
              <Typography ellips={true} weight={600} color="TextSecondary">
                {email}
              </Typography>
            </EllipsisTooltip>
          </div>
          <div className={clsx(classes.second)}>
            <EllipsisTooltip tooltipMessage={`Приглашён ${shortDate(created)}`}>
              <Typography ellips={true} size="TableRowSubHeader" color="TextSecondary">{`Приглашён ${shortDate(
                created,
              )}`}</Typography>
            </EllipsisTooltip>
          </div>
        </>
      ) : (
        <>
          <div className={clsx(classes.first)}>
            <EllipsisTooltip tooltipMessage={getMemberName({ fname, lname, email })} maxWidth={480}>
              <HightLightText
                weight={600}
                textEllips={true}
                text={typografNames(getMemberName({ fname, lname, email }))}
                filterValue={filterValue}
              />
            </EllipsisTooltip>
          </div>
          <div className={clsx(classes.second)}>
            <EllipsisTooltip tooltipMessage={email}>
              <HightLightText
                size="TableRowSubHeader"
                color="TextSecondary"
                textEllips={true}
                text={email}
                filterValue={filterValue}
              />
            </EllipsisTooltip>
          </div>
        </>
      )}
    </div>
  );
};
