import { BasicStore, httpClient, httpClient2, httpClient4 } from '@plarin/core';
import {
  ManageYDTabs,
  manageYDTabs,
  MENU_ITEM_LABEL,
  objYDTypes,
  TDateOption,
  GridApi,
  statusEntities,
  statusToSet,
  StateValueEnum,
} from '@plarin/inputs';
import {
  getSafeParsedLocalStorage,
  ManageYDTabNameEnum,
  NotificationErrorTitle,
  NotificationTitle,
} from '@plarin/utils';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { toast } from 'react-toastify';
import { DateOptions } from '../../types/common-types';
import { YdCampaignStatuses, YdAdStatuses, ChangeStatusBody, YdBidResp } from '../../types/connect-yd/types';
import { QuantitySelected, TStatusYD } from '../../types/manage-yd/types';
import {
  Dictionary,
  TManageYDData,
  TMetricsForGroupingYD,
  TRelativePeriodDate,
  TYdAdGroupsParamsReq,
  TYdObjType,
} from '../../types/manage-yd/types';
import { TYDMetric, TYDMetricGroup } from '../../types/metrics';
import { mainTabData } from '../dictionary/manage-yd';
import { DownloadExcelError, ignoredYDGroupingMetrics, STATUS, STORAGE_ITEM } from '../utils/constants';
import { downloadFile } from '../utils/downloadFIle';
import { grouping } from '../utils/grouping';
import { returnItemStateByTabName } from '../utils/manage-yd';
import { parseStatistics, parseStringToCamelCase } from '../utils/parseStatData/parseStatistics';
import { parseToData } from '../utils/parseStatData/parseYdData';

type DOptions = Pick<TDateOption, 'endDate' | 'startDate'> | TRelativePeriodDate;

// const checkFast72 = (): boolean => {
//   const checkedMetricsYD = localStorage.getItem('checkedMetricsYD');
//   return !!checkedMetricsYD && JSON.parse(checkedMetricsYD).includes('satistics_fast72');
// };

export class ManageYDStore extends BasicStore {
  drawerAction?: () => void;
  // fast72Checked: boolean = checkFast72();
  metricsYD?: TYDMetricGroup[];
  metricsForGroupingYD: TMetricsForGroupingYD = {} as TMetricsForGroupingYD;
  dictionary?: Dictionary;
  data?: TManageYDData[] = [];
  errorTable?: boolean = false;
  drawerVisible: boolean = false;
  metricsFilterValue: string = '';
  isAccountColumnActive: boolean = false;
  tableTabs: number[] = [3, 5];
  tableLoading: boolean = false;
  filterStatuses?: TStatusYD = 'not_archived';
  dateOptions: DOptions = {} as DOptions;
  filterValue?: string;
  date: DateOptions = {
    storeLabel: MENU_ITEM_LABEL.All_TIME,
    storeEndDate: Date.now(),
    storeStartDate: +new Date('2012, 01, 01'),
  } as DateOptions;

  isGroupDestroyed: boolean = false;

  currentTab?: ManageYDTabNameEnum;
  selectedAccountsIds: number[] = [];
  selectedCampaignsIds: number[] = [];
  selectedAdGroupsIds: number[] = [];
  selectedAdIds: number[] = [];
  quantitySelected: QuantitySelected = {
    selectedAccounts: 0,
    selectedCampaigns: 0,
    selectedAdGroups: 0,
    selectedAds: 0,
  };
  deleteAction?: () => void;

  configRequestStat: Partial<TYdAdGroupsParamsReq> = {};

  constructor() {
    super();
    makeObservable<this>(this, {
      // fast72Checked: observable,
      isAccountColumnActive: observable,
      drawerAction: observable,
      metricsYD: observable,
      metricsForGroupingYD: observable,
      isGroupDestroyed: observable,
      errorTable: observable,
      dictionary: observable,
      data: observable.shallow,
      drawerVisible: observable,
      tableLoading: observable,
      filterStatuses: observable,
      dateOptions: observable,
      date: observable,
      filterValue: observable,
      metricsFilterValue: observable,

      currentTab: observable,
      selectedAccountsIds: observable.shallow,
      selectedCampaignsIds: observable.shallow,
      selectedAdGroupsIds: observable.shallow,
      selectedAdIds: observable.shallow,
      quantitySelected: observable.shallow,

      setSelectedIds: action,
      deleteAction: observable,
      tableTabs: observable,
    });
  }

  getSelectedIds = (tabName: ManageYDTabNameEnum) => {
    switch (tabName) {
      case ManageYDTabNameEnum.ACCOUNTS:
        return this.selectedAccountsIds;
      case ManageYDTabNameEnum.CAMPAIGNS:
        return this.selectedCampaignsIds;
      case ManageYDTabNameEnum.GROUPS:
        return this.selectedAdGroupsIds;
      case ManageYDTabNameEnum.ADS:
        return this.selectedAdIds;
      default:
        return [];
    }
  };

  setSelectedIds = (ids: number[], tabName: ManageYDTabNameEnum) => {
    if (tabName === ManageYDTabNameEnum.ACCOUNTS) {
      this.quantitySelected.selectedAccounts = ids.length;
    }

    if (tabName === ManageYDTabNameEnum.CAMPAIGNS) {
      this.quantitySelected.selectedCampaigns = ids.length;
    }

    if (tabName === ManageYDTabNameEnum.GROUPS) {
      this.quantitySelected.selectedAdGroups = ids.length;
    }

    if (tabName === ManageYDTabNameEnum.ADS) {
      this.quantitySelected.selectedAds = ids.length;
    }

    switch (tabName) {
      case ManageYDTabNameEnum.ACCOUNTS:
        this.selectedAccountsIds = ids;
        return;
      case ManageYDTabNameEnum.CAMPAIGNS:
        this.selectedCampaignsIds = ids;
        return;
      case ManageYDTabNameEnum.GROUPS:
        this.selectedAdGroupsIds = ids;
        return;
      case ManageYDTabNameEnum.ADS:
        this.selectedAdIds = ids;
        return;
    }
  };

  checkedTabs = (id: number) => {
    if (this.tableTabs.includes(id)) {
      runInAction(() => (this.tableTabs = this.tableTabs.filter(el => el !== id)));
    } else {
      runInAction(() => (this.tableTabs = [...this.tableTabs, id]));
    }
  };

  setDeleteAction = (action?: () => void) => {
    runInAction(() => (this.deleteAction = action));
  };

  setCurrentTab = (tabName: ManageYDTabNameEnum) => runInAction(() => (this.currentTab = tabName));

  setIsActiveAccountColumn = (value: boolean) => runInAction(() => (this.isAccountColumnActive = value));
  // setFast72Checked = (value: boolean) => runInAction(() => (this.fast72Checked = value));
  setDateOption = (dateOption: TDateOption) => {
    const { label, endDate, startDate } = dateOption;
    let result: DOptions = '' as TRelativePeriodDate;
    if (label === 'За сегодня') {
      result = 'today';
    }
    if (label === 'За вчера') {
      result = 'yesterday';
    }
    if (label === 'Текущая неделя') {
      result = 'this_week';
    }
    if (label === 'Прошлая неделя') {
      result = 'last_week';
    }
    if (label === 'Текущий месяц') {
      result = 'current_month';
    }
    if (label === 'Прошлый месяц') {
      result = 'last_month';
    }
    if (label === 'За определенный период') {
      result = { startDate, endDate };
    }
    if (label === 'За все время') {
      result = {} as DOptions;
    }
    runInAction(() => (this.date = { storeLabel: label, storeStartDate: startDate, storeEndDate: endDate }));
    runInAction(() => (this.dateOptions = result));
  };

  private setIsErrorTable = (isErrorTable: boolean) => runInAction(() => (this.errorTable = isErrorTable));
  private startLoading = () => runInAction(() => (this.tableLoading = true));
  private stopLoading = () => runInAction(() => (this.tableLoading = false));

  setMetricsYD = (metrics: TYDMetricGroup[]) =>
    runInAction(() => {
      this.metricsYD = metrics;
      this.processMetricsForGroupingYD(metrics);
    });

  setFilterStatuses = (newStatus: TStatusYD) => runInAction(() => (this.filterStatuses = newStatus));
  setFilterValue = (value: string) => runInAction(() => (this.filterValue = value));
  setMetricFilterValue = (value: string) => runInAction(() => (this.metricsFilterValue = value));
  setDrawerAction = (action?: () => void) => runInAction(() => (this.drawerAction = action));
  toggleDrawer = () => runInAction(() => (this.drawerVisible = !this.drawerVisible));
  closeDrawer = () => runInAction(() => (this.drawerVisible = false));

  processMetricsForGroupingYD(result: TYDMetricGroup[], fromData?: boolean) {
    const metricsForGroupingYD: TMetricsForGroupingYD = {
      account: [],
      ad_group: [],
      campaign: [],
      ad: [],
    };

    const groupMetricsArr = Object.keys(metricsForGroupingYD); // Оставляем тип string[]

    groupMetricsArr.forEach(groupingName => {
      // Проверяем, является ли ключ значением типа TObjType
      if (groupingName in metricsForGroupingYD) {
        result.forEach(group => {
          group.fields.forEach(metric => {
            if (
              metric.grouping &&
              metric.obj_types.includes(groupingName as TYdObjType) && // Приводим к TObjType здесь
              !ignoredYDGroupingMetrics[groupingName as TYdObjType].includes(metric.path)
            ) {
              if (fromData) {
                // Если fromData = true, добавляем метрики с checked: false
                metricsForGroupingYD[groupingName as TYdObjType].push({ ...metric, checked: false });
              } else {
                // Стандартная логика с проверкой isChecked
                const isChecked = this.metricsForGroupingYD[groupingName as TYdObjType]?.find(
                  el => el.path === metric.path,
                )?.checked;
                if (metric.checked || metric.hidden) {
                  metricsForGroupingYD[groupingName as TYdObjType].push({ ...metric, checked: isChecked || false });
                }
              }
            }
          });
        });
      }
    });

    // Дополняем существующие метрики и заменяем, если пути совпадают
    runInAction(() => {
      Object.keys(metricsForGroupingYD).forEach(groupingName => {
        if (groupingName in metricsForGroupingYD) {
          const existingMetrics = this.metricsForGroupingYD[groupingName as TYdObjType] || [];

          // Объединяем существующие метрики с новыми, заменяя дубли по path
          const updatedMetrics = [...existingMetrics];

          metricsForGroupingYD[groupingName as TYdObjType].forEach(newMetric => {
            const existingIndex = updatedMetrics.findIndex(m => m.path === newMetric.path);
            if (existingIndex > -1) {
              // Если метрика с таким path уже существует, заменяем её
              updatedMetrics[existingIndex] = newMetric;
            } else {
              // Иначе добавляем новую метрику
              updatedMetrics.push(newMetric);
            }
          });

          // Обновляем группу метрик
          this.metricsForGroupingYD[groupingName as TYdObjType] = updatedMetrics;
        }
      });
    });
  }

  getMetricsYD = async () => {
    const checkedMetricsYD = localStorage.getItem(STORAGE_ITEM.localStorage.METRICSYD);

    await this.execRequest<TYDMetricGroup[]>(httpClient2.get('/api/v1/core/flat_metrics/yd'))
      .then(res => {
        const dictionary = {} as Dictionary;
        res.forEach(group =>
          group.fields.forEach(metric => (dictionary[parseStringToCamelCase(metric.path)] = metric.type)),
        );

        const result = res.map(group => ({
          ...group,
          selected: false,
          fields: group.fields.map(metric => ({
            ...metric,
            checked: checkedMetricsYD ? checkedMetricsYD.includes(metric.path) : false,
          })),
        }));

        runInAction(() => (this.dictionary = dictionary));

        this.processMetricsForGroupingYD(result);

        this.setMetricsYD(
          result.map(group => ({
            ...group,
            fields: group.fields.map(metric => ({
              ...metric,
              checked: checkedMetricsYD ? checkedMetricsYD.includes(metric.path) : false,
            })),
          })),
        );
      })
      .catch(err => {
        this.onLoadError(err);
        runInAction(() => (this.metricsYD = []));
        runInAction(() => (this.metricsForGroupingYD = { campaign: [], ad: [], ad_group: [], account: [] }));
      });
  };

  // ===========change status===========
  changeManageYdStatus = async (
    ids: number[],
    status: YdAdStatuses | YdCampaignStatuses,
    tabName: ManageYDTabNameEnum,
    gridApi: GridApi,
  ) => {
    const reqBody: ChangeStatusBody = { ids, status: status };
    let path = '';

    // можем поменять статус только в этих двух вкладках
    if (tabName === ManageYDTabNameEnum.CAMPAIGNS) path = '/api/v1/connect/yd/campaign/status';
    if (tabName === ManageYDTabNameEnum.ADS) path = '/api/v1/connect/yd/ad/status';

    const rows = ids.map(id => gridApi.getRowNode(id.toString())?.data);
    gridApi.applyTransaction({ update: rows.map(el => ({ ...el, cellLoadingName: 'status' })) });
    gridApi.redrawRows();

    await this.execRequest<YdBidResp>(httpClient2.post(path, reqBody))
      .then(response => {
        if (response) {
          this.changeStatusSuccess(ids, status, response, gridApi, tabName);
        } else {
          throw new Error('Сервер вернул ошибку, попробуйте позднее');
        }
      })
      .catch((error: string) => this.changeStatusError(error))
      .finally(() => {
        const rows = ids.map(id => gridApi.getRowNode(id?.toString())?.data);
        gridApi.applyTransaction({ update: rows.map(el => ({ ...el, cellLoadingName: '' })) });
        gridApi.redrawRows();
      });
  };

  private changeStatusSuccess = (
    ids: number[],
    status: YdAdStatuses | YdCampaignStatuses,
    resp: YdBidResp,
    gridApi: GridApi,
    tabName: ManageYDTabNameEnum,
  ) => {
    let statusKeys = {};

    const objState = returnItemStateByTabName(tabName) || '--gotErrorTabName--';

    if (status === statusToSet.SUSPEND) {
      statusKeys = {
        ...statusEntities.SUSPENDED,
        [objState]: StateValueEnum.SUSPENDED,
      };
    } else if (status === statusToSet.RESUME) {
      statusKeys = {
        ...statusEntities.ON,
        [objState]: StateValueEnum.ON,
      };
    } else if (status === statusToSet.ARCHIVE) {
      statusKeys = {
        ...statusEntities.ARCHIVED,
        [objState]: StateValueEnum.ARCHIVED,
      };
    } else if (status === statusToSet.UNARCHIVE) {
      statusKeys = {
        ...statusEntities.SUSPENDED,
        [objState]: StateValueEnum.SUSPENDED,
      };
    }

    if (resp.errors?.length) {
      this.addNotification({
        type: STATUS.ERROR,
        title: 'Ошибка изменения статуса',
        message:
          resp.errors.length > 1
            ? resp.errors.map(e => e.obj_id).join()
            : resp.errors[0].message
            ? resp.errors[0].message
            : 'Произошла ошибка при изменении статуса',
      });
    } else {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: 'Статус успешно изменен',
      });

      this.data!.forEach(el => (ids.includes(el.key) ? { ...el, ...statusKeys, cellLoadingName: '' } : el));

      gridApi.applyTransaction({
        update: ids.map(id => ({
          ...gridApi.getRowNode(id?.toString())?.data,
          ...statusKeys,
        })),
      });
    }
  };

  private changeStatusError = (error: string) => {
    this.addNotification({
      type: 'error',
      title: 'Ошибка изменения статуса',
      message: error,
    });
  };

  private onLoadError = (error: any) => {
    runInAction(() => {
      this.data = [];
      this.errorTable = !!error;
    });
  };

  getYDStat = async (
    currentTabName: ManageYDTabs,
    //  wsDictionary: TWsDictionary
  ) => {
    this.startLoading();
    this.setIsErrorTable(false);

    !this.metricsYD?.length &&
      (await this.getMetricsYD().catch(err => {
        this.onLoadError(err);
        throw new Error(err);
      }));

    let reqPath = '';
    let reqPathStat = '';

    const selectedStatus = this.filterStatuses;

    const requestConfig = {
      status: selectedStatus?.length ? selectedStatus : 'not_archived',
      cols: [],
    } as TYdAdGroupsParamsReq;

    if (typeof this.dateOptions === 'string') {
      requestConfig.relative_period = this.dateOptions;
    } else {
      if (this.dateOptions?.startDate && this.dateOptions?.endDate) {
        requestConfig.date_from = moment(this.dateOptions?.startDate).format('YYYY-MM-DD');
        requestConfig.date_to = moment(this.dateOptions?.endDate).format('YYYY-MM-DD');
      }
    }

    const checkedMetricsYD = getSafeParsedLocalStorage<string[]>(STORAGE_ITEM.localStorage.METRICSYD);
    checkedMetricsYD &&
      this.metricsYD &&
      this.metricsYD.forEach(group =>
        group.fields.forEach(
          metric =>
            checkedMetricsYD.includes(metric.path) &&
            !!metric.obj_types.filter(objType => objType === objYDTypes[currentTabName]).length &&
            //  metric.path !== FAST_STAT.SatisticsFast72 &&
            // metric.path !== FAST_STAT.SatisticsFast1 &&
            requestConfig.cols?.push(metric.path),
        ),
      );

    this.configRequestStat = requestConfig;

    if (currentTabName === manageYDTabs.ACCOUNTS) {
      reqPath = '/api/v1/core/yd/accounts';
      // reqPathStat = '/api/v1/core/mt/accounts/additional';
      delete requestConfig.status;
    }

    if (currentTabName === manageYDTabs.CAMPAIGNS) {
      reqPath = '/api/v1/core/yd/campaigns';
      // reqPathStat = '/api/v1/core/mt/ad_plans/additional';
      this.selectedAccountsIds.length && (requestConfig.client_ids = this.selectedAccountsIds);
    }

    if (currentTabName === manageYDTabs.GROUPS) {
      reqPath = '/api/v1/core/yd/ad_groups';
      // reqPathStat = '/api/v1/core/mt/ad_groups/additional';
      this.selectedAccountsIds.length && (requestConfig.client_ids = this.selectedAccountsIds);
      this.selectedCampaignsIds.length && (requestConfig.campaign_ids = this.selectedCampaignsIds);
    }

    if (currentTabName === manageYDTabs.ADS) {
      reqPath = '/api/v1/core/yd/ads';
      // reqPathStat = '/api/v1/core/mt/ads/additional';
      this.selectedAccountsIds.length && (requestConfig.client_ids = this.selectedAccountsIds);
      this.selectedCampaignsIds.length && (requestConfig.campaign_ids = this.selectedCampaignsIds);
      this.selectedAdGroupsIds.length && (requestConfig.ad_group_ids = this.selectedAdGroupsIds);
    }

    await this.execRequest<string>(httpClient.post(reqPath, requestConfig))
      .then(async resp => {
        const statistic = parseStatistics(resp);
        const columns = statistic[0].map(item => parseStringToCamelCase(item));
        const values = statistic.filter((_, index) => index !== 0);

        const filteredMetrics = this.metricsYD
          ?.map(el => ({
            ...el,
            fields: el.fields.filter(a => !!a.grouping && statistic[0].includes(a.path)), // Фильтруем поля
          }))
          .filter(el => el.fields.length > 0);

        filteredMetrics && this.processMetricsForGroupingYD(filteredMetrics, true);

        const data = values.length
          ? parseToData(
              columns,
              values,
              this.dictionary || {},
              currentTabName,
              // wsDictionary
            )
          : [];

        const group = this.metricsForGroupingYD[objYDTypes[currentTabName]].filter(el => el.checked);

        runInAction(() => {
          this.data =
            data && group.length
              ? grouping({
                  primaryMetric: group[0], // Первая метрика для группировки
                  // secondaryMetric: undefined, // Вторая метрика для вложенной группировки, если она есть, иначе `undefined`
                  data: data, // Данные для группировки
                  metrics: this.metricsYD || [], // Метрики для обработки
                  mainTabData: mainTabData[currentTabName] as [string, string], // Данные таба, преобразованные в кортеж из двух строк
                })
              : data;
        });
      })
      .catch(() => {
        this.onLoadError;
      })
      .finally(() => {
        this.stopLoading();
        // this.checkTranslation(currentTabName);
      });
  };

  setIsGroupDestroyed = (value: boolean) => runInAction(() => (this.isGroupDestroyed = value));

  setGroupingMetrics = (tab: ManageYDTabs, path: string, clickedMetric?: TYDMetric) => {
    runInAction(
      () =>
        (this.metricsForGroupingYD = {
          ...this.metricsForGroupingYD,
          [objYDTypes[tab]]: this.metricsForGroupingYD[objYDTypes[tab]].map(metric =>
            metric.path === path ? { ...metric, checked: !metric.checked } : { ...metric, checked: false },
          ),
        }),
    );

    runInAction(() => (this.tableLoading = true));

    setTimeout(() => {
      if (clickedMetric && !clickedMetric.checked && path) {
        if (new Set(Object.keys(this.data![0])).has('orgHierarchy')) {
          const data = this.data!.filter(el => el.orgHierarchy && el.orgHierarchy.length).map(el => {
            const { orgHierarchy, ...data } = el;
            return { ...data };
          });
          runInAction(
            () =>
              (this.data = grouping({
                primaryMetric: clickedMetric, // Первая метрика для группировки
                // secondaryMetric: undefined, // Вторая метрика для вложенной группировки, если она есть, иначе `undefined`
                data: data ?? [], // Данные для группировки
                metrics: this.metricsYD ?? [], // Метрики для обработки
                mainTabData: mainTabData[tab] as [string, string], // Данные таба, преобразованные в кортеж из двух строк
              })),
          );
        } else {
          runInAction(
            () =>
              (this.data = grouping({
                primaryMetric: clickedMetric, // Первая метрика для группировки
                // secondaryMetric: undefined, // Вторая метрика для вложенной группировки, если она есть, иначе `undefined`
                data: this.data || [], // Данные для группировки
                metrics: this.metricsYD || [], // Метрики для обработки
                mainTabData: mainTabData[tab] as [string, string], // Данные таба, преобразованные в кортеж из двух строк
              })),
          );
        }
      } else {
        if (new Set(Object.keys(this.data![0])).has('orgHierarchy')) {
          const data = this.data!.filter(el => el.orgHierarchy && el.orgHierarchy.length).map(el => {
            const { orgHierarchy, ...data } = el;
            return { ...data };
          });
          runInAction(() => (this.data = data));
        }
      }
      runInAction(() => (this.tableLoading = false));
    }, 300);
  };

  downloadExcelStat = async (currentTabName: ManageYDTabs, setLoading: Dispatch<SetStateAction<boolean>>) => {
    let reqPath = '';
    const controller = new AbortController();
    const keyNotification = Date.now();
    const body = { ...this.configRequestStat, xlsx: 1 };

    this.addNotification({
      title: NotificationTitle.DOWNLOAD_REPORT,
      type: STATUS.LOADING,
      message: 'Идет подготовка отчета...',
      key: keyNotification,
      canceledDownloadExcel: e => {
        e.stopPropagation();
        controller.abort();
        setLoading(false);
      },
    });

    if (currentTabName === manageYDTabs.ACCOUNTS) {
      reqPath = '/api/v1/core/yd/accounts';
      this.selectedAccountsIds.length && (body.client_ids = this.selectedAccountsIds);
      delete body.status;
    }

    if (currentTabName === manageYDTabs.CAMPAIGNS) {
      reqPath = '/api/v1/core/yd/campaigns';
      this.selectedCampaignsIds.length && (body.campaign_ids = this.selectedCampaignsIds);
    }

    if (currentTabName === manageYDTabs.GROUPS) {
      reqPath = '/api/v1/core/yd/ad_groups';
      this.selectedAdGroupsIds.length && (body.ad_group_ids = this.selectedAdGroupsIds);
    }

    if (currentTabName === manageYDTabs.ADS) {
      reqPath = '/api/v1/core/yd/ads';
      this.selectedAdIds.length && (body.ad_ids = this.selectedAdIds);
    }

    setTimeout(async () => {
      try {
        let name = '';
        const blob = await this.execRequest(
          httpClient
            .post(reqPath, body, {
              responseType: 'blob',
              signal: controller.signal,
            })
            .then((res: any) => {
              // prettier-ignore
              name = decodeURIComponent(escape(atob(res.headers.get('content-disposition').split('"')[1])));
              return res;
            }),
        );

        downloadFile(blob, name);
        // Удаление нотификатора
        this.deleteNotification(keyNotification);
        toast.dismiss(keyNotification);
      } catch (e) {
        if (!controller.signal?.aborted) {
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.LOAD_ERROR,
            message: <DownloadExcelError />,
          });
        }
      } finally {
        setLoading(false);
      }
    }, 1500);
  };

  resetStore = () =>
    runInAction(() => {
      this.metricsFilterValue = '';
      this.drawerAction = undefined;
      this.tableLoading = false;
      // this.filterStatuses = [StatusEnum.active, StatusEnum.blocked];
      this.filterValue = undefined;
      this.date = {
        storeLabel: MENU_ITEM_LABEL.All_TIME,
        storeEndDate: Date.now(),
        storeStartDate: +new Date('2012, 01, 01'),
      } as DateOptions;
      // this.tableTabs = [3, 5];
    });
}
