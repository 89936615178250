import { Checkbox as CheckboxMaterial, FormControl, FormControlLabel } from '@mui/material';
import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import classes from './checkbox.module.scss';
import { IconChecked } from './icon-checked';
import { IconPartChecked } from './icon-part-checked';
import { IconUnchecked } from './icon-unchecked';

interface CheckboxProps {
  value: boolean;
  onChange?: (newValue: boolean) => void;
  label?: string;
  labelSize?: string;
  colorLabel?: string;
  disabled?: boolean;
  className?: string;
  labelMargin?: string;
  weightLabel?: number;
  size?: 'small' | 'medium';
  partChecked?: boolean;
  isInverted?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = function Checkbox({
  value,
  onChange,
  label,
  labelSize,
  colorLabel,
  disabled,
  className,
  labelMargin,
  weightLabel,
  size,
  partChecked = false,
  isInverted,
}) {
  let sizeValue = 16;
  if (size === 'small') {
    sizeValue = 16;
  }
  if (size === 'medium') {
    sizeValue = 20;
  }

  return (
    <FormControl>
      <FormControlLabel
        className={clsx(classes.controlLabel, className)}
        control={
          <CheckboxMaterial
            checked={value}
            disabled={disabled}
            classes={{
              root: clsx(
                size === 'small' && classes.small,
                size === 'medium' && classes.medium,
                labelMargin ? labelMargin : classes.labelMargin,
              ),
            }}
            onChange={e => void onChange?.(e.target.checked)}
            checkedIcon={partChecked ? <IconPartChecked /> : <IconChecked isInverted={isInverted} size={sizeValue} />}
            icon={<IconUnchecked size={sizeValue} />}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            indeterminate={false}
          />
        }
        label={
          <Typography
            weight={weightLabel}
            size={labelSize ? labelSize : 'authContent'}
            color={colorLabel ? colorLabel : 'textSecondary'}
          >
            {label}
          </Typography>
        }
      />
    </FormControl>
  );
};
