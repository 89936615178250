import { getItemsType, MtOptimisationLevel } from '@plarin/inputs';
import { manageVkTableMainNames, ManageVkTabNameEnum, isUrlPreProd, isUrlProd } from '@plarin/utils';
import { Dictionary, MetricRecord } from '../../../types/manage-vk/types';
import { TWsDictionary } from '../../../types/profile/types';

type StatusKey = '0' | '1' | '2';

export const parseToData = (
  columns: string[],
  values: string[][],
  dict: Dictionary,
  tabName: ManageVkTabNameEnum,
  wsDictionary: TWsDictionary,
) => {
  const statusTranslations: { [key in StatusKey]: string } = {
    '0': tabName === 'ad_ids' ? 'Остановлено' : 'Остановлена',
    '1': tabName === 'ad_ids' ? 'Активное' : 'Активная',
    '2': tabName === 'ad_ids' ? 'Архивное' : 'Архивная',
  };

  const getStatusColor = (status: string, deliveryStatus: string, moderationStatus: string): string => {
    if (status === '2') return 'archived';
    if (status === '0') return moderationStatus === 'banned' ? 'banned' : 'stopped';
    if (status === '1') {
      if (deliveryStatus === 'delivering') return 'active';
      return moderationStatus === 'banned' ? 'banned' : 'stopped';
    }
    return '';
  };

  const getSortByStatus = (status: string): number => {
    if (status.includes('Активн')) return 0;
    if (status.includes('Остан')) return 1;
    if (status.includes('Архив')) return 2;
    return 3;
  };

  const idMapping: { [key: string]: string } = {
    client_ids: 'accountId',
    ad_plan_ids: 'adPlanId',
    campaign_ids: 'campaignId',
    ad_ids: 'adId',
  };
  const returnId = idMapping[tabName] || 'accountId';

  const columnIndexMap: { [key: string]: number } = columns.reduce((acc, col, index) => {
    acc[col] = index;
    return acc;
  }, {} as { [key: string]: number });

  const processStat = (stat: string[]): MetricRecord => {
    const obj: MetricRecord = {};
    for (const col in columnIndexMap) {
      const index = columnIndexMap[col];
      const type = dict[col];
      const value = stat[index];

      if (type === 'int' || type === 'percent' || type === 'float') {
        obj[col] = +value;
      } else if (type === 'currency' && value) {
        obj[col] = parseFloat(value);
      } else if (type === 'json' && value) {
        obj[col] = JSON.parse(value.replace(/('|`|^\w)/g, ''));
      } else {
        obj[col] = value;
      }
    }
    obj.key = +obj[returnId];
    obj.cellLoadingName = '';
    obj.previewSmall = 'none';

    if (obj.status) {
      const status = obj.status as string;
      const deliveryStatus = obj.deliveryStatus as string;
      const adModerationStatus = (obj.adModerationStatus as string) || '';
      obj.pastStatus = '';
      obj.nextStatus = '';
      obj.statusColor = getStatusColor(status, deliveryStatus, adModerationStatus);
      obj.status = statusTranslations[status as StatusKey] || '';
    }

    obj.budgetOptimizationLevel =
      obj.network === 'mt'
        ? 'none'
        : obj.adPlanDaily || obj.adPlanLifetime
        ? MtOptimisationLevel.adPlan
        : MtOptimisationLevel.adGroup;

    if (!isUrlProd && !isUrlPreProd && obj.adPlanId) {
      const items = getItemsType(window.location.pathname);
      const isVaNetwork = obj.network === 'va';
      const isMtNetwork = obj.network === 'mt';

      // Фейковые лимиты и траты
      const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;

      const setRandomValues = (field: string, lifetimeField: string, dailyField: string) => {
        if (isVaNetwork) {
          obj.statisticsSpentAll = getRandomArbitrary(0, +obj[lifetimeField] || 99000000);
        } else if (isMtNetwork) {
          if (!obj.statisticsSpentAll && !obj[lifetimeField]) {
            const randomSpentAll = getRandomArbitrary(0, 99000000);
            obj.statisticsSpentAll = randomSpentAll;
            obj[lifetimeField] = randomSpentAll;
          }
          if (!obj.statisticsSpentToday && !obj[dailyField]) {
            obj[dailyField] = getRandomArbitrary(0, 99000000);
          }
        }
      };

      switch (items) {
        case 'ad_plan':
          setRandomValues('statisticsSpentAll', 'adPlanLifetime', 'adPlanDaily');
          break;
        case 'campaign':
        case 'banner':
          setRandomValues('statisticsSpentAll', 'campaignLifetime', 'campaignDaily');
          break;
      }
    }

    if (!isUrlProd && !isUrlPreProd) {
      // Фейковые лимиты и траты
      const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;

      if (obj.accountName === 'VKADS TESTS') {
        obj.accountCurrency = 'USD';
      }

      if (obj.accountCurrency === 'USD') {
        obj.statisticsSpent = getRandomArbitrary(0, 9900);
        obj.statisticsSpentAll = getRandomArbitrary(0, 9900);
        obj.statisticsCpc = getRandomArbitrary(0, 9900);
        obj.statisticsCpm = getRandomArbitrary(0, 99);
      }

      if (obj.accountCurrency === 'EUR') {
        obj.statisticsSpent = getRandomArbitrary(0, 9900);
        obj.statisticsSpentAll = getRandomArbitrary(0, 9900);
        obj.statisticsCpc = getRandomArbitrary(0, 9900);
        obj.statisticsCpm = getRandomArbitrary(0, 99);
      }
    }

    if (obj.budgetLifetime) {
      obj.percentBudgetLifetime = `${100 - (+obj.statisticsSpentAll / +obj.budgetLifetime) * 100 || 100}`;
    }

    if (typeof obj.status !== 'number' && !obj.status?.includes('Архив')) {
      obj.fastStat = 0;
      obj.fastStat72 = 0;
      obj.statisticsSpentToday = 0;
    }

    if ('mtProject' in obj) {
      // @ts-ignore
      obj.mtProject = (obj.mtProject as string)
        .split(',')
        .map(el => wsDictionary.projects[el] || { name: 'Не определен' });
    }

    if ('responsible' in obj) {
      obj.responsible = wsDictionary.members[obj.responsible as string] || 'Не указано';
    }

    return obj;
  };

  const data = values.map(processStat);

  if (data.length > 0 && 'status' in data[0]) {
    const sortAndFilterData = (data: MetricRecord[]): MetricRecord[] => {
      data.sort((a, b) => {
        const aStatus = getSortByStatus(a.status as string);
        const bStatus = getSortByStatus(b.status as string);
        if (aStatus === bStatus) {
          return (a[manageVkTableMainNames[tabName]] as string).localeCompare(
            b[manageVkTableMainNames[tabName]] as string,
          );
        }
        return aStatus - bStatus;
      });

      const statusFilter = (statusIncludes: string): MetricRecord[] =>
        data.filter(obj => typeof obj.status === 'string' && obj.status.includes(statusIncludes));

      const active = statusFilter('Активн');
      const disabled = statusFilter('Остановл');
      const archived = statusFilter('Архивн');

      const activeActive = active.filter(el => el.statusColor !== 'banned');
      const activeBanned = active.filter(el => el.statusColor === 'banned');
      const disabledActive = disabled.filter(el => el.statusColor !== 'banned');
      const disabledBanned = disabled.filter(el => el.statusColor === 'banned');

      return data[0].adModerationStatus
        ? [...activeActive, ...activeBanned, ...disabledActive, ...disabledBanned, ...archived]
        : [...active, ...disabled, ...archived];
    };

    return sortAndFilterData(data);
  }

  return data;
};
