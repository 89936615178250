import { CloseCircleIcon, Typography } from '@plarin/design';
import { ActionTableButton, Popover, CustomList } from '@plarin/inputs';
import { sendMetricGoal } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useMemo, useRef, useState } from 'react';
import { TMetric, TObjType } from '../../../types/metrics';
import { useAppDataStore } from '../app-data-provider';
import { GroupingButtonContent } from './grouping-button-content';
import { GroupingNameItem } from './grouping-name-item';
import classes from './style.module.scss';
import { TMetricsForGrouping } from '../../stores/manageVK.store';

type GroupingMetricsProps = {
  tab: TObjType;
  path: string;

  checkedMetricIndex?: number;
};

interface TableGroupingVKProps {
  tab: TObjType;
  metricsForGrouping: TMetricsForGrouping;
  setGroupingMetrics: (props: GroupingMetricsProps) => void;
  title: string;
  checkedMetricIndex: number;
}

export const TableGroupingVK = ({
  tab,
  metricsForGrouping,
  setGroupingMetrics,
  title,
  checkedMetricIndex = 0,
}: TableGroupingVKProps) => {
  const metrics = metricsForGrouping[tab];
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [arrowActive, setArrowActive] = useState(false);
  const [popoverClose, setPopoverClose] = useState(false);

  const openMenu = () => {
    setArrowActive(true);
    setPopoverClose(false);
    setOpen(true);
  };

  const closeMenu = () => {
    setArrowActive(false);
    setPopoverClose(true);
    setOpen(false);
  };

  const activeMetrics = useMemo(() => {
    const checkedMetrics = metrics.filter(el => el.checked);
    return checkedMetrics.length >= checkedMetricIndex + 1;
  }, [metrics, checkedMetricIndex]);

  const checkedMetrics = metrics.filter(el => el.checked);
  const checkedMetric = checkedMetrics[checkedMetricIndex];
  const groupingName = checkedMetric ? checkedMetric.name?.ru : '';

  const previousMetric = checkedMetricIndex > 0 ? checkedMetrics[checkedMetricIndex - 1] : null;
  const isButtonDisabled = useMemo(() => {
    if (checkedMetricIndex === 0) {
      return false; // Кнопка активна, если нет предыдущих метрик
    }
    return !previousMetric; // Кнопка дизейблится, если нет предыдущей метрики
  }, [checkedMetricIndex, previousMetric]);

  const handleMetricClick = (metric: TMetric, index: number) => {
    setGroupingMetrics({ tab, path: metric.path, checkedMetricIndex });
    sendMetricGoal('usage_vk_button_grouping', 'manage/vk');
  };

  const filteredMetrics = useMemo(() => {
    if (checkedMetricIndex === 0) {
      // На уровне 0 показываем все метрики
      return metrics;
    }

    if (checkedMetricIndex === 1) {
      // На уровне 1 исключаем метрики, которые выбраны на уровне 0
      return metrics.filter(metric => metric.groupingLevel !== 0);
    }

    if (checkedMetricIndex === 2) {
      // На уровне 2 исключаем метрики, которые выбраны на уровнях 0 и 1
      return metrics.filter(metric => metric.groupingLevel !== 0 && metric.groupingLevel !== 1);
    }

    return metrics;
  }, [metrics, checkedMetricIndex]);

  return (
    <>
      <div ref={ref} className={classes.groupingZone}>
        <ActionTableButton
          className={classes.buttonWidth}
          textTooltip={activeMetrics ? `Сгруппировано по: ${groupingName}` : 'Сгруппировать объекты'}
          placement="top"
          action={openMenu}
          icon={<GroupingButtonContent isOpen={arrowActive} isActive={activeMetrics} title={title} />}
          active={open}
          isButtonDisabled={isButtonDisabled}
        />
      </div>
      <Popover
        isListLeft
        anchorEl={ref.current}
        isOpen={open}
        setIsOpen={closeMenu}
        classNameWrapper={popoverClose ? classes.popoverClosed : classes.popoverOpen}
      >
        <div className={classes.menuContent}>
          <CustomList classProps={classes.menuList}>
            <>
              {filteredMetrics.map((el, index) => (
                <GroupingNameItem metric={el} key={el.path} action={() => handleMetricClick(el, index)} />
              ))}
            </>
          </CustomList>
          <div className={classes.hr} />
          <div
            className={clsx(classes.resetGrouping, activeMetrics && classes.hoverResetGrouping)}
            onClick={() => {
              if (activeMetrics) {
                closeMenu();
                setGroupingMetrics({ tab, path: '', checkedMetricIndex });
              }
            }}
          >
            <CloseCircleIcon color={activeMetrics ? 'main' : 'disabled'} />
            <Typography size="Main" color={activeMetrics ? 'TextMain' : 'TextDisabled'}>
              Сбросить группировку
            </Typography>
          </div>
        </div>
      </Popover>
    </>
  );
};
