import { DeleteIcon, PauseIcon, PlayIcon } from '@plarin/design';
import { SelectButton, GridApi, TRows } from '@plarin/inputs';
import React, { useMemo } from 'react';
import { TAFStoreData, TEnableAF } from 'types/connect-af/types';
import { ModalTypes } from '../../../../src/utils/integration-converters';
import { ModalData as ModalDataAF } from '../../../../types/connect-af/types';
import { TGAStoreData, ModalDataGA, TEnableGA } from '../../../../types/connect-ga/types';
import { TYDStoreData, ModalData as ModalDataYD, TEnableYD } from '../../../../types/connect-yd/types';
import { ModalData as ModalDataYM, TYMStoreData, TEnableYM } from '../../../../types/connect-ym/types';

enum ActionKeysEnum {
  enabled = '1',
  disabled = '2',
  deleted = '3',
}

// можем включить интеграцию только у тех детей, у которых родитель включён. Родителей же включить можем всегда
const getRowsForEnable = (rows: (TYMStoreData | TAFStoreData | TGAStoreData | TYDStoreData)[]) =>
  rows.filter(item => item.plarin_status === 'disabled' && item._parent_status !== 'disabled');

// а вот выключать можем и детей и родителей без оглядки на дополнительные факторы, потому что бэкенд не пришлёт "включённого" ребёнка у выключенного родителя
const getRowsForDisable = (rows: (TYMStoreData | TAFStoreData | TGAStoreData | TYDStoreData)[]) =>
  rows.filter(item => item.plarin_status === 'enabled');

interface ActionBarProps {
  selectedRows: (TYMStoreData | TAFStoreData | TGAStoreData | TYDStoreData)[];
  setModalData:
    | ((data: ModalDataAF) => ModalDataAF)
    | ((data: ModalDataYM) => ModalDataYM)
    | ((data: ModalDataGA) => ModalDataGA)
    | ((data: ModalDataYD) => ModalDataYD);
  enableAction: TEnableAF | TEnableYM | TEnableGA | TEnableYD;
  tableLoading: boolean;
  gridApi: GridApi;
  isYD?: boolean; // экшн-бар работает с таблицей integrations YD, и нужно использовать другие методы для вкл/выкл сущностей
}

// экшенбар для таблицы интеграций АФ, ЯМ, ГА. Детей можно вкл/выкл отдельно от родителей - позволяет формировать сложный reqObj для остановки интеграции
export const SelectDropMenu = ({
  selectedRows,
  setModalData,
  enableAction,
  tableLoading,
  gridApi,
  isYD,
}: ActionBarProps) => {
  const parentsRows = useMemo(() => selectedRows && selectedRows.filter(item => item.isParent), [selectedRows]);
  const childRows = useMemo(() => selectedRows && selectedRows.filter(item => !item.isParent), [selectedRows]);

  // любой выключенный родитель и выключенные дети включённых родителей
  const rowsForEnable: (TYMStoreData | TAFStoreData | TGAStoreData | TYDStoreData)[] = useMemo(
    () => [...getRowsForEnable(parentsRows), ...getRowsForEnable(childRows)],
    [selectedRows],
  );

  // любой выключенный родитель или деть
  const rowsForDisable: (TYMStoreData | TAFStoreData | TGAStoreData | TYDStoreData)[] = useMemo(
    () => [...getRowsForDisable(parentsRows), ...getRowsForDisable(childRows)],
    [selectedRows],
  );

  const enableActionDefault = () => {
    enableAction({
      // @ts-ignore
      parentRows: getRowsForEnable(parentsRows),
      // @ts-ignore
      childRows: getRowsForEnable(childRows),
      gridApi: gridApi,
    });
  };

  const enableActionYD = () => {
    enableAction(
      {
        // @ts-ignore
        db_ids: [...getRowsForEnable(parentsRows)?.map(el => el._id), ...getRowsForEnable(childRows)?.map(el => el.id)],
      },
      gridApi,
    );
  };

  const pauseAction = () => {
    setModalData({
      type: ModalTypes.pause,
      // @ts-ignore
      selectedRows: rowsForDisable,
      names: [...rowsForDisable.map(row => row.header1)],
    });
  };

  const pauseYD = () => {
    // @ts-ignore
    setModalData({
      type: ModalTypes.pause,
      name: getRowsForDisable(parentsRows)?.map(el => el.header1),
      ids: getRowsForDisable(parentsRows)?.map(el => el._id),
    });
  };

  const deleteAction = () => {
    setModalData({
      type: ModalTypes.remove,
      // @ts-ignore
      selectedRows: parentsRows,
      names: [...parentsRows.map(row => row.header1)],
    });
  };

  const deleteYD = () => {
    // @ts-ignore
    setModalData({
      type: ModalTypes.remove,
      name: getRowsForDisable(parentsRows)?.map(el => el.header1),
      ids: getRowsForDisable(parentsRows)?.map(el => el._id),
    });
  };

  const renderList = [
    {
      key: ActionKeysEnum.enabled,
      label: rowsForEnable?.length === 1 ? 'Включить интеграцию' : `Включить интеграции (${rowsForEnable?.length})`,
      iconLeft: () => <PlayIcon />,
      rowsCount: rowsForEnable?.length,
      action: () => (isYD ? enableActionYD() : enableActionDefault()),
    },
    {
      key: ActionKeysEnum.disabled,
      label: rowsForDisable?.length === 1 ? 'Выключить интеграцию' : `Выключить интеграции (${rowsForDisable?.length})`,
      iconLeft: () => <PauseIcon />,
      rowsCount: rowsForDisable?.length,
      action: () => (isYD ? pauseYD() : pauseAction()),
    },
    {
      key: ActionKeysEnum.deleted,
      rowsCount: parentsRows?.length,
      label:
        parentsRows?.length === 1
          ? 'Удалить интеграцию и все данные'
          : `Удалить интеграции и все данные (${parentsRows?.length})`,
      iconLeft: () => <DeleteIcon />,
      action: () => (isYD ? deleteYD() : deleteAction()),
    },
  ].filter(
    item =>
      (item.key === ActionKeysEnum.deleted && item.rowsCount) ||
      (item.key !== ActionKeysEnum.deleted && item.rowsCount),
  );

  return (
    <div>
      <SelectButton
        renderList={renderList}
        disabled={
          tableLoading ||
          (selectedRows && !selectedRows.length) ||
          (!parentsRows.length && !rowsForDisable.length && !rowsForEnable.length) // этот случай возможен, если в компонент переданы чайлы выключенного родителя: их можно сюда передать из-за того что у них есть чекбокс, но при этом включить их нельзя
        }
      />
    </div>
  );
};
