import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { TwoLinesRenderer } from '../two-lines-renderer';
import classes from './style.module.scss';

interface RightsRendererProps extends ICellRendererParams {
  first: string;
  second: string;
}

export const RightsRenderer = ({ first, second, data, ...rest }: RightsRendererProps) =>
  data[second] === 'Множественное подключение' ? (
    <div className={classes.multiline}>
      Множественное
      <br />
      подключение
    </div>
  ) : (
    <TwoLinesRenderer firstLine={first} secondLine={second} data={data} {...rest} />
  );
