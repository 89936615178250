import { useState } from 'react';

export const useDebounce = () => {
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const debounce = (func: () => void, time: number) => {
    typingTimeout && clearTimeout(typingTimeout);
    const timeout = setTimeout(() => func(), time);
    setTypingTimeout(timeout);
  };
  return debounce;
};
