import { Typography } from '@plarin/design';
import React, { useState, useEffect } from 'react';
import { TRows } from '../types';
import classes from './style.module.scss';
import { filteredOption, TCurrencyFiltrator } from './utils';

export const CurrencyFiltrator = ({ filteredRows, deselectRows }: TCurrencyFiltrator) => {
  const [rub, setRub] = useState<TRows>([]);
  const [usd, setUsd] = useState<TRows>([]);
  const [eur, setEur] = useState<TRows>([]);
  const [unknown, setUnknown] = useState<TRows>([]);

  useEffect(() => {
    const rubArr: TRows = [],
      usdArr: TRows = [],
      eurArr: TRows = [],
      unknownArr: TRows = [];

    filteredRows.forEach(row => {
      if (row.accountCurrency === 'RUB') {
        rubArr.push(row);
      } else if (row.accountCurrency === 'USD') {
        usdArr.push(row);
      } else if (row.accountCurrency === 'EUR') {
        eurArr.push(row);
      } else {
        unknownArr.push(row);
      }
    });

    rubArr.length && setRub(rubArr);
    usdArr.length && setUsd(usdArr);
    eurArr.length && setEur(eurArr);
    unknownArr.length && setUnknown(unknownArr);
  }, []);

  return (
    <div className={classes.filtratorWrap}>
      <Typography size="AuthContent" weight={700} component="p" componentProps={{ className: classes.mb12 }}>
        Массовое изменение стратегии
      </Typography>
      <Typography component="p" size="AuthContent" componentProps={{ className: classes.mb12 }}>
        Для продолжения изменения стратегий оставьте рекламные кампании только с одной валютой.
      </Typography>
      <Typography component="p" size="AuthContent">
        Выберите, что оставить:
      </Typography>

      <div>
        {filteredOption({ val: rub, message: 'Оставить ₽ ', deselectRows: deselectRows })}
        {filteredOption({ val: usd, message: 'Оставить $ ', deselectRows: deselectRows })}
        {filteredOption({ val: eur, message: 'Оставить € ', deselectRows: deselectRows })}

        {filteredOption({ val: unknown, message: 'Неизвестная валюта ', deselectRows: deselectRows, isActive: false })}
      </div>
    </div>
  );
};
