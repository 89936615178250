import { Typography } from '@plarin/design';
import React from 'react';
import classes from './styles.module.scss';

export const Chips = ({ names }: { names: string[] }) => {
  return (
    <div className={classes.chips}>
      {names.map((el, index) => (
        <div key={index} className={classes.chipsItem}>
          <Typography size="SmallText" color="TextSmallButton" key={index}>
            {el.slice(0, 1).toUpperCase() + el.slice(1)}
          </Typography>
        </div>
      ))}
    </div>
  );
};
