import { Typography } from '@plarin/design';
import { GridApi, Modal, ModalActionNamesEnum } from '@plarin/inputs';
import { typografText } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ModalTypes } from '../../../../src/utils/integration-converters';
import { TAFStoreData } from '../../../../types/connect-af/types';
import { useAppDataStore } from './../../../components/app-data-provider';
import { ConnectionError } from './ConnectionError';
import { ConnectionInputs } from './ConnectionInputs';
import classes from './style.module.scss';

const textRemove = {
  one: {
    title: 'Интеграция ',
    text: ' будет удалена из Plarin вместе с ранее накопленной статистикой.',
  },
  many: {
    title: 'Интеграции ',
    text: ' будут удалены из Plarin вместе с ранее накопленной статистикой.',
  },
};

export const MetricsConfirmModal = observer(({ gridApi }: { gridApi?: GridApi }) => {
  const {
    integrationsAF: {
      connectFormData,
      modalData,
      updateData,
      resetConnectForm,
      isLoading,
      setIsConnectFailed,
      isConnectFailed,
      connectAppsflyerAccount,
      connectionError,
      clearModalData,
      disableIntegrationAF,
      deleteAccountsAF: disconnectAccounts,
    },
  } = useAppDataStore();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleCloseConnectModal = () => {
    setIsConnectFailed(false);
    clearModalData();
    resetConnectForm();
  };

  // Дизейблим кнопку Подключить, если оба поля ввода пустые
  useEffect(() => {
    const shouldDisable = !connectFormData.name || !connectFormData.token;
    setIsDisabled(shouldDisable);
  }, [connectFormData.name, connectFormData.token]);

  const getParentRows = (rows: TAFStoreData[]) => rows.filter(row => row.isParent);
  const getChildsRows = (rows: TAFStoreData[]) => rows.filter(row => !row.isParent);

  const getEnabledRows = (rows: TAFStoreData[]) => rows.filter(row => row.plarin_status === 'enabled');

  const parentRows = getParentRows(modalData.selectedRows || []);
  const childRows = getChildsRows(modalData.selectedRows || []);

  const textAccounts = React.useMemo(
    () =>
      modalData.name &&
      modalData.name.map((element, index) => (index === modalData.name!.length - 1 ? element : element + ', ')),
    [modalData.name],
  );

  switch (modalData.type) {
    case ModalTypes.connectAccount:
      return (
        <Modal
          width={592}
          open={modalData.type === ModalTypes.connectAccount}
          closeModal={handleCloseConnectModal}
          workspaceHeader="connectAppsflyer"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: handleCloseConnectModal,
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Connect,
            isLoading: isLoading,
            action: connectAppsflyerAccount,
          }}
          title="Подключение Appsflyer"
          classNameChildren={classes.children}
          classNameIcon={classes.icon}
          disabledFirstAction={isDisabled}
        >
          <>
            {isConnectFailed && <ConnectionError errorCode={connectionError} />}
            <Typography component="p" componentProps={{ className: classes.subheader }} size="AuthContent" weight={600}>
              {typografText('Для подключения новой интеграции следуйте инструкции ниже:')} <br />
            </Typography>
            <Typography component="p" componentProps={{ className: classes.subheaderWarning }} size="AuthContent">
              <span className={classes.textWarning}>{typografText('Внимание:')}</span>{' '}
              {typografText('получить Токен API может только администратор в аккаунте Appsflyer.')}{' '}
              <a
                href="https://support.appsflyer.com/hc/ru/articles/360004562377-Управление-токенами-API#retrieving-api-tokens"
                target="_blank"
                className={classes.link}
                rel="noreferrer"
              >
                Подробнее
              </a>
              .
            </Typography>
            <div className={classes.listWrapper}>
              <Typography size="AuthContent">
                {typografText('1) В интерфейсе Appsflyer откройте')}{' '}
                <span className={classes.textAccent}>{typografText('меню пользователя')}</span>{' '}
                {typografText(' (раскрывающийся список под адресом электронной почты в верхнем правом углу);')}
              </Typography>
              <Typography size="AuthContent">
                {typografText('2) Выберите ')}{' '}
                <span className={classes.textAccent}>{typografText('«Центр безопасности»')}</span>;
              </Typography>
              <Typography size="AuthContent">
                {typografText('3) В разделе ')}{' '}
                <span className={classes.textAccent}>{typografText('«Токены API AppsFlyer»')}</span>{' '}
                {typografText(' нажмите ')}{' '}
                <span className={classes.textAccent}>{typografText('«Управление токенами API AppsFlyer»')}</span>;
              </Typography>
              <Typography size="AuthContent">{typografText('4) Отобразится доступный токен (V2.0);')}</Typography>
              <Typography size="AuthContent">
                {typografText('5) Скопируйте необходимый токен и вставьте в текстовое поле ниже.')} <br />
              </Typography>
            </div>
            <ConnectionInputs data={connectFormData} isLoading={isLoading} updateData={updateData} />
          </>
        </Modal>
      );

    case ModalTypes.pause:
      return (
        <Modal
          open={modalData.type === ModalTypes.pause}
          closeModal={() => clearModalData()}
          workspaceHeader="remove"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () => clearModalData(),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Unplug,
            isLoading: isLoading,
            action: () =>
              disableIntegrationAF({
                parentRows: getEnabledRows(parentRows),
                childRows: getEnabledRows(childRows),
                gridApi: gridApi,
              }),
          }}
          title="Выключить интеграцию?"
        >
          <Typography size="AuthContent">
            {' '}
            {'После выключения '}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>
            {' синхронизация будет остановлена и новые данные поступать не будут до возобновления интеграции.'}
          </Typography>
        </Modal>
      );

    case ModalTypes.remove:
      return (
        <Modal
          open={modalData.type === ModalTypes.remove}
          closeModal={() => clearModalData()}
          workspaceHeader="remove"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () => clearModalData(),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Delete,
            isLoading: isLoading,
            action: () => disconnectAccounts(parentRows),
          }}
          title="Удалить интеграцию?"
        >
          <Typography size="AuthContent">
            {parentRows?.length === 1 ? textRemove.one.title : textRemove.many.title}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>

            {parentRows?.length === 1 ? textRemove.one.text : textRemove.many.text}
          </Typography>
        </Modal>
      );

    default:
      return null;
  }
});
