import { LimitTypes, MtOptimisationLevel, SaveBlockingMessages, TNewStrategyBids } from '../inputs';
import {
  getCommonDailyLimit,
  getCommonLifetimeLimit,
  getCommonLimitType,
  limitMinMaxOk,
  limitWasChanged,
  maxPriceWasChanged,
  rowDataHasError,
  StrategyBidModes,
} from './strategy-form-utils';
import { TcheckForManyRows, TcheckForOneRow, TcheckIfCanSave } from './types';

const deleteUndefinedObjectKeys = (obj: {}) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach(key =>
    newObj[key as keyof typeof newObj] === undefined ? delete newObj[key as keyof typeof newObj] : {},
  );
  return newObj;
};

export const checkIfCanSave = ({
  newBidsData,
  mode,
  initialMode,
  setMaySave,
  setBlockingMessage,
  minPriceLimit,
  maxPriceLimit,
  commonDailyLimit,
  commonLifetimeLimit,
  limitType,
  minBudgetLimit,
  budgetLimitsDisabled,
  newEndDateHasError,
  newStartDateHasError,
  selectedRows,
  startDateWasChanged,
  endDateWasChanged,
  prevBidsData,
  inputsTypeVariants,
  itemsType,
  commonBudgetOptimizationLevel,
  errorPackageLimitBid,
}: TcheckIfCanSave) => {
  // Ошибка, если проблемы с полем package_limit_bid
  if (errorPackageLimitBid) {
    setBlockingMessage(SaveBlockingMessages.itemErrorVA);
    return setMaySave(false);
  }

  // ошибка, когда мы не понимаем, что редактируем: адплан, гр.кампаний или баннеры
  if (!itemsType) {
    setBlockingMessage(SaveBlockingMessages.itemTypeError);
    return setMaySave(false);
  }

  // форма редактирования отркрыта для нескольких сущностей, у которых разный тип оптимизации лимита бюджета: на уровне кампании и на уровне группы
  if (!commonBudgetOptimizationLevel) {
    setBlockingMessage(SaveBlockingMessages.itemTypeError);
    return setMaySave(false);
  }

  // есть ошибки в пришедших с бэкенда данных
  // Был долгий период, когда с бэкенда приходили некорректные объекты, с которыми по факту нельзя было работать. Этот блок кода позволял быстрее определить, почему не работает: опять что-то на бэке сломалось
  if (rowDataHasError(selectedRows[0], itemsType, commonBudgetOptimizationLevel)) {
    setBlockingMessage(SaveBlockingMessages.itemErrorVA);
    return setMaySave(false);
  }

  // I. Выбрана одна кампания
  if (selectedRows.length === 1) {
    // ПРОВЕРКИ НА ОШИБКИ

    return checkForOneRow({
      newBidsData,
      mode,
      initialMode,
      setMaySave,
      setBlockingMessage,
      minPriceLimit,
      maxPriceLimit,
      commonDailyLimit,
      commonLifetimeLimit,
      limitType,
      minBudgetLimit,
      budgetLimitsDisabled,
      newEndDateHasError,
      newStartDateHasError,
      selectedRows,
      startDateWasChanged,
      endDateWasChanged,
      inputsTypeVariants,
      commonBudgetOptimizationLevel,
      errorPackageLimitBid,
    });
  }

  if (selectedRows.length > 1) {
    return checkForManyRows({
      prevBidsData,
      newBidsData,
      mode,
      initialMode,
      setMaySave,
      setBlockingMessage,
      minPriceLimit,
      inputsTypeVariants,
      maxPriceLimit,
      commonDailyLimit,
      commonLifetimeLimit,
      limitType,
      minBudgetLimit,
      itemsType,
      newStartDateHasError,
      newEndDateHasError,
      errorPackageLimitBid,
    });
  }

  setBlockingMessage('');
  return setMaySave(true);
};

export const checkForOneRow = ({
  newBidsData,
  mode,
  initialMode,
  setMaySave,
  setBlockingMessage,
  minPriceLimit,
  maxPriceLimit,
  commonDailyLimit,
  commonLifetimeLimit,
  limitType,
  minBudgetLimit,
  budgetLimitsDisabled,
  newEndDateHasError,
  newStartDateHasError,
  selectedRows,
  startDateWasChanged,
  endDateWasChanged,
  inputsTypeVariants,
  commonBudgetOptimizationLevel,
}: TcheckForOneRow) => {
  const isInputsTypeVariants1 = inputsTypeVariants === 'inputsTypeVariants1';
  if (mode !== StrategyBidModes.minPrice) {
    // для определения ставки (макс. стоимость) в зависимости от формы стратегий
    const price = isInputsTypeVariants1 ? 'maxPrice' : 'price';
    if (typeof newBidsData[0][price] !== 'number' || (minPriceLimit && newBidsData[0][price] < minPriceLimit)) {
      setBlockingMessage(SaveBlockingMessages.maxPriceIsNull);
      return setMaySave(false);
    }

    if (maxPriceLimit && newBidsData[0][price] > maxPriceLimit) {
      setBlockingMessage(SaveBlockingMessages.maxPriceTooBig);
      return setMaySave(false);
    }
  }

  // Если это условие отработало, то в коде, редактирующем лимит, есть ошибка
  if (!budgetLimitsDisabled && limitType === LimitTypes.differentLimitTypes) {
    return setMaySave(false);
  }

  if (limitType === LimitTypes.daily) {
    if (!commonDailyLimit || !limitMinMaxOk(commonDailyLimit, minBudgetLimit)) {
      return setMaySave(false);
    }
  }

  if (limitType === LimitTypes.lifetime) {
    if (!commonLifetimeLimit || !limitMinMaxOk(commonLifetimeLimit, minBudgetLimit)) {
      return setMaySave(false);
    }
  }

  if (newStartDateHasError || newEndDateHasError) {
    return setMaySave(false);
  }

  // ПРОВЕРКИ НА НАЛИЧИЕ ИЗМЕНЕНИЙ:
  if (mode !== initialMode) {
    setBlockingMessage('');
    return setMaySave(true);
  }
  if (maxPriceWasChanged(selectedRows[0], newBidsData[0]) && mode === StrategyBidModes.maxPrice) {
    setBlockingMessage('');
    return setMaySave(true);
  }

  if (
    selectedRows[0].price !== newBidsData[0].price &&
    (mode === StrategyBidModes.maxImpressions || mode === StrategyBidModes.fixedRate)
  ) {
    setBlockingMessage('');
    return setMaySave(true);
  }

  if (limitWasChanged(selectedRows[0], newBidsData[0], newBidsData[0].budgetOptimizationLevel)) {
    setBlockingMessage('');
    return setMaySave(true);
  }

  if (startDateWasChanged || endDateWasChanged) {
    setBlockingMessage('');
    return setMaySave(true);
  }

  // если переключились с "Предельной цены" на "Минимальную", это измение типа стратегии - значит, есть что сохранять
  if (initialMode !== mode && mode === StrategyBidModes.minPrice) {
    setBlockingMessage('');
    return setMaySave(true);
  }
  // для проверки изменения состояния бюджета и селекта за день/за все время у группы объявлений/объявлений
  if (commonBudgetOptimizationLevel !== MtOptimisationLevel.adPlan) {
    if (
      commonDailyLimit !== +selectedRows[0].campaignDaily ||
      commonLifetimeLimit !== +selectedRows[0].campaignLifetime
    ) {
      setBlockingMessage('');
      return setMaySave(true);
    }
  }

  return setMaySave(false);
};

export const checkForManyRows = ({
  prevBidsData,
  newBidsData,
  mode,
  initialMode,
  setMaySave,
  setBlockingMessage,
  minPriceLimit,
  inputsTypeVariants,
  maxPriceLimit,
  commonDailyLimit,
  commonLifetimeLimit,
  limitType,
  minBudgetLimit,
  newStartDateHasError,
  newEndDateHasError,
}: TcheckForManyRows) => {
  const isInputsTypeVariants1 = inputsTypeVariants === 'inputsTypeVariants1';
  const initBidsDataObj = JSON.parse(prevBidsData);
  // для проверки на изменение от начального состояния с учетом даты
  const preparationForComparisonBidsData = (BidsData: TNewStrategyBids) => {
    return BidsData.map((el: any) => {
      return deleteUndefinedObjectKeys(
        Object.fromEntries(
          Object.entries({
            ...el,
            adPlanStop: new Date(el.adPlanStop).getTime(),
            campaignStop: new Date(el.campaignStop).getTime(),
            adPlanStart: new Date(el.adPlanStart).getTime(),
            campaignStart: new Date(el.campaignStart).getTime(),
          }).sort(),
        ),
      );
    });
  };
  const newPrevBidsDataObj = preparationForComparisonBidsData(initBidsDataObj);
  const updatedBettingData = preparationForComparisonBidsData(newBidsData);
  // проверяем, изменилось ли что-то

  if (
    JSON.stringify(newPrevBidsDataObj) === JSON.stringify(updatedBettingData) &&
    mode === initialMode &&
    limitType === getCommonLimitType(initBidsDataObj)
  ) {
    return setMaySave(false);
  }

  if (newStartDateHasError || newEndDateHasError) {
    return setMaySave(false);
  }

  // ПРОВЕРКИ НА ОШИБКИ
  // TODO: здесь пока не все проверки

  if (mode !== StrategyBidModes.minPrice && mode !== StrategyBidModes.differentModes) {
    const price = isInputsTypeVariants1 ? 'maxPrice' : 'price';
    if (typeof newBidsData[0][price] !== 'number' || (minPriceLimit && newBidsData[0][price] < minPriceLimit)) {
      setBlockingMessage(SaveBlockingMessages.maxPriceIsNull);
      return setMaySave(false);
    }

    if (maxPriceLimit && newBidsData[0][price] > maxPriceLimit) {
      setBlockingMessage(SaveBlockingMessages.maxPriceTooBig);
      return setMaySave(false);
    }
  }

  // ноль в инпуте для лимита бюджета - невалидное значение
  // TODO: Катя, в каком случае отображается эта ошибка?
  if (getCommonDailyLimit(newBidsData) === 0 && getCommonLifetimeLimit(newBidsData) === 0) {
    return setMaySave(false);
  }

  // дневной лимит в пределах допустимоо
  if (limitType === LimitTypes.daily) {
    if (!!commonDailyLimit && !limitMinMaxOk(commonDailyLimit, minBudgetLimit)) {
      return setMaySave(false);
    }
  }

  // общий лимит в пределах допустимого
  if (limitType === LimitTypes.lifetime) {
    if (!!commonLifetimeLimit && !limitMinMaxOk(commonLifetimeLimit, minBudgetLimit)) {
      return setMaySave(false);
    }
  }
  setBlockingMessage('');
  return setMaySave(true);
};
