import { Input, TextArea } from '@plarin/inputs';
import clsx from 'clsx';
import React from 'react';
import { TConnectFormData } from 'types/connect-af/types';
import classes from './style.module.scss';

interface ConnectionInputsProps {
  data: { name: string; token: string };
  isLoading: boolean;
  updateData: (key: keyof TConnectFormData, value: string) => void;
}

export const ConnectionInputs: React.FC<ConnectionInputsProps> = ({ data, isLoading, updateData }) => {
  return (
    <div className={classes.inputsWrapper}>
      <Input
        type="text"
        inputRootWrapperDefault
        value={data.name}
        size={48}
        maxLength={42}
        maxCounterValue={42}
        onChange={newValue => updateData('name', newValue)}
        onBlur={() => {
          updateData('name', data?.name.trim());
        }}
        label="Название интеграции"
        disabled={isLoading}
        className="input"
      />
      <TextArea
        classNameRoot={clsx(classes.textArea, isLoading && classes.textAreaDisabled)}
        value={data.token}
        setValue={newValue => updateData('token', newValue)}
        size={48}
        maxLengthValue={1000}
        maxCounterValue={1000}
        label="Введите токен из Appsflyer"
        maxRows={6}
        disabled={isLoading}
        onBlurAction={() => updateData('token', data.token.trim())}
      />
    </div>
  );
};
