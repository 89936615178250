import Joi from '@hapi/joi';
import {
  BasicFormStore,
  EApiCodes,
  emailAllowedSymbols,
  emailValidateRule,
  httpClient,
  passwordAllowedSymbols,
  passwordValidateRule,
  TFormInitParams,
} from '@plarin/core';
import { TAlertProps } from '@plarin/inputs';
import { timeToSendReq } from '@plarin/utils';
import { AxiosError } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { history, saveAuthTokens } from '../../components/app';
import { routerPaths } from '../../routing/router-path';
import { ProfileStore } from '../../stores/profile.store';
import { STORAGE_ITEM } from '../../utils/constants';
import { getAlertProps } from '../../utils/get-alert-props';
import { getLocalSelectedWS } from '../../utils/localStorageSelectedWS';
import { TLoginReq, TLoginResp } from './types';

export type TLoginEmailFormData = TLoginReq;

const initData: TFormInitParams<TLoginEmailFormData> = {
  email: {
    value: '',
    validators: {
      change: emailAllowedSymbols.allow(''),
      blur: emailValidateRule.allow(''),
      submit: Joi.string().required(),
    },
  },
  password: {
    value: '',
    validators: {
      change: passwordAllowedSymbols.allow(''),
      blur: passwordValidateRule.allow(''),
      submit: passwordValidateRule.required(),
    },
  },
  stay_logged_in: {
    value: true,
  },
};

export class LoginEmailFormStore extends BasicFormStore<TLoginEmailFormData> {
  alertProps?: TAlertProps;
  needCaptcha = false;
  profileStore: ProfileStore;

  constructor(profileStore: ProfileStore) {
    super(initData);
    this.profileStore = profileStore;
    makeObservable(this, {
      alertProps: observable,
      needCaptcha: observable,
      setCaptchaToken: action.bound,
      submit: action.bound,
    });
  }

  setCaptchaToken = (token: string) => {
    // @ts-ignore
    this.data.captcha_token = token;
  };

  login = async (data: TLoginReq) => {
    localStorage.setItem(STORAGE_ITEM.localStorage.REMAIN_SYSTEM, data.stay_logged_in ? 'true' : 'false');
    this.isPending = true;
    await this.execRequest<[TLoginResp]>([httpClient.post('/api/v1/user/auth/login', data)])
      .then(async ([resp]: [TLoginResp]) => {
        saveAuthTokens(resp.data!.access_token, resp.data!.refresh_token, resp.data!.refresh_expires);

        await this.profileStore.loadProfileWithCheckInvites().then(e => {
          if (e?.invites?.length) {
            this.profileStore.setIsRootRedirect(true);
            history.push(routerPaths.auth.ROOT);
          }
        });
      })
      .catch(this.loginError)
      .finally(() => runInAction(() => (this.isPending = false)));
  };
  submit = this.onSubmit(() => {
    this.errors = this.validateAll();
    if (this.isValid) {
      this.login(this.data);
    }
  });

  loginError = (err: AxiosError<any>) => {
    if (err.response?.data.error && err.response?.data.error.code === EApiCodes.NEED_CAPTCHA) {
      this.needCaptcha = true;
    } else {
      this.alertProps = getAlertProps(err);
    }
    if (err.response?.data.error.details?.next_request_allowed) {
      setTimeout(() => {
        this.alertProps = undefined;
        this.needCaptcha = false;
      }, timeToSendReq(err.response?.data.error.details?.next_request_allowed));
    }
  };
}
