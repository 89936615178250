import { GroupingIcon, SmallArrowDownIcon, Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

export const GroupingButtonContent = ({
  isOpen,
  isActive,
  title,
}: {
  isOpen: boolean;
  isActive: boolean;
  title: string;
}) => {
  return (
    <div className={classes.buttonContentWrapper}>
      <div className={classes.buttonIconWrapper}>
        <GroupingIcon className={clsx(isActive && classes.redCircle)} />
      </div>
      <Typography size="Main" weight={600} color="TextPrimary">
        {title}
      </Typography>
      <SmallArrowDownIcon className={clsx('rotatable', isOpen && 'deg180')} />
    </div>
  );
};
