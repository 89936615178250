import { BasicStore, httpClient2 } from '@plarin/core';
import { GridApi } from '@plarin/inputs';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { TGAConnectResp } from '../../types/connect-ga/types';
import {
  ModalData,
  TYDStoreData,
  YDDisableReq,
  YDEnableReq,
  YDRemoveReq,
  YDResponse,
  TEnableYD,
} from '../../types/connect-yd/types';
import { history } from '../components/app';
import { routerPaths } from '../routing/router-path';
import { STATUS } from '../utils/constants';
import { getAccountsIntegrationsStatus, getAccountsItemsSyncStatus } from '../utils/integration-converters';

export class IntegrationsYDStore extends BasicStore {
  data: TYDStoreData[] = [];
  tableLoading: boolean = false;
  tableError: boolean = false;
  searchTableYd: string = '';
  modalData: ModalData = {} as ModalData;
  isLoading: boolean = false;

  constructor() {
    super();
    makeObservable<this>(this, {
      data: observable,
      tableLoading: observable,
      tableError: observable,
      modalData: observable,
      isLoading: observable,
      connectYandexDirectAccount: action.bound,
      checkQueryString: action.bound,
      searchTableYd: observable,
    });
  }

  setTableLoading = (value: boolean) => runInAction(() => (this.tableLoading = value));
  setTableError = (value: boolean) => runInAction(() => (this.tableError = value));
  setsearchTableYd = (value: string) => runInAction(() => (this.searchTableYd = value));

  setModalData = (data: ModalData) => runInAction(() => (this.modalData = data));
  private setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  getData = async () => {
    this.setTableLoading(true);
    this.setTableError(false);

    setTimeout(async () => {
      await this.execRequest<YDResponse[]>(httpClient2.get('/api/v1/connect/yd/integration/list'))
        .then(res =>
          runInAction(() => {
            const result = res.reduce((accumulator: TYDStoreData[], account) => {
              // дети внутри аккаунта
              if (account.items.length) {
                account.items.forEach(item => {
                  accumulator.push({
                    ...item,
                    key: item.header2,
                    _id: account._id,
                    _parent_status: account.plarin_status,
                    orgHierarchy: [account.header1, item.header2],
                    syncStatusCode: item.issues.length ? STATUS.BLOCKED : item.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      item.plarin_status,
                      item.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    // у детей также отображаем статус интеграции
                    integrationStatus: getAccountsIntegrationsStatus(
                      item.plarin_status,
                      item.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: item.issues?.length ? STATUS.BLOCKED : item.plarin_status,
                  });
                });
              }

              // аккаунт
              accumulator.push({
                ...account,
                key: account.header1,
                isParent: true,
                integrationStatus: getAccountsIntegrationsStatus(
                  account.plarin_status,
                  account.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                ),
                integrationStatusCode: account.issues.length ? STATUS.BLOCKED : account.plarin_status,
              });
              return accumulator;
            }, []);
            this.data = result.sort((a, b) => a.header1.localeCompare(b.header1));
          }),
        )
        .catch(e => {
          this.setTableError(!!e);
          this.setTableLoading(false);
        })
        .finally(() => this.setTableLoading(false));
    }, 500);
  };

  connectYandexDirectAccount = async (workspaceShortname: string) => {
    await this.execRequest<TGAConnectResp>(
      httpClient2.get('/api/v1/connect/yd/url', {
        params: {
          url: `${window.location.origin}/${workspaceShortname}${routerPaths.integrations.YANDEX_DIRECT}`,
        },
      }),
    )
      .then(res =>
        runInAction(() => {
          if (res.url) {
            window.location.href = res.url;
          }
        }),
      )
      .catch(e => {});
  };

  checkQueryString = () => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    const error = urlSearchParams.get('error');
    // @ts-ignore
    const errorParams = urlSearchParams && decodeURIComponent(urlSearchParams);
    var regex = /error_string":"([^"]+)"/;
    var matches = errorParams.match(regex);
    var errorString = matches && matches[1];
    errorString = errorString && decodeURIComponent(errorString.replace(/\+/g, ' '));

    // при упехе
    //     code = <код_подтверждения>
    // client_id = <идентификатор_приложения>
    // client_secret = <пароль_приложения>

    if (error) {
      this.addNotification({
        type: STATUS.ERROR,
        title: error,
        message: errorString ? errorString : '',
      });
    }
    if (urlSearchParams.get('client_id')) {
      window.history.replaceState(null, '', window.location.pathname);
      this.addNotification({
        type: STATUS.SUCCESS,
        title: `Аккаунт ${urlSearchParams.get('yd_user_id')} успешно подключен`,
      });
    }
  };

  enableYD: TEnableYD = async (data, gridApi) => {
    runInAction(
      () =>
        (this.data = this.data.map(row => {
          // это родитель
          if (row.isParent && data.db_ids.includes(row._id)) {
            return { ...row, integrationStatusCode: 'loading' };
          }

          // это чайлд: отображаем лоадер одиночно редактируемым чайлдам
          // но также отображает лоадер детям редактируемых аккаунтов, если аккаунт редактируется
          if ((!row.isParent && row.id && data.db_ids.includes(row.id)) || data.db_ids.includes(row._id)) {
            return {
              ...row,
              syncStatusCode: 'loading',
              integrationStatusCode: 'loading',
            };
          }
          return row;
        })),
    );

    setTimeout(async () => {
      await this.execRequest<YDResponse[]>(httpClient2.post('/api/v1/connect/yd/integration/enable', data))
        .then(res => {
          const prevData = [...this.data];
          let newData = [...this.data];

          res.forEach(resAccount => {
            newData = newData.map(tableRow => {
              // Это родитель
              if (tableRow._id === resAccount._id && tableRow.isParent) {
                return {
                  ...tableRow,
                  plarin_status: resAccount.plarin_status,
                  integrationStatus: getAccountsIntegrationsStatus(
                    resAccount.plarin_status,
                    resAccount.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  integrationStatusCode: resAccount.issues.length ? STATUS.BLOCKED : resAccount.plarin_status,
                };
              } else {
                // это чайлд
                const child = resAccount.items?.find(app => app.id === tableRow.id);

                if (child) {
                  return {
                    ...tableRow,
                    _parent_status: resAccount.plarin_status,
                    plarin_status: child.plarin_status,
                    integrationStatus: getAccountsIntegrationsStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,

                    syncStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }

                return tableRow;
              }
            });
          });

          runInAction(() => (this.data = [...newData]));

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? 'Интеграция успешно включена' : 'Интеграции успешно включены',
          });
        })

        .catch(error => {
          // поскольку не получилось включить синхронизацию, убираем лоадер у тех строк, которые пытались включить, и возвращаем всем редактируемым сущностям их предыдущий статус синхронизации
          runInAction(
            () =>
              (this.data = this.data.map(tableRow => {
                // это родитель
                if (tableRow.isParent && tableRow.integrationStatus === 'loading' && tableRow.plarin_status) {
                  return {
                    ...tableRow,
                    integrationStatus: getAccountsIntegrationsStatus(
                      tableRow.plarin_status,
                      tableRow.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: tableRow.issues?.length ? STATUS.BLOCKED : tableRow.plarin_status,
                  };
                } else if (tableRow.plarin_status) {
                  // это чайлд
                  return {
                    ...tableRow,
                    integrationStatus: getAccountsIntegrationsStatus(
                      tableRow.plarin_status,
                      tableRow.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: tableRow.issues?.length ? STATUS.BLOCKED : tableRow.plarin_status,

                    syncStatusCode: tableRow.issues?.length ? STATUS.BLOCKED : tableRow.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      tableRow.plarin_status,
                      tableRow.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }

                return tableRow;
              })),
          );

          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  disableYD = async (data: YDDisableReq, gridApi?: GridApi) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<YDResponse[]>(httpClient2.post('/api/v1/connect/yd/integration/disable', data))
        .then(res => {
          let newData = [...this.data];

          res.forEach(resAccount => {
            newData = newData.map(tableRow => {
              // Это родитель
              if (tableRow._id === resAccount._id && tableRow.isParent) {
                return {
                  ...tableRow,
                  plarin_status: resAccount.plarin_status,
                  integrationStatus: getAccountsIntegrationsStatus(
                    resAccount.plarin_status,
                    resAccount.issues.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  integrationStatusCode: resAccount.issues.length ? STATUS.BLOCKED : resAccount.plarin_status,
                };
              } else {
                // это чайлд
                const child = resAccount.items?.find(app => app.id === tableRow.id);

                if (child) {
                  return {
                    ...tableRow,
                    _parent_status: resAccount.plarin_status,
                    plarin_status: child.plarin_status,
                    integrationStatus: getAccountsIntegrationsStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,

                    syncStatusCode: child.issues?.length ? STATUS.BLOCKED : child.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      child.plarin_status,
                      child.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }
                return tableRow;
              }
            });
          });

          runInAction(() => (this.data = [...newData]));

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? 'Интеграция успешно выключена' : 'Интеграции успешно выключены',
          });
        })
        .catch(error => {
          // поскольку не получилось включить синхронизацию, убираем лоадер у тех строк, которые пытались включить, и возвращаем всем редактируемым сущностям их предыдущий статус синхронизации
          runInAction(
            () =>
              (this.data = this.data.map(tableRow => {
                // это родитель
                if (tableRow.isParent && tableRow.integrationStatus === 'loading' && tableRow.plarin_status) {
                  return {
                    ...tableRow,
                    integrationStatus: getAccountsIntegrationsStatus(
                      tableRow.plarin_status,
                      tableRow.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: tableRow.issues?.length ? STATUS.BLOCKED : tableRow.plarin_status,
                  };
                } else if (tableRow.plarin_status) {
                  // это чайлд
                  return {
                    ...tableRow,
                    integrationStatus: getAccountsIntegrationsStatus(
                      tableRow.plarin_status,
                      tableRow.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: tableRow.issues?.length ? STATUS.BLOCKED : tableRow.plarin_status,

                    syncStatusCode: tableRow.issues?.length ? STATUS.BLOCKED : tableRow.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      tableRow.plarin_status,
                      tableRow.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }

                return tableRow;
              })),
          );

          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.setModalData({
            type: '',
            name: [],
            ids: [],
          });
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  removeYD = async (data: YDRemoveReq) => {
    this.setIsLoading(true);
    setTimeout(async () => {
      await this.execRequest<string[]>(httpClient2.post('/api/v1/connect/yd/integration/disconnect', data))
        .then(res => {
          const removeNames = this.data
            .filter(el => el.isParent && data.db_ids.includes(el._id))
            .map(el => el.header1)
            .join(', ');

          runInAction(() => {
            this.data = this.data.filter(el => !data.db_ids.includes(el._id));
          });

          this.addNotification({
            type: STATUS.SUCCESS,
            title: data.db_ids.length === 1 ? `Аккаунт ${removeNames} отключен` : `Аккаунты ${removeNames} отключены`,
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setModalData({
            type: '',
            name: [],
            ids: [],
          });
          this.setIsLoading(false);
        });
    }, 500);
  };
}
