import { currencyOrder } from '../dictionary/integrations';
import { TCampaignsData } from '../../types/manage-vk/types';
import { TMetric, TMetricGroup, TYDMetric, TYDMetricGroup } from '../../types/metrics';
import { sortRow } from './common';
import { parseStringToCamelCase } from './parseStatData/parseStatistics';
import { CurrencyEnum, currency as formatCurrency } from '@plarin/utils';

interface IGroupingParams {
  primaryMetric?: TMetric | TYDMetric;
  secondaryMetric?: TMetric | TYDMetric;
  tertiaryMetric?: TMetric | TYDMetric;
  data: TCampaignsData[];
  metrics: TMetricGroup[] | TYDMetricGroup[];
  mainTabData: string[];
}

export const grouping = ({
  primaryMetric,
  secondaryMetric,
  tertiaryMetric,
  data,
  metrics,
  mainTabData,
}: IGroupingParams) => {
  const [tabMainName, tabMainId] = mainTabData;
  const groupingVariants = new Set<string>();

  // Если primaryMetric не задана, возвращаем исходные данные без группировки
  if (!primaryMetric) {
    return data;
  }

  const filteredData = data?.filter(el => !el.isGroupRow);

  // Группировка по первичной метрике
  filteredData?.forEach(el => {
    if (primaryMetric?.path === 'mt_project') {
      groupingVariants.add(el.mtProject?.map((el: { name: string }) => el.name).join(', '));
    } else {
      groupingVariants.add(el[parseStringToCamelCase(primaryMetric?.path)]);
    }
  });

  const groupedData = [...groupingVariants]
    .sort((a, b) => sortRow(a, b))
    .flatMap(primaryGroupingVariant => {
      const dataForPrimaryGrouping = filteredData?.filter(item => {
        if (primaryMetric.path === 'mt_project') {
          return item.mtProject?.map((el: { name: string }) => el.name).join(', ') === primaryGroupingVariant;
        } else {
          return item[parseStringToCamelCase(primaryMetric.path)] === primaryGroupingVariant;
        }
      });

      const primaryGroup = JSON.parse(JSON.stringify(dataForPrimaryGrouping[0]));

      Object.keys(primaryGroup).forEach(key => {
        let mathAllowed: TMetric['math_allowed'] = undefined;
        let mathDefault: TMetric['math_default'] = undefined;
        let isCurrencyMetric = false;

        // Determine the metric properties based on the key and type
        metrics.forEach(group =>
          group.fields.forEach(metric => {
            if (parseStringToCamelCase(metric.path) === key) {
              mathAllowed = metric.math_allowed;
              mathDefault = metric.math_default;
              isCurrencyMetric = metric.type === 'currency';
            }
          }),
        );

        // Handle only if mathAllowed is set
        if (mathAllowed) {
          if (isCurrencyMetric) {
            // Handle currency metrics
            if (
              key !== 'price' &&
              key !== 'adPlanLifetime' &&
              key !== 'adPlanDaily' &&
              key !== 'statisticsSpentToday'
            ) {
              const currencySums: Record<CurrencyEnum, { total: number; count: number }> = {} as Record<
                CurrencyEnum,
                { total: number; count: number }
              >;

              dataForPrimaryGrouping.forEach(elem => {
                const currency = elem['accountCurrency'] as CurrencyEnum;
                const value = +elem[key];

                if (value) {
                  if (!currencySums[currency]) {
                    currencySums[currency] = { total: 0, count: 0 };
                  }
                  currencySums[currency].total += value;
                  currencySums[currency].count += 1;
                }
              });
              // formatCurrency(total, currency)

              if (mathDefault === 'sum') {
                primaryGroup[key] = Object.entries(currencySums)
                  .sort(
                    ([currencyA], [currencyB]) =>
                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                  )
                  .map(([currency, { total }]) => `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`)
                  .join('<br />');
              }

              if (mathDefault === 'avg') {
                primaryGroup[key] = Object.entries(currencySums)
                  .sort(
                    ([currencyA], [currencyB]) =>
                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                  )
                  .map(
                    ([currency, { total, count }]) =>
                      `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                  )
                  .join('<br />');
              }

              // if (mathDefault === 'sum') {
              //   primaryGroup[key] = Object.entries(currencySums)
              //     .map(([currency, { total }]) => `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`)
              //     .join('<br />');
              // }
              // if (mathDefault === 'avg') {
              //   primaryGroup[key] = Object.entries(currencySums)

              //     .map(
              //       ([currency, { total, count }]) =>
              //         `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
              //     )
              //     .join('<br />');
              // }
            } else {
              primaryGroup[key] = null;
            }
          } else {
            // Handle non-currency metrics
            let result = 0;
            let resultLength = 0;

            dataForPrimaryGrouping.forEach(elem => {
              if (+elem[key]) {
                result += +elem[key];
                resultLength += 1;
              }
            });

            if (mathDefault === 'sum') {
              primaryGroup[key] = result;
            }
            if (mathDefault === 'avg') {
              primaryGroup[key] = resultLength ? result / resultLength : null;
            }
          }
        } else {
          // Default handling when mathAllowed is not set
          if (key !== 'accountCurrency') {
            primaryGroup[key] = null;
          }
        }
      });

      primaryGroup[tabMainName] = `${
        Array.isArray(primaryGroupingVariant) && primaryGroupingVariant.length > 0
          ? primaryGroupingVariant[0]?.code
          : primaryGroupingVariant
      }`;
      primaryGroup[tabMainId] = null;
      primaryGroup.orgHierarchy = undefined;
      primaryGroup.isGroupRow = true;
      primaryGroup.key = dataForPrimaryGrouping[0]?.key + Date.now();
      primaryGroup.bidCurrent = null;

      if (secondaryMetric) {
        const secondaryVariants = new Set<string>();

        dataForPrimaryGrouping
          ?.filter(el => !el.isGroupRow)
          .forEach(el => {
            if (secondaryMetric?.path === 'mt_project') {
              secondaryVariants.add(el.mtProject?.map((el: { name: string }) => el.name).join(', '));
            } else {
              secondaryVariants.add(el[parseStringToCamelCase(secondaryMetric?.path)]);
            }
          });

        [...secondaryVariants].sort((a, b) => sortRow(a, b));

        return [
          primaryGroup,
          ...[...secondaryVariants]
            // .sort((a, b) => sortRow(a, b))
            .flatMap(secondaryGroupingVariant => {
              const dataForSecondaryGrouping = dataForPrimaryGrouping.filter(item => {
                if (secondaryMetric.path === 'mt_project') {
                  return item.mtProject?.map((el: { name: string }) => el.name).join(', ') === secondaryGroupingVariant;
                } else {
                  return item[parseStringToCamelCase(secondaryMetric.path)] === secondaryGroupingVariant;
                }
              });

              const secondaryGroup = JSON.parse(JSON.stringify(dataForSecondaryGrouping[0]));

              Object.keys(secondaryGroup).forEach(key2 => {
                let mathAllowed: TMetric['math_allowed'] = undefined;
                let mathDefault: TMetric['math_default'] = undefined;
                let isCurrencyMetric = false;

                // Determine the metric properties based on the key2 and type
                metrics.forEach(group =>
                  group.fields.forEach(metric => {
                    if (parseStringToCamelCase(metric.path) === key2) {
                      mathAllowed = metric.math_allowed;
                      mathDefault = metric.math_default;
                      isCurrencyMetric = metric.type === 'currency';
                    }
                  }),
                );

                // Handle only if mathAllowed is set
                if (mathAllowed) {
                  if (isCurrencyMetric) {
                    // Handle currency metrics
                    if (
                      key2 !== 'price' &&
                      key2 !== 'adPlanLifetime' &&
                      key2 !== 'adPlanDaily' &&
                      key2 !== 'statisticsSpentToday'
                    ) {
                      const currencySums: Record<CurrencyEnum, { total: number; count: number }> = {} as Record<
                        CurrencyEnum,
                        { total: number; count: number }
                      >;

                      dataForSecondaryGrouping.forEach(elem => {
                        const currency = elem['accountCurrency'] as CurrencyEnum;
                        const value = +elem[key2];

                        if (value) {
                          if (!currencySums[currency]) {
                            currencySums[currency] = { total: 0, count: 0 };
                          }
                          currencySums[currency].total += value;
                          currencySums[currency].count += 1;
                        }
                      });

                      if (mathDefault === 'sum') {
                        secondaryGroup[key2] = Object.entries(currencySums)
                          .sort(
                            ([currencyA], [currencyB]) =>
                              currencyOrder.indexOf(currencyA as CurrencyEnum) -
                              currencyOrder.indexOf(currencyB as CurrencyEnum),
                          )
                          .map(
                            ([currency, { total }]) =>
                              `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                          )
                          .join('<br />');
                      }

                      if (mathDefault === 'avg') {
                        secondaryGroup[key2] = Object.entries(currencySums)
                          .sort(
                            ([currencyA], [currencyB]) =>
                              currencyOrder.indexOf(currencyA as CurrencyEnum) -
                              currencyOrder.indexOf(currencyB as CurrencyEnum),
                          )
                          .map(
                            ([currency, { total, count }]) =>
                              `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                          )
                          .join('<br />');
                      }

                      // if (mathDefault === 'sum') {
                      //   secondaryGroup[key2] = Object.entries(currencySums)
                      //     .map(
                      //       ([currency, { total }]) =>
                      //         `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                      //     )
                      //     .join('<br />');
                      // }
                      // if (mathDefault === 'avg') {
                      //   secondaryGroup[key2] = Object.entries(currencySums)
                      //     .map(
                      //       ([currency, { total, count }]) =>
                      //         `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                      //     )
                      //     .join('<br />');
                      // }
                    } else {
                      secondaryGroup[key2] = null;
                      secondaryGroup.bidCurrent = null;
                    }
                  } else {
                    // Handle non-currency metrics
                    let result = 0;
                    let resultLength = 0;

                    dataForSecondaryGrouping.forEach(elem => {
                      if (+elem[key2]) {
                        result += +elem[key2];
                        resultLength += 1;
                      }
                    });

                    if (mathDefault === 'sum') {
                      secondaryGroup[key2] = result;
                    }
                    if (mathDefault === 'avg') {
                      secondaryGroup[key2] = resultLength ? result / resultLength : null;
                    }
                  }
                } else {
                  // Default handling when mathAllowed is not set
                  if (key2 !== 'accountCurrency') {
                    secondaryGroup[key2] = null;
                  }
                }
              });

              secondaryGroup[tabMainName] = `${
                Array.isArray(secondaryGroupingVariant) && secondaryGroupingVariant.length > 0
                  ? secondaryGroupingVariant[0].code
                  : secondaryGroupingVariant
              }`;
              secondaryGroup.key = dataForSecondaryGrouping[0]?.key + Date.now();
              secondaryGroup.isGroupRow = true;
              secondaryGroup.orgHierarchy = [primaryGroup.key, secondaryGroup.key];

              if (tertiaryMetric) {
                const tertiaryVariants = new Set<string>();

                dataForSecondaryGrouping
                  ?.filter(el => !el.isGroupRow)
                  .forEach(el => {
                    if (tertiaryMetric?.path === 'mt_project') {
                      tertiaryVariants.add(el.mtProject?.map((el: { name: string }) => el.name).join(', '));
                    } else {
                      tertiaryVariants.add(el[parseStringToCamelCase(tertiaryMetric?.path)]);
                    }
                  });

                return [
                  secondaryGroup,
                  ...[...tertiaryVariants]
                    .sort((a, b) => sortRow(a, b))
                    .flatMap(tertiaryGroupingVariant => {
                      const dataForTertiaryGrouping = dataForSecondaryGrouping.filter(item => {
                        if (tertiaryMetric.path === 'mt_project') {
                          return (
                            item.mtProject?.map((el: { name: string }) => el.name).join(', ') ===
                            tertiaryGroupingVariant
                          );
                        } else {
                          return item[parseStringToCamelCase(tertiaryMetric.path)] === tertiaryGroupingVariant;
                        }
                      });

                      const tertiaryGroup = JSON.parse(JSON.stringify(dataForTertiaryGrouping[0]));

                      Object.keys(tertiaryGroup).forEach(key => {
                        let mathAllowed: TMetric['math_allowed'] = undefined;
                        let mathDefault: TMetric['math_default'] = undefined;
                        let isCurrencyMetric = false;

                        // Determine the metric properties based on the key and type
                        metrics.forEach(group =>
                          group.fields.forEach(metric => {
                            if (parseStringToCamelCase(metric.path) === key) {
                              mathAllowed = metric.math_allowed;
                              mathDefault = metric.math_default;
                              isCurrencyMetric = metric.type === 'currency';
                            }
                          }),
                        );

                        // Handle only if mathAllowed is set
                        if (mathAllowed) {
                          if (isCurrencyMetric) {
                            // Handle currency metrics
                            if (
                              key !== 'price' &&
                              key !== 'adPlanLifetime' &&
                              key !== 'adPlanDaily' &&
                              key !== 'statisticsSpentToday'
                            ) {
                              const currencySums: Record<CurrencyEnum, { total: number; count: number }> = {} as Record<
                                CurrencyEnum,
                                { total: number; count: number }
                              >;

                              dataForTertiaryGrouping.forEach(elem => {
                                const currency = elem['accountCurrency'] as CurrencyEnum;
                                const value = +elem[key];

                                if (value) {
                                  if (!currencySums[currency]) {
                                    currencySums[currency] = { total: 0, count: 0 };
                                  }
                                  currencySums[currency].total += value;
                                  currencySums[currency].count += 1;
                                }
                              });

                              if (mathDefault === 'sum') {
                                tertiaryGroup[key] = Object.entries(currencySums)
                                  .sort(
                                    ([currencyA], [currencyB]) =>
                                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                                  )
                                  .map(
                                    ([currency, { total }]) =>
                                      `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                                  )
                                  .join('<br />');
                              }

                              if (mathDefault === 'avg') {
                                tertiaryGroup[key] = Object.entries(currencySums)
                                  .sort(
                                    ([currencyA], [currencyB]) =>
                                      currencyOrder.indexOf(currencyA as CurrencyEnum) -
                                      currencyOrder.indexOf(currencyB as CurrencyEnum),
                                  )
                                  .map(
                                    ([currency, { total, count }]) =>
                                      `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                                  )
                                  .join('<br />');
                              }

                              // if (mathDefault === 'sum') {
                              //   tertiaryGroup[key] = Object.entries(currencySums)
                              //     .map(
                              //       ([currency, { total }]) =>
                              //         `${formatCurrency(total, currency as keyof typeof CurrencyEnum)}`,
                              //     )
                              //     .join('<br />');
                              // }
                              // if (mathDefault === 'avg') {
                              //   tertiaryGroup[key] = Object.entries(currencySums)

                              //     .map(
                              //       ([currency, { total, count }]) =>
                              //         `${formatCurrency(total / count, currency as keyof typeof CurrencyEnum)}`,
                              //     )
                              //     .join('<br />');
                              // }
                            } else {
                              tertiaryGroup[key] = null;
                            }
                          } else {
                            // Handle non-currency metrics
                            let result = 0;
                            let resultLength = 0;

                            dataForTertiaryGrouping.forEach(elem => {
                              if (+elem[key]) {
                                result += +elem[key];
                                resultLength += 1;
                              }
                            });

                            if (mathDefault === 'sum') {
                              tertiaryGroup[key] = result;
                            }
                            if (mathDefault === 'avg') {
                              tertiaryGroup[key] = resultLength ? result / resultLength : null;
                            }
                          }
                        } else {
                          // Default handling when mathAllowed is not set
                          if (key !== 'accountCurrency') {
                            tertiaryGroup[key] = null;
                          }
                        }
                      });

                      tertiaryGroup[tabMainName] = `${
                        Array.isArray(tertiaryGroupingVariant)
                          ? tertiaryGroupingVariant[0]?.code
                          : tertiaryGroupingVariant
                      }`;
                      tertiaryGroup.key = dataForTertiaryGrouping[0]?.key + Date.now();
                      tertiaryGroup.isGroupRow = true;
                      tertiaryGroup.orgHierarchy = [primaryGroup.key, secondaryGroup.key, tertiaryGroup.key];
                      secondaryGroup.bidCurrent = null;

                      return [
                        tertiaryGroup,
                        ...dataForTertiaryGrouping.map(dataItem => ({
                          ...dataItem,
                          orgHierarchy: [...tertiaryGroup.orgHierarchy, dataItem.key],
                        })),
                      ];
                    }),
                ];
              }

              return [
                secondaryGroup,
                ...dataForSecondaryGrouping.map(dataItem => ({
                  ...dataItem,
                  orgHierarchy: [...secondaryGroup.orgHierarchy, dataItem.key],
                })),
              ];
            }),
        ];
      }

      return [
        primaryGroup,
        ...dataForPrimaryGrouping.map(dataItem => ({ ...dataItem, orgHierarchy: [primaryGroup.key, dataItem.key] })),
      ];
    });

  return groupedData;
};
