import { GetQuickFilterTextParams, rendererSelector, StatusColorEnum } from '@plarin/inputs';
import { shortDate, isUrlProd } from '@plarin/utils';
import { BidRendererMenu, BudgetEditRenderer } from '../../pages/manage-vk/tabs-content/bid-renderer-menu';
import { sortRow } from '../../utils/common';
import { FAST_STAT } from '../../utils/constants';
import { parseStringToCamelCase } from '../../utils/parseStatData/parseStatistics';
import { comparatorStatus } from '../../utils/manage-yd';
import { ScheduleButtons } from '../schedule-buttons';
import { TCustomColumn } from './types';
import { defRendererSelector, formatValues, getPercentType } from './utils';

export const customColumns: TCustomColumn = (metric, wsDictionary, tabName) => ({
  // PROJECTS: {
  //   headerName: 'Проект',
  //   headerComponentParams: tabName === 'accountName' ? {} : { subheader: 'Ответственный за кампанию' },
  //   field: parseStringToCamelCase(metric.path),
  //   lockPinned: true,
  //   cellRendererSelector: rendererSelector('blank'),
  //   cellRendererParams: {
  //     wsDictionary,
  //     innerRenderer: 'ProjectsRenderer',
  //   },
  //   minWidth: 180,
  //   cellClassRules: {
  //     'sync-status-disabled': params => returnCellRules(params.data.status),
  //     banned: param => param.data.statusColor === 'banned',
  //   },
  //   comparator: (a, b) => {
  //     if (a[0].name === 'Не определен') return -1;
  //     if (b[0].name === 'Не определен') return 1;
  //     if (a.length >= 2 && b.length >= 2) return a.length - b.length;
  //     if (a.length <= 2 && b.length >= 2) return 1;
  //     if (a.length >= 2 && b.length <= 2) return -1;
  //     return sortRow(a[0].name, b[0].name, true);
  //   },
  // },
  STATUS: {
    headerName: 'Трансляция',
    field: parseStringToCamelCase(metric.path),
    lockPinned: true,
    cellRendererSelector: rendererSelector('blank'),
    cellRenderer: 'StatusRenderer',
    cellRendererParams: {
      tabName: tabName,
      status: 'status',
      innerRenderer: 'TranslationRendererYd',
    },
    minWidth: 180,
    cellClassRules: {
      'sync-status-disabled': params => params.data.statusColor === StatusColorEnum.ARCHIVED,
      banned: param => param.data.statusColor === StatusColorEnum.BANNED,
    },

    comparator: comparatorStatus(tabName!),
  },

  // PERIOD: {
  //   headerName: 'Расписание',
  //   headerComponentParams: { subheader: 'Дата запуска - Дата остановки' },
  //   type: 'rightAligned',
  //   lockPinned: true,
  //   resizable: false,
  //   field: parseStringToCamelCase(metric.path),
  //   cellRendererSelector: rendererSelector('blank'),
  //   cellRendererParams: {
  //     innerRenderer: 'PeriodRenderer',
  //     tabName: tabName,
  //     ButtonComponent: ScheduleButtons,
  //   },
  //   minWidth: 200,
  //   headerClass: 'right',
  //   cellClassRules: {
  //     'sync-status-disabled': params => returnCellRules(params.data.status),
  //     banned: param => param.data.statusColor === 'banned',
  //   },
  //   getQuickFilterText: params =>
  //     `${shortDate(params.data.adPlanStop || '')} ${shortDate(params.data.adPlanStart || '')} ${shortDate(
  //       params.data.campaignStart || '',
  //     )} ${shortDate(params.data.campaignStop || '')}`,
  // },

  BUDGET: {
    headerName: 'Бюджет',
    headerComponentParams: { subheader: 'Стратегия' },
    lockPinned: true,
    resizable: false,
    field: parseStringToCamelCase(metric.path),
    cellRendererSelector: rendererSelector('blank'),
    cellRendererParams: {
      colName: 'BUDGET',
      innerRenderer: 'BudgetRendererYd',
      tabName: tabName,
    },
    type: 'rightAligned',
    minWidth: 200,
    maxWidth: 300,
    headerClass: metric.position,
    cellClass: metric.position,
    cellClassRules: {
      'sync-status-disabled': params => params.data.statusColor === StatusColorEnum.ARCHIVED,
      banned: param => param.data.statusColor === StatusColorEnum.BANNED,
    },
    comparator: (a, b, nodeA, nodeB) => {
      !isUrlProd && console.log(nodeA, nodeB);

      const { dailyBudgetAmount: dailyBudgetAmountA, weeklySpendLimit: weeklySpendLimitA } = nodeA.data;
      const { dailyBudgetAmount: dailyBudgetAmountB, weeklySpendLimit: weeklySpendLimitB } = nodeB.data;

      const parseAmount = (amount: string) => (amount !== '' && +amount !== 0 ? +amount : null);

      const dailyA = parseAmount(dailyBudgetAmountA);
      const dailyB = parseAmount(dailyBudgetAmountB);
      const weeklyA = parseAmount(weeklySpendLimitA);
      const weeklyB = parseAmount(weeklySpendLimitB);

      if (dailyA !== null && dailyB !== null) {
        return dailyA - dailyB;
      }
      if (dailyA !== null) {
        return 1;
      }
      if (dailyB !== null) {
        return -1;
      }

      if (weeklyA !== null && weeklyB !== null) {
        return weeklyA - weeklyB;
      }
      if (weeklyA !== null) {
        return 1;
      }
      if (weeklyB !== null) {
        return -1;
      }

      return 0;
    },
  },

  // BUDGET_LIFETIME: {
  //   headerName: 'Общий бюджет',
  //   headerComponentParams: { subheader: 'Расход / Лимит' },
  //   lockPinned: true,
  //   resizable: false,
  //   field: parseStringToCamelCase(metric.path),
  //   cellRendererSelector: rendererSelector('blank'),
  //   cellRendererParams: {
  //     colName: 'BUDGET_LIFETIME',
  //     innerRenderer: 'BudgetRenderer',
  //     Component: BudgetEditRenderer,
  //     tabName: tabName,
  //   },
  //   type: 'rightAligned',
  //   minWidth: 200,
  //   maxWidth: 300,
  //   headerClass: metric.position,
  //   cellClass: metric.position,
  //   cellClassRules: {
  //     'sync-status-disabled': params => returnCellRules(params.data.status),
  //     banned: param => param.data.statusColor === 'banned',
  //   },
  //   comparator: (a, b, nodeA, nodeB) => {
  //     const dailyA = nodeA.data[getSortBudget(tabName)];
  //     const dailyB = nodeB.data[getSortBudget(tabName)];

  //     if (typeof dailyA === 'number' && typeof dailyB === 'number' && dailyA === dailyB) {
  //       return nodeA.data.statisticsSpentAll - nodeB.data.statisticsSpentAll;
  //     }
  //     return dailyA - dailyB;
  //   },
  // },

  // BUDGET_DAILY: {
  //   headerName: 'Дневной бюджет',
  //   headerComponentParams: { subheader: 'Расход / Лимит' },
  //   field: parseStringToCamelCase(metric.path),
  //   lockPinned: true,
  //   resizable: false,
  //   cellRendererSelector: rendererSelector('blank'),
  //   cellRendererParams: {
  //     colName: 'BUDGET_DAILY',
  //     innerRenderer: 'BudgetRenderer',
  //     Component: BudgetEditRenderer,
  //     tabName: tabName,
  //   },
  //   type: 'rightAligned',
  //   minWidth: 200,
  //   maxWidth: 300,
  //   headerClass: 'right',
  //   cellClass: 'right',
  //   cellClassRules: {
  //     'sync-status-disabled': params => returnCellRules(params.data.status),
  //     banned: param => param.data.statusColor === 'banned',
  //   },
  //   comparator: (a, b, nodeA, nodeB) => {
  //     const dailyA = nodeA.data[getSortBudget(tabName, true)];
  //     const dailyB = nodeB.data[getSortBudget(tabName, true)];

  //     if (typeof dailyA === 'number' && typeof dailyB === 'number' && dailyA === dailyB) {
  //       return nodeA.data.statisticsSpentToday - nodeB.data.statisticsSpentToday;
  //     }
  //     return dailyA - dailyB;
  //   },
  // },

  // PRICE: {
  //   headerName: metric.name?.ru,
  //   headerComponentParams: { subheader: 'Тип ставки' },
  //   field: parseStringToCamelCase(metric.path),
  //   lockPinned: true,
  //   cellRendererSelector: rendererSelector('blank'),
  //   valueFormatter: props => formatValues(props, metric.type),
  //   cellRendererParams: {
  //     bidType: 'bidType',
  //     innerRenderer: 'BidCellRenderer',
  //     Component: BidRendererMenu,
  //   },
  //   headerClass: 'right',
  //   cellClass: 'right',
  //   type: 'rightAligned',
  //   cellClassRules: {
  //     'sync-status-disabled': params => returnCellRules(params.data.status),
  //     banned: param => param.data.statusColor === 'banned',
  //   },
  //   minWidth: 150,
  // },
  // FAST_STAT: {
  //   headerName: metric.name?.ru,
  //   field: FAST_STAT.Faststat,
  //   lockPinned: true,
  //   cellRendererParams: {
  //     innerRenderer: 'FastStatRenderer',
  //     fastStatType: FAST_STAT.Faststat,
  //   },

  //   cellStyle: {
  //     padding: '0px',
  //   },
  //   sortable: false,
  //   resizable: false,
  //   minWidth: 256,
  //   maxWidth: 256,

  //   cellClassRules: {
  //     'sync-status-disabled': params => returnCellRules(params.data.status),
  //     banned: param => param.data.statusColor === 'banned',
  //   },
  //   cellRendererSelector: rendererSelector('blank'),
  // },
  // FAST_STAT_72: {
  //   headerName: metric.name?.ru,
  //   cellRendererSelector: rendererSelector('blank'),
  //   lockPinned: true,
  //   cellStyle: {
  //     padding: '0px',
  //   },
  //   cellRendererParams: {
  //     innerRenderer: 'FastStatRenderer',
  //     fastStatType: FAST_STAT.Faststat72,
  //   },
  //   sortable: false,
  //   resizable: false,
  //   minWidth: 304,
  //   maxWidth: 304,
  //   cellClassRules: {
  //     'sync-status-disabled': params => returnCellRules(params.data.status),
  //     banned: param => param.data.statusColor === 'banned',
  //   },
  //   field: FAST_STAT.Faststat72,
  // },
  DEFAULT: {
    headerName: metric.name?.ru,
    field: parseStringToCamelCase(metric.path),
    type: metric.position === 'right' ? 'rightAligned' : undefined,
    lockPinned: true,
    cellRenderer: 'DefaultTooltipCell',
    valueFormatter: (params: any) =>
      params.node?.rowPinned && (metric.type.includes('string') || metric.type.includes('datetime'))
        ? ''
        : formatValues(params, getPercentType(metric.type, metric.round_digits), metric.round_digits),
    cellRendererSelector: defRendererSelector(metric),
    headerClass: metric.position,
    cellClass: metric.position,
    width: metric.width ? metric.width : 150,
    cellClassRules: {
      'sync-status-disabled': params => params.data.statusColor === StatusColorEnum.ARCHIVED,
      banned: param => param.data.statusColor === StatusColorEnum.BANNED,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => '',
  },
});
