import { WORKSPACE_ID } from '@plarin/core';
import { appLocalStorage } from '../components/app';

export interface ILocalSelectedWS {
  selectedWS: string;
  userId: string;
}

const CustomParseJSON = <T>(value?: string | null) => {
  if (!value) {
    return undefined;
  }
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return undefined;
  }
};

const localSelectedWS = () => CustomParseJSON<ILocalSelectedWS[]>(appLocalStorage.get(WORKSPACE_ID));

const resetSelectedWS = (userId: string) => {
  return (
    localSelectedWS()?.filter(el => {
      return el.userId !== userId;
    }) || []
  );
};

// Получаем текущий выбранный WS для текущего пользователя
export const getLocalSelectedWS = (userId: string) => {
  return localSelectedWS()?.filter(e => e.userId === userId)?.[0]?.selectedWS;
};

//  Для изменения выбраного воркспейса
export const changeLocalSelectedWS = ({ selectedWS, userId }: ILocalSelectedWS) => {
  appLocalStorage.set(
    WORKSPACE_ID,
    JSON.stringify([
      ...resetSelectedWS(userId),
      {
        userId,
        selectedWS,
      },
    ]),
  );
};

export const resetLocalSelectedWS = (userId: string) => {
  appLocalStorage.set(WORKSPACE_ID, JSON.stringify(resetSelectedWS(userId)));
};
