import { BasicStore } from '@plarin/core';
import {
  Avatar,
  BranchesIcon,
  FolderIconColored,
  GoogleAnalyticsIcon,
  HeadsetIcon,
  IdeaIcon,
  LayoutIconColored,
  PeopleIconColored,
  PersonIconColored,
  PostAutomatIcon,
  SettingsIconColored,
  VKIcon,
  VkAdsIcon,
  Typography,
  YandexDirectIcon,
  YandexMetricIcon,
  AppsflyerIcon,
} from '@plarin/design';
import {
  EllipsisTooltip,
  TMenuSection,
  TMenuItem,
  TTopLevelItems,
  menuSectionsPaths,
  menuItemsPath,
} from '@plarin/inputs';
import { isUrlProd } from '@plarin/utils';
import { makeObservable, observable, runInAction } from 'mobx';
import React from 'react';
import { TWsDictionary } from '../../types/profile/types';
import { DrawerId } from '../enums';
import { routerPaths } from '../routing/router-path';
import { getSecondLetter, sortRow } from '../utils/common';
import { menuSectionIndexes } from '../utils/constants';

const defaultMenuBottomItems: TTopLevelItems = [
  {
    id: DrawerId.proposeIdea,
    title: 'Предложить идею',
    icon: <IdeaIcon hasSafebox={true} className="menu-icon" />,
    link: '',
    isOpenDrawer: false,
  },
  {
    id: DrawerId.support,
    title: 'Техническая поддержка',
    icon: <HeadsetIcon className="menu-icon" />,
    link: '',
  },
];

const VKMenuItem = {
  title: 'VK Реклама',
  layoutTitle: 'VK Реклама',
  icon: <VkAdsIcon />,
  selected: false,
  index: 1,
  menuItemKey: menuItemsPath.MANAGE_VK,
  link: routerPaths.manageVK.ACCOUNTS,
};

const YDMenuItem = {
  title: 'Яндекс Директ',
  layoutTitle: 'Яндекс Директ',
  icon: <YandexDirectIcon />,
  selected: false,
  index: 2,
  menuItemKey: menuItemsPath.MANAGE_YD,
  link: routerPaths.manageYD.ACCOUNTS,
};

const ManageAds = [{ ...VKMenuItem }, { ...YDMenuItem }];

const integrationsSection = {
  title: 'Интеграции',
  sectionIndex: menuSectionIndexes.integrations,
  isOpen: false,
  path: menuSectionsPaths.INTEGRATIONS,
  menuItems: [
    {
      title: 'VK Реклама',
      layoutTitle: 'VK Реклама',
      link: routerPaths.integrations.ACCOUNT,
      icon: <VKIcon />,
      selected: false,
      menuItemKey: menuItemsPath.INTEGRATIONS_VK,
      index: 1,
    },
    {
      title: 'Яндекс Директ',
      layoutTitle: 'Яндекс Директ',
      link: routerPaths.integrations.YANDEX_DIRECT,
      icon: <YandexDirectIcon />,
      selected: false,
      menuItemKey: menuItemsPath.INTEGRATIONS_YANDEX_DIRECT,
      index: 2,
    },
    {
      title: 'Яндекс Метрика',
      layoutTitle: 'Яндекс Метрика',
      link: routerPaths.integrations.YANDEX_METRICS,
      icon: <YandexMetricIcon />,
      selected: false,
      menuItemKey: menuItemsPath.INTEGRATIONS_YANDEX_METRICS,
      index: 3,
    },
    {
      title: 'Google Analytics',
      layoutTitle: 'Google Analytics',
      link: routerPaths.integrations.GOOGLE_ANALYTICS,
      icon: <GoogleAnalyticsIcon />,
      selected: false,
      menuItemKey: menuItemsPath.INTEGRATIONS_GOOGLE_ANALYTICS,
      index: 4,
    },
    {
      title: 'Appsflyer',
      layoutTitle: 'Appsflyer',
      link: routerPaths.integrations.APPSFLYER,
      icon: <AppsflyerIcon />,
      selected: false,
      menuItemKey: menuItemsPath.INTEGRATIONS_APPSFLYER,
      index: 5,
    },
  ],
};

const defaultMenuData: TMenuSection[] = [
  {
    title: 'Управление рекламой',
    sectionIndex: menuSectionIndexes.manage,
    isOpen: false,
    path: menuSectionsPaths.MANAGE,
    menuItems: ManageAds,
  },
  {
    title: 'Отчеты',
    sectionIndex: menuSectionIndexes.reports,
    isOpen: false,
    path: menuSectionsPaths.REPORTS,
    menuItems: [
      {
        title: 'Список отчетов',
        layoutTitle: 'Список отчетов',
        icon: <LayoutIconColored className="menu-icon" />,
        selected: false,
        index: 1,
        menuItemKey: menuItemsPath.ALL_REPORTS,
        link: routerPaths.reports.BASE,
      },
    ],
  },
  {
    sectionIndex: menuSectionIndexes.ws,
    title: 'Пространство',
    isOpen: false,
    path: menuSectionsPaths.WORKSPACE,
    menuItems: [
      {
        title: 'Пользователи',
        layoutTitle: 'Пользователи',
        link: routerPaths.workspaces.MEMBERS,
        icon: <PersonIconColored className="menu-icon" />,
        selected: false,
        index: 1,
        menuItemKey: menuItemsPath.MEMBERS,
      },
      {
        title: 'Команды',
        layoutTitle: 'Команды',
        link: routerPaths.workspaces.TEAMS,
        icon: <PeopleIconColored className="menu-icon" />,
        selected: false,
        menuItemKey: menuItemsPath.TEAMS,
        index: 2,
      },
      {
        title: 'Проекты',
        layoutTitle: 'Проекты',
        link: routerPaths.workspaces.PROJECTS,
        icon: <FolderIconColored className="menu-icon" />,
        selected: false,
        menuItemKey: menuItemsPath.PROJECTS,
        index: 3,
      },
      {
        title: 'Настройки пространства',
        layoutTitle: 'Настройки пространства',
        link: routerPaths.workspaces.SETTINGS,
        icon: <SettingsIconColored className="menu-icon" />,
        selected: false,
        menuItemKey: menuItemsPath.SETTINGS,
        index: 4,
      },
    ],
  },

  integrationsSection,
];

export class MenuStore extends BasicStore {
  menuVisible?: boolean = true;
  isMenuHover: boolean = false;
  disableShowButton: boolean = false;
  menuTopItems: TTopLevelItems = [];
  menuData = defaultMenuData;
  menuBottomItems = defaultMenuBottomItems;
  unreadIntercomCount: number = 0;

  constructor() {
    super();
    makeObservable<this>(this, {
      menuVisible: observable,
      isMenuHover: observable,
      disableShowButton: observable,
      menuTopItems: observable,
      menuData: observable,
      menuBottomItems: observable,
      unreadIntercomCount: observable,
    });
    this.initMenuVisible();
  }

  // Зачем оно такое?
  initMenuVisible = () => {
    const menuIsVisible = localStorage.getItem('menuVisible');
    if (menuIsVisible) {
      menuIsVisible === 'false' && runInAction(() => (this.menuVisible = false));
    } else {
      localStorage.setItem('menuVisible', 'true');
    }
  };

  openSection = (index: number, supressOpen?: boolean) => {
    if (supressOpen && this.menuData[index - 1].isOpen) return;
    runInAction(
      () =>
        (this.menuData = this.menuData.map(el =>
          el.sectionIndex === index ? { ...el, isOpen: !el.isOpen } : { ...el },
        )),
    );
  };

  // делаем пункт меню выбранным
  selectMenuItem = (menuItemKey: TMenuItem['menuItemKey'], selected: boolean, sectionIndex: number) => {
    const data = this.menuData.map(menuSection => {
      return {
        ...menuSection,
        menuItems: menuSection.menuItems.map(menuItem => {
          return menuItem.menuItemKey === menuItemKey && menuSection.sectionIndex === sectionIndex
            ? { ...menuItem, selected: selected }
            : { ...menuItem, selected: false };
        }),
      };
    });
    runInAction(() => (this.menuData = data));
  };

  // делает так, что ни один пункт меню не является выбранным
  deselectMenuItem = () => this.selectMenuItem('none', false, 0);

  setMenuProjects = (projects: TWsDictionary['projects']) => {
    const newMenuData: TMenuSection[] = [
      {
        title: 'Проекты',
        sectionIndex: menuSectionIndexes.projectsOverview,
        isOpen: this.menuData[0]?.title === 'Проекты' && this.menuData[0]?.isOpen,
        path: menuSectionsPaths.ALL_PROJECTS_OVERVIEW,
        menuItems: Object.values(projects)
          .sort((a, b) => sortRow(a.name, b.name))
          .map((project, index) => ({
            title: project.name,
            layoutTitle: (
              <>
                <Avatar
                  fname={project.name[0]}
                  lname={getSecondLetter(project.name)}
                  colorAvatar={project.avatar_color}
                  type="square"
                  size={40}
                />
                <EllipsisTooltip tooltipMessage={project.name}>
                  <Typography size="ModalTitle" weight={600} ellips color="TextPrimary">
                    {project.name}
                  </Typography>
                </EllipsisTooltip>
              </>
            ),
            icon: (
              <Avatar
                fname={project.name[0]}
                lname={getSecondLetter(project.name)}
                colorAvatar={project.avatar_color}
                type="square"
                size={20}
              />
            ),
            selected: false,
            index: index,
            menuItemKey: project._id,
            link: routerPaths.ALL_PROJECTS_OVERVIEW.PROJECT_OVERVIEW + project._id,
          })),
      },
      ...this.menuData.filter(el => el.title !== 'Проекты'),
    ];

    runInAction(() => (this.menuData = newMenuData));
  };

  // Используется исключительно для реакции на изменение ссылки в окне браузера
  updateMenuData = ({ mainMenuIndex, subMenuIndex }: { mainMenuIndex: number; subMenuIndex: string }) =>
    runInAction(
      () =>
        (this.menuData = this.menuData.map(mainMenuItem => ({
          ...mainMenuItem,
          isOpen: !mainMenuItem.isOpen ? mainMenuItem.sectionIndex === mainMenuIndex : mainMenuItem.isOpen,
          subMenuItems: mainMenuItem.menuItems.map(el => ({ ...el, selected: el.menuItemKey === subMenuIndex })),
        }))),
    );

  setOpenDrawer = (id: number) => {
    runInAction(() => (this.menuBottomItems.filter(item => item.id === id)[0].isOpenDrawer = true));
  };
  setCloseDrawer = (id: number) => {
    runInAction(() => (this.menuBottomItems.filter(item => item.id === id)[0].isOpenDrawer = false));
  };

  applyHover = () => runInAction(() => (this.isMenuHover = true));
  removeHover = () => runInAction(() => (this.isMenuHover = false));

  setMenuStatus = (status: boolean) => {
    localStorage.setItem('menuVisible', JSON.stringify(status));
  };

  toggleVisibleMenu = () =>
    runInAction(() => {
      if (this.menuVisible) {
        this.disabledShowButton();
        this.isMenuHover = false;
        this.menuVisible = !this.menuVisible;
        setTimeout(() => this.enabledShowButton(), 300);
        this.setMenuStatus(this.menuVisible);
      } else {
        this.menuVisible = !this.menuVisible;
        this.isMenuHover = false;
        this.setMenuStatus(this.menuVisible);
      }
    });

  private disabledShowButton = () => runInAction(() => (this.disableShowButton = true));
  private enabledShowButton = () => runInAction(() => (this.disableShowButton = false));

  setUnreadIntercomCount = (count: number) => {
    runInAction(() => {
      this.unreadIntercomCount = count;
    });
  };

  resetStore = () =>
    runInAction(() => {
      this.menuVisible = true;
      this.isMenuHover = false;
      this.disableShowButton = false;
      this.menuTopItems = [];
      this.menuData = defaultMenuData;
      this.menuBottomItems = defaultMenuBottomItems;
      this.unreadIntercomCount = 0;
    });
}
