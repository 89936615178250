import { Typography } from '@plarin/design';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useAppDataStore } from '../../components/app-data-provider';
import classes from './style.module.scss';

interface IGoalsSettings {
  data: { _id: string; id: string };
}

export const GoalsSettingsGA = observer(({ data }: IGoalsSettings) => {
  const {
    integrationsGA: { getGoalsGA },
  } = useAppDataStore();
  const onOpen = useCallback((e: MouseEvent, ref: HTMLDivElement) => {
    e.stopPropagation();
    getGoalsGA({ gaUserId: data._id, profileId: data.id });
  }, []);

  return (
    <div
      ref={ref => {
        if (!ref) return;
        ref.onclick = e => onOpen(e, ref);
      }}
    >
      <Typography size="TableRowSubHeader" color="LinkPrimary" componentProps={{ className: classes.ref }}>
        Выбрать цели
      </Typography>
    </div>
  );
});
