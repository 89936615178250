import { GridApi, Switch, isYdActive, isYdSuspended, StateValueEnum, SwitcherTooltip } from '@plarin/inputs';
import { ManageVkTabNameEnum, ManageYDTabNameEnum } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { returnId as returnIdForVk } from '../../utils/manage-vk';
import { returnItemIdByTabName as returnIdForYd } from '../../utils/manage-yd';
import { useAppDataStore } from '../app-data-provider';
import classes from './styles.module.scss';

type TNetwork = 'vk' | 'yd';

type TProps = {
  network: TNetwork;
  tabNameVk?: ManageVkTabNameEnum;
  tabNameYd?: ManageYDTabNameEnum;
  data: any;
  gridApi: GridApi;
};

const isChecked = (network: TNetwork, data: any) => {
  if (network === 'vk') return data.status?.includes('Актив');
  if (network === 'yd') return isYdActive(data);
};

const applyCheckedStyle = (network: TNetwork, data: any) => {
  if (network === 'vk') return !(data.cellLoadingName === 'status') && data.status?.includes('Актив');

  // TODO: может понадобиться проверка на условие, что cell is loading
  if (network === 'yd') return !(data.cellLoadingName === 'status') && isYdActive(data);
};

export const SwitchStatusEntity = ({
  network,
  tabNameVk, // обязательно передаём, если network === 'vk'
  tabNameYd, // обязательно передаём, если network === 'yd'
  data,
  gridApi,
}: TProps) => {
  const {
    manageVK: { changeManageVkStatus: changeVkStatus },
    manageYD: { changeManageYdStatus: changeYdStatus },
  } = useAppDataStore();

  const switchIsDisabled = () => {
    // значит, сейчас уже отправлен запрос на изменение статуса, и строчка таблицы задизейблена
    if (data.cellLoadingName === 'status') {
      return true;
    }

    if (network === 'vk') {
      return data.status?.includes('Архив');
    }

    if (network === 'yd') {
      // TODO: в этом случае добавить тултипы свитчеру
      // для ads: 'Объявление неактивно (черновик, ожидает модерации, отклонено) или принадлежит к неактивной либо остановленной кампании.'
      // для campaign: 'Кампания неактивна (черновик, ожидает модерации, отклонена, отсутствуют средства на кампании или на общем счете, нет активных объявлений).'
      if (data.adState === StateValueEnum.OFF || data.campaignState === StateValueEnum.OFF) return true;

      return !isYdActive(data) && !isYdSuspended(data);
    }
  };

  // текст тултипа для задизейбленного свитчера
  const getTooltipText = () => {
    if (network === 'yd' && switchIsDisabled()) {
      if (data.adState === StateValueEnum.OFF) {
        return (
          // https://yandex.ru/dev/direct/doc/dg/objects/ad.html#status
          <>
            Объявление неактивно (черновик, ожидает модерации, отклонено) или принадлежит к неактивной либо
            остановленной кампании.
          </>
        );
      }

      if (data.campaignState === StateValueEnum.OFF) {
        return (
          // https://yandex.ru/dev/direct/doc/dg/objects/campaign.html#status
          <>
            Кампания неактивна (черновик, ожидает модерации, отклонена, отсутствуют средства на кампании или на общем
            счете, нет активных объявлений).
          </>
        );
      }
    }
    return undefined;
  };

  const onChangeStatus = (ref: HTMLDivElement) => {
    if (!ref || switchIsDisabled()) return;

    ref.onclick = event => {
      event.stopPropagation();

      if (network === 'vk' && tabNameVk) {
        const id = returnIdForVk(tabNameVk).toString();
        const newVkStatus = isChecked(network, data) ? 'blocked' : 'active';

        changeVkStatus([+data[id]], newVkStatus, tabNameVk, gridApi).then();
      }

      if (network === 'yd' && tabNameYd) {
        const id = returnIdForYd(tabNameYd).toString();
        const newYdStatus = !isYdActive(data) ? 'resume' : 'suspend';

        changeYdStatus([+data[id]], newYdStatus, tabNameYd, gridApi).then();
      }
    };
  };

  return (
    <div className={classes.switchWrapper}>
      <SwitcherTooltip content={getTooltipText()} component="span" placement="bottom">
        <>
          <Switch
            checked={isChecked(network, data)}
            className={classes.switch}
            disabled={switchIsDisabled()}
            components={{
              Track: () => (
                <span className={clsx(classes.track, applyCheckedStyle(network, data) && classes.trackChecked)} />
              ),
            }}
          />

          <div
            className={clsx(classes.switchWrapperButton, switchIsDisabled() && classes.switchIsDisabled)}
            ref={onChangeStatus}
          />
        </>
      </SwitcherTooltip>
    </div>
  );
};
