import { Typography } from '@plarin/design';
import { GridApi, Modal, ModalActionNamesEnum } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { TYMStoreData } from '../../../../types/connect-ym/types';
import { useAppDataStore } from './../../../components/app-data-provider';

const textRemove = {
  one: {
    title: 'Аккаунт ',
    text: ' будет отключен и удален из Plarin, связи с проектами и вся ранее накопленная статистика по целям и E-commerce будут удалены.',
  },
  many: {
    title: 'Аккаунты ',
    text: ' будут отключены и удалены из Plarin, связи с проектами и вся ранее накопленная статистика по целям и E-commerce будут удалены.',
  },
};

export const MetricsConfirmModal = observer(({ gridApi }: { gridApi: GridApi }) => {
  const {
    integrationsYM: { modalData, clearModalData, isLoading, disableYM, removeYM },
  } = useAppDataStore();

  const getParentRows = (rows: TYMStoreData[]) => rows.filter(row => row.isParent);
  const getChildsRows = (rows: TYMStoreData[]) => rows.filter(row => !row.isParent);

  const getEnabledRows = (rows: TYMStoreData[]) => rows.filter(row => row.plarin_status === 'enabled');

  const parentRows = getParentRows(modalData.selectedRows || []);
  const childRows = getChildsRows(modalData.selectedRows || []);

  const textAccounts = useMemo(
    () =>
      modalData.names &&
      modalData.names.map((element, index) => (index === modalData.names!.length - 1 ? element : element + ', ')),
    [modalData.names],
  );
  switch (modalData.type) {
    case 'pause':
      return (
        <Modal
          open={modalData.type === 'pause'}
          closeModal={() => clearModalData()}
          workspaceHeader="pause"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () => clearModalData(),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Pause,
            isLoading: isLoading,
            action: () => {
              disableYM({
                parentRows: getEnabledRows(parentRows),
                childRows: getEnabledRows(childRows),
                gridApi: gridApi,
              });
            },
          }}
          title="Выключить интеграцию?"
        >
          <Typography size="AuthContent">
            {'После выключения '}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>
            {
              ' все связи с проектами будут сохранены, но синхронизация будет остановлена и новые данные поступать не будут до возобновления интеграции.'
            }
          </Typography>
        </Modal>
      );

    case 'remove':
      return (
        <Modal
          open={modalData.type === 'remove'}
          closeModal={() => clearModalData()}
          workspaceHeader="remove"
          secondAction={{
            actionTitle: ModalActionNamesEnum.Cancel,
            action: () => clearModalData(),
          }}
          firstAction={{
            actionTitle: ModalActionNamesEnum.Unplug,
            isLoading: isLoading,
            action: () => {
              removeYM({ db_ids: parentRows.map(row => row._id) });
            },
          }}
          title="Отключить аккаунт?"
        >
          <Typography size="AuthContent">
            {textAccounts?.length === 1 ? textRemove.one.title : textRemove.many.title}
            <Typography size="AuthContent" weight={600}>
              {textAccounts}
            </Typography>
            {textAccounts?.length === 1 ? textRemove.one.text : textRemove.many.text}
          </Typography>
        </Modal>
      );

    default:
      return null;
  }
});
