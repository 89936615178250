import { EllipsisTooltip, HightLightText, copy, StatusColorEnum } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useState } from 'react';
import classes from '../style.module.scss';

interface SecondLineContentProps {
  data: any;
  secondLine: string;
  copySecondLine?: boolean;
  filterValue?: any;
  currentStatusName?: string;
  type?: string;
}

export const SecondLineContent: React.FC<SecondLineContentProps> = ({
  data,
  secondLine,
  copySecondLine,
  filterValue,
  currentStatusName,
  type,
}) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const tooltipText = (isCopied: boolean) => (isCopied ? 'Скопировано!' : 'Нажмите, чтобы скопировать');
  const resetIsCopied = () => setIsCopied(false);
  const updateData = (value: boolean) => setIsShowTooltip(value);

  return (
    <div
      className={clsx(
        classes.second,
        currentStatusName &&
          data[currentStatusName] &&
          (data[currentStatusName] === 'disabled' ||
            data[currentStatusName].includes('Архивн') ||
            data[currentStatusName] === StatusColorEnum.ARCHIVED ||
            data.syncStatusCode === 'disabled') &&
          classes.errorGray,
      )}
    >
      {/* {type && RenderIcon(data[type])} */}

      {copySecondLine ? (
        <EllipsisTooltip
          classNameContainer={classes.containerTooltip}
          tooltipMessage={isShowTooltip ? data[secondLine] : tooltipText(isCopied)}
          isShowTooltip={updateData}
        >
          <span
            className={classes.secondCopy}
            ref={ref => {
              if (!ref) return;
              ref.onclick = event => {
                copy(event, data[secondLine]).then(() => setIsCopied(true));
              };
            }}
            onMouseLeave={resetIsCopied}
          >
            <HightLightText text={data[secondLine]} filterValue={filterValue} textEllips={true} />
          </span>
        </EllipsisTooltip>
      ) : (
        <EllipsisTooltip tooltipMessage={data[secondLine]}>
          <HightLightText text={data[secondLine]} filterValue={filterValue} textEllips={true} />
        </EllipsisTooltip>
      )}
    </div>
  );
};
