import { Typography } from '@plarin/design';
import React, { useState, useEffect } from 'react';
import { TRows } from '../types';
import classes from './style.module.scss';
import { filteredOption, TCurrencyFiltrator } from './utils';

export const NetworkFiltrator = ({ filteredRows, deselectRows }: TCurrencyFiltrator) => {
  const [vk, setVk] = useState<TRows>([]);
  const [mt, setMt] = useState<TRows>([]);
  const [unknown, setUnknown] = useState<TRows>([]);

  useEffect(() => {
    const vkArr: TRows = [],
      mtArr: TRows = [],
      unknownArr: TRows = [];

    filteredRows.forEach(row => {
      if (row.network === 'va') {
        vkArr.push(row);
      } else if (row.network === 'mt') {
        mtArr.push(row);
      } else {
        unknownArr.push(row);
      }
    });

    vkArr.length && setVk(vkArr);
    mtArr.length && setMt(mtArr);
    unknownArr.length && setUnknown(unknownArr);
  }, []);

  return (
    <div className={classes.filtratorWrap}>
      <Typography size="AuthContent" weight={700} component="p" componentProps={{ className: classes.mb12 }}>
        Массовое изменение стратегии
      </Typography>
      <Typography component="p" size="AuthContent" componentProps={{ className: classes.mb12 }}>
        Для продолжения изменения стратегий оставьте рекламные кампании только одной из рекламных платформ.
      </Typography>
      <Typography component="p" size="AuthContent">
        Продолжить изменять:
      </Typography>

      <div>
        {filteredOption({ val: vk, message: 'VK Реклама ', deselectRows: deselectRows })}
        {filteredOption({ val: mt, message: 'myTarget  ', deselectRows: deselectRows })}

        {filteredOption({ val: unknown, message: 'Неизвестная сеть', deselectRows: deselectRows, isActive: false })}
      </div>
    </div>
  );
};
