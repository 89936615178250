import { AuthorizedOnly, isServerError, UnAuthorizedOnly } from '@plarin/core';
import { Preloader } from '@plarin/design';
import { isUrlProd, isUrlDev } from '@plarin/utils';
import { getYmCounter } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { useAppDataStore } from '../components/app-data-provider';
import { DataLoader } from '../components/data-loader';
import { CheckWorkspaceLayout } from '../components/layout/checkWorkspaceLayout';
import { RoutesLayout } from '../components/layout/routes-layout';
import { ErrorPage } from '../pages/error-page/error-page';
import Reports from '../pages/reports';
import { WorkspaceRoot } from '../pages/workspace-root';
import { NotificationRenders, RouteContent } from './route-content';
import { routerPaths } from './router-path';

const RedirectToLogin = () => <Redirect to={routerPaths.unAuth.LOGIN} />;

export const RedirectToRoot = () => <Redirect to={routerPaths.auth.ROOT} />;

// export const RedirectToWsBase = () => <Redirect to={routerPaths.workspaces.BASE} />;

const WorkspaceRoutes = observer(() => {
  const {
    profileStore: { WSRole, currentWorkspace },
  } = useAppDataStore();
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!currentWorkspace._id) {
      history.push(routerPaths.auth.ROOT);
    }
  }, [currentWorkspace]);

  return (
    <CheckWorkspaceLayout>
      {WSRole === 'wsrole:guest' ? (
        <Switch>
          <RouteContent exact path={path} component={WorkspaceRoot} />
          <RouteContent path="*" component={RedirectToRoot} />
        </Switch>
      ) : (
        <Switch>
          <RouteContent exact path={path} component={WorkspaceRoot} />
          <RouteContent exact path={routerPaths.auth.MANAGE_VK} componentImportPath={routerPaths.page.MANAGE_VK} />
          <RouteContent exact path={routerPaths.auth.MANAGE_YD} componentImportPath={routerPaths.page.MANAGE_YD} />
          <RouteContent
            exact
            path={routerPaths.auth.YANDEX_DIRECT}
            componentImportPath={routerPaths.page.YANDEX_DIRECT}
          />
          <RouteContent
            exact
            path={routerPaths.auth.YANDEX_METRICS}
            componentImportPath={routerPaths.page.YANDEX_METRICS}
          />
          <RouteContent
            exact
            path={routerPaths.auth.GOOGLE_ANALYTICS}
            componentImportPath={routerPaths.page.GOOGLE_ANALYTICS}
          />
          <RouteContent exact path={routerPaths.auth.APPSFLYER} componentImportPath={routerPaths.page.APPSFLYER} />
          <RouteContent exact path={routerPaths.auth.VKONTAKTE} componentImportPath={routerPaths.page.INTEGRATIONS} />

          <RouteContent
            exact
            path={routerPaths.auth.REPORTS}
            component={Reports}
            // componentImportPath={routerPaths.page.REPORTS}
          />
          <RouteContent exact path={routerPaths.auth.MEMBERS} componentImportPath={routerPaths.page.MEMBERS} />
          <RouteContent exact path={routerPaths.auth.TEAMS} componentImportPath={routerPaths.page.TEAMS} />
          <RouteContent exact path={routerPaths.auth.PROJECTS} componentImportPath={routerPaths.page.PROJECTS} />
          <RouteContent
            exact
            path={routerPaths.auth.PROJECT_OVERVIEW}
            componentImportPath={routerPaths.page.PROJECT_OVERVIEW}
          />

          {(WSRole === 'wsrole:owner' || WSRole === 'wsrole:admin') && (
            <RouteContent exact path={routerPaths.auth.SETTINGS} componentImportPath={routerPaths.page.SETTINGS} />
          )}

          <RouteContent path="*" component={RedirectToRoot} />
        </Switch>
      )}
    </CheckWorkspaceLayout>
  );
});

export const Routes: React.FC = observer(() => {
  const {
    profileStore: { isAuthorized, error, logout, loadVersion },
  } = useAppDataStore();

  useEffect(() => {
    !isUrlProd && loadVersion();
  }, []);

  const location = useLocation();
  if (isUrlProd || isUrlDev) {
    useEffect(() => {
      if (location.pathname !== '/') {
        const sendHit = () => {
          // @ts-ignore
          window.ym(getYmCounter(), 'hit', location.pathname);
        };
        const timeoutId = setTimeout(sendHit, 500);
        return () => clearTimeout(timeoutId);
      }
    }, [location.pathname]);
  }

  return (
    <DataLoader fallback={<Preloader />}>
      <AuthorizedOnly isAuthorized={isAuthorized} logout={logout}>
        <RoutesLayout>
          <Switch>
            {error && isServerError(error) && <RouteContent component={ErrorPage} />}
            <RouteContent
              exact
              path={[routerPaths.auth.ROOT, routerPaths.auth.WSLIST]}
              componentImportPath={routerPaths.page.ROOT}
            />
            <RouteContent exact path={routerPaths.auth.PROFILE} componentImportPath={routerPaths.page.PROFILE} />
            <RouteContent path={routerPaths.workspaces.BASE} component={WorkspaceRoutes} />
          </Switch>
        </RoutesLayout>
      </AuthorizedOnly>
      <UnAuthorizedOnly isAuthorized={isAuthorized}>
        <Switch>
          {error && isServerError(error) && <RouteContent component={ErrorPage} />}

          <RouteContent
            exact
            path={[routerPaths.unAuth.REGISTRATION, routerPaths.unAuth.CONFIRM_EMAIL, routerPaths.unAuth.NEW_PASSWORD]}
            componentImportPath={routerPaths.page.REGISTRATION}
          />
          <RouteContent
            exact
            path={routerPaths.unAuth.REGISTRATION_CHECK_MAIL}
            componentImportPath={routerPaths.page.CHECK_MAIL}
          />
          <RouteContent exact path={routerPaths.unAuth.LOGIN} componentImportPath={routerPaths.page.LOGIN} />

          <RouteContent
            exact
            path={routerPaths.unAuth.CREATE_PASSWORD}
            componentImportPath={routerPaths.page.CREATE_PASSWORD}
          />
          <RouteContent
            exact
            path={routerPaths.unAuth.FORGOT_PASSWORD}
            componentImportPath={routerPaths.page.FORGOT_PASSWORD}
          />
          <RouteContent
            exact
            path={routerPaths.unAuth.REGISTRATION_SOCIAL}
            componentImportPath={routerPaths.page.REGISTRATION_SOCIAL}
          />
          <RouteContent
            path={`${routerPaths.unAuth.PROFILE}/:params*`}
            componentImportPath={routerPaths.page.PROFILE}
          />
          <RouteContent path="*" component={RedirectToLogin} />
        </Switch>
      </UnAuthorizedOnly>
      <NotificationRenders />
    </DataLoader>
  );
});
