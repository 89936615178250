import { Preloader, Typography } from '@plarin/design';
import { useDebounce, isUrlProd } from '@plarin/utils';
import React, { useState, useEffect, useCallback } from 'react';
import { BidItemsType } from '../../../inputs';
import { ProjectionPoint, ProjectionResp, TGetProjection, TRows } from '../../types';
import { AreaChartBids, InfoBlockBids } from './components';
import classes from './style.module.scss';

type BidChartProps = {
  bidValue: number;
  setBidValue: (newBid: number) => void;
  selectedRows: TRows;
  getProjection: TGetProjection;
  mode: string;
  trueMaxBid: number;
  itemType: BidItemsType;
};

const getNoProjectionMessage = (itemsType: BidItemsType) => {
  if (itemsType === 'ad_plan') return 'Для выбранного кабинета прогноз аудитории недоступен';
  if (itemsType === 'campaign') return 'Для выбранной группы объявлений прогноз аудитории недоступен';
  if (itemsType === 'banner') return 'Для выбранного объявления прогноз аудитории недоступен';

  return 'Прогноз аудитории недоступен';
};

export const BidChart = ({
  setBidValue,
  bidValue,
  selectedRows,
  getProjection,
  mode,
  trueMaxBid,
  itemType,
}: BidChartProps) => {
  const { campaignId, adId, adPlanId } = selectedRows[0];
  const [currentRate, setCurrentRate] = useState<ProjectionPoint>();
  const [newRate, setNewRate] = useState<ProjectionPoint>();
  const [projection, setProjection] = useState<ProjectionResp>({} as ProjectionResp);
  const [points, setPoints] = useState<ProjectionPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const debounce = useDebounce();
  const addNewBidChart = useCallback(() => {
    if (projection.chart_data) {
      const data = projection.chart_data;
      const diffArr = data.map(x => bidValue - x.price);

      if (!bidValue) {
        setNewRate(currentRate);
      } else if (data.some(point => point.price === bidValue)) {
        const index = data.findIndex(point => point.price === bidValue);
        setPoints(data);
        setNewRate({
          uniqs: data[index].uniqs,
          share: data[index].share,
          price: bidValue,
        });
      } else {
        const index = diffArr.findIndex(x => x <= 0) - 1;
        const lastIndex = index + 1;

        const uniqs = Math.round(
          ((bidValue - data[index].price) * (data[lastIndex].uniqs - data[index].uniqs)) /
            (data[lastIndex].price - data[index].price) +
            data[index].uniqs,
        );
        const proc = Math.round(
          ((bidValue - data[index].price) * (data[lastIndex].share - data[index].share)) /
            (data[lastIndex].price - data[index].price) +
            data[index].share,
        );

        const newPoint = {
          uniqs: uniqs,
          share: proc,
          price: bidValue,
        };

        setPoints([...data, newPoint].sort((a, b) => a.price - b.price));
        setNewRate(newPoint);
      }
    }
  }, [bidValue, projection.chart_data]);

  useEffect(() => {
    setIsLoading(true);
    getProjection(campaignId || adPlanId, false)
      .then(res => {
        if (res.chart_data[res.chart_data.length - 1].price < trueMaxBid) {
          !isUrlProd &&
            console.log(
              `Максимальная ставка из проджекшена округлена до числа, которое меньше реальной максимальной ставки: ${
                res.chart_data[res.chart_data.length - 1].price
              } < ${trueMaxBid}`,
            );

          res.chart_data[res.chart_data.length - 1].price = trueMaxBid;

          !isUrlProd && console.log(`Мы подменили price в последней точке на неокруглённый максимум: ${trueMaxBid}`);
        }

        if (res.chart_data[res.chart_data.length - 1].price > trueMaxBid) {
          !isUrlProd &&
            console.log(
              `Максимальная ставка из проджекшена округлена до числа, которое больше реальной максимальной ставки: ${
                res.chart_data[res.chart_data.length - 1].price
              } > ${trueMaxBid}`,
            );

          res.chart_data[res.chart_data.length - 1].price = trueMaxBid;

          !isUrlProd && console.log(`Мы подменили price в последней точке на неокруглённый максимум: ${trueMaxBid}`);
        }

        return setProjection(res);
      })
      .catch(err => setError(err.message))
      .finally(() => setIsLoading(false));
  }, [campaignId, adId, adPlanId, getProjection]);

  useEffect(() => {
    debounce(() => addNewBidChart(), 200);
  }, [bidValue]);

  useEffect(() => {
    setPoints(projection.chart_data);
    setTimeout(() => {
      setCurrentRate(projection.point);
      addNewBidChart();
    }, 1000);
  }, [projection.point]);

  return isLoading ? (
    <div className={classes.placeholder}>
      <Preloader size={24} thickness={3} className={classes.loadingText} />
      <Typography size="Caption" color="TextSecondary" componentProps={{ className: classes.errorMessage }}>
        Загрузка прогноза
      </Typography>
    </div>
  ) : (
    <>
      {error ? (
        <div className={classes.placeholder}>
          <Typography size="Caption" color="TextSecondary" componentProps={{ className: classes.errorMessage }}>
            {getNoProjectionMessage(itemType)}
          </Typography>
          {mode === 'Относительно текущей' && (
            <>
              <br />
              <Typography size="Caption" color="TextSecondary" componentProps={{ className: classes.errorMessage }}>
                Новая ставка: {bidValue} руб.
              </Typography>
            </>
          )}
        </div>
      ) : (
        <div className={classes.marginBlock}>
          <InfoBlockBids
            currentRate={currentRate}
            newRate={currentRate?.price !== newRate?.price ? newRate : undefined}
          />

          <AreaChartBids
            projection={projection}
            points={points}
            setNewRate={setNewRate}
            setBidValue={setBidValue}
            currentRate={currentRate}
            newRate={newRate}
          />
        </div>
      )}
    </>
  );
};
