import { initAppUtils, initInterceptors, RouterStore } from '@plarin/core';
import { ThemeProvider } from '@plarin/design';
import { getSafeParsedLocalStorage, preventScrollBack } from '@plarin/utils';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Router } from 'react-router';
import { routerPaths } from '../../routing/router-path';
import { Routes } from '../../routing/routes';
import { TAppDataStoreProps } from '../../stores/app-data.store';
import { IntegrationsAFStore } from '../../stores/integrationsAF.store';
import { IntegrationsGAStore } from '../../stores/integrationsGA.store';
import { IntegrationsVKStore } from '../../stores/integrationsVK.store';
import { IntegrationsYDStore } from '../../stores/integrationsYD.store';
import { IntegrationsYMStore } from '../../stores/integrationsYM.store';
import { ManageVKStore } from '../../stores/manageVK.store';
import { ManageYDStore } from '../../stores/manageYD.store';
import { MembersStore } from '../../stores/members.store';
import { MenuStore } from '../../stores/menu.store';
import { ProfileStore } from '../../stores/profile.store';
import { ProjectOverviewStore } from '../../stores/project-overview.store';
import { ProjectsStore } from '../../stores/projects.store';
import { ReportsStore } from '../../stores/reports.store';
import { TeamsStore } from '../../stores/teams.store';
import { WsStore } from '../../stores/ws.store';
import { AppDataStoreProvider } from '../app-data-provider';
import { IntercomProviderWrapper } from '../intercom-provider';
import { IntercomGlobal } from '../intercom-provider/global-intercom';
import { ColumnState } from 'ag-grid-community';

const history = createBrowserHistory({ basename: PUBLIC_PATH });

const { appLocalStorage, appSessionStorage, saveAuthTokens, removeAuthTokens } = initAppUtils({
  name: 'plarin_app',
});

export const appData: TAppDataStoreProps = {
  stores: {
    router: new RouterStore(history),
    profile: new ProfileStore(),
    integrationsVK: new IntegrationsVKStore(),
    manageYD: new ManageYDStore(),
    manageVK: new ManageVKStore(),
    menu: new MenuStore(),
    reports: new ReportsStore(),
    members: new MembersStore(),
    teams: new TeamsStore(),
    projects: new ProjectsStore(),
    integrationsGA: new IntegrationsGAStore(),
    integrationsYM: new IntegrationsYMStore(),
    projectOverview: new ProjectOverviewStore(),
    integrationsYD: new IntegrationsYDStore(),
    integrationsAF: new IntegrationsAFStore(),
    ws: new WsStore(),
  },
  storage: appLocalStorage,
};

const App: React.FC = observer(() => {
  const originalConsoleError = console.error;

  console.error = function (message, ...args) {
    if (
      typeof message === 'string' &&
      (message.includes('License Key Not Found') ||
        message.includes('AG Grid Enterprise License') ||
        message ===
          '****************************************************************************************************************' ||
        message.includes('All AG Grid Enterprise features are unlocked.') ||
        message.includes(
          'This is an evaluation only version, it is not licensed for development projects intended for production.',
        ) ||
        message.includes('If you want to hide the watermark, please email info@ag-grid.com for a trial license.'))
    ) {
      return;
    }

    originalConsoleError.apply(console, [message, ...args]);
  };

  useEffect(() => {
    window.addEventListener('wheel', preventScrollBack, { passive: false });

    const getColumns = getSafeParsedLocalStorage<Record<string, ColumnState[]>>('columns'); // Указываем, что это объект с ключами-строками

    if (getColumns) {
      const newColumns = {} as { [key: string]: unknown };
      for (let key in getColumns) {
        newColumns[key] = getColumns[key].map((el: { colId: string }) => {
          if (el?.colId === 'bid_current') {
            return { ...el, colId: 'price' };
          }
          return el;
        });
      }
      localStorage.setItem('columns', JSON.stringify(newColumns));
    } // временное решение для подстановки колонки price в место bid_current у пользователей (временно на пару месяцев)
    return () => {
      window.removeEventListener('wheel', preventScrollBack);
    };
  }, []);

  return (
    <Router history={history}>
      <AppDataStoreProvider {...appData}>
        <IntercomProviderWrapper>
          <ThemeProvider>
            <Routes />
          </ThemeProvider>
        </IntercomProviderWrapper>
      </AppDataStoreProvider>
    </Router>
  );
});

const resetStores = () => {
  appData.stores.profile.resetStore();
  appData.stores.integrationsVK.resetStore();
  appData.stores.manageVK.resetStore();
  appData.stores.menu.resetStore();
  appData.stores.reports.resetStore();
  appData.stores.members.resetStore();
  appData.stores.teams.resetStore();
  appData.stores.projects.resetStore();
};

const logout = async () => {
  appData.stores.profile.logout().finally(() => {
    removeAuthTokens();
    IntercomGlobal.shutdown();
    resetStores();
    // Согласована полная перезагрузка страницы, чтобы shutdown Интеркома, который вызывается выше, не создавал багов при разлогинивании пользователя.
    // Без перезагрузки неправильно работает isOpen, который мы используем в apps/app/src/components/page-title/index.tsx
    // (кейс при неправильной работе после shutdown без перезагрузки описан здесь https://github.com/devrnt/react-use-intercom/issues/666)
    window.location.href = routerPaths.unAuth.LOGIN;
  });
};

const logoutFromWorkspace = () => {
  appData.stores.profile.logoutFromWorkspace();
};

initInterceptors({
  getToken: appLocalStorage.get,
  getWorkspaceId: appSessionStorage.get,
  saveTokens: saveAuthTokens,
  logout,
  logoutFromWorkspace,
  ignorePaths: [
    'api/v1/user/auth/login',
    'api/v1/user/registration/phone',
    'api/v1/user/auth/logout',
    'api/v1/user/auth/login/phone/code',
    'api/v1/user/phone/send_code',
    'api/v1/user/oauth/registration_url',
    'api/v1/user/phone/validate',
    'api/v1/user/confirm/email',
  ],
});

export { App, history, appLocalStorage, appSessionStorage, saveAuthTokens, logout, removeAuthTokens };
