import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React from 'react';
import { formatObjective } from '../../../edit-bid/strategy-form-utils';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

export const DefaultTooltipCell = (props: ICellRendererParams) => {
  const content =
    props.valueFormatted && props.valueFormatted !== '--'
      ? props.valueFormatted
      : props.value || (props.node?.rowPinned ? '' : '--');

  const renderContent = () => {
    if (props.colDef?.field === 'adPlanObjective') {
      return <Typography ellips={true}>{formatObjective(props.data) || content}</Typography>;
    }

    // для отображения нескольких строк валют при группировке
    if (typeof content === 'string' && content.includes('<br />')) {
      return (
        <div
          className={clsx(props.colDef?.headerClass, classes.ellipsis)}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    }
    if (typeof content === 'string' || typeof content === 'number') {
      return <Typography ellips={true}>{content}</Typography>;
    }
    return <></>;
  };

  return (
    <div className={clsx(props.colDef?.headerClass)}>
      <EllipsisTooltip tooltipMessage={content}>{renderContent()}</EllipsisTooltip>
    </div>
  );
};
