export const parseStringToCamelCase = (item: string): string => {
  const stringToArr = item.split('');
  stringToArr.forEach((item, index) => {
    (item === '.' || item === '_') && (stringToArr[index + 1] = stringToArr[index + 1].toUpperCase());
  });
  return stringToArr
    .filter(item => item !== '.')
    .filter(item => item !== '_')
    .join('');
};

export const parseStatistics = (str: string) => {
  const parts = str.split('\u0019');
  const length = parts[0].split('\u0016').length;
  return parts.map(part => part.split('\u0016')).filter(elements => elements.length === length);
};
