import { Typography } from '@plarin/design';
import React from 'react';
import classes from './characters-counter.module.scss';

export const CharactersCounter = ({
  inputValueLength,
  maxCounterValue,
  fontSize,
  errorText,
}: {
  inputValueLength: number;
  maxCounterValue: number;
  fontSize?: number;
  errorText?: string;
}) => {
  return (
    <span className={classes.counter}>
      <Typography
        size={fontSize === 32 ? 'TableRowSubHeader' : 'Caption'}
        color={errorText ? 'Danger' : 'TextSecondary'}
      >
        {inputValueLength} из {maxCounterValue}
      </Typography>
    </span>
  );
};
