import { EApiCodes } from '@plarin/core';
import { CloseIcon, Typography } from '@plarin/design';
import { typografText } from '@plarin/utils';
import React from 'react';
import classes from './style.module.scss';

const ConnectionErrorMessages: Record<string, string> = {
  [EApiCodes.VALIDATION_ERROR]: 'Ошибка при сохранении. Проверьте введенный токен.',
  [EApiCodes.DUPLICATE_AF_USER_TOKEN]: 'Этот токен Appsflyer уже добавлен в пространстве.',
  [EApiCodes.DUPLICATE_AF_USER_NAME]: 'Такое название интеграции уже используется, задайте другое.',
  [EApiCodes.INVALID_AF_USER_TOKEN]: 'Токен Appsflyer недействителен. Воспользуйтесь инструкцией, чтобы создать новый.',
  [EApiCodes.INTERNAL_SERVER_ERROR]: 'Внутренняя ошибка сервера. Попробуйте еще раз позднее.',
  default: 'Ошибка при сохранении. Попробуйте еще раз.',
};

export const ConnectionError = ({ errorCode }: { errorCode: string }) => {
  return (
    <div className={classes.errorWrapper}>
      <CloseIcon iconType="solid" color="status_error" hasSafebox={false} />
      <Typography size="AuthContent">
        {typografText(ConnectionErrorMessages[errorCode] || ConnectionErrorMessages.default)}
      </Typography>
    </div>
  );
};
