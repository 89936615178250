import {
  cellRendererSelector,
  Column,
  GridApi,
  ICellRendererParams,
  isActualGridApi,
  Table,
  TextError,
} from '@plarin/inputs';
import { currency, int, IntegrationsTabNameEnum, percent3, relativeDate } from '@plarin/utils';
import { GetQuickFilterTextParams } from 'ag-grid-enterprise';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Cabinet } from '../../../../types/integration/types';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SyncStatusEnum } from '../../../dictionary/integrations';
import { STATUS } from '../../../utils/constants';
import { useFilter } from '../../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { IntegrationsActionBarForCabinets } from '../integrations-action-bar-for-cabinets';
import { TabContentWithoutAccounts } from '../tab-content-without-accounts/tab-content-without-accounts';

export interface ITabContentWithAccounts {
  data?: Cabinet[];
}

const column: Column[] = [
  {
    pinned: 'left',
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    resizable: false,
    field: 'checkbox',
    headerName: '',
    headerClass: 'checkbox',
    headerCheckboxSelection: true,
    checkboxSelection: params => params.data.syncStatusCode !== SyncStatusEnum.loading,
    cellRendererSelector: params => {
      if (params.data.syncStatusCode === SyncStatusEnum.loading) {
        return { component: 'PreloaderRenderer' };
      }
      return { component: 'agGroupCellRenderer' };
    },
    cellClass: 'integrationStatusCode checkbox',
    valueGetter: params => params.data.syncStatusCode,
    cellClassRules: {
      deleted: param =>
        param.data.syncStatusCode === STATUS.DISABLED ||
        param.data.integrationStatusCode === STATUS.BLOCKED ||
        param.data.integrationStatusCode === STATUS.DELETED,
      active: param =>
        param.data.syncStatusCode === STATUS.ENABLED && param.data.integrationStatusCode === STATUS.ACTIVE,
      blocked: param =>
        param.data.syncStatusCode === STATUS.ENABLED &&
        (param.data.integrationStatusCode === STATUS.BLOCKED || param.data.integrationStatusCode === STATUS.DELETED),
    },
    headerTooltip: 'true',
    minWidth: 64,
    maxWidth: 64,
    // showRowGroup: true,
    cellRendererParams: {
      suppressCount: true,
      value: '', // благодаря этому параметру в колонке типа 'checkbox' отображается кнопка для вкл/выкл сортировки для "множественных подлкючений"
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
      loaderPrecisePositioning: -8,
    },
  },
  {
    headerName: 'Рекламная сеть',
    field: 'network',
    hide: true,
  },
  { headerName: 'ID рекламного кабинета', field: 'clientId', hide: true },
  // { headerName: 'syncStatusCode', field: 'syncStatusCode', hide: true },
  {
    pinned: 'left',
    headerName: 'Название рекламного кабинета',
    headerComponentParams: { subheader: 'ID кабинета' },
    field: 'name',
    cellRenderer: 'NameRenderer',
    cellRendererSelector: params => {
      if (params.node.rowPinned) return { component: 'NameCountRenderer' };
    },
    cellRendererParams: {
      text: ['рекламный кабинет', 'рекламных кабинета', 'рекламных кабинетов', 'm'],
      innerRenderer: 'NameRenderer',
      showSelectedAmount: true,
      multipleConnectionsToCabinet: true,
    },
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
      'padding-left-large': param => param?.node?.data.orgHierarchy?.length, // увеличивает отступ слева у множественных подключений
    },
    minWidth: 325,
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
    valueGetter: params => `${params.data.name} ${params.data.syncStatusCode}`,
    getQuickFilterText: (params: GetQuickFilterTextParams) => {
      return `${params.value} ${params.data.clientId} ${params.data.parentId} ${params.data.parentName}`;
    },
  },
  {
    headerName: 'Права доступа',
    headerComponentParams: { subheader: 'Тип подключения' },
    field: 'rights',
    // tooltipField: 'rights',
    cellRenderer: 'RightsRenderer',
    cellRendererParams: {
      first: 'rights',
      second: 'connectionType',
    },
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
  {
    headerName: 'Синхронизация данных',
    headerComponentParams: { subheader: 'Последняя синхронизациия' },
    field: 'syncStatus',
    cellRenderer: 'IntegrationStatusRenderer',
    cellRendererParams: {
      dateFormatter: relativeDate,
      bullet: 'integrationStatusCode',
      statusName: 'syncStatus',
      lastUpdated: 'syncDate',
      alternativeBullet: 'syncStatusCode',
    },
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
    getQuickFilterText: () => '',
  },
  {
    headerName: 'Статус интеграции',
    headerComponentParams: { subheader: 'Последняя синхронизациия' },
    cellRenderer: 'IntegrationStatusRenderer',
    cellRendererParams: (params: ICellRendererParams) => ({
      dateFormatter: relativeDate,
      statusName: 'integrationStatus',
      lastUpdated: 'integrationDate',
      hasError:
        params.data.syncStatusCode !== 'disabled' &&
        (params.data.integrationStatusCode === 'blocked' || params.data.syncStatusCode === 'blocked'),
      errorTitle: params.data.integrationStatus,
      hintComponent: <TextError />,
    }),
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
    getQuickFilterText: () => '',
  },
  {
    headerName: 'Статус рекл. каб.',
    field: 'status',
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
  {
    headerName: 'Баланс',
    field: 'balance',
    cellRendererSelector: cellRendererSelector('DefaultSumRenderer', 'currency'),
    cellRendererParams: { suppressLeaf: true },
    valueFormatter: props => currency(props.value, props.data?.currency),
    type: 'rightAligned',
    cellClass: 'right',
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },

  {
    headerName: 'Общий расход',
    field: 'spent',
    cellRendererSelector: cellRendererSelector('DefaultSumRenderer', 'currency'),
    cellRendererParams: { suppressLeaf: true },
    valueFormatter: props => currency(props.value, props.data?.currency),
    type: 'rightAligned',
    cellClass: 'right',
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
  {
    headerName: 'Показы',
    field: 'shows',
    cellRendererSelector: cellRendererSelector(),
    cellRendererParams: { suppressLeaf: true },
    valueFormatter: props => int(props.value),
    type: 'rightAligned',
    cellClass: 'right',
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
  {
    headerName: 'Клики',
    field: 'clicks',
    cellRendererSelector: cellRendererSelector(),
    cellRendererParams: { suppressLeaf: true },
    valueFormatter: props => int(props.value),
    type: 'rightAligned',
    cellClass: 'right',
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
  {
    headerName: 'CTR',
    field: 'ctr',
    cellRendererSelector: cellRendererSelector('DefaultAvgRenderer'),
    cellRendererParams: { suppressLeaf: true },
    valueFormatter: props => percent3(props.value),
    type: 'rightAligned',
    cellClass: 'right',
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
];

export const TabContentCabinets = observer(() => {
  const {
    integrationsVK: { isTableLoading, filterValue, cabinets, hasError, fetchClients, showActionBar },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [selectedRows, setSelectedRows] = useState<Cabinet[]>([]);

  useEffect(() => {
    setTimeout(() => {
      isActualGridApi(gridApi) &&
        setSelectedRows(gridApi.getSelectedRows()?.filter(item => item.rights !== 'Множественное подключение'));
    });
  }, [cabinets, gridApi]);

  useFilter(filterValue, gridApi);

  return (
    <>
      {isTableLoading || (!!cabinets && cabinets.length) || hasError ? (
        <>
          {showActionBar && <IntegrationsActionBarForCabinets gridApi={gridApi} selectedRows={selectedRows} />}

          <Table<Cabinet>
            tabName={IntegrationsTabNameEnum.CABINETS}
            showStatusBar
            columns={column}
            rowData={cabinets || []}
            setSelectedRows={setSelectedRows}
            onGridReady={setGridApi}
            isRowGroup
            tableLoading={isTableLoading!}
            errorTable={hasError}
            ActionError={fetchClients}
          />
        </>
      ) : (
        <TabContentWithoutAccounts tabType="adverts" />
      )}
    </>
  );
});
