import { Fade, Tooltip } from '@mui/material';
import { typografText, typografNames } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import classes from './main-tooltip.module.scss';
import { IMainTooltip } from './tooltip-props';

// стайлгайд: https://www.figma.com/file/zMZfWmPLcR91jXkr4izJIN/UI-Kit-Plarin?node-id=259%3A226
// Осторожно! Этот компонент обернёт своего child в обёртку, переданную в пропсе component. Например, в span. Из-за этого может поехать вёрстка.

export const MainTooltip = <TComponent extends React.ElementType>({
  children,
  tooltipMessage: content,
  isVisible,
  component: Component,
  componentProps,
  componentClassName,
  placement,
  textAlign = 'left',
  componentFeature,
  maxWidth = 488,
  followCursor,
  isTypograf = true,
  typografType = 'names',
}: IMainTooltip<TComponent>) => {
  // const [open, setOpen] = React.useState(false); // Не удалять! Нужно для отладки ширины тултипа

  const renderTooltipContent = () => {
    // тултип для многострочной группировки
    if (typeof content === 'string' && content.includes('<br />')) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }

    return isTypograf
      ? typografType === 'default'
        ? typografText(String(content))
        : typografNames(String(content))
      : content;
  };

  return isVisible ? (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 200 }}
      title={renderTooltipContent()}
      // disableFocusListener
      // disableHoverListener
      // disableTouchListener
      // open={open} // Не удалять! Нужно для отладки ширины тултипа
      classes={{ tooltip: clsx(followCursor ? classes.contentFollowed : classes.content) }}
      placement={placement ? placement : 'bottom'}
      disableInteractive
      PopperProps={{
        style: { textAlign: textAlign, maxWidth: maxWidth, width: 'fit-content', overflowWrap: 'break-word' },
      }}
      followCursor={followCursor || false}
    >
      <Component
        // onClick={() => setOpen(!open)} // Не удалять! Нужно для отладки ширины тултипа
        {...(componentProps as any)}
        className={clsx(
          componentFeature === 'textEllips' && classes.ellips,
          componentFeature === 'toggleButton' && classes.toggleButton,
          componentClassName,
        )}
      >
        {children}
      </Component>
    </Tooltip>
  ) : (
    <Component
      {...(componentProps as any)}
      className={clsx(
        componentFeature === 'textEllips' && classes.ellips,
        componentFeature === 'toggleButton' && classes.toggleButton,
        componentClassName,
      )}
    >
      {children}
    </Component>
  );
};
