import { Typography } from '@plarin/design';
import { typografText } from '@plarin/utils';
import clsx from 'clsx';
import React from 'react';
import { useIntercom } from 'react-use-intercom';
import classes from './style.module.scss';

interface IDynamicTextError {
  name: string;
  account: string;
}

export const DynamicTextError = ({ name, account }: IDynamicTextError) => {
  return (
    <>
      <p>Истекли ключи авторизации!</p>
      <p>
        {`Обычно это происходит после изменения пароля в аккаунте ${name} - в этом случае ${name} “отзывает” все связи с
        ранее подключенными внешними приложениями.`}
      </p>
      <p>{`Для исправления ошибки необходимо переподключить ${account} повторно.`}</p>
    </>
  );
};

export const AppsflyerConnectionError = () => {
  return (
    <>
      <p>{typografText('Интеграция с Appsflyer не работает!')}</p>

      <p>
        {typografText('Обычно это происходит после изменения или отзыва ранее подключенного')}{' '}
        <span className={classes.textBold}>{typografText('Токена API')}</span> {typografText(' в Appsflyer.')}
      </p>

      <p>
        {typografText('Для возобновления интегации необходимо удалить текущую и добавить по-новой с новым')}{' '}
        <span className={classes.textBold}>{typografText('Токеном API')}</span>
        {typografText(', следуя инструкции при подключении.')}
      </p>
    </>
  );
};

export const TextError = () => {
  const { show: showIntercom, getVisitorId } = useIntercom();
  const visitorId = getVisitorId();
  const supportRedirect = () => {
    window.open('https://plarin.ru/support/', '_blank');
  };

  return (
    <>
      <p>{typografText('Истекли ключи авторизации или достигнут лимит по количеству доступных ключей!')}</p>
      <p>
        {typografText(
          'Обычно это происходит из-за изменения прав доступа или пароля аккаунта, истечения срока действия ключей или отзыва прав доступа предоставленных приложению Plarin вручную на стороне рекламного канала.',
        )}
      </p>
      <p>
        {typografText('Пожалуйста, обратитесь в')}{' '}
        <span onClick={visitorId ? showIntercom : supportRedirect} className={clsx(classes.hintText)}>
          {typografText('техническую поддержку Plarin')}
        </span>{' '}
        {typografText('для восстановления интеграции или получения инструкции по дальнейшим шагам.')}
      </p>
    </>
  );
};
