import { Page } from '@plarin/core';
import { Typography } from '@plarin/design';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useAppDataStore } from '../../components/app-data-provider';
import { AuthConfirmPhoneForm } from '../../components/auth/auth-confirm-phone-form';
import {
  AuthFormContainerSocialButtonsTemplate,
  AuthFormContainerTemplate,
} from '../../components/auth/auth-form-template';
import '../../components/auth/auth-form-template/auth-form-container-template.scss';
import { AuthRegPageTemplate } from '../../components/auth/auth-page-template';
import { AuthTabsTemplate } from '../../components/auth/auth-tabs-template';
import { TABS } from '../../utils/constants';
import { RegistrationEmailForm } from './registration-email-form';
import { RegistrationPageStore } from './registration-page.store';
import { RegistrationPhoneForm } from './registration-phone-form';

export const RegistrationPage: React.FC = observer(function RegistrationPage() {
  const { profileStore, alertProps: appAlertProps, resetAlert } = useAppDataStore();
  const [pageStore] = useState(() => new RegistrationPageStore(profileStore));
  const { emailFormStore, phoneFormStore, alertProps, tabs, activeTab, selectTab, getSocialUrl } = pageStore;
  const { boot } = useIntercom();

  useEffect(() => {}, [tabs, emailFormStore.isPending, phoneFormStore.isPending]);
  useEffect(() => {
    setTimeout(
      () =>
        boot({
          hideDefaultLauncher: false,
        }),
      0,
    );
  }, [boot]);
  return (
    <Page title="Регистрация">
      <AuthRegPageTemplate>
        {phoneFormStore.needConfirm ? (
          <AuthFormContainerTemplate title="Подтвердите регистрацию">
            <AuthConfirmPhoneForm store={phoneFormStore} />
          </AuthFormContainerTemplate>
        ) : (
          <AuthFormContainerSocialButtonsTemplate
            title="Регистрация"
            onSocialButtonClick={getSocialUrl}
            alertProps={appAlertProps || alertProps || emailFormStore.alertProps || phoneFormStore.alertProps}
            disabled={emailFormStore.isPending || phoneFormStore.isPending}
          >
            <>
              <AuthTabsTemplate
                tabs={tabs}
                tab={activeTab}
                onTabChange={() => {
                  selectTab(activeTab);
                  resetAlert();
                }}
              >
                {tab =>
                  tab === TABS.EMAIL ? (
                    <RegistrationEmailForm store={emailFormStore} />
                  ) : (
                    <RegistrationPhoneForm store={phoneFormStore} />
                  )
                }
              </AuthTabsTemplate>
            </>
          </AuthFormContainerSocialButtonsTemplate>
        )}
      </AuthRegPageTemplate>
    </Page>
  );
});

export default RegistrationPage;
