import { TMenuSection } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { getLocalSelectedWS } from '../../utils/localStorageSelectedWS';
import { useAppDataStore } from '../app-data-provider';
import { MenuBlock } from '../menu';
import { PageTitle } from '../page-title';
import classes from './style.module.scss';

export const RoutesLayout: React.FC = observer(({ children }) => {
  const {
    menu: { menuData },
    profileStore: { workspaceName, WSRole, workspaces, data },
    manageVK: { getMetricsVK, metricsVK },
    manageYD: { getMetricsYD, metricsYD },
  } = useAppDataStore();

  const location = useLocation();

  const title = useCallback(
    (data: TMenuSection[]) => {
      if (location.pathname === '/' || location.pathname === '/wslist') {
        return 'Ваши пространства';
      }

      if (location.pathname.includes('profile')) {
        return 'Личный кабинет';
      }

      const selectedMenuItem = data.flatMap(menuSection => menuSection.menuItems).find(menuItem => menuItem.selected);

      if (selectedMenuItem) {
        return selectedMenuItem.title;
      }
    },
    [menuData],
  );

  const layoutTitle = useCallback(
    (data: TMenuSection[]) => {
      if (location.pathname === '/') {
        return 'Ваши пространства';
      }

      if (location.pathname.includes('profile')) {
        return 'Личный кабинет';
      }

      const selectedMenuItem = data
        .flatMap(menuSection => menuSection.menuItems)
        .find(menuItem => menuItem.selected === true);

      if (selectedMenuItem) {
        return selectedMenuItem.layoutTitle;
      }
    },
    [menuData],
  );

  useEffect(() => {
    !metricsVK && getMetricsVK();
  }, []);

  useEffect(() => {
    window.document.title = title(menuData) || workspaceName;
  }, [title]);
  const selectedWS = getLocalSelectedWS(data.user_id);
  const isMenuAllowed = !!(WSRole || workspaces?.map(el => el.workspace_id).includes(selectedWS || ''));

  return (
    <div className={classes.rootWrapper}>
      {isMenuAllowed && <MenuBlock />}
      <div className={clsx(classes.titleLayout, 'autoHeightAnchor')}>
        <PageTitle title={layoutTitle(menuData) || workspaceName} />
        {children}
      </div>
    </div>
  );
});
