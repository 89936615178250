import { GridIcon, RedoIcon, DownloadReportIcon } from '@plarin/design';
import { ActionTableButton, Popover } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { TableGroupingVK } from '../../../../components/table-grouping';
import classes from '../styles.module.scss';
import { useGetStatTable } from '../utils/useGetStatTable';

export const ClientsActionBar = observer(() => {
  const {
    manageVK: {
      tableLoading,
      toggleDrawer,
      metricsVK,
      errorTable,
      downloadExcelStat,
      metricsForGrouping,
      setGroupingMetrics,
    },
  } = useAppDataStore();
  const getStatUp = useGetStatTable(ManageVkTabNameEnum.ACCOUNTS);

  const reportMenu = useRef(null);
  const [isOpenReportMenu, setOpenReportMenu] = useState(false);
  const [isLoadingActionMenu, setLoadingActionMenu] = useState(false);

  const onOpenReportMenu = useCallback(() => {
    setOpenReportMenu(true);
  }, []);

  const onCloseReportMenu = useCallback(() => {
    setOpenReportMenu(false);
  }, []);

  // const onOpenReportDrawer = () => {
  //   if (data) {
  //     const clients = createReportClientsDrawer(gridApi);
  //     const checkedMetricsVK = localStorage.getItem(STORAGE_ITEM.localStorage.METRICSVK);
  //     updateInitialStateDrawer({
  //       reportClients: clients,
  //       entities: ['ad'],
  //       fields: checkedMetricsVK && JSON.parse(checkedMetricsVK),
  //     });
  //   }
  //   openDrawer();
  // };

  const onDownloadExcel = useCallback(() => {
    setLoadingActionMenu(true);
    downloadExcelStat(ManageVkTabNameEnum.ACCOUNTS, setLoadingActionMenu).then();
  }, [downloadExcelStat]);

  const options = [
    {
      name: 'Скачать таблицу в Excel',
      action: () => {
        onDownloadExcel();
        onCloseReportMenu();
      },
    },
    // {
    //   name: 'Создать отчет',
    //   action: () => {
    //     onOpenReportDrawer();
    //     onCloseReportMenu();
    //   },
    // },
  ];

  return (
    <div className={classes.actionContent}>
      <div className={classes.leftContainer} />
      <div className={classes.rightContainer}>
        <TableGroupingVK
          tab="account"
          metricsForGrouping={metricsForGrouping}
          setGroupingMetrics={setGroupingMetrics}
          title="Группировка"
          checkedMetricIndex={0}
        />
        <TableGroupingVK
          tab="account"
          metricsForGrouping={metricsForGrouping}
          setGroupingMetrics={setGroupingMetrics}
          title="Группировка 2"
          checkedMetricIndex={1}
        />{' '}
        <TableGroupingVK
          tab="account"
          metricsForGrouping={metricsForGrouping}
          setGroupingMetrics={setGroupingMetrics}
          title="Группировка 3"
          checkedMetricIndex={2}
        />
        <div ref={reportMenu} className={classes.tableReport}>
          <ActionTableButton
            textTooltip={metricsVK?.length && !errorTable ? 'Отчёт' : 'Произошла ошибка'}
            action={metricsVK?.length && !errorTable ? onOpenReportMenu : () => {}}
            loading={isLoadingActionMenu}
            className={classes.downloadIcon}
            icon={<DownloadReportIcon />}
          />
        </div>
        <ActionTableButton
          textTooltip={metricsVK?.length ? 'Настроить колонки' : 'Произошла ошибка'}
          action={metricsVK?.length ? toggleDrawer : () => {}}
          icon={<GridIcon />}
        />
        <ActionTableButton
          textTooltip="Обновить"
          action={() => getStatUp()}
          loading={tableLoading}
          icon={<RedoIcon />}
          isReloadButton={true}
        />
      </div>
      <Popover
        isListLeft
        classNameWrapper={classes.reportMenu}
        setIsOpen={onCloseReportMenu}
        anchorEl={reportMenu.current}
        isOpen={isOpenReportMenu}
        dataList={options}
      />
    </div>
  );
});
