import {
  ManageYDTabID,
  manageYDTabs,
  ManageYDTabs,
  StateValueEnum,
  StatusValueEnum,
  statusEntities,
} from '@plarin/inputs';

import { Dictionary, MetricRecord } from '../../../types/manage-vk/types';
import { isUrlPreProd, isUrlProd } from '@plarin/utils';

export const returnId = (tabName: ManageYDTabs) => {
  switch (tabName) {
    case manageYDTabs.ACCOUNTS:
      return ManageYDTabID.accountId;
    case manageYDTabs.CAMPAIGNS:
      return ManageYDTabID.campaignId;
    case manageYDTabs.GROUPS:
      return ManageYDTabID.adGroupId;
    case manageYDTabs.ADS:
      return ManageYDTabID.adId;
    default:
      return ManageYDTabID.accountId;
  }
};

export const parseToData = (columns: string[], values: string[][], dict: Dictionary, tabName: ManageYDTabs) => {
  const idKey = returnId(tabName);
  const isAccountsOrGroups = tabName === manageYDTabs.ACCOUNTS || tabName === manageYDTabs.GROUPS;

  const getStatusForTab = (status: string, state: string) => {
    if (state === StateValueEnum.ARCHIVED) return statusEntities.ARCHIVED;
    if (state === StateValueEnum.ON) return statusEntities.ON;
    if (state === StateValueEnum.OFF_BY_MONITORING) return statusEntities.OFF_BY_MONITORING;
    if (state === StateValueEnum.SUSPENDED || state === StateValueEnum.ENDED) {
      return status === StatusValueEnum.REJECTED ? statusEntities.REJECTED : statusEntities.SUSPENDED;
    }
    if (state === StateValueEnum.OFF) {
      if (status === StatusValueEnum.ACCEPTED || status === StatusValueEnum.PREACCEPTED) {
        return statusEntities.OFF;
      }
    }
    if (status === StatusValueEnum.DRAFT) return statusEntities.DRAFT;
    if (status === StatusValueEnum.MODERATION) return statusEntities.MODERATION;
    return statusEntities.NO_DATA;
  };

  const data: MetricRecord[] = values.map(stat => {
    const obj = {} as MetricRecord;
    let status = '';
    let state = '';

    stat.forEach((el, index) => {
      const columnName = columns[index];
      const columnType = dict[columnName];
      switch (columnType) {
        case 'int':
          obj[columnName] = +el;
          break;
        case 'percent':
          obj[columnName] = +el;
          break;
        case 'float':
          obj[columnName] = +el;
          break;
        case 'currency':
          obj[columnName] = parseFloat(el);
          break;
        case 'json':
          obj[columnName] = JSON.parse(el.replace(/('|`|^\w)/g, ''));
          break;
        default:
          obj[columnName] = el;
      }

      if (columnName.endsWith('Status')) status = el;
      if (columnName.endsWith('State')) state = el;
    });

    obj.key = Number(obj[idKey]);
    obj.cellLoadingName = '';

    if (!isAccountsOrGroups) {
      const dataStatus = getStatusForTab(status, state);
      obj.statusColor = dataStatus.statusColor;
      obj.statusText = dataStatus.statusText;
      obj.translationText = dataStatus.translationText;
    }

    if (!isUrlProd && !isUrlPreProd) {
      // Фейковые лимиты и траты
      const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min;

      if (obj.accountId === '301512509') {
        obj.accountCurrency = 'USD';
      }

      if (obj.accountId === '88757314') {
        obj.accountCurrency = 'EUR';
      }

      if (obj.accountCurrency === 'USD') {
        obj.statisticsSpent = getRandomArbitrary(0, 9900);
        obj.statisticsSpentAll = getRandomArbitrary(0, 9900);
        obj.statisticsCpc = getRandomArbitrary(0, 9900);
        obj.statisticsCpm = getRandomArbitrary(0, 99);
      }

      if (obj.accountCurrency === 'EUR') {
        obj.statisticsSpent = getRandomArbitrary(0, 9900);
        obj.statisticsSpentAll = getRandomArbitrary(0, 9900);
        obj.statisticsCpc = getRandomArbitrary(0, 9900);
        obj.statisticsCpm = getRandomArbitrary(0, 99);
      }
    }

    return obj;
  });

  return data;
};
