import { float2, int } from '@plarin/utils';
import { TCurrencySymbol } from '../inputs/bids/utils';
import { EditBidFormTypes, TgetRequestData } from './types';

// функция для определения того, что за тип ставки у объекта кампании/объявления, и какую форму для редактирования ставки в связи с этим нужно использовать
// в будущем в функции должно стать намного больше условий, а пока не до конца понятно, какие свойства объекта на что нам указывают
export const getFormType = (row: Record<string, any>): EditBidFormTypes => {
  if (row.network === 'va') return 'StrategyEditBid';

  if (row.network === 'mt') {
    if (
      row.bidType === 'CPM' &&
      row.bidAutobiddingMode === 'max_shows' &&
      (row.maxPrice === 0 || row.maxPrice === '') // в таске написано, что должно равняться нулю, но судя по тестовым кабинетам, тут приходит пустая строка
    )
      return 'PlugAutoPrice';

    // Это условие подходит и для всех нормальных фиксированных ставок. Видимо, условие для "максимума показов" задано неверно.
    // if (
    //   row.bidType === 'CPM' &&
    //   row.bidAutobiddingMode === 'second_price_mean' &&
    //   (row.maxPrice === 0 || row.maxPrice === '') // в таске написано, что должно равняться нулю, но судя по тестовым кабинетам, тут приходит пустая строка
    // )
    //   return 'PlugMaxShows';

    if (row.bidType === 'CPM' && row.bidAutobiddingMode === 'max_shows' && row.maxPrice > 0) return 'PlugMarginalRate';

    if (
      row.bidAutobiddingMode === 'fixed' ||
      row.bidAutobiddingMode === 'second_price' ||
      row.bidAutobiddingMode === 'second_price_mean'
    )
      return 'FixedEditBid';
  }

  return 'unknown';
};

export const getDate = (dateArg: string | Date): Date | null => {
  if (typeof dateArg === 'string') {
    return dateArg.length ? new Date(dateArg) : null;
  }

  return dateArg;
};

// величина ставки для ячейки со ставкой в таблице manage vk
// если цена - целое число, то возвращаем число неизменным, делаем отбивку пробела тысячными, добавляем неразрывный пробел и знак валюты: 12 348 ₽
// если число не целое, то дополнительно округляем его до 2 знаков после запятой: 15,01 $ / 15,50 $ / 15,55 €
export const getPriceForBidCell = (price: string | number, currencySymbol: TCurrencySymbol) => {
  const priceVal = Number(price);

  if (Number.isInteger(priceVal)) return `${int(priceVal)}\u00a0${currencySymbol}`;

  return `${float2(priceVal)}\u00a0${currencySymbol}`;
};

export const getTypeVariants = (e: string) => {
  if (
    [
      'leadads',
      'dzen',
      'odkl',
      'site_conversions',
      'socialaudio',
      'socialengagement',
      'socialvideo',
      'storeproductssales',
      'vk_miniapps',
      'appinstalls',
      'in_app_conversions',
      'reengagement',
    ].includes(e)
  ) {
    return 'inputsTypeVariants1';
  }
  if (
    [
      'branding_dzen',
      'branding_socialengagement',
      'branding_universal_banner',
      'branding_video',
      'branding_html5',
    ].includes(e)
  ) {
    return 'inputsTypeVariants2';
  }
  return '';
};

export const headerTypeVariants2 = (bidAutobiddingMode: string, price: number, currencySymbol: TCurrencySymbol) => {
  const formattedPrice = price ? getPriceForBidCell(price, currencySymbol) : '';

  if (bidAutobiddingMode === 'second_price_mean') return 'Макс. показов ' + `(${formattedPrice})`;
  if (bidAutobiddingMode === 'second_price') return 'Пред. цена ' + `(${formattedPrice})`;
  if (bidAutobiddingMode === 'fixed') return 'Фикс. ставка ' + `(${formattedPrice})`;
};

export const autobiddingMode = {
  'Максимум показов': 'second_price_mean',
  'Предельная цена': 'second_price',
  'Фиксированная ставка': 'fixed',
};

export const getRequestData = ({
  inputsTypeVariants,
  newData,
  itemsType,
  startDate,
  stopDate,
  mode,
}: TgetRequestData) => {
  if (inputsTypeVariants === 'inputsTypeVariants1') {
    return {
      name: 'AUTOBIDDING_MODE' as 'AUTOBIDDING_MODE',
      autobidding_mode: 'max_goals',
      obj_type: itemsType || '',
      obj_id: +newData.item_id,
      network: newData.network,
      price: 0,
      max_price: mode === 'Минимальная цена' ? 0 : newData.maxPrice || 0,
      budget_limit: newData.limitLifetime,
      budget_limit_day: newData.limitDaily,
      date_start: startDate,
      date_end: stopDate,
    };
  }
  if (inputsTypeVariants === 'inputsTypeVariants2') {
    return {
      name: 'AUTOBIDDING_MODE' as 'AUTOBIDDING_MODE',
      autobidding_mode: autobiddingMode[mode as keyof typeof autobiddingMode] || newData.autobidding_mode,
      obj_type: itemsType || '',
      obj_id: +newData.item_id,
      network: newData.network,
      price: newData.price || 0,
      max_price: 0,
      budget_limit: newData.limitLifetime,
      budget_limit_day: newData.limitDaily,
      date_start: startDate,
      date_end: stopDate,
    };
  }
};

export const getMaxBid = (arr: Array<number | undefined>): number | undefined => {
  if (arr.some(item => item === undefined)) {
    return undefined;
  }
  return Math.max(...(arr as number[]));
};

export const getMinBid = (arr: Array<number | undefined>): number | undefined => {
  if (arr.some(item => item === undefined)) {
    return undefined;
  }
  return Math.min(...(arr as number[]));
};
