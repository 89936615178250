import React, { useEffect, useState } from 'react';
import { ProjectionResp } from '../../../types';
import classes from '../style.module.scss';
import { RowInfoCampaign } from './row-info-campaign';
import { ListInfoCampaignProps, TProjectionRowData } from './types';

export const ListInfoCampaign = ({
  itemsBids,
  selectedRows,
  getProjection,
  removeCampaign,
  itemType,
}: ListInfoCampaignProps) => {
  const idForProjection = itemType === 'ad_plan' ? 'adPlanId' : 'campaignId';

  const startState = {} as TProjectionRowData;

  new Set(selectedRows.map(el => el[idForProjection])).forEach(el => {
    startState[el] = {
      loading: true,
      fullfiled: false,
      error: '',
      projection: {} as ProjectionResp,
    };
  });
  const [projections, setProjections] = useState<TProjectionRowData>(startState);

  const fetchProjection = (el: string | number) => {
    // @ts-ignore
    setProjections(prev => ({ ...prev, [el]: { ...prev[el], fullfiled: true } }));
    getProjection(el, false)
      .then(projection => {
        const trueMaxBid = Number(selectedRows.filter(row => el === row[idForProjection])[0].packageLimitBidMax);

        if (projection.chart_data[projection.chart_data.length - 1].price < trueMaxBid) {
          projection.chart_data[projection.chart_data.length - 1].price = trueMaxBid;
        }
        if (projection.chart_data[projection.chart_data.length - 1].price > trueMaxBid) {
          projection.chart_data[projection.chart_data.length - 1].price = trueMaxBid;
        }

        setProjections(prev => ({ ...prev, [el]: { projection, error: '', loading: false, fullfiled: false } }));
      })
      .catch(e =>
        setProjections(prev => ({
          ...prev,
          [el]: { projection: {} as ProjectionResp, error: e.message, loading: false, fullfiled: false },
        })),
      );
  };

  useEffect(() => {
    new Set(selectedRows.slice(0, 6).map(el => el[idForProjection])).forEach(el => {
      fetchProjection(el);
    });
  }, [selectedRows]);

  const getItemId = (item: Record<string, any>) => {
    if (itemType === 'campaign') return item.campaignId;
    if (itemType === 'ad_plan') return item.adPlanId;
    if (itemType === 'banner') return item.adId;

    return 0;
  };

  const getItemName = (item: Record<string, any>) => {
    if (itemType === 'campaign') return item.campaignName || item.campaignId;
    if (itemType === 'ad_plan') return item.adPlanName || item.adPlanId;
    if (itemType === 'banner') return item.adName || item.adId;

    return 'Имя не найдено';
  };

  const getBidValue = (item: Record<string, any>) => {
    const newBid = itemsBids.filter(camp => camp.item_id.toString() === getItemId(item))[0];
    // Если у нас вместо максимального лимита ставки пришёл undefined, то отображаем в RowInfoCampaign исходную ставку
    if (!newBid.maxBid) {
      const previousData = selectedRows.filter(camp => camp.key.toString() === getItemId(item))[0];
      return previousData.bidCurrent;
    }
    return newBid.bid > newBid.maxBid ? newBid.maxBid : newBid.bid;
  };

  return (
    <div className={classes.boxInfo}>
      {selectedRows.map(item => {
        return (
          <RowInfoCampaign
            key={getItemId(item)}
            name={getItemName(item)}
            bid={item.price}
            curr={item.accountCurrency}
            id={item[idForProjection]}
            bidValue={getBidValue(item)}
            removeCampaign={removeCampaign}
            getProjection={fetchProjection}
            trueMaxBid={Number(item.packageLimitBidMax)}
            projectionData={projections[item[idForProjection]]}
          />
        );
      })}
    </div>
  );
};
