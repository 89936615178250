import { CloseCircleIcon, ErrorIcon, Typography } from '@plarin/design';
import { EllipsisTooltip, MainTooltip } from '@plarin/inputs';
import { TooltipError, TooltipMessage } from '@plarin/utils';
import React, { useRef, useState } from 'react';
import { TShortClientsData } from '../../../../../../types/reports/reports';
import classes from '../../reports-drawer-content.module.scss';

const ReportClientsItem = ({
  client,
  deleteAction,
}: {
  client: TShortClientsData[0];
  deleteAction: (id: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={classes.clientsListItem}
      key={client.account_id}
      onMouseLeave={() => setIsHover(false)}
      onMouseOver={() => !isHover && setIsHover(true)}
    >
      <div className={classes.textBlock}>
        <div className={classes.iconBlock} ref={ref}>
          {/* @ts-ignore*/}
          {client.has_error && (
            <MainTooltip
              tooltipMessage={TooltipError.CLIENT_NOT_AVAILABLE}
              isVisible={true}
              component="span"
              followCursor={true}
              componentClassName={classes.errorTooltip}
            >
              <ErrorIcon color="status_error" />
            </MainTooltip>
          )}

          <EllipsisTooltip tooltipMessage={client.account_name || client.account_username}>
            <Typography size="Main" ellips={true}>
              {client.account_name || client.account_username}
            </Typography>
          </EllipsisTooltip>
        </div>
        <div className={classes.userNameText}>
          <Typography size="Main" color="TextSecondary">
            ({client.account_username}, {client.account_id})
          </Typography>
        </div>
      </div>
      <div className={classes.deleteAction}>
        {isHover && (
          <MainTooltip
            tooltipMessage={TooltipMessage.DELETE_FROM_LIST}
            isVisible={true}
            component="span"
            followCursor={true}
          >
            <CloseCircleIcon onClick={() => deleteAction(client.account_id)} />
          </MainTooltip>
        )}
      </div>
    </div>
  );
};

export const SelectedClientsMenu = ({
  reportClients,
  deleteAction,
}: {
  reportClients: TShortClientsData;
  deleteAction: (id: number) => void;
}) => {
  return (
    <div className={classes.clientsList}>
      {[...reportClients]
        .sort((a, b) => a.account_name!.localeCompare(b.account_name!))
        .map(client => (
          <ReportClientsItem client={client} deleteAction={deleteAction} key={client!.account_id} />
        ))}
    </div>
  );
};
