import { ICellRendererParams } from 'ag-grid-community';

interface CellRendererSelector extends ICellRendererParams {
  innerRenderer?: string;
}

export const nameCellRendererSelector = (params: ICellRendererParams) => {
  if (params.node.rowPinned) {
    return { component: 'NameCountRenderer' };
  }
  return { component: 'agGroupCellRenderer' };
};

export const cellRendererSelector =
  (component: string = 'DefaultSumRenderer', type?: string) =>
  (params: ICellRendererParams) => {
    if (params.node.rowPinned) {
      return { component, params: { ...params, type: type } };
    }
    if (params.colDef?.cellRendererParams?.suppressLeaf && params?.data?.orgHierarchy?.length > 1) {
      return { component: () => '' };
    }
    return {};
  };

export const rendererSelector =
  (component: string = 'DefaultSumRenderer') =>
  (params: CellRendererSelector) => {
    const { innerRenderer } = params?.colDef?.cellRendererParams;
    if (params.node.rowPinned) return component === 'blank' ? { component: null } : { component };
    const hasOrg = new Set(Object.keys(params.node.data)).has('orgHierarchy');
    if (hasOrg && (!params.node.data.orgHierarchy || params.node.data.isGroupRow)) {
      return { component: undefined };
    }
    return { component: innerRenderer };
  };

export const CustomCellRendererSelector = (params: ICellRendererParams) => {
  if (params.node.rowPinned) {
    return { component: 'CustomFooterRenderer' };
  }

  const hasOrg = new Set(Object.keys(params.node.data)).has('orgHierarchy');

  if (hasOrg && (!params.node.data.orgHierarchy || params.node.data.isGroupRow)) {
    return { component: 'GroupNameRenderer' };
  }
  // return { component: 'TwoLinesRenderer' };
};
