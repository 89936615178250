import { Typography, ChevronRightIcon } from '@plarin/design';
import { typografElem } from '@plarin/utils';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { CustomMenuItem } from '../../../custom-menu-item';
import { Popover } from '../../../popover';
import classes from './style.module.scss';
import { Args, getDescriptions, TRANSLATION } from './utils';
import { EllipsisTooltip } from '../../../tooltip';

type TItemData = {
  title: string;
  description?: string | ((args: Args) => string);
};

export const TranslationItem = ({
  itemName,
  itemDescription,
  args,
  onClose,
  isPositionLeft,
}: {
  itemName: keyof typeof TRANSLATION;
  itemDescription: string;
  args: Args;
  onClose: (e: React.MouseEvent | Event) => void;
  isPositionLeft: boolean;
}) => {
  const [isHovered, setHovered] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const itemRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const itemData = TRANSLATION[itemName] as TItemData;
  const onHover = () => setHovered(true);
  const onLeave = () => setHovered(false);

  const title = itemData && itemData.title ? itemData.title : itemName;
  const description = getDescriptions(itemData, title, args, itemDescription);

  useEffect(() => {
    if (textRef.current) {
      const text = (description || '')
        .split(' ')
        .map(elem => (elem.includes('http') ? `<a href="elem" target="_blank" rel="noreferrer">${elem}</a>` : elem))
        .join(' ');
      textRef.current.innerHTML = text;
      typografElem(textRef.current);
    }
  }, [isHovered, addOpen]);

  return (
    <>
      <CustomMenuItem
        getRef={el => {
          itemRef.current = el;
        }}
        className={clsx(classes.translationItem, !description && classes.translationItemPaddingRight)}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
      >
        <EllipsisTooltip classNameContainer={classes.translationItemTitle} tooltipMessage={title}>
          <Typography size="small" color={isHovered ? 'TextWhite' : 'TextPrimary'} ellips>
            {title}
          </Typography>
        </EllipsisTooltip>

        {description && <ChevronRightIcon color={isHovered ? 'white' : 'main'} />}
      </CustomMenuItem>
      {description && (
        <Popover
          isRight
          classNameWrapper={clsx(classes.translationItemContent, classes.translationItemPaper)}
          setIsOpen={onClose}
          onMouseEnter={() => setAddOpen(true)}
          onMouseLeave={() => setAddOpen(false)}
          anchorEl={itemRef.current}
          isOpen={isHovered || addOpen}
        >
          <Typography size="Caption" component="div" componentProps={{ ref: textRef, className: classes.issuesText }}>
            {description}
          </Typography>
        </Popover>
      )}
    </>
  );
};
