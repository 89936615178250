// Функция для безопасного получения и парсинга данных из localStorage
export const getSafeParsedLocalStorage = <T>(key: string): T | null => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(`Ошибка при парсинге localStorage для ключа "${key}":`, error);
    localStorage.removeItem(key);
    return null;
  }
};
