import { Typography } from '@plarin/design';
import { isAfter, isSameDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LimitTypes } from '../budget-input';
import commonClasses from '../common.module.scss';
import { DateInput } from './components/date-input';
import classes from './styles.module.scss';

interface BidDateProps {
  title: string;
  initialStartDate: Date | null;
  initialEndDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setStartDateWasChanged: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setEndDateWasChanged: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setNewStartDateHasError: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setNewEndDateHasError: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  newStartDateHasError?: boolean;
  newEndDateHasError?: boolean;
  startDateErrorMessage: string;
  endDateErrorMessage: string;
  limitType: LimitTypes;
  adPlanStartDate: Date | null;
  adPlanEndDate: Date | null;
  initialLimitType?: string;
  datesAreDifferent?: { stopDate: boolean; startDate: boolean };
  areDatesLimitedByAdplan: boolean; // когда редактируем даты групп и или баннеров, их даты проведения находятся в пределах дат проведения родительского адплана
  isDisabled?: boolean;
}

export const BidDateInputs = ({
  title,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  limitType,
  initialStartDate,
  setStartDateWasChanged,
  setEndDateWasChanged,
  initialEndDate,
  newStartDateHasError,
  startDateErrorMessage,
  endDateErrorMessage,
  newEndDateHasError,
  adPlanStartDate,
  adPlanEndDate,
  areDatesLimitedByAdplan,
  datesAreDifferent,
  initialLimitType,
  isDisabled,
}: BidDateProps) => {
  const [localStartDate, setLocalStartDate] = useState<null | Date>(startDate);
  const [localEndDate, setLocalEndDate] = useState<null | Date>(endDate);
  const [firstDateWasChanged, setFirstDateWasChanged] = useState<boolean>(false);
  const [lastDateWasChanged, setLastDateWasChanged] = useState<boolean>(false);

  useEffect(() => {
    const newDate = localStartDate;
    const initialDate = initialStartDate;
    if (datesAreDifferent?.startDate) {
      setStartDateWasChanged(firstDateWasChanged);
    } else {
      // если задана новая дата, проверяем, отличается ли она от исходной
      if (newDate && initialDate) {
        if (isSameDay(newDate, initialDate)) {
          setStartDateWasChanged(false);
        } else {
          setStartDateWasChanged(true);
        }
      }

      // если сбросили дату, проверяем, нужно ли отметить, что дата была изменена
      if ((!newDate && initialStartDate) || (newDate && !initialStartDate)) {
        setStartDateWasChanged(true);
      }

      if (!newDate && !initialStartDate) {
        setStartDateWasChanged(false);
      }
    }
  }, [setStartDateWasChanged, localStartDate, initialStartDate]);

  useEffect(() => {
    const newDate = localEndDate;
    const initialDate = initialEndDate;
    if (datesAreDifferent?.stopDate) {
      setEndDateWasChanged(lastDateWasChanged);
    } else {
      // если задана новая дата, проверяем, отличается ли она от исходной
      if (newDate && initialDate) {
        if (isSameDay(newDate, initialDate)) {
          setEndDateWasChanged(false);
        } else {
          setEndDateWasChanged(true);
        }
      }

      // если сбросили дату, проверяем, нужно ли отметить, что дата была изменена
      if ((!newDate && initialEndDate) || (newDate && !initialEndDate)) {
        setEndDateWasChanged(true);
      }

      if (!newDate && !initialEndDate) {
        setEndDateWasChanged(false);
      }
    }
  }, [setEndDateWasChanged, localEndDate, initialEndDate]);

  const setStartDateHandler = useCallback(
    (value: Date | null) => {
      setLocalStartDate(value);
      setStartDate(value);
      setFirstDateWasChanged(true);
    },
    [setStartDate],
  );

  const setEndDateHandler = useCallback(
    (value: Date | null) => {
      setLocalEndDate(value);
      setEndDate(value);
      setLastDateWasChanged(true);
    },
    [setEndDate],
  );

  const shouldReset: boolean | undefined = useMemo(() => {
    if (
      limitType === LimitTypes.lifetime &&
      datesAreDifferent?.stopDate &&
      (initialLimitType === LimitTypes.differentLimitTypes || initialLimitType === LimitTypes.daily)
    ) {
      return true;
    }
    return localStartDate && localEndDate ? isAfter(localStartDate, localEndDate) : undefined;
  }, [localStartDate, localEndDate, limitType, datesAreDifferent]);

  // очищает дату окончания
  useEffect(() => {
    if (
      limitType === LimitTypes.lifetime &&
      datesAreDifferent?.stopDate &&
      (initialLimitType === LimitTypes.differentLimitTypes || initialLimitType === LimitTypes.daily)
    ) {
      setEndDateHandler(null);
    }
  }, [limitType]);

  // позволяет понять, какая из двух дат более поздняя
  const getLatestDate = (firstDate: Date | null, secondDate: Date | null) => {
    if (!secondDate && !firstDate) return null;
    if (!secondDate) return firstDate;
    if (!firstDate) return secondDate;

    if (secondDate > firstDate) {
      return secondDate;
    } else {
      return firstDate;
    }
  };

  return (
    <div className={classes.datesContainer}>
      <div className={commonClasses.headerContainer}>
        <Typography
          size="TableRowSubHeader"
          color="TextSecondary"
          componentProps={{ className: commonClasses.inputBidHeader }}
        >
          {title}
          {limitType === LimitTypes.lifetime && <span className={classes.inputRequired}>*</span>}
        </Typography>
      </div>
      <div className={classes.inputContainer}>
        <DateInput
          isDisabled={isDisabled}
          datesAreDifferent={datesAreDifferent?.startDate}
          setDate={setStartDateHandler}
          initialDate={startDate}
          hasError={newStartDateHasError}
          validationMessage={startDateErrorMessage}
          // Начинается либо с сегодняшнего дня, либо с даты начала работы адплана, если она позже сегодняшнего дня
          minDate={
            areDatesLimitedByAdplan
              ? getLatestDate(new Date(new Date().setHours(0, 0, 0, 0)), adPlanStartDate)
              : new Date(new Date().setHours(0, 0, 0, 0))
          }
          // если группа находится в адплане, у которого есть дата окончания, то для группы нельзя выбрать дату позже даты окончания работы адплана
          maxDate={areDatesLimitedByAdplan && !!adPlanEndDate ? adPlanEndDate : undefined}
        />

        <DateInput
          isDisabled={isDisabled}
          datesAreDifferent={datesAreDifferent?.stopDate}
          resetValue={shouldReset}
          setDate={setEndDateHandler}
          initialDate={endDate}
          hasError={newEndDateHasError}
          validationMessage={endDateErrorMessage}
          // дата окончания: равна или позже даты начала адплана/группы. Если это группа, то >= началу адплана, >= началу группы, >= сегодняшнему дню
          minDate={
            areDatesLimitedByAdplan
              ? getLatestDate(new Date(new Date().setHours(0, 0, 0, 0)), getLatestDate(localStartDate, adPlanStartDate)) // тут мы выбираем самую позднюю из трёх дат: сегодняшний день, старт адплана, старт группы
              : getLatestDate(new Date(new Date().setHours(0, 0, 0, 0)), localStartDate)
          }
          // если это группа, то её дата окончания должна быть <= дате окончания адплана
          maxDate={areDatesLimitedByAdplan && !!adPlanEndDate ? adPlanEndDate : undefined}
        />
      </div>
    </div>
  );
};
