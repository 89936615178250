import { ShopCartIcon } from '@plarin/design';
import { Column, GetQuickFilterTextParams, DynamicTextError } from '@plarin/inputs';
import { syncCellRelativeDate } from '@plarin/utils';
import type { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { SettingsCellMenu } from '../../components/metrics-widgets';
import { SyncStatusEnum } from '../../dictionary/integrations';
import { sortRow } from '../../utils/common';
import { STATUS } from '../../utils/constants';
import { GoalsSettingsYM } from './goals-settings';
import classes from './style.module.scss';

export const columns: Column[] = [
  {
    pinned: 'left',
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    resizable: false,
    headerName: '',
    headerClass: 'checkbox',
    headerCheckboxSelection: true,
    checkboxSelection: params => params.data.integrationStatusCode !== SyncStatusEnum.loading,
    cellRendererSelector: params => {
      if (params.data.integrationStatusCode === SyncStatusEnum.loading) {
        return { component: 'PreloaderRenderer' };
      }
      return { component: 'agGroupCellRenderer' };
    },
    minWidth: 64,
    maxWidth: 64,
    cellRendererParams: {
      suppressCount: true,
      value: '',
      suppressDoubleClickExpand: true,
      suppressEnterExpand: true,
      loaderPrecisePositioning: -8,
    },
    cellStyle: { borderRight: '1px solid transparent' },
    cellClass: params => (params.data.isParent ? 'connectionStatusPanelCode' : undefined),
    cellClassRules: {
      enabled: params => params.data.plarin_status === 'enabled',
      disable: params => params.data.plarin_status === 'disabled',
      blocked: params => params.data.plarin_status === 'enabled' && params.data.integrationStatusCode === 'blocked',
    },
    headerTooltip: 'true',
  },
  {
    headerName: 'Название счетчика',
    field: 'header1',
    headerComponentParams: { subheader: 'Идентификатор' },
    cellRendererParams: {
      isNoneCounter: true,
      page: 'ym',
      firstLine: 'header1',
      secondLine: 'header2',
      ecommerceProp: 'ecommerce',
      SettingsCellMenu: SettingsCellMenu,
      classNames: classes.groupName,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) =>
      `${params?.data?.header1 || ''} ${params?.data?.header2 || ''} ${params?.data?.id || ''}`,
    flex: 2,
    resizable: false,
    cellRendererSelector: e => {
      if (!e?.data?.items) {
        return { component: 'TwoLinesRenderer' };
      }
      return { component: 'GroupNameRenderer' };
    },
    cellClassRules: {
      'sync-status-disabled': param => param.data.plarin_status === STATUS.DISABLED,
      hasOrgHierarchy: params => !!params.node.data.orgHierarchy,
    },

    comparator: (a, b) => sortRow(a, b),
  },
  {
    headerName: 'Цели',
    cellRendererSelector: e => {
      if (!e?.data?.items) return { component: 'GoalsRenderer' };
    },
    cellRendererParams: {
      Component: GoalsSettingsYM,
    },
    type: 'rightAligned',
    minWidth: 156,
    flex: 1,
    resizable: false,
    sortable: false,
  },
  {
    headerName: 'Проекты',
    cellRendererSelector: e => {
      if (!e?.data?.items) return { component: 'ProjectListRenderer', params: { projectsData: e?.data?.projects } };
    },
    flex: 1,
    resizable: false,
    sortable: false,
  },
  {
    headerName: 'Статус интеграции',
    headerComponentParams: { subheader: 'Последнее обновление' },
    field: 'integrationStatusCode',
    cellRendererSelector: params => {
      if (params.data.integrationStatusCode === SyncStatusEnum.loading) {
        return { component: 'PreloaderRenderer' };
      }
      return { component: 'IntegrationStatusRenderer' };
    },
    cellRendererParams: (params: ICellRendererParams) => ({
      dateFormatter: syncCellRelativeDate,
      bullet: 'integrationStatusCode',
      alternativeBullet: 'plarin_status',
      statusName: 'integrationStatus',
      lastUpdated: 'last_metadata_updated',
      hasError:
        params.data.plarin_status === 'enabled' &&
        (params.data.integrationStatusCode === 'blocked' || params.data.syncStatusCode === 'blocked'),
      errorTitle: params.data.integrationStatus,
      hintComponent: <DynamicTextError name="Яндекс" account={params?.data?.header1} />,
    }),
    cellClassRules: {
      'sync-status-disabled': param => param.data.plarin_status === STATUS.DISABLED,
    },
    flex: 1,
    resizable: false,
  },
  {
    headerName: 'Синхронизация данных',
    headerComponentParams: { subheader: 'Последняя синхронизация' },
    cellRendererSelector: params => {
      if (params.data.syncStatusCode === SyncStatusEnum.loading) {
        return { component: 'PreloaderRenderer' };
      }
      return { component: 'IntegrationStatusRenderer' };
    },
    cellRendererParams: (params: ICellRendererParams) => ({
      dateFormatter: syncCellRelativeDate,
      bullet: 'syncStatusCode',
      alternativeBullet: 'plarin_status',
      statusName: 'syncStatus',
      lastUpdated: 'last_updated',
      hasError:
        params.data.plarin_status === 'enabled' &&
        (params.data.integrationStatusCode === 'blocked' || params.data.syncStatusCode === 'blocked'),
      errorTitle: params.data.syncStatus,
      hintComponent: <DynamicTextError name="Яндекс" account={params?.data?.header1} />,
    }),
    cellClassRules: {
      'sync-status-disabled': params => params.data.plarin_status === STATUS.DISABLED,
    },
    flex: 1,
    resizable: false,
    sortable: false,
  },
];
