import { RedoIcon } from '@plarin/design';
import {
  ActionBar,
  ActionTableButton,
  Button,
  GridApi,
  isActualGridApi,
  SearchDefaultInput,
  StatusPanelDef,
  Table,
} from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { TYMStoreData } from '../../../types/connect-ym/types';
import { useAppDataStore } from '../../components/app-data-provider';
import {
  connectionStatusPanel,
  ConnectionStatusPanelTypes,
} from '../../components/connection-status-panel/connection-status-panel';
import { GoalsDrawerYM } from '../../components/drawer/goals-drawer/goals-drawer-ym';
import { SelectDropMenu } from '../../components/metrics-widgets';
import { useFilter } from '../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { MetricsConfirmModal } from './modals';
import { NoRowsOverlay } from './no-rows-overlay';
import classes from './style.module.scss';
import { columns } from './ym-columns';

const IntegrationsYM = observer(() => {
  const {
    integrationsYM: {
      data,
      getData,
      searchTableYm,
      setSearchTableYm,
      tableLoading,
      tableError,
      connectYandexAccount,
      checkQueryString,
      setModalData,
      enableYM,
      isOpenGoalsDrawerYM,
    },
    profileStore: { workspaceShortname },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [selectedRows, setSelectedRows] = useState<TYMStoreData[]>([]);

  useEffect(() => {
    setTimeout(() => {
      isActualGridApi(gridApi) && setSelectedRows(gridApi.getSelectedRows());
    });
  }, [data, gridApi]);

  useEffect(() => {
    getData();
    checkQueryString();
  }, []);

  useFilter(searchTableYm, gridApi);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: connectionStatusPanel,
          align: 'left',
          statusPanelParams: { type: ConnectionStatusPanelTypes.yandex },
        },
      ],
    };
  }, []);

  return (
    <>
      <SearchDefaultInput
        placeholder="Поиск"
        filterValue={searchTableYm}
        setFilter={setSearchTableYm}
        searchIconColor="main"
        inputStyle="tableSearch"
      />
      <div
        className={clsx(
          classes.tableWrapper,
          classes.fixFlexColumns,
          !data?.length && classes.emptyTable,
          tableError && classes.fixErrorPadding,
        )}
      >
        <ActionBar>
          <div className={classes.actionWrapper}>
            {!tableError && !tableLoading && (
              <>
                <div className={classes.connect}>
                  <Button color="primary" size="small" onClick={() => connectYandexAccount(workspaceShortname)}>
                    Подключить
                  </Button>

                  <SelectDropMenu
                    selectedRows={selectedRows || []}
                    setModalData={setModalData}
                    enableAction={enableYM}
                    tableLoading={tableLoading}
                    gridApi={gridApi}
                  />
                </div>
                <ActionTableButton
                  textTooltip="Обновить"
                  action={getData}
                  loading={tableLoading}
                  icon={<RedoIcon />}
                  isReloadButton={true}
                />
              </>
            )}
          </div>
        </ActionBar>

        <Table
          onGridReady={setGridApi}
          setSelectedRows={setSelectedRows}
          columns={columns}
          statusBar={data.length && statusBar}
          rowData={data || []}
          tableLoading={tableLoading}
          agNoRowsOverlay={() => <NoRowsOverlay action={() => connectYandexAccount(workspaceShortname)} />}
          needAutoHeight
          errorTable={tableError}
          ActionError={getData}
          groupDefaultExpanded={1}
          extraFooterHeight={40}
        />
      </div>

      <MetricsConfirmModal gridApi={gridApi} />
      <GoalsDrawerYM isDrawerOpen={isOpenGoalsDrawerYM} gridApi={gridApi} />
    </>
  );
});

export default IntegrationsYM;
