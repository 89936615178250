import { Column, EllipsisTooltip, HightLightText } from '@plarin/inputs';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ActionsName } from '../../actions-name';
import classes from '../style.module.scss';
import { TooltipContent } from './TooltipContent';

interface FirstLineContentProps {
  data: any;
  firstLine: string;
  currentStatusName?: string;
  column: any;
  disableEditName?: boolean;
  isHoverCell: boolean;
  setIsHoverCell: React.Dispatch<React.SetStateAction<boolean>>;
  props: any;
  filterValue: any;
  tabNavigationHandler: () => void;
  cellName: string;
}

export const FirstLineContent: React.FC<FirstLineContentProps> = ({
  data,
  firstLine,
  currentStatusName,
  filterValue,
  tabNavigationHandler,
  isHoverCell,
  setIsHoverCell,
  column,
  disableEditName,
  cellName,
  props,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const editNameIsDisabled = disableEditName || (currentStatusName && data[currentStatusName]?.includes('Архивн'));
  const adObjectTooltipEnabled = props.tabName !== 'client_ids' && /\/manage\/vk/.test(window.location.pathname);

  return (
    <div className={classes.name}>
      {!adObjectTooltipEnabled && (
        <EllipsisTooltip
          classNameContainer={clsx(classes.firstValue)}
          tooltipMessage={currentStatusName && data[currentStatusName] === 'disabled' ? 'Выключена' : data[firstLine]}
        >
          <HightLightText
            text={currentStatusName && data[currentStatusName] === 'disabled' ? 'Выключена' : data[firstLine]}
            filterValue={filterValue}
            textEllips={true}
            onClick={tabNavigationHandler}
          />
        </EllipsisTooltip>
      )}

      {/* Для VK Рекламы используем кастомный тултип */}
      {adObjectTooltipEnabled && (
        <>
          <HightLightText
            componentProps={{
              className: clsx(classes.firstValue),
              onMouseEnter: () => setIsTooltipVisible(true),
              onMouseLeave: () => setIsTooltipVisible(false),
            }}
            text={currentStatusName && data[currentStatusName] === 'disabled' ? 'Выключена' : data[firstLine]}
            filterValue={filterValue}
            textEllips={true}
            onClick={tabNavigationHandler}
          />
          <TooltipContent data={data} active={isTooltipVisible} cellName={cellName} />
        </>
      )}

      {isHoverCell && data[firstLine] && (
        <ActionsName
          name={data[firstLine]}
          tabName={props.tabName}
          data={data}
          gridApi={props.api}
          column={column as any as Column}
          setIsHoverCell={setIsHoverCell}
          disableEditName={editNameIsDisabled}
        />
      )}
    </div>
  );
};
