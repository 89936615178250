import { Dispatch, InputHTMLAttributes, SetStateAction } from 'react';

export type TInputBasicTypes = 'text' | 'password' | 'phone' | 'email' | 'box' | 'number' | undefined;

enum TypeInput {
  fName = 'fName',
  lName = 'lName',
  sName = 'sName',
  email = 'email',
  password = 'password',
  newPassword = 'newPassword',
  smsCode = 'smsCode',
}

export type TInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, keyof TBaseType> & TBaseType;

type TBaseType = {
  name?: string;
  value: string;
  onChange: (newValue: string) => void;
  label: string;
  hint?: string;
  error?: boolean;
  disabled?: boolean;
  type?: TInputBasicTypes;
  customType?: TypeInput;
  isFocused?: boolean;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  fullWidth?: boolean;
  actionTitle?: string;
  action?(): void;
  isDeactivated?: boolean;
  deactivatedMessage?: string;
  inputRootWrapperDefault?: boolean;
  disabledRead?: boolean;
  disableMinHeight?: boolean;
  maxlength?: number;
  disabledInput?: boolean;
  maxCounterValue?: number;
};
