import { Button, MainTooltip } from '@plarin/inputs';
import React, { useEffect } from 'react';
import classes from './style.module.scss';

interface IFooterGoals {
  setIsDrawerOpen: (e: boolean) => void;
  onEdit: () => void;
  isDisabled: boolean;
  isLoadingPost: boolean;
  onDestroyed: () => void;
}

export const FooterGoals = ({ onDestroyed, isLoadingPost, isDisabled, onEdit, setIsDrawerOpen }: IFooterGoals) => {
  const onClose = () => {
    setIsDrawerOpen(false);
  };

  // для состояния лоадинга кнопки Сохранить, чтоб лоадинг не завершался пока дровер не закроется
  useEffect(() => {
    return () => {
      onDestroyed();
    };
  }, []);

  return (
    <div className={classes.footer}>
      <MainTooltip tooltipMessage="" isVisible={true} component="span" followCursor={true}>
        <Button
          loading={isLoadingPost}
          onClick={!isLoadingPost ? onEdit : undefined}
          size="small"
          color="primary"
          variant="filled"
          disabled={!isDisabled}
        >
          Сохранить
        </Button>
      </MainTooltip>
      <Button disabled={isLoadingPost} size="small" variant="outlined" color="primary" onClick={onClose}>
        Отменить
      </Button>
    </div>
  );
};
