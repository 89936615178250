import { Typography } from '@plarin/design';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { EllipsisTooltip } from '../../../tooltip';
import classes from './style.module.scss';

type TComponent = React.FC<{
  data: any;
}>;

interface GoalsRendererProps extends ICellRendererParams {
  Component: TComponent;
}

export const GoalsRenderer = (props: GoalsRendererProps) => {
  const { data, Component } = props;

  const goalsNumber = data?.goals;

  return (
    <div className={classes.wrapGoals}>
      <EllipsisTooltip tooltipMessage={goalsNumber}>
        <Typography size="Caption" ellips color={goalsNumber ? 'TextPrimary' : 'TextSecondary'}>
          {goalsNumber || 'Цели не выбраны'}
        </Typography>
      </EllipsisTooltip>
      <Component data={data} />
    </div>
  );
};
