import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';
import { bannedObjectives, formatObjective } from '../strategy-form-utils';
import { TRows } from '../types';
import classes from './style.module.scss';
import { filteredOption, TCurrencyFiltrator } from './utils';

type TObjectivesCollection = {
  objective: string;
  bidType: string;
  isActive: boolean;
  rows: TRows;
};

export const ObjectiveFiltrator = ({ filteredRows, deselectRows }: TCurrencyFiltrator) => {
  const [data, setData] = useState<TObjectivesCollection[]>([]);
  const [hasMaxHeight, setHasMaxHeight] = useState<boolean>(false);

  const messageRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // создадим коллекцию с видами обджективов, на основании которой потом нагенерим варианты для фильтрации
    const collection: TObjectivesCollection[] = [];

    // проверяем, есть ли в коллекции такой вид обджектива
    filteredRows.forEach(row => {
      const index = collection.findIndex(
        item => item.objective === row.adPlanObjective && item.bidType === row.bidType,
      );

      // уже есть такой вид обжектива, дополняем его массив
      if (index >= 0) {
        collection[index].rows.push(row);
      } else {
        // нет такого вида обджектива, создаём объект для его хранения
        collection.push({
          objective: row.adPlanObjective,
          bidType: row.bidType,
          rows: [row],
          isActive: !bannedObjectives.find(banned => row.adPlanObjective === banned),
        });
      }
    });

    // дополнительно сортируем, чтобы неактивные обджективы оказались в конце массива
    setData([...collection.filter(c => c.isActive), ...collection.filter(c => !c.isActive)]);
  }, []);

  // этот компонент может содержать в себе очень длинный список, который выходил бы за пределы экрана. Поэтому ограничиваем модалку по высоте, и будет скролл, если список слишком большой.
  const maxHeight = 198 > window.innerHeight - 280 ? 198 : window.innerHeight - 280;

  // на маке скролл отображается постоянно, поэтому приходится добавлять классы для скролла только когда он реально необходим
  useEffect(() => {
    if (
      messageRef.current?.clientHeight &&
      listRef.current?.clientHeight &&
      maxHeight > messageRef.current?.clientHeight + listRef.current?.clientHeight
    ) {
      setHasMaxHeight(false);
    } else {
      setHasMaxHeight(true);
    }
  }, [data]);

  return (
    <div
      className={clsx(classes.filtratorWrap, hasMaxHeight && classes.filtratorHeight)}
      style={{ maxHeight: hasMaxHeight ? maxHeight : undefined }}
    >
      <div ref={messageRef}>
        <Typography size="AuthContent" weight={700} component="p" componentProps={{ className: classes.mb12 }}>
          Массовое изменение стратегии
        </Typography>
        <Typography component="p" size="AuthContent" componentProps={{ className: classes.mb12 }}>
          Для продолжения изменения стратегий оставьте рекламные кампании только с одинаковой целью.
        </Typography>
        <Typography component="p" size="AuthContent">
          Продолжить изменять:
        </Typography>
      </div>
      <div ref={listRef} onClick={() => console.log('listRef', listRef.current?.clientHeight)}>
        {data.map(collection =>
          filteredOption({
            val: collection.rows,
            message: `${collection.rows[0] && formatObjective(collection.rows[0])} — ${collection.bidType} `,
            // message: ` — ${collection.bidType} `,
            deselectRows: deselectRows,
            isActive: collection.isActive,
          }),
        )}
      </div>
    </div>
  );
};
