import { Page } from '@plarin/core';
import { Preloader } from '@plarin/design';
import { Alert, Button, InCenter, InputPassword } from '@plarin/inputs';
import { useDebounce } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useAppDataStore } from '../../components/app-data-provider';
import { AuthCompleteButtonsGroup, AuthCompleteFormTemplate } from '../../components/auth/auth-complete-template';
import { AuthLayoutTemplate } from '../../components/auth/auth-layout-template';
import { ProtectionImage } from '../../components/protection-image';
import { CreatePasswordPageStore } from './create-password-page.store';

const CreatePasswordPage: React.VFC = observer(() => {
  const { profileStore } = useAppDataStore();
  const [pageStore] = useState(() => new CreatePasswordPageStore(profileStore));
  const {
    alertProps,
    data,
    submit,
    passwordStyle,
    imageStyle,
    onChange,
    onBlur,
    getErrorMsg,
    errors,
    isPending,
    isActivate,
    isLoading,
    checkPassword,
    setPasswordStyle,
    validateAll,
  } = pageStore;

  const debounce = useDebounce();

  useEffect(() => {
    if (!validateAll().password && data.password.length > 7) {
      debounce(() => checkPassword({ password: data.password }), 300);
    } else {
      setPasswordStyle(undefined);
    }
  }, [validateAll, data.password, checkPassword, setPasswordStyle]);

  return isLoading ? (
    <Preloader />
  ) : (
    <Page title={isActivate ? 'Создайте новый пароль' : 'Создайте пароль для входа'}>
      <AuthLayoutTemplate
        sidebar={
          <InCenter>
            <ProtectionImage style={errors.password ? 'error' : imageStyle} />
          </InCenter>
        }
      >
        <AuthCompleteFormTemplate
          title={isActivate ? 'Создайте новый пароль' : 'Создайте пароль для входа'}
          subtitle="Пароль должен содержать не менее 8 символов"
          formProps={{ onSubmit: submit }}
        >
          {alertProps && (
            <div role="alert" className="auth-form-template__error">
              <Alert {...alertProps} />
            </div>
          )}
          <InputPassword
            value={data.password}
            onChange={onChange('password')}
            onBlur={onBlur('password')}
            label="Пароль"
            passwordComplexity={data.password.length > 0 ? passwordStyle : undefined}
            errorHint={getErrorMsg('password')}
            fullWidth
            autoFocus={true}
            size={48}
          />
          <AuthCompleteButtonsGroup>
            <Button variant="filled" size="medium" color="primary" submit={true} disabled={isPending}>
              Далее
            </Button>
          </AuthCompleteButtonsGroup>
        </AuthCompleteFormTemplate>
      </AuthLayoutTemplate>
    </Page>
  );
});

export default CreatePasswordPage;
