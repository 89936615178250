import { IconStatus } from '@plarin/design';
import { getNumeral, int } from '@plarin/utils';
import { IStatusPanelParams } from 'ag-grid-enterprise';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useAppDataStore } from '../app-data-provider';
import classes from './style.module.scss';

// если число равно нулю, то int возвращает строку '--', а нам это здесь не нужно
const getNumber = (num: number) => (num ? int(num) : String(num));

export enum ConnectionStatusPanelTypes {
  yandex = 'yandex-metrica',
  google = 'google-analytics',
  yandexDirect = 'yandex-direct',
  appsflyer = 'appsflyer',
}

interface IconnectionStatusPanel extends IStatusPanelParams {
  type: ConnectionStatusPanelTypes;
}

// константы для функции, склоняющей существительное после числителя (один аккаунт, два аккаунта)
const strAccounts = ['аккаунт', 'аккаунта', 'аккаунтов'];
const strIntegration = ['интеграция', 'интеграции', 'интеграций'];
const strErrors = ['ошибка', 'ошибки', 'ошибок'];
const strGoogleItem = ['представление', 'представления', 'представлений'];
const strYandexItem = ['счётчик', 'счётчика', 'счётчиков'];
const strAppsflyerItem = ['приложение', 'приложения', 'приложений'];
const strSyncing = ['синхронизируется', 'синхронизируются', 'синхронизируются'];
const strYandexDirectItem = ['клиент', 'клиента', 'клиентов'];

const getAccountsSum = (accountsTotal: number, accountsError: number, arrParentName: string[]) => {
  if (!accountsError)
    return (
      <span className={classes.accTotal}>{`${getNumber(accountsTotal)} ${getNumeral(
        accountsTotal,
        arrParentName,
      )}`}</span>
    );

  return (
    <span className={classes.accTotal}>
      {`${getNumber(accountsTotal)} ${getNumeral(accountsTotal, arrParentName)}: `}
      <IconStatus status="error" className={classes.status} />
      {`${getNumber(accountsError)} ${getNumeral(accountsError, strErrors)}`}
    </span>
  );
};

const getChildrenSum = (
  accountType: ConnectionStatusPanelTypes,
  childrenTotal: number,
  childrenSynced: number,
  childrenErrors: number,
) => {
  const childrenName = (() => {
    switch (accountType) {
      case ConnectionStatusPanelTypes.google:
        return strGoogleItem;
      case ConnectionStatusPanelTypes.yandex:
        return strYandexItem;
      case ConnectionStatusPanelTypes.yandexDirect:
        return strYandexDirectItem;
      case ConnectionStatusPanelTypes.appsflyer:
        return strAppsflyerItem;
    }
  })();

  const total = `${getNumber(childrenTotal)} ${getNumeral(childrenTotal, childrenName)}`;

  if (!childrenSynced && !childrenErrors) return <span>{total}</span>;

  const synced = childrenSynced ? (
    <span>
      <IconStatus status="active" className={classes.status} />
      <span className={classes.mr2}>{`${getNumber(childrenSynced)} ${getNumeral(childrenSynced, strSyncing)}`}</span>
    </span>
  ) : undefined;

  const errors = childrenErrors ? (
    <span>
      <IconStatus status="error" className={classes.status} />
      <span className={classes.mr2}>{`${getNumber(childrenErrors)} ${getNumeral(childrenErrors, strErrors)}`}</span>
    </span>
  ) : undefined;

  return (
    <span>
      {`${total}: `}
      {synced}
      {errors}
    </span>
  );
};

// компонент statusPanel запихивается в таблицу с помощью объекта statusBar
// дока по statusBar для statusPanel: https://www.ag-grid.com/react-data-grid/component-status-bar/

export const connectionStatusPanel = observer((props: IconnectionStatusPanel) => {
  const {
    integrationsYM: { data: YMData },
    integrationsGA: { data: GAData },
    integrationsYD: { data: YDData },
    integrationsAF: { data: AFData },
  } = useAppDataStore();
  const accountType: ConnectionStatusPanelTypes = props.type;
  const parentNames = accountType === ConnectionStatusPanelTypes.appsflyer ? strIntegration : strAccounts;

  const data = (() => {
    switch (accountType) {
      case ConnectionStatusPanelTypes.google:
        return GAData;
      case ConnectionStatusPanelTypes.yandex:
        return YMData;
      case ConnectionStatusPanelTypes.yandexDirect:
        return YDData;
      case ConnectionStatusPanelTypes.appsflyer:
        return AFData;
    }
  })();

  const [first, setfirst] = useState(0);

  const updateStatusBar = () => {
    // @ts-ignore
    setfirst(props.api.rowModel.rowsToDisplay.length);
  };

  let accountsTotal = 0;
  let accountsError = 0;
  let childrenTotal = 0;
  let childrenSynced = 0;
  let childrenErrors = 0;

  data.map(rowNode => {
    // это item, а не аккаунт
    if (rowNode.isParent) {
      // общее количество аккаунтов
      accountsTotal += 1;
      // ошибки в подключении аккаунта
      if (rowNode.plarin_status === 'enabled' && rowNode.issues?.length) {
        accountsError += 1;
      }
    }

    if (!rowNode.isParent) {
      // общее количество счётчиков/представлений
      childrenTotal += 1;

      if (rowNode.plarin_status === 'enabled' && rowNode.issues?.length) {
        // ошибки синхронизации
        childrenErrors += 1;
      } else if (
        rowNode.syncStatusCode === 'enabled' &&
        // @ts-ignore
        (accountType === ConnectionStatusPanelTypes.appsflyer || rowNode.projects?.length)
      ) {
        // успешно синхронизированные - это те, у которых нет ошибок в issues, синхронизация включена и заодно у которых есть что-то в проектах
        childrenSynced += 1;
      }
    }
  });

  useEffect(() => {
    props.api.addEventListener('modelUpdated', updateStatusBar);

    return () => props.api.removeEventListener('modelUpdated', updateStatusBar);
  }, [YMData, GAData, YDData, AFData]);

  return (
    <div className={classes.statusPanel} key={first}>
      {getAccountsSum(accountsTotal, accountsError, parentNames)}
      {getChildrenSum(accountType, childrenTotal, childrenSynced, childrenErrors)}
    </div>
  );
});
