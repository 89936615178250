import { ActionBar, GridApi, manageYDTabs, Table } from '@plarin/inputs';
import { ManageYDTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { DateOptions } from '../../../../../types/common-types';
import { MetricRecord } from '../../../../../types/manage-vk/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { dynamicColumnsYDRenderer } from '../../../../components/manage-yd-custom-columns/dynamic-column-renderer-yd';
import { getColumnsForTableYD } from '../../../../utils/manage-yd';
import { useFilter } from '../utils/quickFilterSwitcher';
import { useGroupingSelect } from '../utils/useGrouping';
import { TYDMetric } from './../../../../../types/manage-yd/types';
import { AdGroupsActionContent } from './ad-groups-action-content';

export const YDGroupsTab = observer(() => {
  const {
    manageYD: {
      tableLoading,
      data,
      getYDStat,
      dictionary,
      filterStatuses,
      metricsYD,
      filterValue,
      // getFastStat,
      // fast72Checked,
      errorTable,
      date,

      // isAccountColumnActive,
      isGroupDestroyed,
      setIsGroupDestroyed,
      setCurrentTab,
      quantitySelected,

      setSelectedIds,
      getSelectedIds,
      setDeleteAction,
    },
    profileStore: { wsDictionary },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [loading, setLoading] = useState(true);
  const [names, setNames] = useState<TYDMetric[]>([]);

  const { storeLabel, storeStartDate, storeEndDate } = date as unknown as DateOptions;
  const isGroupingActive = !!data?.length && data[0].hasOwnProperty('orgHierarchy');

  // TODO: Когда добавится столбец с фастстатой, прописать хэндлеры onRowGroupOpened и onBodyScroll по аналогии с ВК.

  useEffect(() => {
    getYDStat(manageYDTabs.GROUPS).then();
    setLoading(false);
  }, [
    storeLabel,
    storeStartDate,
    storeEndDate,
    getYDStat,

    quantitySelected.selectedCampaigns,
    quantitySelected.selectedAccounts,

    filterStatuses,
  ]);

  useEffect(() => {
    setCurrentTab(ManageYDTabNameEnum.GROUPS);
    setDeleteAction(() => {
      gridApi.deselectAll();
    });
  }, [setCurrentTab, gridApi, setDeleteAction]);

  useEffect(() => {
    data?.length &&
      dictionary &&
      metricsYD &&
      setNames(
        getColumnsForTableYD(
          Object.keys(data[0]),
          dictionary,
          metricsYD,
          // fast72Checked
        ),
      );
  }, [data, dictionary, metricsYD]);

  useFilter(filterValue, gridApi);

  useGroupingSelect(gridApi, isGroupDestroyed, setIsGroupDestroyed, getSelectedIds, ManageYDTabNameEnum.GROUPS, data);

  return (
    <>
      <ActionBar>
        <AdGroupsActionContent />
      </ActionBar>

      <Table<MetricRecord>
        showStatusBar
        columns={useMemo(
          () =>
            dynamicColumnsYDRenderer(names, ManageYDTabNameEnum.GROUPS, 'groupName', wsDictionary, isGroupingActive),
          [names],
        )}
        rowData={data || []}
        onGridReady={setGridApi}
        ActionError={() => getYDStat(manageYDTabs.GROUPS)}
        errorTable={errorTable}
        getSelectedIds={getSelectedIds}
        setSelectedKeys={setSelectedIds}
        tabName={ManageYDTabNameEnum.GROUPS}
        // onBodyScroll={(nodeRendered, api) => {
        //   (gridApi && gridApi.getDisplayedRowCount() === 0) && // без этого условия не отображается заглушка, если таблица пустая (PYT-7010)
        //   getFastStat(api, nodeRendered, fast72Checked, FastStatTypes.ADS, FAST_STAT_IDS.ad);
        //   getAdsSmallPreview(api, nodeRendered);
        // }}

        tableLoading={loading || tableLoading}
        isGroupDestroyed={isGroupDestroyed}
        setGroupDestroyed={setIsGroupDestroyed}
        // onRowGroupOpened={onRowGroupOpened}
      />
    </>
  );
});
