import {
  ManageVkTabNameEnum,
  int,
  IntegrationsTabNameEnum,
  ManageYDTabNameEnum,
  getNumeral,
  getSafeParsedLocalStorage,
} from '@plarin/utils';
import { ColumnApi, ColumnState, GridApi } from 'ag-grid-community';
import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { Column } from './';

export const copy = (
  event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLSpanElement> | MouseEvent,
  value: string,
) => {
  event.stopPropagation();
  return navigator.clipboard.writeText(value);
};

// Эта утилита для проверки обращения к актуальной апи таблицы - для избежания обращений в различных промисах к апи таблицы которая уже была демонтирована
// Утилита не позволяет обращаться к демонтированному грид апи
// Проверяет свойства destroyCalled у грид апи, и если она не была демонтирована то вернет актуальный апи, в противном случае null
// Для избежания утечки памяти в случае появления предупреждения о memory leak - необходимо использовать во всех методах перед обращением к gridApi
export const isActualGridApi = (gridApi: GridApi<any> | null) => {
  return (gridApi as unknown as any)?.destroyCalled === false ? gridApi : null;
};

export const customSelectNodes = (selectedRowKeys?: number[], value?: boolean, api?: GridApi) => {
  if (selectedRowKeys && selectedRowKeys.length && api) {
    const nodes: any[] = [];
    api.forEachNode(node => selectedRowKeys.includes(+node.key!) && nodes.push(node));
    api.setNodesSelected({ nodes, newValue: true });
  }
};

const defaultFast72ColumnState = {
  aggFunc: null,
  colId: 'fastStat72',
  flex: null,
  hide: false,
  pinned: null,
  pivot: false,
  pivotIndex: null,
  rowGroup: false,
  rowGroupIndex: null,
  sort: null,
  sortIndex: null,
  width: 370,
};

const defaultAccountColumnState = {
  aggFunc: null,
  colId: 'accountName',
  flex: null,
  hide: false,
  pinned: null,
  pivot: false,
  pivotIndex: null,
  rowGroup: false,
  rowGroupIndex: null,
  sort: null,
  sortIndex: null,
  width: 300,
};

const defaultAdPlanColumnState = {
  aggFunc: null,
  colId: 'adPlanName',
  flex: null,
  hide: false,
  pinned: null,
  pivot: false,
  pivotIndex: null,
  rowGroup: false,
  rowGroupIndex: null,
  sort: null,
  sortIndex: null,
  width: 300,
};

export const getNewState = (
  tabName: ManageVkTabNameEnum | IntegrationsTabNameEnum | ManageYDTabNameEnum,
  prevColumnState: ColumnState[],
  newColumnState: ColumnState[],
) => {
  const newColumnStateIds = new Set(newColumnState.map(col => col.colId));
  const prevColumnStateIds = new Set(prevColumnState.map(col => col.colId));
  const newState: ColumnState[] = [];

  let needNewState: boolean = false;
  prevColumnState.forEach((col, index) => {
    !newColumnState[index] && (needNewState = true);
    newColumnState[index] && col.colId !== newColumnState[index].colId && (needNewState = true);
  });

  if (!needNewState && prevColumnState.length === newColumnState.length) {
    return prevColumnState;
  }

  prevColumnState.forEach((el, index, array) => {
    newState.push(el);
    if (index === 1) {
      if (tabName === ManageVkTabNameEnum.ADPLANS) {
        newColumnStateIds.has('accountName') &&
          !prevColumnStateIds.has('accountName') &&
          newState.push(defaultAccountColumnState);
      }
      if (tabName === ManageVkTabNameEnum.ADGROUPS) {
        newColumnStateIds.has('accountName') &&
          !prevColumnStateIds.has('accountName') &&
          newState.push(defaultAccountColumnState);

        newColumnStateIds.has('adPlanName') &&
          !prevColumnStateIds.has('adPlanName') &&
          newState.push(defaultAdPlanColumnState);
      }
      if (tabName === ManageVkTabNameEnum.ADS) {
        newColumnStateIds.has('accountName') &&
          !prevColumnStateIds.has('accountName') &&
          newState.push(defaultAccountColumnState);

        newColumnStateIds.has('adPlanName') &&
          !prevColumnStateIds.has('adPlanName') &&
          newState.push(defaultAdPlanColumnState);
      }
    }

    if (el.colId === 'fastStat' && newColumnStateIds.has('fastStat72') && !prevColumnStateIds.has('fastStat72')) {
      newState.push(defaultFast72ColumnState);
    }
  });
  return newState;
};

export const isDisabledStatus = (status: string) => {
  return status.includes('Ожидает подтверждения') || status.includes('Заблокированный');
};

export const getFooterText = (tabName: ManageVkTabNameEnum | ManageYDTabNameEnum, value: number) => {
  if (tabName === ManageVkTabNameEnum.ACCOUNTS) {
    return `${value} ${getNumeral(value, ['Рекламный кабинет', 'Рекламных кабинета', 'Рекламных кабинетов'])}`;
  }
  if (tabName === ManageYDTabNameEnum.ACCOUNTS) {
    return `${value} ${getNumeral(value, ['Клиент', 'Клиента', 'Клиентов'])}`;
  }
  if (tabName === ManageVkTabNameEnum.ADPLANS || tabName === ManageYDTabNameEnum.CAMPAIGNS) {
    return `${value} ${getNumeral(value, ['Кампания', 'Кампании', 'Кампаний'])}`;
  }
  if (tabName === ManageVkTabNameEnum.ADGROUPS || tabName === ManageYDTabNameEnum.GROUPS) {
    return `${value} ${getNumeral(value, ['Группа объявлений', 'Группы объявлений', 'Групп объявлений'])}`;
  }
  if (tabName === ManageVkTabNameEnum.ADS || tabName === ManageYDTabNameEnum.ADS) {
    return `${value} ${getNumeral(value, ['Объявление', 'Объявления', 'Объявлений'])}`;
  }
};

export const saveColumnState = (api: ColumnApi) => {
  const href = window.location.href;
  const columns = getSafeParsedLocalStorage<Record<string, ColumnState[]>>('columns');

  const newColumns: Record<string, ColumnState[]> = {
    ...columns,
    [href]: api.getColumnState(),
  };

  localStorage.setItem('columns', JSON.stringify(newColumns));
};

export const applyColumnState = (
  api: ColumnApi,
  tabName?: ManageVkTabNameEnum | IntegrationsTabNameEnum | ManageYDTabNameEnum,
) => {
  const href = window.location.href;
  const columnState = getSafeParsedLocalStorage<Record<string, ColumnState[]>>('columns');

  if (columnState && columnState.hasOwnProperty(href)) {
    const stateToApply = tabName ? getNewState(tabName, columnState[href], api.getColumnState()) : columnState[href];

    api.applyColumnState({
      state: stateToApply,
      applyOrder: true,
    });
  }
};

// Лечим ситуацию, когда при уменьшении окна по вертикали таблица не переключается с режима автовысоты на стандартный режим. Лечение необходимо только при получении пропса needAutoHeight плюс при уменьшении высоты окна до размера, в который перестают вмещаться все строки таблицы.
export const useAutoHeightFix = (
  needAutoHeight: boolean,
  gridRef: React.RefObject<AgGridReact<any>>,
  needHeight: number,
  aboveTable: number,
) => {
  useEffect(() => {
    if (needAutoHeight) {
      const handleResize = () => {
        const pageDomElement = document.querySelector('.autoHeightAnchor');
        const height = pageDomElement!.getBoundingClientRect().height - aboveTable;

        if (needHeight > height) {
          gridRef && gridRef.current && gridRef.current?.api.setDomLayout('normal');
        }
      };

      let prevHeight = window.innerHeight;

      const fixTableHeight = debounce(() => {
        const currentHeight = window.innerHeight;

        if (currentHeight < prevHeight) {
          handleResize();
        }
        prevHeight = currentHeight;
      }, 500);
      window.addEventListener('resize', fixTableHeight);

      return () => {
        window.removeEventListener('resize', fixTableHeight);
      };
    }
  }, [needHeight]);
};

export const isNotResizable = (columns: Column[]) => {
  return columns.every(col => col.resizable === false || col.hide === true);
};

export const toggleClassFillHeightScroll = (className: string, needAutoHeight?: boolean) => {
  const pageDomElement = document.querySelector('.ag-body-vertical-scroll-viewport');
  const haveScroll = !!(pageDomElement ? pageDomElement!.getBoundingClientRect().width : 0);

  if (!needAutoHeight && pageDomElement) {
    if (haveScroll) {
      document.querySelector('.ag-center-cols-clipper')?.classList.remove(className);
    } else {
      document.querySelector('.ag-center-cols-clipper')?.classList.add(className);
    }
  }
};

export const formatValue = (value: number, formatter: string | ValueFormatterFunc | undefined, data: unknown) => {
  // todo: Нужно что-то придумать с каренси для суммы в мультивалютах. сейчас все складываем и лепим рубль
  return typeof formatter === 'function' ? formatter({ value, data } as ValueFormatterParams) : value;
};
