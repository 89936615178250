import { ShapeIcon, Typography } from '@plarin/design';
import { Drawer, TitleOptions } from '@plarin/inputs';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { TDataGoalsGA } from '../../../../types/connect-ga/types';
import { useAppDataStore } from '../../app-data-provider';
import { WsDrawerPreloader } from '../preloaders/preloaders';
import { BodyFirstBlock, SourceComparison, GoalsList, Ecommerce, FooterGoals } from './components';
import { ModalCorrection } from './components/modal-correction';
import { IGoalsDrawer, IGoalsList, IinitialData } from './type';
import { dataFirstBlock, dataGoalsList, dataSourceComparison, itemsSourceComparison } from './utils';

export const GoalsDrawerGA = observer(({ isDrawerOpen }: IGoalsDrawer) => {
  const {
    integrationsGA: {
      dataGoalsGA,
      isLoading,
      setIsOpenGoalsDrawerGA,
      postGoalsGA,
      isLoadingPostGoalsGA,
      setIsLoadingPostGoalsGA,
    },
  } = useAppDataStore();

  const [valueSourceComparison, setValueSourceComparison] = useState({ lable: 'utm_content', value: 'utm_content' });
  const [valueEcommerce, setValueEcommerce] = useState<boolean | undefined>(false);
  const [goalsList, setGoalsList] = useState<IGoalsList[]>([]);
  const [initialData, setInitialData] = useState<IinitialData | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isOpenModalCorrection, setIsOpenModalCorrection] = useState(false);

  const onCloseDrawer = () => {
    if (isDrawerOpen && !isLoadingPostGoalsGA) {
      isDisabled ? setIsOpenModalCorrection(true) : setIsOpenGoalsDrawerGA(false);
    }
  };

  const editGoalsGA = () => {
    postGoalsGA({
      ga_user_id: dataGoalsGA?.gaUserId ?? '',
      profile_id: Number(dataGoalsGA?.profileId ?? 0),
      ecommerce: valueEcommerce,
      source: valueSourceComparison.value as keyof TDataGoalsGA['source'],
      enabled_goals: goalsList.reduce((prev, current) => {
        const result = prev;
        if (current.enabled) {
          result.push(current.id);
        }
        return result;
      }, [] as number[]),
    });
  };

  useEffect(() => {
    if (dataGoalsGA) {
      setValueEcommerce(dataGoalsGA.ecommerce);
      setGoalsList(dataGoalsList(dataGoalsGA));
      setValueSourceComparison(dataSourceComparison(dataGoalsGA));

      setInitialData({
        valueSourceComparison: dataSourceComparison(dataGoalsGA),
        valueEcommerce: dataGoalsGA.ecommerce,
        goalsList: dataGoalsList(dataGoalsGA),
      });
    }
  }, [dataGoalsGA]);

  useEffect(() => {
    setIsDisabled(
      JSON.stringify({
        valueSourceComparison,
        valueEcommerce,
        goalsList,
      }) !== JSON.stringify(initialData),
    );
  }, [valueSourceComparison, valueEcommerce, goalsList]);

  return (
    <>
      <Drawer
        isOpen={isDrawerOpen}
        workspaceDrawer
        title={
          <TitleOptions
            circle
            iconBlock={<ShapeIcon />}
            titleName={
              <Typography weight={600} size="DrawerProject" componentProps={{ className: 'mt2' }}>
                Настройка Google Analytics
              </Typography>
            }
          />
        }
        preloader={<WsDrawerPreloader />}
        loading={isLoading}
        closeDrawer={onCloseDrawer}
        actionBlock={
          <FooterGoals
            isLoadingPost={isLoadingPostGoalsGA}
            isDisabled={isDisabled}
            setIsDrawerOpen={setIsOpenGoalsDrawerGA}
            onEdit={editGoalsGA}
            onDestroyed={() => setIsLoadingPostGoalsGA(false)}
          />
        }
      >
        <div>
          <BodyFirstBlock data={dataFirstBlock({ dataGoals: dataGoalsGA, title: ['Аккаунт', 'Представление'] })} />
          <SourceComparison
            items={itemsSourceComparison}
            title="Источник сопоставления"
            defaultValue={valueSourceComparison.value}
            onChangeValue={item => {
              setValueSourceComparison(item);
            }}
          />
          {dataGoalsGA?.ecommerce !== null && <Ecommerce value={!!valueEcommerce} onChange={setValueEcommerce} />}
          <GoalsList
            title="Цели"
            isCount={true}
            subTitle="Все цели"
            items={goalsList ?? []}
            onChangeValue={items => {
              setGoalsList(items);
            }}
          />
        </div>
      </Drawer>
      {isDisabled && isOpenModalCorrection && !isLoadingPostGoalsGA && (
        <ModalCorrection
          onCloseModal={() => setIsOpenModalCorrection(false)}
          firstAction={() => {
            setIsOpenModalCorrection(false);
            setIsOpenGoalsDrawerGA(false);
          }}
          secondAction={() => {
            setIsOpenModalCorrection(false);
            editGoalsGA();
          }}
        />
      )}
    </>
  );
});
