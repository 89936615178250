export type Args = { [index: string]: string };

export interface ItemDataType {
  title: string;
  description?: string | ((args: Args) => string);
}

export const getDescriptions = (itemData: ItemDataType, title: string, args: Args, defaultDescription: string) => {
  if (itemData) {
    if (itemData.description) {
      return typeof itemData.description === 'function' ? itemData.description(args) : itemData.description;
    } else {
      return null;
    }
  }
  return defaultDescription;
};

export const parseStatus = (status: string | number) => {
  switch (status) {
    case 0:
      return 'Остановлена';
    case 1:
      return 'Активная';
    case 2:
      return 'Архивная';
    default:
      return status;
  }
};

export const parseDeliveryStatus = (deliveryStatus: string) => {
  switch (deliveryStatus) {
    case 'delivering':
      return 'Показывается';
    case 'pending':
      return 'Ожидает модерации';
    default:
      return 'Не транслируется';
  }
};

export const TRANSLATION = {
  ACCOUNT_INACTIVE: { title: 'Рекламный кабинет неактивен' },
  AD_PLAN_ARCHIVED: { title: 'Кампания удалена' },
  AD_PLAN_OUT_OF_DATE_END: {
    title: 'Достигнута дата остановки кампании',
    description: (args: Args) => `Трансляция кампании завершена ${args.date_end} согласно настройкам расписания.`,
  },
  AD_PLAN_OUT_OF_DATE_START: {
    title: 'Кампания ожидает запуска',
    description: (args: Args) => `Трансляция начнется ${args.date_start} в соответствии с настройками расписания.`,
  },
  AD_PLAN_STOPPED: { title: 'Кампания не запущена и не транслируется' },
  ARCHIVED: { title: 'Удалено' },
  BALANCE_OUT_OF_DAILY_LIMIT: {
    title: 'Приостановлена по дневному лимиту рекламного кабинета',
    description: (args: Args) =>
      `Трансляция приостановлена по достижении дневного лимита рекламного кабинета: ${args.limit_value}.`,
  },
  BALANCE_OUT_OF_FULL_LIMIT: {
    title: 'Общий бюджет исчерпан',
    description: (args: Args) => `Достигнуто ограничение по общему бюджету: ${args.limit_value}.`,
  },
  BALANCE_OUT_OF_MONTHLY_A_LIMIT: {
    title: 'Приостановлена по месячному лимиту рекламного кабинета',
    description: (args: Args) =>
      `Трансляция приостановлена по достижении месячного лимита рекламного кабинета: ${args.limit_value}.`,
  },
  BALANCE_OUT_OF_MONTHLY_LIMIT: {
    title: 'Приостановлена по месячному лимиту рекламного кабинета',
    description: (args: Args) =>
      `Трансляция приостановлена по достижении месячного лимита рекламного кабинета: ${args.limit_value}.`,
  },
  BANNER_FORMAT_DELETED: {
    title: 'Формат объявлений больше не поддерживается',
    description: 'Вы не можете менять настройки кампании или добавлять в нее новые объявления.',
  },
  CAMPAIGN_ARCHIVED: { title: 'Кампания удалена' },
  CHECK_STOPPED: {
    title: 'Проверьте настройки',
    description: 'Пожалуйста, проверьте настройки кампании и попробуйте запустить рекламу снова.',
  },
  COUNTER_DELETED: { title: 'Счетчик удален', description: 'Счетчик {counter} был удален и кампания остановлена.' },
  DOOH_MSG: {
    title: 'Внешние идентификаторы видео для баннера',
  },
  DOOH_NO_MONEY: {
    title: 'Недостаточно средств',
    description: (args: Args) => `Недостаточно средств на балансе: ${args.amount}.`,
  },
  DOOH_PAD_DELETED: { title: 'Недостаточно поверхностей' },
  GOAL_DELETED: {
    title: 'Цель удалена',
    description: (args: Args) =>
      `Цель ${args.goal} счетчика ${args.counter} была удалена. Проверьте настройки и возобновите трансляцию.`,
  },
  LAL_ARCHIVED: {
    title: 'Look-alike удален',
    description: (args: Args) => `Look-alike ${args.lookalike_id} was archived and campaign was stopped.`,
  },
  LAL_FAILED: {
    title: 'Look-alike не рабочий',
    description: (args: Args) => `Look-alike ${args.lookalike_id} was failed and campaign was stopped.`,
  },
  NO_ALLOWED_BANNERS: {
    title: 'Нет активных объявлений',
    description: 'В группе объявлений отсутствуют объявления, одобренные модератором.',
  },
  NO_BANNERS_WITH_ACTIVE_STATUS: { title: 'Нет активных объявлений' },
  NO_CAMPAIGNS_WITH_ACTIVE_STATUS: { title: 'Нет активных групп объявлений' },
  BANNED: { title: 'Отклонено модерацией', description: 'Объявление не было одобрено модерацией.' },
  NO_MONEY: {
    title: 'Баланс рекламного кабинета исчерпан',
    description:
      'Трансляция приостановлена из-за нехватки средств на балансе. Трансляция автоматически возобновится после пополнения баланса.',
  },
  OFFER_GROUP_NOT_LOADED: {
    title: 'Группа товаров еще не загружена',
    description:
      'Трансляция начнется после завершения сбора данных по группе товаров. Этот процесс займет не более 2-х часов.',
  },
  OUT_OF_DATE_END: {
    title: 'Достигнута дата остановки',
    description: (args: Args) =>
      `Трансляция группы объявлений завершена ${args.date_end} согласно настройкам расписания.`,
  },
  OUT_OF_DATE_START: {
    title: 'Группа ожидает запуска',
    description: (args: Args) => `Трансляция начнется ${args.date_start} в соответствии с настройками расписания.`,
  },
  OUT_OF_DAY_LIMIT: {
    title: 'Приостановлена по дневному лимиту',
    description: (args: Args) =>
      `Трансляция приостановлена по достижении дневного лимита: ${args.day_limit} ${args.currency}. Трансляция возобновится в начале следующих суток.`,
  },
  OUT_OF_FULL_LIMIT: {
    title: 'Общий бюджет исчерпан',
    description: (args: Args) => `Достигнуто ограничение по общему бюджету: ${args.full_limit} ${args.currency}.`,
  },
  OUT_OF_TIME_TARGETING: {
    title: 'Приостановлена по расписанию',
    description: (args: Args) => `Трансляция приостановлена по расписанию до ${args.time_start}.`,
  },
  PACKAGE_DELETED: {
    title: 'Формат объявлений не поддерживается',
    description:
      'Используемый формат объявлений больше не поддерживается. Вы не можете менять настройки кампании или добавлять в нее новые объявления.',
  },
  PACKAGE_UNAVAILABLE: {
    title: 'Формат объявлений недоступен',
    description:
      'Используемый формат объявлений больше недоступен. Вы не можете менять настройки кампании или добавлять в нее новые объявления.',
  },
  PARENT_ACCOUNT_INACTIVE: { title: 'Аккаунт агентства не подтвержден', description: 'Трансляция прекращена.' },
  PAYMENT_DETAILS_UNCONFIRMED: {
    title: 'Необходимо подтвердить платежные данные',
  },
  PRICELIST_BLOCKED: {
    title: 'Прайслист заблокирован',
    description: (args: Args) => `Прайслист ${args.pricelist_id} был заблокирован, кампания остановлена.`,
  },
  PRICELIST_DELETED: {
    title: 'Каталог товаров удален',
    description: (args: Args) =>
      `Каталог товаров ${args.pricelist}, используемый в кампании, был удалён. Для возобновления показа рекламы укажите работающий каталог.`,
  },
  PRICELIST_OFFER_GROUP_DELETED: {
    title: 'Группа товаров удалена',
    description: (args: Args) =>
      `Группа товаров ${args.offer_group}, используемая в кампании была удалена. Для возобновления показа рекламы укажите другую группу.`,
  },
  REMARKETING_AUDIENCE_CHANGED: {
    title: 'Аудитория изменена',
    description: (args: Args) => `Аудитория ${args.audience}, используемая в рекламе, была изменена.`,
  },
  SEGMENT_CHANGED: {
    title: 'Аудитория изменена',
    description: (args: Args) => `Аудитория ${args.segment}, используемая в рекламе, была изменена.`,
  },
  SEGMENT_DELETED: {
    title: 'Аудитория удалена',
    description: (args: Args) => `Аудитория ${args.segment}, используемая в рекламе, была удалена.`,
  },
  SK_AD_CAMPAIGN_ID_WITHDRAWN: {
    title: 'SKAd ID отозван',
    description: 'Используемый в группах объявлений SKAd ID был отозван владельцем приложения.',
  },
  STOPPED: { title: 'Объявление остановлено' },
  NO_DELIVERING_CAMPAIGNS: {
    title: 'Группы объявлений не транслируются',
    description: 'В кампании нет активных групп объявлений.',
  },
  CAMPAIGN_STOPPED: {
    title: 'Группа объявлений не запущена и не транслируются',
  },
  PRICELIST_OFFER_GROUP_TOO_SMALL: {
    title: 'Группа товаров слишком маленькая',
    description:
      'Используемая группа товаров  содержит менее 6 активных товаров. Добавьте товары или укажите другую группу.',
  },
  ON_MODERATION: {
    title: 'На проверке',
  },
};
