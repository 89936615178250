export const TooltipError = {
  SOME_CLIENTS_NOT_AVAILABLE:
    'Один или несколько рекламных кабинетов \n недоступны или не обновляются. Откройте \n редактирование отчета, чтобы узнать \n подробнее.',
  CLIENT_NOT_AVAILABLE:
    'Рекламный кабинет недоступен или не обновляется.\n Откройте редактирование отчета, чтобы узнать подробнее.',
  NO_REPORT_DOWNLOAD:
    'Отчет без рекламных кабинетов \n недоступен для скачивания. Откройте \n редактирование отчета, что бы \n добавить рекламные кабинеты.',
};

export const TooltipMessage = {
  DOWNLOAD_REPORT: 'Скачать отчет',
  EDIT: 'Редактировать',
  SUGGEST_IDEA:
    'Предложите нам свои идеи и пожелания. Мы будем благодарны вам за обратную связь и обязательно учтем Ваши пожелания.',
  TEST_FUNCTIONALITY: 'Этот функционал является экспериментальным и продолжает развиваться',
  DELETE_FROM_LIST: 'Удалить из списка',
};

export const TooltipErrorRole = {
  INVITE: 'Только владелец и администраторы могу приглашать пользователей',
  TEAM: 'Только владелец и администраторы могут создавать команды',
  PROJECT: 'Только владелец и администраторы могут создавать проекты',
};
