import { DownloadReportIcon, GridIcon, RedoIcon } from '@plarin/design';
import { ActionTableButton, Popover } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useRef, useState } from 'react';
import { ActionContentProps } from '../../../../../types/manage-vk/types';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { ManageVkEditMenu } from '../../../../components/manage-vk-edit-menu/manage-vk-edit-menu';
import { TableGroupingVK } from '../../../../components/table-grouping';
import { STORAGE_ITEM } from '../../../../utils/constants';
import { createReportClientsDrawer } from '../../../../utils/reports';
import { useGetStatTable } from '../utils/useGetStatTable';
import classes from './style.module.scss';
import { TObjType, TMetric } from 'types/metrics';

export const AdGroupsActionContent = observer(({ gridApi }: ActionContentProps) => {
  const {
    manageVK: {
      tableLoading,
      toggleDrawer,
      getStat,
      downloadExcelStat,
      data,
      selectedAdGroupsIds,
      metricsVK,
      errorTable,
      metricsForGrouping,
      setGroupingMetrics,
    },
    reports: { openDrawer, updateInitialStateDrawer },
    profileStore: { wsDictionary },
  } = useAppDataStore();

  const reportMenu = useRef(null);
  const [isOpenReportMenu, setOpenReportMenu] = useState(false);
  const [isLoadingActionMenu, setLoadingActionMenu] = useState(false);

  const getStatUp = useGetStatTable(ManageVkTabNameEnum.ADGROUPS);

  const onOpenReportMenu = useCallback(() => {
    setOpenReportMenu(true);
  }, []);

  const onCloseReportMenu = useCallback(() => {
    setOpenReportMenu(false);
  }, []);

  const onOpenReportDrawer = () => {
    if (data && gridApi) {
      const clients = createReportClientsDrawer(gridApi);
      const checkedMetricsVK = localStorage.getItem(STORAGE_ITEM.localStorage.METRICSVK);
      updateInitialStateDrawer({
        reportClients: clients,
        entities: ['campaign'],
        fields: checkedMetricsVK && JSON.parse(checkedMetricsVK),
      });
    }
    openDrawer();
  };

  const onDownloadExcel = useCallback(() => {
    setLoadingActionMenu(true);
    downloadExcelStat(ManageVkTabNameEnum.ADGROUPS, setLoadingActionMenu).then();
  }, [downloadExcelStat]);

  const options = [
    {
      name: 'Скачать таблицу в Excel',
      action: () => {
        onDownloadExcel();
        onCloseReportMenu();
      },
    },
    {
      name: 'Создать отчет',
      action: () => {
        onOpenReportDrawer();
        onCloseReportMenu();
      },
    },
  ];

  return (
    <div className={classes.actionContent}>
      <div className={classes.leftPanel}>
        <ManageVkEditMenu
          disabled={tableLoading || !selectedAdGroupsIds.length}
          gridApi={gridApi}
          tabName={ManageVkTabNameEnum.ADGROUPS}
        />
      </div>
      <div className={classes.rightPanel}>
        <TableGroupingVK
          tab="campaign"
          metricsForGrouping={metricsForGrouping}
          setGroupingMetrics={setGroupingMetrics}
          title="Группировка"
          checkedMetricIndex={0}
        />
        <TableGroupingVK
          tab="campaign"
          metricsForGrouping={metricsForGrouping}
          setGroupingMetrics={setGroupingMetrics}
          title="Группировка 2"
          checkedMetricIndex={1}
        />{' '}
        <TableGroupingVK
          tab="campaign"
          metricsForGrouping={metricsForGrouping}
          setGroupingMetrics={setGroupingMetrics}
          title="Группировка 3"
          checkedMetricIndex={2}
        />
        <div ref={reportMenu} className={classes.tableReport}>
          <ActionTableButton
            textTooltip={metricsVK?.length && !errorTable ? 'Отчёт' : 'Произошла ошибка'}
            action={metricsVK?.length && !errorTable ? onOpenReportMenu : () => {}}
            loading={isLoadingActionMenu}
            className={classes.downloadIcon}
            icon={<DownloadReportIcon />}
          />
        </div>
        <ActionTableButton
          textTooltip={metricsVK?.length ? 'Настроить колонки' : 'Произошла ошибка'}
          action={metricsVK?.length ? toggleDrawer : () => {}}
          icon={<GridIcon />}
        />
        <ActionTableButton
          textTooltip="Обновить"
          action={() => getStatUp()}
          loading={tableLoading}
          icon={<RedoIcon />}
          isReloadButton={true}
        />
      </div>
      <Popover
        isListLeft
        classNameWrapper={classes.reportMenu}
        setIsOpen={onCloseReportMenu}
        anchorEl={reportMenu.current}
        isOpen={isOpenReportMenu}
        dataList={options}
      />
    </div>
  );
});
