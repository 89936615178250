import { MegaphoneIcon, NoImageIcon, Preloader } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { EllipsisTooltip } from '../../../../../tooltip';
import classes from './styles.module.scss';

export const AdsPreview = ({ data }: { data: any }) => {
  const isArchived = data.status?.includes('Архив');

  if (data.previewSmall === 'loading' || data.previewSmall === 'none') {
    return (
      <div className={classes.imgContainer}>
        <Preloader size={12} thickness={5} />
      </div>
    );
  }
  if (data.previewSmall === 'vk_post') {
    return (
      <div className={clsx(classes.imgContainer, classes.imgContainerVkPost, isArchived && classes.imgArchived)}>
        <MegaphoneIcon color="white" size={16} />
      </div>
    );
  }
  return (
    <div
      className={clsx(
        classes.imgContainer,
        data.previewSmall ? classes.imgHover : classes.noImgHover,
        isArchived && (data.previewSmall ? classes.imgArchived : classes.noImgArchived),
      )}
    >
      {data.previewSmall ? (
        <img className={classes.img} src={data.previewSmall} />
      ) : (
        <EllipsisTooltip tooltipMessage="Изображение отсутствует">
          <NoImageIcon size={16} color="grey" />
        </EllipsisTooltip>
      )}
    </div>
  );
};
