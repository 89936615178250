import { BasicStore, EApiCodes, httpClient, httpClient2 } from '@plarin/core';
import { GridApi } from '@plarin/inputs';
import axios from 'axios';
import { makeObservable, observable, runInAction } from 'mobx';
import { toJS } from 'mobx';
import { STATUS } from 'src/utils/constants';
import {
  ModalData,
  TAFConnectResp,
  AFResponse,
  TAFStoreData,
  TConnectFormData,
  TIntegrationChange,
  TDeleteAccount,
  TEnableAF,
} from '../../types/connect-af/types';
import {
  getAccountsIntegrationsStatus,
  getAccountsItemsSyncStatus,
  getChildsReqObject,
} from '../utils/integration-converters';

const emptyConnectFormData: TConnectFormData = {
  name: '',
  token: '',
};

const emptyModalData: ModalData = {
  type: '',
  connectAccountData: { name: '', token: '' },
  selectedRows: [],
  name: [],
  gridApi: undefined,
};

export const getApiCodeAxiosError = (error: unknown): EApiCodes | undefined => {
  if (axios.isAxiosError(error) && error.response?.data) {
    return error.response!.data.error.code;
  }
};

export class IntegrationsAFStore extends BasicStore {
  connectFormData: TConnectFormData = emptyConnectFormData;
  data: TAFStoreData[] = [];
  tableLoading: boolean = false;
  tableError: boolean = false;
  modalData: ModalData = {} as ModalData;
  isLoading: boolean = false;
  isConnectFailed: boolean = false;
  connectionError: string = '';
  searchTableAF: string = '';

  constructor() {
    super();
    makeObservable<this>(this, {
      connectFormData: observable,
      data: observable,
      tableLoading: observable,
      tableError: observable,
      modalData: observable,
      isLoading: observable,
      isConnectFailed: observable,
      connectionError: observable,
      searchTableAF: observable,
    });
  }

  setModalDataAF = (data: ModalData) => runInAction(() => (this.modalData = data));
  clearModalData = () => runInAction(() => (this.modalData = { ...emptyModalData }));
  setTableLoading = (value: boolean) => runInAction(() => (this.tableLoading = value));
  setTableError = (value: boolean) => runInAction(() => (this.tableError = value));

  private setIsLoading = (loading: boolean) => runInAction(() => (this.isLoading = loading));

  getData = async () => {
    this.setTableLoading(true);
    this.setTableError(false);

    setTimeout(async () => {
      await this.execRequest<AFResponse[]>(httpClient2.get('/api/v1/connect/af/integration/list'))
        .then(res =>
          runInAction(
            () =>
              (this.data = res.reduce((accumulator: TAFStoreData[], account) => {
                // представления внутри аккаунта
                if (account.items?.length) {
                  account.items.forEach(item => {
                    accumulator.push({
                      ...item,
                      key: item.id,
                      _id: account._id,
                      _parent_status: account.plarin_status,
                      orgHierarchy: [account._id, item.id],
                      syncStatusCode: item.issues?.length ? STATUS.BLOCKED : item.plarin_status,
                      syncStatus: getAccountsItemsSyncStatus(
                        item.plarin_status,
                        item.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      plarin_status: item.plarin_status,

                      // у приложений также отображаем статус интеграции
                      integrationStatus: getAccountsIntegrationsStatus(
                        item.plarin_status,
                        item.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                      ),
                      integrationStatusCode: item.issues?.length ? STATUS.BLOCKED : item.plarin_status,
                    });
                  });
                }

                // аккаунт
                accumulator.push({
                  ...account,
                  key: account._id,
                  header1: account.header1,
                  integrationStatus: getAccountsIntegrationsStatus(
                    account.plarin_status,
                    account.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  isParent: true,
                  integrationStatusCode: account.issues?.length ? STATUS.BLOCKED : account.plarin_status,
                });
                return accumulator;
              }, [])),
          ),
        )
        .catch(e => {
          this.setTableError(!!e);
          this.setTableLoading(false);
        })
        .finally(() => this.setTableLoading(false));
    }, 500);
  };

  updateData = (key: keyof TConnectFormData, value: any) => {
    runInAction(() => {
      if (this.connectFormData) {
        this.connectFormData[key] = value;
      }
    });
  };

  disableIntegrationAF = ({
    parentRows,
    childRows,
    gridApi,
  }: {
    parentRows: TAFStoreData[];
    childRows: TAFStoreData[];
    gridApi?: GridApi;
  }) => {
    this.setIsLoading(true);

    const reqBody: TIntegrationChange = {
      db_ids: parentRows.map(row => row._id),
      app_ids: getChildsReqObject(childRows),
    };

    setTimeout(async () => {
      await this.execRequest<AFResponse[]>(httpClient2.post('/api/v1/connect/af/integration/disable', reqBody))
        .then(resObj => {
          let newData = [...this.data];

          resObj.forEach(resAccount => {
            newData = newData.map(tableRow => {
              // Это аккаунт
              if (tableRow._id === resAccount._id && !tableRow.id) {
                return {
                  ...tableRow,
                  plarin_status: resAccount.plarin_status,
                  integrationStatus: getAccountsIntegrationsStatus(
                    resAccount.plarin_status,
                    resAccount.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  integrationStatusCode: resAccount.issues?.length ? STATUS.BLOCKED : resAccount.plarin_status,
                };
              } else {
                // обновляем данные для приложений внутри аккаунтов
                const app = resAccount.items?.find(app => app.id === tableRow.id);

                if (app) {
                  return {
                    ...tableRow,
                    _parent_status: resAccount.plarin_status,
                    plarin_status: app.plarin_status,
                    integrationStatus: getAccountsIntegrationsStatus(
                      app.plarin_status,
                      app.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: app.issues?.length ? STATUS.BLOCKED : app.plarin_status,

                    syncStatusCode: app.issues?.length ? STATUS.BLOCKED : app.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      app.plarin_status,
                      app.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }

                return tableRow;
              }
            });
          });

          runInAction(() => (this.data = [...newData]));

          this.addNotification({
            type: STATUS.SUCCESS,
            title:
              parentRows.length + childRows.length === 1
                ? 'Интеграция успешно выключена'
                : 'Интеграции успешно выключены',
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.clearModalData();
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  enableIntegrationAF: TEnableAF = ({ parentRows, childRows, gridApi }) => {
    const reqBody: TIntegrationChange = {
      db_ids: parentRows.map(row => row._id),
      app_ids: getChildsReqObject(childRows),
    };

    const parentIds = parentRows.map(row => row._id);
    const childIds = childRows.map(row => row.id);

    // проставляем лоадер редактируемым строкам таблицы
    runInAction(
      () =>
        (this.data = this.data.map(row => {
          // это аккаунт
          if (row.isParent && parentIds.includes(row._id)) {
            return { ...row, integrationStatusCode: 'loading' };
          }

          // это приложение: отображаем лоадер одиночно редактируемым приложениям
          // также отображает лоадер детям редактируемых аккаунтов при редактировании через экшен строки аккаунта
          if ((!row.isParent && parentIds.includes(row._id)) || childIds.includes(row.id)) {
            return {
              ...row,
              syncStatusCode: 'loading',
              integrationStatusCode: 'loading',
            };
          }

          return row;
        })),
    );

    setTimeout(async () => {
      await this.execRequest<AFResponse[]>(httpClient2.post('/api/v1/connect/af/integration/enable', reqBody))
        .then(resObj => {
          let newData = [...this.data];

          resObj.forEach(resAccount => {
            newData = newData.map(tableRow => {
              // Это аккаунт
              if (tableRow._id === resAccount._id && tableRow.isParent) {
                return {
                  ...tableRow,
                  plarin_status: resAccount.plarin_status,
                  integrationStatus: getAccountsIntegrationsStatus(
                    resAccount.plarin_status,
                    resAccount.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                  ),
                  integrationStatusCode: resAccount.issues?.length ? STATUS.BLOCKED : resAccount.plarin_status,
                };
              } else {
                // обновляем данные для приложений внутри аккаунтов
                const app = resAccount.items?.find(app => app.id === tableRow.id);

                if (app) {
                  return {
                    ...tableRow,
                    _parent_status: resAccount.plarin_status,
                    plarin_status: app.plarin_status,
                    integrationStatus: getAccountsIntegrationsStatus(
                      app.plarin_status,
                      app.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                    integrationStatusCode: app.issues?.length ? STATUS.BLOCKED : app.plarin_status,
                    syncStatusCode: app.issues?.length ? STATUS.BLOCKED : app.plarin_status,
                    syncStatus: getAccountsItemsSyncStatus(
                      app.plarin_status,
                      app.issues?.length ? STATUS.BLOCKED : STATUS.ACTIVE,
                    ),
                  };
                }

                return tableRow;
              }
            });
          });

          runInAction(() => (this.data = [...newData]));

          this.addNotification({
            type: STATUS.SUCCESS,
            title:
              parentRows.length + childRows.length === 1
                ? 'Интеграция успешно включена'
                : 'Интеграции успешно включены',
          });
        })

        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          gridApi && gridApi.redrawRows();
        });
    }, 500);
  };

  deleteAccountsAF = (parentRows: TAFStoreData[]) => {
    this.setIsLoading(true);

    const reqBody: TDeleteAccount = {
      db_ids: parentRows.map(row => row._id),
    };

    setTimeout(async () => {
      await this.execRequest<string[]>(httpClient2.post('/api/v1/connect/af/integration/disconnect', reqBody))
        .then(res => {
          const removedNames = this.data
            .filter(el => el.isParent && reqBody.db_ids.includes(el._id))
            .map(el => el.header1)
            .join(', ');

          runInAction(() => {
            this.data = this.data.filter(el => !reqBody.db_ids.includes(el._id));
          });

          this.addNotification({
            type: STATUS.SUCCESS,
            title:
              reqBody.db_ids.length === 1 ? `Аккаунт ${removedNames} отключен` : `Аккаунты ${removedNames} отключены`,
          });
        })
        .catch(error => {
          console.log(error);
          this.addNotification({
            type: STATUS.ERROR,
            title: error,
          });
        })
        .finally(() => {
          this.setIsLoading(false);
          this.clearModalData();
        });
    }, 500);
  };

  resetConnectForm = () => {
    runInAction(() => {
      this.connectFormData = emptyConnectFormData;
    });
  };

  setIsConnectFailed = (value: boolean) => runInAction(() => (this.isConnectFailed = value));
  setSearchTableAF = (value: string) => runInAction(() => (this.searchTableAF = value));

  connectAppsflyerAccount = async () => {
    this.setIsLoading(true);
    try {
      const res = await this.execRequest<TAFConnectResp>(
        httpClient.post('/api/v1/connect/af/new', {
          name: this.connectFormData.name,
          token: this.connectFormData.token,
        }),
      );
      runInAction(() => {
        this.addNotification({
          type: STATUS.SUCCESS,
          title: `Аккаунт ${this.connectFormData.name} успешно подключен`,
        });
        this.setModalDataAF({ type: '' });
        this.setIsConnectFailed(false);
        this.resetConnectForm();
        this.getData();
      });
    } catch (error) {
      runInAction(() => {
        const errorCode = getApiCodeAxiosError(error) as EApiCodes | undefined;
        this.connectionError = errorCode && Object.values(EApiCodes).includes(errorCode) ? errorCode : 'default';
        this.setIsConnectFailed(true);
      });
    } finally {
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  };
}
