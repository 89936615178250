import { SmallArrowRightIcon } from '@plarin/design';
import { Checkbox, EllipsisTooltip, HightLightText, manageVkTabs } from '@plarin/inputs';
import {
  ManageVkTabNameEnum,
  switchFilter,
  typografNames,
  ObjectValue,
  isUrlPreProd,
  isUrlProd,
  getSafeParsedLocalStorage,
} from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TMetricGroup } from '../../../types/metrics';
import { FAST_STAT, METRICS_GROUP_PATHS, STORAGE_ITEM } from '../../utils/constants';
import { getSortMetrics } from '../../utils/getSortMetrics';
import { useAppDataStore } from '../app-data-provider';
import { Chips } from '../chips';
import classes from './list.module.scss';

export type ManageVkTabsTypes = ObjectValue<typeof manageVkTabs>;

export const ListVk = observer(() => {
  const { id: tabName } = useParams<{ id: ManageVkTabsTypes }>();

  const {
    manageVK: {
      setDrawerAction,
      getStat,
      fast72Checked,
      setFast72Checked,
      metricsFilterValue: filter,
      metricsVK,
      getMetricsVK,
      setMetrics,
    },
    profileStore: { wsDictionary },
  } = useAppDataStore();

  const [metricsList, setMetricsList] = useState<TMetricGroup[]>([]);

  const filterMetrics = useCallback(
    (searchMetrics: string) => {
      return metricsList
        .map(item => {
          return searchMetrics && item.name.ru.toLowerCase().includes(searchMetrics.toLowerCase())
            ? {
                ...item,
                selected: item.name.ru.toLowerCase().includes(searchMetrics.toLowerCase()) && !item.selected,
              }
            : {
                ...item,
                selected:
                  item.fields
                    .map(el => el.name?.ru.toLowerCase().includes(searchMetrics.toLowerCase()))
                    .includes(true) && !item.selected,
                fields: item.fields.filter(el => el.name?.ru.toLowerCase().includes(searchMetrics.toLowerCase())),
              };
        })
        .filter(item => item.fields.length);
    },
    [filter, metricsList],
  );

  const filterMetricsList = useMemo(() => {
    const wordsSearchMetrics = filter.split(' ');
    if (wordsSearchMetrics.length >= 2) {
      let resFilter: string[] = [];
      for (let index = 0; index < wordsSearchMetrics.length; index++) {
        filterMetrics(wordsSearchMetrics[index]).length
          ? resFilter.push(wordsSearchMetrics[index])
          : resFilter.push(switchFilter(wordsSearchMetrics[index]));
      }

      return filterMetrics(resFilter.join(' '));
    }

    return filter
      ? filterMetrics(filter).length
        ? filterMetrics(filter)
        : filterMetrics(switchFilter(filter))
      : metricsList;
  }, [filter, metricsList]);

  const onSelect = useCallback((path: string) => {
    setMetricsList(prev =>
      prev.map(item => (item.path === path ? { ...item, selected: !item.selected } : { ...item })),
    );
  }, []);

  const onCheckAll = (groupPath: string) => {
    if (filter) {
      const nonFilterFields =
        metricsList
          .find(item => item.path === groupPath)
          ?.fields.filter(
            ({ name }) =>
              !filterMetricsList
                .find(item => item.path === groupPath)
                ?.fields.map(el => el.name?.ru)
                .includes(name?.ru),
          ) || [];

      setMetricsList(prev =>
        prev.map(group =>
          group.path === groupPath
            ? {
                ...group,
                fields: [
                  ...nonFilterFields,
                  ...filterMetricsList
                    .filter(item => item.path === groupPath)[0]
                    .fields.map((metric, _, originArray) => ({
                      ...metric,
                      checked: !(originArray.filter(el => el.checked).length === originArray.length),
                    })),
                ].sort((a, b) => (a.name && b.name ? a.name.ru.localeCompare(b.name!.ru) : 0)),
              }
            : group,
        ),
      );
    } else {
      setMetricsList(prev =>
        prev.map(group =>
          group.path === groupPath
            ? {
                ...group,
                fields: group.fields.map((metric, _, originArray) => ({
                  ...metric,
                  checked: !(originArray.filter(el => el.checked).length === originArray.length),
                })),
              }
            : group,
        ),
      );
    }
  };

  const onSingleCheck = (groupPath: string, metricPath: string) => {
    setMetricsList(prev =>
      prev.map(group =>
        group.path === groupPath
          ? {
              ...group,
              fields: group.fields.map(metric =>
                metric.path === metricPath ? { ...metric, checked: !metric.checked } : metric,
              ),
            }
          : group,
      ),
    );
  };

  useEffect(() => {
    setMetricsList(prev => prev.map(item => ({ ...item, selected: false })));
  }, [filter]);

  useEffect(() => {
    if (metricsVK?.length) {
      const checkedMetricsVK = getSafeParsedLocalStorage<string[]>(STORAGE_ITEM.localStorage.METRICSVK);
      if (checkedMetricsVK) {
        setMetricsList(
          metricsVK.map(group => ({
            ...group,
            fields:
              isUrlProd || isUrlPreProd
                ? group.fields
                    .map(metric => ({
                      ...metric,
                      checked: checkedMetricsVK.includes(metric.path),
                    }))
                    .filter(metric => !metric.hidden)
                : group.fields.map(metric => ({
                    ...metric,
                    checked: checkedMetricsVK.includes(metric.path),
                  })),
          })),
        );
      } else {
        setMetricsList(
          metricsVK.map(group => ({
            ...group,
            fields: isUrlProd || isUrlPreProd ? group.fields.filter(metric => !metric.hidden) : group.fields,
          })),
        );
      }
    }
  }, [fast72Checked, getMetricsVK, metricsVK]);

  useEffect(() => {
    setDrawerAction(() => {
      metricsList.forEach(group => {
        if (group.path === METRICS_GROUP_PATHS.STATISTIC) {
          group.fields.forEach(metric => {
            if (metric.path === FAST_STAT.SatisticsFast72) {
              setFast72Checked(metric.checked!);
            }
          });
        }
      });

      const checkedMetricsVK: string[] = [];
      metricsList.forEach(group =>
        group.fields.forEach(metric => metric.checked && checkedMetricsVK.push(metric.path)),
      );
      localStorage.setItem(STORAGE_ITEM.localStorage.METRICSVK, JSON.stringify(checkedMetricsVK));

      // for metrics in store
      setMetrics(
        metricsVK?.map(group => ({
          ...group,
          fields: group.fields.map(metric => ({ ...metric, checked: checkedMetricsVK.includes(metric.path) })),
        })) || [],
      );

      tabName === manageVkTabs.ACCOUNTS && getStat(ManageVkTabNameEnum.ACCOUNTS, wsDictionary).then();
      tabName === manageVkTabs.ADPLANS && getStat(ManageVkTabNameEnum.ADPLANS, wsDictionary).then();
      tabName === manageVkTabs.AD_GROUPS && getStat(ManageVkTabNameEnum.ADGROUPS, wsDictionary).then();
      tabName === manageVkTabs.ADS && getStat(ManageVkTabNameEnum.ADS, wsDictionary).then();
    });
  }, [getStat, metricsList, setFast72Checked, setDrawerAction, tabName]);

  return (
    <>
      {filterMetricsList.map(({ path, name: { ru }, selected, fields }, index) => (
        <div className={clsx(classes.listItem)} key={path + index}>
          <div className={classes.listButton} onClick={() => onSelect(path)}>
            <div className={classes.icon}>
              <SmallArrowRightIcon className={clsx(classes.collapseIcon, selected && classes.collapseIconTransform)} />
            </div>
            <div
              onClick={e => {
                e.stopPropagation();
                onCheckAll(path);
              }}
            >
              <Checkbox
                value={metricsList.find(el => el.path === path)?.fields.some(metric => metric.checked) || false}
                partChecked={
                  metricsList.find(el => el.path === path)?.fields.length !==
                  metricsList.find(el => el.path === path)?.fields.filter(metric => metric.checked).length
                }
              />
            </div>

            <EllipsisTooltip tooltipMessage={ru}>
              <HightLightText textEllips={true} text={typografNames(ru)} filterValue={filter} size="Caption" />
            </EllipsisTooltip>
          </div>
          {selected && (
            <div className={classes.collapse}>
              {fields.map((item, index) => (
                <div
                  key={index}
                  onClick={() => onSingleCheck(path, item.path)}
                  className={clsx(
                    classes.listButton,
                    classes.subListButton,
                    fields.length - 1 !== index && classes.subListButtonBorder,
                  )}
                >
                  <Checkbox value={item.checked!} />

                  <EllipsisTooltip classNameTooltip={classes.title} tooltipMessage={item.name?.ru || item.path}>
                    <HightLightText
                      textEllips={true}
                      text={typografNames(item.name?.ru || item.path)}
                      filterValue={filter}
                      size="Caption"
                      color={!item.hidden ? 'TextPrimary' : 'TextTertiary'}
                    />
                  </EllipsisTooltip>

                  <div className={classes.chipsWrap}>
                    <Chips names={getSortMetrics(item.obj_types)} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
});
