import { TextField } from '@mui/material';
import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { BidValidationMessage } from '../';
import commonClasses from '../common.module.scss';
import { updateNumericInputStates } from '../utils';

type TNumericCustomInput = {
  currentValue: string;
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
  hasError: boolean;
  header: string;
  alertMessage: string;
  isDisabled?: boolean;
};

type TBidLimitsInput = {
  currencySymbol: string;
  maxBid: number | undefined;
  minBid: number | undefined;
  customMaxBid: number | undefined;
  customMinBid: number | undefined;
  setCustomMaxBid: React.Dispatch<React.SetStateAction<number | undefined>>;
  setCustomMinBid: React.Dispatch<React.SetStateAction<number | undefined>>;
  customLimitsErrorSetter: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
};

export const BidLimitInput = ({
  currentValue,
  setCurrentValue,
  hasError,
  header,
  alertMessage,
  isDisabled,
}: TNumericCustomInput) => {
  const [initialValue] = useState(currentValue);

  return (
    <>
      <div className={commonClasses.headerContainer}>
        <Typography
          size="TableRowSubHeader"
          color="TextSecondary"
          componentProps={{ className: commonClasses.inputBidHeader }}
        >
          {header}
        </Typography>
      </div>

      <NumericFormat
        value={currentValue}
        defaultValue={initialValue}
        valueIsNumericString={true}
        allowNegative={false}
        customInput={TextField}
        InputProps={{
          classes: {
            root: clsx(commonClasses.inputWrap, commonClasses.inputNumericWrap),
            input: commonClasses.inputInput,
            focused: commonClasses.inputFocused,
            error: commonClasses.inputError,
            disabled: commonClasses.disabledInput,
          },
          error: hasError,
        }}
        decimalSeparator=","
        thousandSeparator=" "
        decimalScale={2}
        onValueChange={values => updateNumericInputStates(values, setCurrentValue)}
        onBlur={() => {
          if (currentValue === '') {
            // если мы удалили все символы из инпута, то при onBlur в пустой инпут подставится исходное значение
            setCurrentValue(initialValue);
          }
        }}
        disabled={isDisabled}
      />

      {alertMessage && <BidValidationMessage text={alertMessage} />}
    </>
  );
};

export const BidLimitsInput = ({
  currencySymbol,
  maxBid,
  minBid,
  customMaxBid,
  setCustomMaxBid,
  customMinBid,
  setCustomMinBid,
  customLimitsErrorSetter,
  isDisabled,
}: // setError,
TBidLimitsInput) => {
  const [numericCustomMaxBid, setNumericCustomMaxBid] = useState<string>(String(customMaxBid)); // строковое представление лимита
  const [maxBidHasError, setMaxBidHasError] = useState(false);
  const [minBidAlertMessage, setMinBidAlertMessage] = useState('');

  const [numericCustomMinBid, setNumericCustomMinBid] = useState<string>(String(customMinBid));
  const [minBidHasError, setMinBidHasError] = useState(false);
  const [maxBidAlertMessage, setMaxBidAlertMessage] = useState('');

  const maxBidHeader = `Не больше (макс. ${maxBid === undefined ? '' : maxBid} ${currencySymbol})`;
  const minBidHeader = `Не меньше (мин.  ${minBid === undefined ? '' : minBid} ${currencySymbol})`;

  // валидация инпута, ограничивающего максимальную ставку
  useEffect(() => {
    if (maxBid === undefined || minBid === undefined || customMaxBid === undefined || customMinBid === undefined)
      return;
    if (customMaxBid > maxBid) {
      setMaxBidHasError(true);
      setMaxBidAlertMessage(`Лимит больше ${maxBid} ${currencySymbol}`);
      return;
    }

    if ((customMaxBid < minBid || customMaxBid < customMinBid) && customMinBid < maxBid) {
      setMaxBidHasError(true);

      setMaxBidAlertMessage(
        `Лимит меньше минимума (${customMinBid > minBid ? customMinBid : minBid} ${currencySymbol})`,
      );
      return;
    }
    setMaxBidAlertMessage('');
    setMaxBidHasError(false);
  }, [customMaxBid, customMinBid, maxBid, minBid]);

  // валидация инпута, ограничивающего минимальную ставку
  useEffect(() => {
    if (maxBid === undefined || minBid === undefined || customMaxBid === undefined || customMinBid === undefined)
      return;
    if (customMinBid < minBid) {
      setMinBidHasError(true);

      setMinBidAlertMessage(`Лимит меньше возможного (${minBid} ${currencySymbol})`);
      return;
    }

    if (customMinBid > maxBid || customMinBid > customMaxBid) {
      setMinBidHasError(true);

      setMinBidAlertMessage(
        `Лимит больше максимума (${customMaxBid < maxBid ? customMaxBid : maxBid} ${currencySymbol})`,
      );
      return;
    }

    setMinBidHasError(false);
    setMinBidAlertMessage('');
  }, [customMinBid, customMaxBid]);

  // передаём данные из строкового представления значения ставки в стейт, хранящий числовое представление значения ставки
  useEffect(() => {
    if (maxBid === undefined || minBid === undefined) return;
    // если мы удалили все символы из инпута, то предел ставки становится равным дефолтному значению.
    if (numericCustomMaxBid === '') {
      return setCustomMaxBid(maxBid);
    }
    setCustomMaxBid(Number(numericCustomMaxBid));
  }, [numericCustomMaxBid]);

  useEffect(() => {
    if (minBid === undefined) return;
    if (numericCustomMinBid === '') {
      return setCustomMinBid(minBid);
    }
    setCustomMinBid(Number(numericCustomMinBid));
  }, [numericCustomMinBid]);

  // если есть ошибки в инпутах для пользовательских min/max ставки, родительский стейт будет знать об это ошибке
  useEffect(() => {
    maxBidHasError || minBidHasError ? customLimitsErrorSetter(true) : customLimitsErrorSetter(false);
  }, [customMaxBid, customMinBid, maxBidHasError, minBidHasError]);

  return (
    <div className={commonClasses.minMaxWrap}>
      <div className={commonClasses.minMaxItem}>
        {/* Максимальная ставка */}
        <BidLimitInput
          currentValue={numericCustomMaxBid}
          setCurrentValue={setNumericCustomMaxBid}
          hasError={maxBidHasError}
          header={maxBidHeader}
          alertMessage={maxBidAlertMessage}
          isDisabled={isDisabled}
        />
      </div>
      <div className={commonClasses.minMaxItem}>
        {/* Минимальная ставка */}
        <BidLimitInput
          currentValue={numericCustomMinBid}
          setCurrentValue={setNumericCustomMinBid}
          hasError={minBidHasError}
          header={minBidHeader}
          alertMessage={minBidAlertMessage}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};
