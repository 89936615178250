import { Typography, TreasuryIcon, TreasuryEurIcon, TreasuryRubIcon, TreasuryUsdIcon } from '@plarin/design';
import { float2, int, toNum } from '@plarin/utils';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import React, { useMemo, useState, useEffect } from 'react';
import { getFormType } from '../../../edit-bid';
import { getCurrencySymbol, getItemsType } from '../../../inputs';
import { MtOptimisationLevel } from '../../../inputs/bids';
import { Popover } from '../../../popover';
import { Skeleton } from '../../../preloader';
import { MainTooltip } from '../../../tooltip';
import classes from './style.module.scss';

interface TComponent extends React.FunctionComponent {
  gridApi: GridApi;
  close: () => void;
}

interface BudgetRendererProps extends ICellRendererParams {
  colName: string;
  tabName: string;
  Component: TComponent;
}

const needBudgetIcon = (tabName: any, budgetOptimizationLevel: MtOptimisationLevel, network: any, limit: any) =>
  (tabName === 'campaignName' || tabName === 'adName') &&
  network === 'va' &&
  budgetOptimizationLevel === MtOptimisationLevel.adPlan &&
  Number(limit) > 0;

const getBudgetIcon = (accountCurrency: any) => {
  if (accountCurrency === 'RUB') return <TreasuryRubIcon className={classes.budgetOptimization} />;
  if (accountCurrency === 'EUR') return <TreasuryEurIcon className={classes.budgetOptimization} />;
  if (accountCurrency === 'USD') return <TreasuryUsdIcon className={classes.budgetOptimization} />;

  return <TreasuryIcon className={classes.budgetOptimization} />;
};

const getSpentPercent = (num: number) => (num < 1000 ? `${Math.floor(num)}%` : '999+%');

export const BudgetRenderer = (props: BudgetRendererProps) => {
  const { valueFormatted, data, Component, api } = props;

  const {
    cellLoadingName,
    status,
    accountCurrency,
    statisticsSpentToday,
    adPlanDaily,
    adPlanLifetime,
    campaignDaily,
    campaignLifetime,
    statisticsSpentAll,
    budgetOptimizationLevel,
    network,
  } = props.data;

  const [isFocused, setIsFocused] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [canEditBudget, setCanEditBudget] = useState(false);

  const handleClick = (event: MouseEvent, ref: HTMLDivElement) => {
    event.stopPropagation();
    if (!archived) {
      setAnchorEl(ref);
      setIsFocused(true);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const archived: Boolean = status?.includes('Архивн');
  const spent = props.colName === 'BUDGET_LIFETIME' ? statisticsSpentAll : statisticsSpentToday;
  const spentIsNum = toNum(spent);

  const limit =
    props.colName === 'BUDGET_LIFETIME'
      ? props.tabName === 'adPlanName'
        ? adPlanLifetime
        : campaignLifetime
      : props.tabName === 'adPlanName'
      ? adPlanDaily
      : campaignDaily;

  useEffect(() => {
    // показываем форму редактирования бюджета только для стратегий
    if (getFormType(data) !== 'StrategyEditBid') {
      setCanEditBudget(false);
    } else if (
      // если мы на вкладке адпланов, а бюджет задаётся для групп, то форму не показываем
      getItemsType(window.location.pathname) === 'ad_plan' &&
      data.budgetOptimizationLevel !== MtOptimisationLevel.adPlan
    ) {
      setCanEditBudget(false);
    } else if (archived) {
      setCanEditBudget(false);
    } else {
      setCanEditBudget(true);
    }
  }, [data]);

  const percentNumber = useMemo(() => {
    if (spent && spent !== 0 && spent !== 'loading' && limit) {
      const useUp = (spent / limit) * 100;
      if (useUp >= 100) {
        return useUp;
      }
      if (useUp > 99) {
        return 99;
      }
      return useUp;
    } else return 0;
  }, [spent, limit]);

  if (cellLoadingName === 'bid') {
    return <Skeleton size="maxLong" height={17} />;
  }

  const renderSpent = () => {
    if (spent === 0 || spent === 'loading') {
      return (
        <span className={classes.skeleton}>
          <Skeleton size="long" height={8} />
        </span>
      );
    }
    if (!spentIsNum) {
      return '';
    }
    return <Typography size="Caption">{`${float2(spent)} из`}</Typography>;
  };

  return (
    <div className={classes.budget}>
      <div className={classes.statistics}>
        {/* Иконка, указывающая, что у ВК-шной рекламной сущности оптимизация на уровне адплана */}
        {needBudgetIcon(props.tabName, budgetOptimizationLevel, network, limit) && (
          <MainTooltip
            tooltipMessage="Оптимизация бюджета включена на уровне Кампании."
            isVisible={true}
            followCursor={true}
            component="span"
            placement="bottom-start"
            componentClassName={classes.budgetOptimization}
            maxWidth={250}
            textAlign="left"
          >
            {getBudgetIcon(accountCurrency)}
          </MainTooltip>
        )}
        {/* Сколько потрачено из бюджета */}
        <div className={classes.spent}>
          {renderSpent()}
          {spentIsNum ? <>&nbsp;</> : ''}
          <div
            className={clsx(classes.totalLimitValue, canEditBudget && classes.clickableLimit)}
            ref={ref => {
              if (!ref) return;
              ref.onclick = event => canEditBudget && handleClick(event, ref);
            }}
          >
            <Typography size="Caption" color={canEditBudget ? 'LinkPrimary' : undefined}>{`${
              limit ? int(limit) : '∞'
            }`}</Typography>
          </div>
          &thinsp;<Typography size="Caption">{` ${getCurrencySymbol(accountCurrency)}`}</Typography>
        </div>
      </div>
      <div className={classes.footerBar}>
        <div className={classes.progressBar}>
          <div
            style={{ width: `${percentNumber < 100 ? percentNumber : 100}%` }}
            className={clsx(
              classes.progress,
              Math.floor(percentNumber) <= 35 && classes.procent25,
              Math.floor(percentNumber) > 30 && classes.procent50,
              Math.floor(percentNumber) > 70 && classes.procent75,
              Math.floor(percentNumber) >= 100 && classes.procent100,
            )}
          />
          {Math.floor(percentNumber) > 50 && (
            <div
              style={{ width: `${100 - percentNumber}%` }}
              className={clsx(classes.progressGray, classes.procentGray75)}
            />
          )}
        </div>
        <Typography size="TableRowSubHeader" color={archived ? 'textDisable' : 'TextSecondary'}>
          {getSpentPercent(percentNumber)}
        </Typography>
      </div>
      <Popover
        classNameWrapper={classes.popoverWrapper}
        isListLeft
        isOpen={open}
        anchorEl={anchorEl?.parentElement || null}
        setIsOpen={handleClose}
        marginTop={3}
        zIndex={1200}
      >
        <Component
          // @ts-ignore
          data={props.data}
          close={handleClose}
          gridApi={api}
        />
      </Popover>
    </div>
  );
};
