import { ErrorIcon, Typography, AndroidOsIcon, MacOsIcon } from '@plarin/design';
import { ManageVkTabNameEnum, isUrlProd } from '@plarin/utils';
import type { GridApi, ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { toJS } from 'mobx';
import React, { useState } from 'react';
import { HightLightText } from '../../../hightlight-text';
import { HintPopup } from '../../../hint-popup';
import { CellLoader } from '../../../preloader';
import { EllipsisTooltip, MainTooltip } from '../../../tooltip';
import { Column } from '../../table';
import { copy } from '../../utils';
import { StatusColorEnum } from '../status-renderer-yd';
// import { RenderIcon } from './IconsToASecondLine';
// import { AdsPreview } from './components/ads-preview';
import classes from './style.module.scss';
// import { TooltipContent } from './tooltip-content';

interface IntegrationStatusRendererProps extends ICellRendererParams {
  bullet?: string;
  alternativeBullet?: string;
  statusName: string;
  lastUpdated: string;
  dateFormatter?: (str: string | null) => string;
  hasError?: boolean;
  errorTitle?: string;
  hintComponent?: JSX.Element;
}

// Ячейка "Статус интеграции", "Статус синхронизации"

export const IntegrationStatusRenderer = ({
  bullet, // статус объекта, влияющий на цвет буллета. Скорее всего, enabled/disabled
  alternativeBullet, // другой статус объекта, влияющий на цвет буллета и др.  Скорее всего active/deleted/block
  statusName,
  lastUpdated,
  dateFormatter,
  hasError,
  errorTitle,
  hintComponent,
  ...props
}: IntegrationStatusRendererProps) => {
  const { data } = props;

  if (data.syncStatusCode === 'loading') return <CellLoader />;

  const isDisabled = data.syncStatusCode === 'disabled';
  const basicStatus = bullet ? data[bullet] : undefined;
  const alternativeStatus = alternativeBullet ? data[alternativeBullet] : undefined;

  const getBullet = () =>
    (basicStatus || alternativeStatus) && (
      <span
        className={clsx(
          alternativeStatus === 'disabled' ? classes[alternativeStatus] : classes[basicStatus],
          classes.bullet,
          classes.mba,
        )}
      />
    );

  const getFirstLine = () => {
    const status = alternativeBullet && alternativeStatus === 'disabled' ? 'Выключена' : data[statusName];

    return (
      <div className={classes.firstLine}>
        <EllipsisTooltip classNameContainer={clsx(classes.firstValue)} tooltipMessage={status}>
          <HightLightText text={status} textEllips={true} />
        </EllipsisTooltip>
      </div>
    );
  };

  const getSecondLine = () => {
    return (
      (data[lastUpdated] || data[lastUpdated] === null) && (
        <div
          className={clsx(
            classes.secondLine,
            alternativeBullet &&
              alternativeStatus &&
              (alternativeStatus === 'disabled' ||
                alternativeStatus.includes('Архивн') ||
                alternativeStatus === StatusColorEnum.ARCHIVED ||
                data.syncStatusCode === 'disabled') &&
              classes.errorGray,
          )}
        >
            {' '}
            <EllipsisTooltip tooltipMessage={dateFormatter ? dateFormatter(data[lastUpdated]) : data[lastUpdated]}>
              <HightLightText
                text={dateFormatter ? dateFormatter(data[lastUpdated]) : data[lastUpdated]}
                textEllips={true}
              />
            </EllipsisTooltip>
        </div>
      )
    );
  };

  return (
    <div
      className={clsx(classes.container, hasError && classes.containerHasHint)}
      onClick={() => !isUrlProd && console.log(toJS(data))}
    >
      <div className={classes.leftBlock}>
        {getBullet()}

        <div className={clsx(classes.leftWrap)}>
          {getFirstLine()}
          {getSecondLine()}
        </div>
      </div>

      {hasError && (
        <HintPopup
          isAutoHorizon
          classNameTrigger={classes.attentionAnchor}
          propsNameError={errorTitle}
          isHeaderBox
          textBody={hintComponent}
          triggerIcon={<ErrorIcon color={isDisabled ? 'disabled' : 'status_error'} />}
        />
      )}
    </div>
  );
};
