import { ValueFormatterFunc } from 'ag-grid-community';
import React from 'react';
import { formatValue } from '../../../utils';
import classes from './style.module.scss';

interface DefaultRendererProps {
  value: number | string;
  formatter?: string | ValueFormatterFunc;
  data?: unknown;
  className?: string;
}

export const DefaultRenderer = ({ value, formatter, data = {}, className }: DefaultRendererProps) => {
  return (
    <div className={className}>
      <span className={classes.text}>{typeof value === 'string' ? value : formatValue(+value, formatter, data)}</span>
    </div>
  );
};
