import { Column, GridApi, isActualGridApi, Table, TextError } from '@plarin/inputs';
import { IntegrationsTabNameEnum, relativeDate } from '@plarin/utils';
import type { ICellRendererParams } from 'ag-grid-community';
import { GetQuickFilterTextParams } from 'ag-grid-enterprise';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Account } from '../../../../types/integration/types';
import { useAppDataStore } from '../../../components/app-data-provider';
import { SyncStatusEnum } from '../../../dictionary/integrations';
import { STATUS } from '../../../utils/constants';
import { useFilter } from '../../manage-vk/tabs-content/utils/quickFilterSwitcher';
import { IntegrationsActionBar } from '../integrations-action-bar';
import { TabContentWithoutAccounts } from '../tab-content-without-accounts/tab-content-without-accounts';

export interface ITabContentWithAccounts {
  data?: Account[];
}

const column: Column[] = [
  {
    pinned: 'left',
    suppressMovable: true,
    lockPosition: true,
    lockPinned: true,
    // resizable: false,
    field: 'checkbox',
    headerClass: 'checkbox',
    headerCheckboxSelection: true,
    checkboxSelection: params => params.data.syncStatusCode !== SyncStatusEnum.loading,
    cellRendererSelector: params =>
      params.data.syncStatusCode === 'loading' ? { component: 'PreloaderRenderer' } : { component: null },
    cellClass: 'integrationStatusCode checkbox',
    cellClassRules: {
      deleted: param =>
        param.data.syncStatusCode === STATUS.DISABLED ||
        param.data.integrationStatusCode === STATUS.BLOCKED ||
        param.data.integrationStatusCode === STATUS.DELETED,
      active: param =>
        param.data.syncStatusCode === STATUS.ENABLED && param.data.integrationStatusCode === STATUS.ACTIVE,
      blocked: param =>
        param.data.syncStatusCode === STATUS.ENABLED && param.data.integrationStatusCode === STATUS.BLOCKED,
    },
    headerTooltip: 'true',
    minWidth: 40,
    maxWidth: 40,
    valueGetter: params => params.data.syncStatusCode,
  },
  {
    pinned: 'left',
    headerName: 'Название',
    field: 'name',
    minWidth: 250,
    cellRenderer: 'NameRenderer',
    headerComponentParams: { subheader: 'ID аккаунта' },
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    cellRendererSelector: params => {
      if (params.node.rowPinned) return { component: 'NameCountRenderer' };
    },
    cellRendererParams: {
      text: ['аккаунт', 'аккаунта', 'аккаунтов', 'm'],
      innerRenderer: 'NameRenderer',
      showSelectedAmount: true,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
    valueGetter: params => `${params.data.name} ${params.data.syncStatusCode}`,
    getQuickFilterText: (params: GetQuickFilterTextParams) => {
      return `${params.value} ${params.data.clientId}`;
    },
  },
  {
    headerName: 'Рекламная сеть',
    field: 'network',
    hide: true,
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
  },
  {
    headerName: 'ID аккаунта',
    field: 'clientId',
    hide: true,
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
  },
  {
    headerName: 'Тип аккаунта',
    headerComponentParams: { subheader: 'Описание' },
    field: 'type',
    cellRenderer: 'TwoLinesRenderer',
    cellRendererParams: {
      firstLine: 'type',
      secondLine: 'description',
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => {
      return `${params.value} ${params.data.description}`;
    },
  },
  {
    headerName: 'Рекламные кабинеты',
    field: 'cabinetCount',
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
  {
    headerName: 'Статус интеграции',
    cellRenderer: 'IntegrationStatusRenderer',
    cellRendererParams: (params: ICellRendererParams) => ({
      dateFormatter: relativeDate,
      bullet: 'integrationStatusCode',
      statusName: 'integrationStatus',
      lastUpdated: 'integrationDate',
      alternativeBullet: 'syncStatusCode',
      hasError:
        params.data.syncStatusCode !== 'disabled' &&
        (params.data.integrationStatusCode === 'blocked' || params.data.syncStatusCode === 'blocked'),
      errorTitle: params.data.integrationStatus,
      hintComponent: <TextError />,
    }),
    headerComponentParams: { subheader: 'Последняя синхронизациия' },
    cellClassRules: {
      'sync-status-disabled': param =>
        param.data.syncStatusCode === STATUS.DISABLED || param.data.integrationStatusCode === STATUS.DELETED,
    },
    getQuickFilterText: () => '',

    resizable: false,
    lockPosition: true,
    suppressMovable: true,
  },
];

export const TabContentAccounts = observer(() => {
  const {
    integrationsVK: { accounts, filterValue, isTableLoading, hasError, fetchClients, showActionBar },
  } = useAppDataStore();

  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [selectedRows, setSelectedRows] = useState<Account[]>([]);

  useFilter(filterValue, gridApi);

  useEffect(() => {
    setTimeout(() => {
      isActualGridApi(gridApi) && setSelectedRows(gridApi.getSelectedRows());
    });
  }, [accounts, gridApi]);

  return (
    <>
      <>
        {isTableLoading || (!!accounts && accounts.length) || hasError ? (
          <>
            {showActionBar && <IntegrationsActionBar gridApi={gridApi} selectedRows={selectedRows} />}
            <Table<Account>
              tabName={IntegrationsTabNameEnum.ACCOUNTS}
              showStatusBar
              columns={column}
              rowData={accounts || []}
              setSelectedRows={setSelectedRows}
              onGridReady={setGridApi}
              tableLoading={isTableLoading!}
              errorTable={hasError}
              ActionError={fetchClients}
            />
          </>
        ) : (
          <TabContentWithoutAccounts tabType="accounts" />
        )}
      </>
    </>
  );
});
