import {
  ImageInsideIcon,
  PerfomaceIcon,
  SourceIcon,
  DinamicIcon,
  MobileIcon,
  GalleryIcon,
  EyeOpenedIcon,
  AutomationIcon,
  AndroidOsIcon,
  MacOsIcon,
  ShopCartIcon,
  Typography,
} from '@plarin/design';
import React from 'react';
import { MainTooltip } from './../../../tooltip';
import classes from './style.module.scss';

export enum CampaignsIconYDEnum {
  TEXT_CAMPAIGN = 'TEXT_CAMPAIGN',
  UNIFIED_CAMPAIGN = 'UNIFIED_CAMPAIGN',
  SMART_CAMPAIGN = 'SMART_CAMPAIGN',
  DYNAMIC_TEXT_CAMPAIGN = 'DYNAMIC_TEXT_CAMPAIGN',
  MOBILE_APP_CAMPAIGN = 'MOBILE_APP_CAMPAIGN',
  MCBANNER_CAMPAIGN = 'MCBANNER_CAMPAIGN',
  CPM_BANNER_CAMPAIGN = 'CPM_BANNER_CAMPAIGN',
  CPM_DEALS_CAMPAIGN = 'CPM_DEALS_CAMPAIGN',
  CPM_FRONTPAGE_CAMPAIGN = 'CPM_FRONTPAGE_CAMPAIGN',
  CPM_PRICE = 'CPM_PRICE',
  DEFAULT = 'DEFAULT',
}

export const RenderIcon = (type: CampaignsIconYDEnum | undefined) => {
  const campaign = type && campaignsIconYD[type] ? campaignsIconYD[type] : campaignsIconYD.DEFAULT;

  return campaign ? (
    <MainTooltip tooltipMessage={campaign.name} isVisible={true} component="span" followCursor={true}>
      {campaign.icon}
    </MainTooltip>
  ) : null;
};

export const campaignsIconYD: { [key in CampaignsIconYDEnum]: { icon: JSX.Element; name: string } } = {
  DEFAULT: {
    icon: <AutomationIcon size={14} />,
    name: 'Создана в Мастере кампаний',
  },
  TEXT_CAMPAIGN: {
    icon: <ImageInsideIcon size={14} />,
    name: 'Текстово-графические объявления',
  },
  UNIFIED_CAMPAIGN: {
    icon: <PerfomaceIcon size={14} />,
    name: 'Единая перфоманс-кампания',
  },
  SMART_CAMPAIGN: {
    icon: <SourceIcon size={14} />,
    name: 'Смарт-баннеры',
  },
  DYNAMIC_TEXT_CAMPAIGN: {
    icon: <DinamicIcon size={14} />,
    name: 'Динамические объявления',
  },
  MOBILE_APP_CAMPAIGN: {
    icon: <MobileIcon size={14} />,
    name: 'Реклама мобильных приложений',
  },
  MCBANNER_CAMPAIGN: {
    icon: <GalleryIcon size={14} />,
    name: 'Баннер на поиске',
  },
  CPM_BANNER_CAMPAIGN: {
    icon: <EyeOpenedIcon size={14} />,
    name: 'Медийная кампания',
  },
  CPM_DEALS_CAMPAIGN: {
    icon: <GalleryIcon size={14} />,
    name: 'Медийная кампания со сделками',
  },
  CPM_FRONTPAGE_CAMPAIGN: {
    icon: <GalleryIcon size={14} />,
    name: 'Медийная кампания с баннером под поисковой строкой',
  },
  CPM_PRICE: {
    icon: <GalleryIcon size={14} />,
    name: 'Кампания с фиксированным СРМ',
  },
};

// иконка операционки телефона для ячейки АФ
export const getOsIcon = (isDisabled: boolean, osName: 'ios' | 'android') => {
  if (osName === 'ios') return <MacOsIcon className={classes.inlineOsIcon} color={isDisabled ? 'disabled' : 'main'} />;
  if (osName === 'android')
    return <AndroidOsIcon className={classes.inlineOsIcon} color={isDisabled ? 'disabled' : 'main'} />;
};

// иконка с корзиной из супермаркета для ГА, ЯД
export const eCommerceIcon = () => (
  <MainTooltip
    tooltipMessage="Доступны данные электронной коммерции"
    isVisible={true}
    component="span"
    followCursor={true}
  >
    <span className={classes.cellIcon}>
      <ShopCartIcon color="tertiary" size={16} />
      <Typography color="Tertiary" size="TableRowSubHeader" ellips>
        E-commerce
      </Typography>
    </span>
  </MainTooltip>
);
