import { Typography } from '@plarin/design';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppDataStore } from 'src/components/app-data-provider';
import { routerPaths } from '../../../routing/router-path';
import { AuthContentTemplate } from '../auth-content-template';
import { AuthSliderLayoutTemplate } from '../auth-layout-template';

export const AuthLoginPageTemplate: React.FC = ({ children }) => {
  const { resetAlert } = useAppDataStore();

  const footer = (
    <Typography color="textTertiary" size="Main">
      <Typography
        underline
        color="linkPrimary"
        component={Link}
        componentProps={{ to: routerPaths.unAuth.REGISTRATION, onClick: resetAlert }}
      >
        Зарегистрироваться
      </Typography>{' '}
      в Plarin
    </Typography>
  );

  return (
    <AuthSliderLayoutTemplate>
      <AuthContentTemplate footer={footer}>{children}</AuthContentTemplate>
    </AuthSliderLayoutTemplate>
  );
};
