import {
  Avatar,
  HeadsetIcon,
  IdeaIcon,
  MegaphoneIcon,
  NotificationsIcon,
  Typography,
  PlarinLogo,
} from '@plarin/design';
import { ActionMenuButton, Button, EllipsisTooltip, IconButton, MainTooltip } from '@plarin/inputs';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { DrawerId } from '../../enums';
import { routerPaths } from '../../routing/router-path';
import { getLocalSelectedWS } from '../../utils/localStorageSelectedWS';
import { useAppDataStore } from '../app-data-provider';
import { CreateNewWs } from '../create-new-ws';
import { WorkspaceDrawer } from '../drawer/workspace-drawer';
import { ProfileMenu } from '../menu/profile-menu/profile-menu';
import { MenuWorkspace } from '../menu/workspace-menu/menu-workspace';
import classes from './style.module.scss';

const showSupportButton = true;

export const PageTitle = observer(({ title }: { title: string | JSX.Element }) => {
  const {
    menu: {
      menuVisible,
      toggleVisibleMenu,
      isMenuHover,
      applyHover,
      removeHover,
      disableShowButton,
      setOpenDrawer,
      unreadIntercomCount,
    },
    ws: { isOpenDrawerNewWS, setShowDrawerNewWS },
    profileStore: {
      setIsRootRedirect,
      workspaceName,
      data: { lname, fname, workspaces: profileWorkspaces, email, user_id: userID },
      workspaceShortname,
      workspaces,
      WSRole,
    },
  } = useAppDataStore();
  const { hide, show, isOpen, boot } = useIntercom();
  const anchorMenuProfile = useRef(null);
  const location = useLocation();
  const [isOpenMenuProfile, setOpenMenuProfile] = useState<boolean>(false);
  const [locatedInProfile, setLocatedInProfile] = useState<boolean>(false);
  const history = useHistory();

  const colorAvatar = useMemo(() => {
    const selectedIdWs = getLocalSelectedWS(userID);
    return profileWorkspaces?.find(el => el.workspace_id === selectedIdWs)?.avatar_color;
  }, [workspaceShortname]);

  const toggleIntercomVisibility = () => {
    if (isOpen) {
      hide();
    } else {
      show();
    }
  };

  const unreadMessages = String(unreadIntercomCount).length < 3 ? `${String(unreadIntercomCount)}` : '99+';

  useEffect(() => {
    const urlWorkspace = window.location.pathname;
    let urlPath = '';
    urlWorkspace.split('/').forEach((el, index) => {
      index === 1 && (urlPath = decodeURIComponent(el));
    });

    setLocatedInProfile(urlWorkspace === routerPaths.auth.PROFILE || urlWorkspace === routerPaths.auth.WSLIST);
    if (
      urlWorkspace !== routerPaths.auth.PROFILE &&
      urlWorkspace !== routerPaths.auth.WSLIST &&
      !!urlPath &&
      !!workspaceShortname &&
      urlPath !== workspaceShortname
    ) {
      history.push(
        `${urlWorkspace
          .split('/')
          .map((el, index) => (index === 1 ? workspaceShortname : el))
          .join('/')}`,
      );
    }
  }, [location]);

  showSupportButton &&
    useEffect(() => {
      boot({
        hideDefaultLauncher: true,
      });
    }, [boot]);

  const isWsSelected = !!workspaceShortname;
  const isMenuAllowed = !!(WSRole || isWsSelected);
  const headerIsWhite = !isMenuAllowed;
  const showButtonCreateWs = !workspaceName && (!!workspaces?.length || locatedInProfile);
  const showButtonAllWs = !!workspaces?.length || locatedInProfile;

  const ListWs = () => (
    <Button
      color="secondary"
      size="small"
      onClick={() => {
        setIsRootRedirect(true);
        history.push('/');
      }}
    >
      <Typography color="TextPrimary" weight={600} size="Main">
        Cписок пространств
      </Typography>
    </Button>
  );

  return (
    <>
      {/* Белый фон для хэдера */}
      {headerIsWhite && <div className={classes.noWsHeaderBg} />}

      <div className={clsx(classes.titleWrapper, headerIsWhite && classes.header)}>
        {/* Кнопка скрытия меню и название воркспейса*/}
        {isMenuAllowed && (
          <div
            className={clsx(
              classes.titleHeader,
              !menuVisible && classes.nonVisibleTitleHeader,
              !menuVisible && classes.titleHeaderShow,
            )}
          >
            <div onMouseLeave={removeHover} className={classes.titleSaveZone}>
              <ActionMenuButton
                isOpen={false}
                onClick={toggleVisibleMenu}
                onMouseEnter={applyHover}
                hover={isMenuHover}
                disabled={disableShowButton}
              />
              {typeof title === 'string' ? (
                <EllipsisTooltip tooltipMessage={title} classNameContainer={classes.mlUnset}>
                  <Typography
                    size="ModalTitle"
                    weight={600}
                    color="TextPrimary"
                    ellips
                    componentProps={{ className: classes.spanMargin }}
                  >
                    {title}
                  </Typography>
                </EllipsisTooltip>
              ) : (
                <div className={classes.elementWrapper}>{title}</div>
              )}
            </div>
          </div>
        )}
        {headerIsWhite && (
          <div className={classes.logo} onClick={() => history.push('/')}>
            <PlarinLogo variant="menu" />
          </div>
        )}
        <div className={clsx(classes.rightBlock)}>
          <div className={classes.rightBlockMenu}>
            <div className={clsx(classes.contentWorkflow, !workspaceName && classes.whiteHeadGap)}>
              {!workspaceName && locatedInProfile && <ListWs />}
              {showButtonCreateWs && <CreateNewWs headerContain setIsDrawerOpen={setShowDrawerNewWS} />}
              {!!workspaces?.length && isMenuAllowed && <MenuWorkspace />}
              <div className={clsx(classes.iconContainer, showButtonAllWs && classes.iconBefore)}>
                <MainTooltip tooltipMessage="Новости и обновления" isVisible={true} component="span">
                  <IconButton className={classes.hoverIcon} color={headerIsWhite ? 'light' : undefined}>
                    <MegaphoneIcon color="main" className={classes.cursor} />
                  </IconButton>
                </MainTooltip>
                <MainTooltip tooltipMessage="Предложить идею" isVisible={true} component="span">
                  <IconButton className={classes.hoverIcon} color={headerIsWhite ? 'light' : undefined}>
                    <IdeaIcon
                      color="main"
                      onClick={() => setOpenDrawer(DrawerId.proposeIdea)}
                      className={classes.cursor}
                    />
                  </IconButton>
                </MainTooltip>

                {/* Если нужно убрать из проекта кнопку поддержки, нужно положить в переменную showSupportButton false. 
                Тогда не будет происходить запуск Intercom */}
                {showSupportButton && (
                  <MainTooltip tooltipMessage="Поддержка" isVisible={true} component="span">
                    <div className={classes.iconWithBadge}>
                      <IconButton
                        className={clsx(classes.hoverIcon, isOpen && classes.intercomShow)}
                        color={headerIsWhite ? 'light' : undefined}
                        onClick={toggleIntercomVisibility}
                      >
                        <HeadsetIcon color="main" className={classes.cursor} />
                        {unreadIntercomCount > 0 && <span className={classes.badge}>{unreadMessages}</span>}
                      </IconButton>
                    </div>
                  </MainTooltip>
                )}
                <MainTooltip tooltipMessage="Уведомления" isVisible={true} component="span">
                  <IconButton className={classes.hoverIcon} color={headerIsWhite ? 'light' : undefined}>
                    <NotificationsIcon color="main" className={classes.cursor} />
                  </IconButton>
                </MainTooltip>
              </div>
            </div>
            <div ref={anchorMenuProfile} className={classes.footerAvatar}>
              <Avatar
                fname={fname || ''}
                lname={lname || ''}
                email={email}
                colorAvatar={colorAvatar}
                action={() => setOpenMenuProfile(true)}
              />
            </div>
          </div>
        </div>
        <ProfileMenu setOpen={setOpenMenuProfile} isOpen={isOpenMenuProfile} anchorEl={anchorMenuProfile.current} />
      </div>
      <WorkspaceDrawer isDrawerOpen={isOpenDrawerNewWS} setIsDrawerOpen={setShowDrawerNewWS} />
    </>
  );
});
