import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React from 'react';
import { TRows } from '../types';
import classes from './style.module.scss';

export type TCurrencyFiltrator = { filteredRows: TRows; deselectRows?: (keysToKeep: any[]) => void };

export const filteredOption = ({
  val,
  message,
  deselectRows,
  isActive = true,
}: {
  val: TRows;
  message: string;
  deselectRows?: (keysToKeep: any[]) => void;
  isActive?: boolean;
}) => {
  return (
    !!val.length && (
      <Typography component="p" size="AuthContent" color={isActive ? 'LinkPrimary' : 'TextSecondary'} key={message}>
        <span
          className={clsx(isActive && classes.option)}
          onClick={() => isActive && deselectRows && deselectRows(val.map(row => row.key))}
        >
          {message + `(${val.length})`}
        </span>
      </Typography>
    )
  );
};
