import { ActionBar, GridApi, IRowNode, Table } from '@plarin/inputs';
import { ManageVkTabNameEnum } from '@plarin/utils';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateOptions } from '../../../../../types/common-types';
import { MetricRecord } from '../../../../../types/manage-vk/types';
import { TMetric } from '../../../../../types/metrics';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { dynamicColumnsRenderer } from '../../../../components/manage-vk-custom-columns/dynamic-column-renderer';
import { FastStatTypes } from '../../../../dictionary/manage-vk';
import { FAST_STAT_IDS } from '../../../../utils/constants';
import { getColumnsForTable } from '../../../../utils/manage-vk';
import { useFilter } from '../utils/quickFilterSwitcher';
import { useGetStatTable } from '../utils/useGetStatTable';
import { useGroupingSelect } from '../utils/useGrouping';
import { AdPlansActionContent } from './adPlans-action-content';

export const AdPlansTab = observer(() => {
  const {
    manageVK: {
      filterValue,
      filterStatuses,
      getStat,
      tableLoading,
      data,
      setSelectedIds,
      quantitySelected,
      setDeleteAction,
      errorTable,
      date,
      setCurrentTab,
      dictionary,
      metricsVK,
      getFastStat,
      fast72Checked,
      isGroupDestroyed,
      setIsGroupDestroyed,
      getSelectedIds,
      getAdditional,
      setLeftNavigationIds,
    },
    profileStore: { wsDictionary },
  } = useAppDataStore();

  const { storeLabel, storeStartDate, storeEndDate } = date as unknown as DateOptions;
  const [gridApi, setGridApi] = useState<GridApi>(null as unknown as GridApi);
  const [names, setNames] = useState<TMetric[]>([]);
  const isGroupingActive = !!data?.length && data[0].hasOwnProperty('orgHierarchy');
  const [loading, setLoading] = useState(true);
  const getStatUp = useGetStatTable(ManageVkTabNameEnum.ADPLANS);
  const handleBodyScroll = (nodeRendered: IRowNode<any>[], api: GridApi<any>) => {
    // без этого условия не отображается заглушка, если таблица пустая (PYT-7010)
    if (api?.getDisplayedRowCount() !== 0) {
      setTimeout(() => {
        getFastStat(api, nodeRendered, fast72Checked, FastStatTypes.ADPLAN, FAST_STAT_IDS.ad_plan);
        getAdditional(api, nodeRendered, FAST_STAT_IDS.ad_plan, ManageVkTabNameEnum.ADPLANS);
      }, 100);
    }
  };

  const handleBodyScrollToFastStat = (nodeRendered: IRowNode<any>[], api: GridApi<any>) => {
    if (api?.getDisplayedRowCount() !== 0) {
      getFastStat(api, nodeRendered, fast72Checked, FastStatTypes.ADPLAN, FAST_STAT_IDS.ad_plan);
    }
  };

  // Получаем фаст стату при раскрытии вложенных строк
  const onRowGroupOpened = useCallback(() => {
    if (gridApi && data?.length) {
      const nodes = gridApi.getRenderedNodes().filter(node => !node.data.isGroupRow);
      if (nodes && nodes.length) {
        getFastStat(gridApi, nodes, fast72Checked, FastStatTypes.ADPLAN, FAST_STAT_IDS.ad_plan);
        getAdditional(gridApi, nodes, FAST_STAT_IDS.ad_plan, ManageVkTabNameEnum.ADPLANS);
      }
    }
  }, [gridApi, data]);

  useEffect(() => {
    getStatUp().then(() => {
      setLoading(false);
    });
  }, [
    storeLabel,
    storeStartDate,
    storeEndDate,
    quantitySelected.selectedClients,
    quantitySelected.selectedAdGroups,
    quantitySelected.selectedAds,
    filterStatuses,
  ]);

  useEffect(() => {
    data?.length &&
      dictionary &&
      metricsVK &&
      setNames(getColumnsForTable(Object.keys(data[0]), dictionary, metricsVK, fast72Checked));
  }, [data, dictionary, metricsVK]);

  useEffect(() => {
    setCurrentTab(ManageVkTabNameEnum.ADPLANS);
    setDeleteAction(() => {
      gridApi.deselectAll();
    });
  }, [setDeleteAction, gridApi]);

  useFilter(filterValue, gridApi);
  useGroupingSelect(gridApi, isGroupDestroyed, setIsGroupDestroyed, getSelectedIds, ManageVkTabNameEnum.ADPLANS, data);

  return (
    <>
      <ActionBar>
        <AdPlansActionContent gridApi={gridApi} />
      </ActionBar>

      <Table<MetricRecord>
        showStatusBar
        columns={useMemo(
          () =>
            dynamicColumnsRenderer(names, ManageVkTabNameEnum.ADPLANS, 'adPlanName', wsDictionary, isGroupingActive),
          [names],
        )}
        rowData={data || []}
        onGridReady={setGridApi}
        getSelectedIds={getSelectedIds}
        setLeftNavigationIds={setLeftNavigationIds}
        setSelectedKeys={setSelectedIds}
        ActionError={() => getStat(ManageVkTabNameEnum.ADPLANS, wsDictionary)}
        errorTable={errorTable}
        tabName={ManageVkTabNameEnum.ADPLANS}
        setDeleteAction={setDeleteAction}
        onBodyScroll={handleBodyScroll}
        onBodyScrollFastStat={handleBodyScrollToFastStat}
        // emptyDataComponent={
        //   <NoRowsComponent
        //     action={() => getStat(CampaignsTabNameEnum.ADPLANS, wsDictionary)}
        //     tabName={CampaignsTabNameEnum.ADPLANS}
        //   />
        // }
        tableLoading={tableLoading || loading}
        isGroupDestroyed={isGroupDestroyed}
        setGroupDestroyed={setIsGroupDestroyed}
        onRowGroupOpened={onRowGroupOpened}
      />
    </>
  );
});
