import { Typography } from '@plarin/design';
import React from 'react';
import classes from './style.module.scss';

export const NoRowsOverlay = ({ action }: { action: () => void }) => {
  return (
    <div className={classes.noRowsOverlay}>
      <Typography
        color="LinkPrimary"
        weight={600}
        size="AuthContent"
        componentProps={{ onClick: action, className: classes.actionText }}
      >
        Подключите первую интеграцию
      </Typography>
    </div>
  );
};
