import { Typography } from '@plarin/design';
import { CellClassParams, Column, GetQuickFilterTextParams } from '@plarin/inputs';
import { distanceDateAndTime } from '@plarin/utils';
import React from 'react';
import { WSMembers } from 'types/workspace/types';
import { WSRolesEnum } from '../../../../types/workspace/enum';
import { sortRow } from '../../../utils/common';
import { comporatorRole, comporatorStatusWorkspaces } from '../../../utils/workspaces';
import { MemberCellMenu } from './member-cell-menu';

export const ColumnsMembers: Column[] = [
  {
    headerName: 'Пользователи',
    headerComponentParams: {
      alignY: 'center',
    },
    cellRenderer: 'MemberRender',
    flex: 2,
    cellRendererParams: {
      MemberCellMenu: MemberCellMenu,
    },
    cellClassRules: {
      'users-status-disabled': (params: CellClassParams<WSMembers>) =>
        params.data?.status === 'blocked' || !Boolean(params.data?._id),
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) =>
      `${params?.data?.fname || ''} ${params?.data?.lname || ''} ${params?.data?.email || ''}`,
    resizable: false,
    lockPosition: true,
    comparator: (a, b, nodeA, nodeB) => sortRow(nodeA.data.fname, nodeB.data.fname),
  },
  {
    headerName: 'Роль',
    headerComponentParams: {
      alignY: 'center',
    },
    field: 'role',
    flex: 1,
    valueGetter: params => WSRolesEnum[params.data.role as keyof typeof WSRolesEnum],
    cellClass: 'text-top',
    cellClassRules: {
      'users-status-disabled': (params: CellClassParams<WSMembers>) =>
        params.data?.status === 'blocked' || !Boolean(params.data?._id),
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => '',
    resizable: false,
    lockPosition: true,
    comparator: (a, b) => comporatorRole(a, b),
  },

  {
    headerName: 'Статус',
    headerComponentParams: {
      alignY: 'center',
    },
    flex: 1,
    cellRenderer: 'StatusRenderer',
    cellClass: 'text-top',
    cellClassRules: {
      'users-status-disabled': (params: CellClassParams<WSMembers>) =>
        params.data?.status === 'blocked' || !Boolean(params.data?._id),
    },
    resizable: false,
    lockPosition: true,
    comparator: (a, b, nodeA, nodeB) => comporatorStatusWorkspaces(nodeA.data.status, nodeB.data.status),
  },

  {
    headerName: 'Последняя активность',
    headerComponentParams: {
      alignY: 'center',
    },
    field: 'last_activity',
    flex: 1,
    valueGetter: params => (params.data.last_activity ? distanceDateAndTime(params.data.last_activity) : '—'),

    cellClass: 'text-top',
    cellClassRules: {
      'users-status-disabled': (params: CellClassParams<WSMembers>) =>
        params.data?.status === 'blocked' || !Boolean(params.data?._id),
    },
    getQuickFilterText: (params: GetQuickFilterTextParams) => '',
    resizable: false,
    lockPosition: true,
    comparator: (a, b, nodeA, nodeB) => {
      return new Date(nodeA.data.last_activity).getTime() - new Date(nodeB.data.last_activity).getTime();
    },
  },
];
