import { formatDistanceStrict, formatRelative, isValid } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { ru } from 'date-fns/locale';

export enum CurrencyEnum {
  RUB = '₽',
  USD = '$',
  CNY = '¥',
  EUR = '€',
}

const IntVal = new Intl.NumberFormat('en', { maximumFractionDigits: 0 });
const IntVal1 = new Intl.NumberFormat('en', { maximumFractionDigits: 1 });
const FloatVal2 = new Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
const FloatVal3 = new Intl.NumberFormat('en', { maximumFractionDigits: 3, minimumFractionDigits: 3 });

const replacer = (str: string) => str.replaceAll(',', ' ').replaceAll('.', ',');
const formatter = (date: Date, formatStr: string) => format(date, formatStr, { locale: ru });

export const toNum = (value?: string | number): number => parseFloat((value as string) || '') || 0;

// (12 345)
export const int = (value: number | string, IntValue?: boolean): string => {
  const res = toNum(value);
  return res ? replacer(IntValue ? IntVal1.format(res) : IntVal.format(res)) : '--';
};

// (1 222,20)
export const float2 = (value: number | string): string => {
  const res = toNum(value);
  return res ? replacer(FloatVal2.format(res)) : '--';
};

// (1 222%)
export const percent = (value: number | string): string => {
  const res = toNum(value);
  return res ? `${replacer(IntVal.format(res))}%` : '--';
};

// (1 222,20%)
export const percent2 = (value: number | string): string => {
  const res = toNum(value);
  return res ? `${replacer(FloatVal2.format(res))}%` : '--';
};

// (1 322,203%)
export const percent3 = (value: number | string): string => {
  const res = toNum(value);
  return res ? `${replacer(FloatVal3.format(res))}%` : '--';
};

// (2,90 ₽ или 33,10 $)
export const currency = (value: number | string, cur: keyof typeof CurrencyEnum = 'RUB'): string => {
  const res = toNum(value);
  return res ? `${replacer(FloatVal2.format(res))} ${CurrencyEnum[cur]}` : '--';
};

// ( ₽ или $)
export const typeCurrency = (cur: keyof typeof CurrencyEnum): string => {
  return ` ${CurrencyEnum[cur]}`;
};

// 2022.01.21
export const shortISODate = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'yyyy.MM.dd') : '--';

// 21.01.2022
export const shortDate = (date: string | number | Date, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'dd.MM.yyyy') : '--';

// 08.05.2019 20:03
export const shortDateTime = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'dd.MM.yyyy HH:mm') : '--';

// 08.05.2019 20:03
export const relativeDate = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatRelative(utcToZonedTime(date, timeZone), new Date(), { locale: ru }) : '--';

// Для ячеек статуса интеграции и синхронизации у YA, GM напишет дату последней синхронизации относительно сегодняшнего дня.
export const syncCellRelativeDate = (date: string | number | null, timeZone = 'Europe/Moscow') => {
  if (date === null) return '--';

  const str = isValid(new Date(date))
    ? formatRelative(utcToZonedTime(date, timeZone), new Date(), { locale: ru })
    : '--';

  // Сделает первую букву заглавной.
  // Строку вида "Сегодня в 12:00" переделает в "Сегодня, 12:00"
  const res = str.charAt(0).toUpperCase() + str.slice(1).split(' в ').join(', ');

  return res;
};

// 1 неделю 3 дня 5 минут назад
export const distanceDateAndTime = (date: string | number, timeZone = 'Europe/Moscow') => {
  if (isValid(new Date(date))) {
    return (new Date().getTime() - new Date(date).getTime()) / 1000 > 60
      ? formatDistanceStrict(utcToZonedTime(date, timeZone), new Date(), { locale: ru, addSuffix: true })
      : 'Сейчас';
  } else {
    return '--';
  }
};

// 08.05.2019 20:03:59
export const shortDateTimeSec = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'dd.MM.yyyy HH:mm:ss') : '--';

// 8 мая
export const date = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'dd MMMM') : '--';

// 8 мая, среда
export const dateAndDay = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'dd MMMM, EEEE') : '--';

// 8 мая, Ср, 20:00
export const dateDayTime = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'dd MMMM, E, HH:mm') : '--';

// 8 мая 2019 г. (если место позволяет, всегда лучше ставить г. через тонкий пробел)
export const fullDate = (date: string | number, timeZone = 'Europe/Moscow') =>
  isValid(new Date(date)) ? formatter(utcToZonedTime(date, timeZone), 'dd MMMM yyyy г.') : '--';

// time formatter 13:59+30:00 => 13:59
export const shortTime = (time: string) => {
  const timeArr = time.split('');
  return timeArr.slice(0, timeArr.indexOf('+')).join('');
};

// 1 => 01
export const formatDate = (date: number) => (date.toString().length === 1 ? `0${date}` : date);

// "Wed Mar 15 2023 00:00:00 GMT+0300" => '2023-03-15'
export const formatDateToReq = (argDate: string | Date) => {
  const date = new Date(argDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  return `${year}-${formatDate(month)}-${formatDate(day)}`;
};

// fast72 formatter
export const fast72DateFormatter = (str: string) => {
  const date = str.slice(0, str.indexOf('T')).split('-');
  const newDate = `${date[2]}.${date[1]}.${date[0]}`;
  const time = (+str[str.indexOf('T') + 1] || '') + str[str.indexOf('T') + 2];
  const formattedTime = time.length === 1 ? `0${time}` : time;
  return `${newDate} ${formattedTime}:00`;
};

// принимает номер телефона вида '79001234567', возвращает '+7 (900) •••-45-67'
export const maskedNumber = (phone: string | undefined) => {
  if (typeof phone === 'string' && phone.length === 11) {
    return `+${phone[0]} (${phone.substring(1, 4)}) •••-${phone.substring(7, 9)}-${phone.substring(9)}`;
  }
  return phone;
};

// принимает номер телефона вида '+79001234567' или '79001234567', возвращает '+7 (900) 999-99-99'
export const formatPhone = (phone: string | undefined) => {
  if (phone?.length === 12) {
    return `${phone?.slice(0, 2)} (${phone?.slice(2, 5)}) ${phone?.slice(5, 8)}-${phone?.slice(8, 10)}-${phone?.slice(
      10,
    )}`;
  }
  if (phone?.length === 11) {
    return `+${phone?.slice(0, 1)} (${phone?.slice(1, 4)}) ${phone?.slice(4, 7)}-${phone?.slice(7, 9)}-${phone?.slice(
      9,
    )}`;
  }
};
