import { TextField, Slider } from '@mui/material';
import { Typography } from '@plarin/design';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { BidValidationMessage } from '../';
import { getPriceForBidCell } from '../../../edit-bid/bidsUtils';
import commonClasses from '../common.module.scss';
import { TAccountCurrency, getCurrencySymbol, TNewFixBids, TNewStrategyBids } from '../utils';
import classes from './style.module.scss';

// Для прокидывания новой ставки из инпута NumericFormat в МАССИВ ОБЪЕКТОВ со ставками
// нам необходимо одновременно менять 1) массив с кампаниями из родительского компонента и 2) строковый стейт инпута NumericFormat, задающий новую ставку для всех кампаний.
// Также эта функция преобразует некорректный пользовательский ввод

export const updateBidStates = (
  values: any, // д.б. объект values, возвращаемый NumericInput и имеющий свойства value, floatValue, formattedValue
  numericSetter: React.Dispatch<React.SetStateAction<string>>, // стейт, непосредственно являющийся значением для инпута (строка)
  parentValuesArray: TNewFixBids | TNewStrategyBids, // стейт, в котором хранятся данные о выбранных кампаниях (id и ставка-число и всё остальное)
  parentSetter:
    | React.Dispatch<React.SetStateAction<TNewFixBids>>
    | React.Dispatch<React.SetStateAction<TNewStrategyBids>>,
  setCommonBid: (arg0: TNewFixBids & TNewStrategyBids, arg1: number) => TNewStrategyBids | TNewFixBids,
) => {
  // если мы ввели '00', преобразуется в '0.0'
  if (values.value.startsWith('00')) {
    // @ts-ignore
    parentSetter(setCommonBid(parentValuesArray, values === '' ? '' : 0));
    return numericSetter('0.0');
  }

  // если мы ввели '01', преобразуется в '1'
  if (
    values.value.startsWith('0') &&
    values.value.length === 2 &&
    values.value &&
    !!Number(values.value[1]) // второй символ в инпуте число, а не знак, отделяющий десятые
  ) {
    // @ts-ignore
    parentSetter(setCommonBid(parentValuesArray, Number(values.value[1])));
    return numericSetter(values.value[1]);
  }
  // @ts-ignore
  parentSetter(setCommonBid(parentValuesArray, values.floatValue === undefined ? '' : values.floatValue));
  numericSetter(values.value);
};

type TBidValueInputProps = {
  setBidValue?: React.Dispatch<React.SetStateAction<number>>;
  selectedItems: TNewFixBids | TNewStrategyBids;
  bidsAreDifferent: boolean;
  getCommonBid: (a: any) => number | '' | -1;
  setNewBids:
    | React.Dispatch<React.SetStateAction<TNewFixBids>>
    | React.Dispatch<React.SetStateAction<TNewStrategyBids>>;
  setCommonBid: (arg0: TNewFixBids & TNewStrategyBids, arg1: number) => TNewStrategyBids | TNewFixBids;
  currency: TAccountCurrency;
  maxBid: number | undefined;
  minBid: number | undefined;
  pinSize?: '10' | '12' | '14';
  header?: string;
  isDisabled?: boolean;
};

// Дока: https://wiki.yandex.ru/plarindev/bazaznanijjrazra/proektirovanie/razrabotkaizmene/chislovojj-input-so-slajjderom-bidvalueinput/
export const BidValueInput = ({
  selectedItems,
  setNewBids,
  setCommonBid,
  bidsAreDifferent,
  getCommonBid,
  maxBid,
  minBid,
  pinSize,
  currency,
  header,
  isDisabled = false,
}: TBidValueInputProps) => {
  // инпут NumericFormat требует в качестве значения число, представленное в строковом формате. Поэтому здесь заводим отдельный стейт с числом в строковом формате, которое потом будет переводиться из строки в число и прокидываться во все ставки выбранных кампаний.
  const [numericBidValue, setNumericBidValue] = useState<string>(
    bidsAreDifferent ? '' : String(getCommonBid(selectedItems)),
  );

  const [hasError, setHasError] = useState(false);

  // вычисляет общую новую ставку в родительском компоненте, либо, если нет общей ставки для всех выделенных кампаний, хранит в себе число -1
  const newBid = getCommonBid(selectedItems);
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    // @ts-ignore
    setNewBids(setCommonBid(selectedItems, Number(newValue)));
  };

  useEffect(() => {
    // если ставки у выбранных кампааний разные, то в инпуте вместо значения ставки будет плейсхолдер
    if (bidsAreDifferent) {
      setNumericBidValue('');
    }
    // если значение ставки изменили с помощью слайдера или с помощью графика, мы здесь меняем следом значение в numericBidValue, чтобы новая ставка отобразилась в инпуте NumericFormat
    if (!bidsAreDifferent && newBid !== Number(numericBidValue)) {
      setNumericBidValue(String(newBid));
    }
  }, [selectedItems]);

  // включает/выключает ошибку в инпуте, проверяя, не больше ли значение допустимого максимума
  // если значение в инпуте будет меньше минимума, здесь ошибка не включится (она включится в onBlure при расфокусе инпута)
  useEffect(() => {
    if (minBid && maxBid) {
      // @ts-ignore
      if (newBid <= maxBid) {
        setHasError(false);
      }

      // @ts-ignore
      if (newBid > maxBid) {
        setHasError(true);
      }
    }
  }, [selectedItems, maxBid]);

  const onValueChange = (values: any) => {
    updateBidStates(values, setNumericBidValue, selectedItems, setNewBids, setCommonBid);
  };

  const getValidationMessage = () => {
    if (maxBid && newBid !== '' && newBid > maxBid)
      return `Максимум ${getPriceForBidCell(maxBid, getCurrencySymbol(currency))}`;
    if (minBid && newBid !== '' && newBid < minBid)
      return `Минимум ${getPriceForBidCell(minBid, getCurrencySymbol(currency))}`;

    return '';
  };

  const newBidSmallerThanMin = !hasError && minBid && !bidsAreDifferent && newBid !== '' && newBid < minBid;

  return (
    <>
      <div className={commonClasses.headerContainer}>
        <Typography
          size="TableRowSubHeader"
          color="TextSecondary"
          componentProps={{ className: commonClasses.inputBidHeader }}
        >
          {header}
        </Typography>
      </div>

      <div className={commonClasses.bidValueWrap}>
        <NumericFormat
          disabled={isDisabled}
          value={numericBidValue}
          defaultValue={numericBidValue}
          valueIsNumericString={true}
          allowNegative={false}
          customInput={TextField}
          InputProps={{
            endAdornment: getCurrencySymbol(currency),
            className: commonClasses.inputWrap,
            classes: {
              root: commonClasses.inputBidValueWrap,
              input: commonClasses.inputInput,
              notchedOutline: commonClasses.inputBorder,
              focused: commonClasses.inputFocused,
              error: commonClasses.inputError,
              disabled: commonClasses.disabledInput,
            },
            error: hasError,
          }}
          decimalSeparator=","
          thousandSeparator=" "
          decimalScale={2}
          onValueChange={values => onValueChange(values)}
          // при анфокусе мы проверяем, вдруг введённая ставка меньше допустимого минимума? Если так, то мы вызываем состояние ошибки инпута
          onBlur={() => {
            if (newBidSmallerThanMin) {
              return setHasError(true);
            }
          }}
          placeholder={bidsAreDifferent ? 'Разные значения' : 'Введите значение'}
        />

        {!isDisabled && (
          <Slider
            className={commonClasses.bidValueSlider}
            classes={{
              thumb: clsx(
                commonClasses.sliderThumb,
                // необходимо определиться с итоговым видом слайдера, а потом удалить эти лишние стили
                pinSize === '10' && commonClasses.pin10,
                pinSize === '12' && commonClasses.pin12,
                pinSize === '14' && commonClasses.pin14,
              ),
              rail: commonClasses.sliderRail,
              track: commonClasses.sliderTrack,
            }}
            value={newBid ? newBid : 0}
            onChange={handleSliderChange}
            min={minBid}
            max={maxBid}
            step={currency === 'USD' || currency === 'EUR' ? 0.1 : 1}
            track={false}
          />
        )}

        {/* Это подложка под слайдер, нужна для маскировки того, что ширина слайдера меньше ширины инпута */}
        {!isDisabled && <div className={clsx(classes.railReplacer)} />}
      </div>

      {hasError && <BidValidationMessage text={getValidationMessage()} styles={classes.errorBidValue} />}
    </>
  );
};
