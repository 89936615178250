import { Typography } from '@plarin/design';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import classes from './style.module.scss';

interface IItem {
  lable: string;
  value: string;
}

interface ISourceComparison {
  title: string;
  items: IItem[];
  defaultValue?: string;
  onChangeValue: (item: IItem) => void;
}

export const SourceComparison = observer(({ title, items, defaultValue, onChangeValue }: ISourceComparison) => {
  const [value, setValue] = useState(defaultValue || items[0]?.value);

  return (
    <div className={clsx(classes.blockHr)}>
      <Typography weight={600} size="AuthContent">
        {title}
      </Typography>
      <div className={clsx(classes.source, classes.titleList)}>
        {items.map(item => {
          return (
            <Typography
              key={item.value}
              size="authContent"
              componentProps={{
                onClick: () => {
                  onChangeValue(item);
                  setValue(item.value);
                },
                className: clsx(item.value === value && classes.defaultChecked),
              }}
            >
              {item.lable}
            </Typography>
          );
        })}
      </div>
    </div>
  );
});
