import {
  getItemsType,
  StrategyBidSaveActionProps,
  TCommonBudgetOptimizationLevel,
  TNewStrategyBids,
  MtOptimisationLevel,
} from '@plarin/inputs';
import { ManageVkTabNameEnum, formatDateToReq } from '@plarin/utils';
import { GridApi, IRowNode } from 'ag-grid-community';
import { Dictionary } from '../../types/manage-vk/types';
import { TMetric, TMetricGroup } from '../../types/metrics';

import { ADS, ManageVkNameCellsEnum, FAST_STAT, METRICS_GROUP_PATHS } from './constants';
import { parseStringToCamelCase } from './parseStatData/parseStatistics';

export const returnId = (tabName: ManageVkTabNameEnum) => {
  let id = 'accountId';
  switch (tabName) {
    case 'client_ids':
      return 'accountId';
    case 'ad_plan_ids':
      return 'adPlanId';
    case 'campaign_ids':
      return 'campaignId';
    case 'ad_ids':
      return 'adId';
  }
  return id;
};

export const getColumnsForTable = (
  columns: string[],
  dictionary: Dictionary,
  metrics: TMetricGroup[],
  fast72Checked?: boolean,
) => {
  const result: TMetric[] = [];
  const defaultColumn = 'satisticsFast';

  // Add the default column if not already present
  if (!columns.includes(defaultColumn)) {
    columns.push(defaultColumn);
  }

  const activeAdsCamelCase = parseStringToCamelCase(ADS.ACTIVE);
  const pausedAdsCamelCase = parseStringToCamelCase(ADS.PAUSED);
  const archivedAdsCamelCase = parseStringToCamelCase(ADS.ARCHIVED);

  columns.forEach(columnName => {
    const columnNameCamelCase = parseStringToCamelCase(columnName);

    if (dictionary[columnName]) {
      if (
        columnNameCamelCase !== activeAdsCamelCase &&
        columnNameCamelCase !== pausedAdsCamelCase &&
        columnNameCamelCase !== archivedAdsCamelCase
      ) {
        metrics.forEach(group => {
          group.fields.forEach(metric => {
            const metricPathCamelCase = parseStringToCamelCase(metric.path);
            if (columnNameCamelCase === metricPathCamelCase) {
              result.push(metric);
            }
            if (
              fast72Checked &&
              columnNameCamelCase === parseStringToCamelCase(FAST_STAT.SatisticsFast1) &&
              metric.path === FAST_STAT.SatisticsFast1
            ) {
              group.fields.forEach(metric => {
                if (metric.path === FAST_STAT.SatisticsFast72) {
                  result.push(metric);
                }
              });
            }
          });
        });
      } else if (columnNameCamelCase === activeAdsCamelCase) {
        result.push({
          hidden: true,
          math_allowed: true,
          math_default: 'sum',
          name: { ru: 'Кол-во объявлений', en: 'Ads count' },
          obj_types: ['account', 'banner', 'ad_plan', 'campaign'],
          path: ADS.ALL,
          position: 'right',
          round_digits: 2,
          type: 'percent',
        });
      }
    }
  });

  return result;
};

export const translateStatus = (status: string, tabName: ManageVkTabNameEnum): string => {
  switch (status) {
    case '0':
      return tabName === 'ad_ids' ? 'Остановлено' : 'Остановлена';
    case '1':
      return tabName === 'ad_ids' ? 'Активное' : 'Активная';
    case '2':
      return tabName === 'ad_ids' ? 'Архивное' : 'Архивная';
    default:
      return '';
  }
};

export const getSortBudget = (tabName?: keyof typeof ManageVkNameCellsEnum, isDaily?: boolean) => {
  if (tabName === 'adPlanName') {
    return isDaily ? 'adPlanDaily' : 'adPlanLifetime';
  }
  if (tabName === 'campaignName' || tabName === 'adName') {
    return isDaily ? 'campaignDaily' : 'campaignLifetime';
  }
  if (tabName === 'accountName') {
    return isDaily ? 'accountDaily' : 'accountLifetime';
  } else {
    return '';
  }
};

export const getSortByStatus = (status: any) => {
  if (status.includes('Активн')) {
    return 0;
  }
  if (status.includes('Остан')) {
    return 1;
  }
  if (status.includes('Архив')) {
    return 2;
  } else {
    return 3;
  }
};

export const comporatorStatus =
  (tabName: keyof typeof ManageVkNameCellsEnum) =>
  (valueA: string, valueB: string, nodeA: IRowNode, nodeB: IRowNode, isDescending: boolean) => {
    let aStatus = getSortByStatus(valueA || '');
    let bStatus = getSortByStatus(valueB || '');

    return aStatus - bStatus === 0
      ? nodeA.data[tabName].toLowerCase() > nodeB.data[tabName].toLowerCase()
        ? 1
        : -1
      : aStatus - bStatus;
  };

// for cells
export const returnCellRules = (status: string): boolean => {
  let value = false;
  if (status === 'Архивная' || status === 'Архивное') {
    value = true;
  }
  return value;
};

// приводит дату к виду "2023-04-27T00:00:00+03:00" - такого вида строку стоку мы получаем от мт-апишки для дасписания у рекламных объектов из vk ads
export const formatDateToReqString = (argDate: string | Date | undefined) =>
  argDate ? formatDateToReq(argDate) + 'T00:00:00+03:00' : null;

// данные непосредственно изменённых рекламных объектов из vk-ads, которые нужно перезаписать в сторе после ответа от апи о том, что всё успешно отредактировалось
export const getEditedStrategyItems = (
  requestData: StrategyBidSaveActionProps,
  isOptimized: boolean, // бюджет оптимизирован на уровне адпдана (кампании), либо не оптимизирован и определён отдельно для каждого campaign (групп)
): Record<
  string,
  {
    maxPrice: number | '';
    adPlanDaily: number | '';
    adPlanLifetime: number | '';
    campaignDaily: number | '';
    campaignLifetime: number | '';
    campaignStart: string | null;
    campaignStop: string | null;
  }
> => {
  const editedItems: Record<
    string,
    {
      price: number | '';
      maxPrice: number | '';
      adPlanDaily: number | '';
      adPlanLifetime: number | '';
      campaignDaily: number | '';
      campaignLifetime: number | '';
      campaignStart: string | null;
      campaignStop: string | null;
      bidAutobiddingMode: string;
    }
  > = {};

  requestData.forEach(
    item =>
      (editedItems[item.obj_id.toString()] = {
        bidAutobiddingMode: item.autobidding_mode || '',
        price: item.price || '',
        maxPrice: item.max_price || '',
        adPlanDaily: isOptimized ? item.budget_limit_day || '' : '',
        adPlanLifetime: isOptimized ? item.budget_limit || '' : '',
        campaignDaily: item.budget_limit_day || '',
        campaignLifetime: item.budget_limit || '',
        campaignStart: formatDateToReqString(item.date_start),
        campaignStop: formatDateToReqString(item.date_end),
      }),
  );

  return editedItems;
};

// данные нередактированных сиблингов (находятся в тех же адпланах или группах), которые нужно обновить
export const getStrategySiblingsNewData = (
  newBidsData: TNewStrategyBids,
  isOptimized: boolean, // бюджет оптимизирован на уровне адпдана (кампании), либо не оптимизирован и определён отдельно для каждого campaign (группы)
  parentIdName: 'adPlanId' | 'campaignId',
  parentIdsSet: Set<string>,
) => {
  const siblingsNewData: Record<
    string,
    {
      maxPrice: number | '';
      adPlanDaily: number | '';
      adPlanLifetime: number | '';
      campaignDaily: number | '';
      campaignLifetime: number | '';
    }
  > = {};

  Array.from(parentIdsSet).forEach(parentId => {
    const data = newBidsData.find(data => data[parentIdName] === parentId);

    if (data) {
      siblingsNewData[parentId] = {
        maxPrice: data.maxPrice || '',
        adPlanDaily: isOptimized ? data.limitDaily || '' : '', // если бюджеты не оптимизированы, то в adPlanDaily и adPlanLifetime всегда лежит пустая строка
        adPlanLifetime: isOptimized ? data.limitLifetime || '' : '',
        campaignDaily: data.limitDaily || '',
        campaignLifetime: data.limitLifetime || '',
      };
    }
  });

  return siblingsNewData;
};

// просто возвращает даты работы кампании, если мы имеем массив кампаний
const getUnoptimizedCampaignsSchedule = (
  siblingsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  >,
  newBidsData: TNewStrategyBids,
  campaignIdsSet: Set<string>,
) => {
  Array.from(campaignIdsSet).forEach(campaignId => {
    const data = newBidsData.find(data => data.campaignId === campaignId);

    if (data) {
      siblingsSchedule[campaignId] = {
        campaignStart: formatDateToReqString(data.campaignStart),
        campaignStop: formatDateToReqString(data.campaignStop),
      };
    }
  });
};

const getOptimizedCampaignsSchedule = (
  siblingsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  >,
  newBidsData: TNewStrategyBids,
  storeData: Record<string, any>[] | undefined,
) => {
  const campaignsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  > = {};

  // собираем данные: у какой группы какое актуальное расписание
  newBidsData.forEach(ad => {
    if (ad.campaignId && !campaignsSchedule[ad.campaignId]) {
      campaignsSchedule[ad.campaignId] = {
        campaignStart: formatDateToReqString(ad.campaignStart),
        campaignStop: formatDateToReqString(ad.campaignStop),
      };
    }
  });

  // заполняем объект для перезаписывания дат работы кампаний для сиблингов отредактированных объявлений
  storeData?.forEach(ad => {
    if (campaignsSchedule[ad.campaignId]) {
      siblingsSchedule[ad.adId] = {
        campaignStart: campaignsSchedule[ad.campaignId].campaignStart,
        campaignStop: campaignsSchedule[ad.campaignId].campaignStop,
      };
    }
  });
};

type TAdsScheduleProps = {
  commonBudgetOptimizationLevel: TCommonBudgetOptimizationLevel;
  itemsType: 'campaign' | 'banner';
  newBidsData: TNewStrategyBids;
  storeData: Record<string, any>[] | undefined;
  parentIdsSet: Set<string>;
};

// собирает объект для обновления дат работы кампаний у сиблингов отредактированных объявлений
export const getAdsCampaignSchedule = ({
  commonBudgetOptimizationLevel,
  itemsType,
  newBidsData,
  storeData,
  parentIdsSet,
}: TAdsScheduleProps) => {
  const siblingsSchedule: Record<
    string,
    {
      campaignStart: string | null;
      campaignStop: string | null;
    }
  > = {};

  if (commonBudgetOptimizationLevel === MtOptimisationLevel.adGroup && itemsType === 'banner') {
    // для кампэйнов нормально редактируется
    getUnoptimizedCampaignsSchedule(siblingsSchedule, newBidsData, parentIdsSet);
  }

  if (commonBudgetOptimizationLevel === MtOptimisationLevel.adPlan && itemsType === 'banner') {
    getOptimizedCampaignsSchedule(siblingsSchedule, newBidsData, storeData);
  }

  return siblingsSchedule;
};

export const getStrategySiblingsData = (
  parentIdName: 'adPlanId' | 'campaignId',
  requestData: StrategyBidSaveActionProps,
  gridApi: GridApi,
) => {
  // массив с id объектов, которые непосредственно редактируются. Это будут либо adPlanId, либо campaignId, либо adId (в зависимости от itemsType)
  const requestIds = requestData.map(item => item.obj_id.toString());

  // массив rowNodes, найденных по id. Будут соответстовать одному из трёх типов рекламных сущностей: adPlan, campaign, banner
  const rowNodes = requestIds.map(id => gridApi.getRowNode(id));

  // если мы редактируем группы или объявления, то в этой переменной соберутся все родительские adPlanId. Если редактируем адплан, то тут будут только редактируемые адпланы
  const parentIdsOfEditedItems: Array<string> = rowNodes
    .map(node => node?.data[parentIdName]) // id могут дублироваться при редактированнии групп или объявлений
    .filter((value, index, self) => value !== undefined && self.indexOf(value) === index); // убрали дублирующиеся id  и undefined (которых быть не должно, но теоретически могут быть)

  // В эту переменную мы записываем данные всех рекламных объектов, имеющих в качестве родителя один из id , указанныв в parentIdsOfEditedItems
  // Если мы редактируем ad-groups или banners, то сюда могут попасть другие строки из таблицы, имеющие тот же родительский id, что и редактируемые строки.
  // Если редактируем адпланы, то сюда просто попадут данные этих адпланов
  const siblingsDataByParentId: any[] = [];

  // создаём массив со всеми объектами, в которых есть один из parentId
  rowNodes.length &&
    parentIdsOfEditedItems.forEach(parentId => {
      gridApi.forEachNodeAfterFilter(
        node => node.data[parentIdName] === parentId && siblingsDataByParentId.push(node.data),
      );
    });

  return {
    keysOfSiblingsToEdit: siblingsDataByParentId.map(item => item.key),
    parentsIds: new Set(parentIdsOfEditedItems),
  };
};
