import { RedoIcon, LoaderIcon, Typography } from '@plarin/design';
import { Input, InputPhone2, rusNumberIsValid, ModalActionNamesEnum, ArrowTooltip } from '@plarin/inputs';
import { TypeInput, useInput, maskedNumber, NotificationTitle } from '@plarin/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDataStore } from '../../../../components/app-data-provider';
import { STATUS, ymDisableClass } from '../../../../utils/constants';
import classes from './style.module.scss';

const ChangeNumberContentStart = observer(() => {
  const {
    profileStore: {
      data,
      setModalAction,
      setContentStep,
      changeOrAddPhone,
      setNewPhone,
      closeModal,
      addNotification,
      globalError,
      setGlobalError,
    },
  } = useAppDataStore();

  const [phone, setPhone] = useState(data.phone || '');
  const [checkLength, setCheckLength] = useState((data.phone?.length || 0) >= 11);
  const [hasError, setHasError] = useState(false);

  const getAction = useCallback(() => {
    if (!rusNumberIsValid(phone)) {
      !checkLength && setCheckLength(true);
      return null;
    }
    if (phone === data.phone || '+' + phone === data.phone) {
      return (
        closeModal(),
        addNotification({
          type: STATUS.SUCCESS,
          title: NotificationTitle.PHONE_NOT_CHANGED,
        })
      );
    }
    return rusNumberIsValid(phone) && setNewPhone(phone) && changeOrAddPhone(phone);
  }, [phone, data.phone, changeOrAddPhone, setNewPhone, checkLength]);

  useEffect(() => {
    if (phone.length >= 11 && !checkLength) {
      setCheckLength(true);
    }
  }, [phone, checkLength, setCheckLength]);

  useEffect(() => {
    setGlobalError('');
  }, [phone, setGlobalError]);

  useEffect(() => {
    !globalError &&
      setModalAction({
        action: getAction,
        actionTitle: ModalActionNamesEnum.Save,
      });
  }, [changeOrAddPhone, phone, setContentStep, setModalAction, setNewPhone, getAction]);

  return (
    <>
      <InputPhone2
        value={phone}
        onChange={setPhone}
        checkLength={checkLength}
        error={globalError}
        dropdownClass={classes.countryList}
        inputClass={ymDisableClass}
        setHasError={setHasError}
      />
      {hasError && <div style={{ height: '24px' }} />}
    </>
  );
});

const ChangeNumberContentFinish = observer(() => {
  const {
    profileStore: {
      setModalTitle,
      isLoading,
      confirmCode,
      sendCode,
      newPhone,
      setDisabledActions,
      setDisabledExit,
      closeModal,
      globalError,
      setGlobalError,
    },
  } = useAppDataStore();

  const smsCode = useInput({
    initialValue: '',
    type: TypeInput.smsCode,
  });
  const { setError, setTextError, setWasChanged, setHint, checkValue: smsCodeCheckValue, ...smsCodeInput } = smsCode;

  const [timer, setTimer] = useState(40);
  const intervalRef = useRef<any>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer(sec => sec - 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  }, [timer]);

  const cancelInterval = () => {
    if (timer < 1) {
      clearInterval(intervalRef.current);
    }
  };
  cancelInterval();

  useEffect(() => {
    setModalTitle('Подтвердить номер телефона');
    setDisabledActions(true);
    setDisabledExit(true);
    return () => {
      setDisabledActions(false);
      setDisabledExit(false);
    };
  }, [setDisabledActions, setDisabledExit, setModalTitle]);

  const sendNewCode = useCallback(() => {
    newPhone && sendCode(newPhone);
    setTimer(40);
    smsCode.onChange('');
    setGlobalError('');
  }, [newPhone, sendCode]);

  useEffect(() => {
    smsCodeCheckValue();
  }, [smsCode, globalError]);

  useEffect(() => {
    smsCode.value.length === 6 && confirmCode(smsCode.value);
  }, [smsCode.value]);

  useEffect(() => {
    globalError && setTextError && setTextError(globalError);
    !globalError && setTextError && setTextError('');
  }, [smsCode.value, globalError, setTextError]);

  useEffect(() => {
    setGlobalError('');
  }, [smsCode.value, setGlobalError]);

  return (
    <div className={classes.phoneFinish}>
      <div className={classes.message}>
        <Typography size="main">Мы отправили 6–значный код на номер {maskedNumber(newPhone)}</Typography>
      </div>

      <Input
        {...smsCodeInput}
        maxLength={6}
        label="Введите код из СМС"
        disabled={isLoading}
        fullWidth
        type="number"
        size={48}
        disableMinHeight={true}
      />
      <div className={classes.retryCode}>
        {isLoading ? (
          <div className={classes.iconRetryCode}>
            <div className={classes.statusContainer}>
              <LoaderIcon className={clsx(classes.icon, 'rotate_circle_infinite', classes.rotatingLoader)} />
              <Typography size="IntegrationLinks" color="TextSecondary">
                Проверяем код
              </Typography>
            </div>
          </div>
        ) : (
          <>
            {timer > 0 ? (
              <Typography size="IntegrationChannelMessage" color="TextSecondary">
                Вы можете получить код повторно через {timer} секунд
              </Typography>
            ) : (
              <div className={classes.iconRetryCode} onClick={sendNewCode}>
                <div className={classes.statusContainer}>
                  <RedoIcon className={classes.icon} color="primary60" />
                  <Typography size="IntegrationLinks" color="LinkPrimary" weight={600}>
                    Отправить новый код
                  </Typography>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.cancelCode} onClick={closeModal}>
        <Typography size="Main" color="LinkPrimary" weight={700}>
          Отменить
        </Typography>
      </div>
      <div className={classes.helper}>
        <ArrowTooltip
          content={
            <div className={classes.textTooltip}>
              Проблемы могут быть на стороне вашего сотового <br /> оператора. Обратитесь в его службу поддержки
            </div>
          }
          component="span"
        >
          <Typography size="Main" color="LinkPrimary">
            Почему не пришло СМС?
          </Typography>
        </ArrowTooltip>
      </div>
    </div>
  );
});

export const ChangeNumberContent = observer(() => {
  const {
    profileStore: { contentStep, setContentStep },
  } = useAppDataStore();

  useEffect(() => {
    setContentStep(1);
  }, [setContentStep]);

  return (
    <>
      {contentStep === 1 && <ChangeNumberContentStart />}
      {contentStep === 2 && <ChangeNumberContentFinish />}
    </>
  );
});
